'use strict';
import Base  from './HomeBase';
import Render  from './HomeRender';
import { connect } from 'react-redux';
class Home extends Base {
    render() {
        return Render.call(this, this.props, this.state);
    }
}

// export default Home
export default Home = connect((state)=>{
    return {user:state.user || {}}
})(Home);
