import React from 'react';
import * as cols from './columns/index';
import TableTemplate from './TableTemplate';

const Blueprints = ({ isLoading, rows }) => {
    const columns = [
        cols.completedCol,
        cols.sentToClientCol,
        cols.clientCol,
        cols.baCol,
        cols.setToBuyDateCol,
        cols.datePromisedCol,
        cols.daysUntilDeliveryCol
    ];

    const sortColumn = columns.find(x => x.path === 'days_till_delivery');

    return (
        <TableTemplate
            isLoading={isLoading}
            title="Blueprints yet to Be Issued"
            rows={rows}
            cols={columns}
            defaultSort={{ column: sortColumn, direction: 'asc' }}
        />
    );
};

export default Blueprints;
