import React from 'react';
import * as cols from './columns/index';
import TableTemplate from './TableTemplate';

const YetToSettle = ({ isLoading ,rows }) => {
    const columns = [
        cols.clientCol,
        cols.propertyCol,
        cols.baCol,
        cols.settleDateCol,
        cols.daysToSettleCol
    ];

    const sortColumn = columns.find(x => x.path === 'days_to_settle');

    return (
        <TableTemplate
            isLoading={isLoading}
            title="Yet to Settle"
            rows={rows}
            cols={columns}
            defaultSort={{ column: sortColumn, direction: 'asc' }}
            showWarnings
        />
    );
};

export default YetToSettle;
