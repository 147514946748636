import React, { Component } from "react";
import _ from "lodash";
import classNames from 'classnames';
import './EndlessTable.css';

// Modules
import api from '../../common/api';

// Required to toggle FA icons
// (https://stackoverflow.com/questions/47707306/toggling-font-awesome-5-icon-with-react)
const renderSortArrow = direction => (
	<span>
		<span style={{ display: direction === "asc" ? 'inline' : 'none' }}>
			<i className="fa fa-caret-up endless-table__sort-icon" />
		</span>
		<span style={{ display: direction === "desc" ? 'inline' : 'none' }}>
			<i className="fa fa-caret-down endless-table__sort-icon" />
		</span>	
	</span>
);

// Transform all rows to object format: { value: ... [, render: fn ] }
const transformToObjects = rows =>
	rows.map(row =>
		_.mapValues(row, cell => {
			if (_.has(cell, "value")) {
				return cell;
			}
			return { value: cell };
		})
	);

//Method to call the API for count number of properties
const countOfProperties = (engagement, cb) => {
	const query = encodeURIComponent(JSON.stringify({ engagement}));
	api.get('/api/property/numberOfPropertiesAttached?filter=' + query).then(propertiesCount => {
		cb(propertiesCount.count);		
	})
}


// Use a renderer derived from: [row renderer] > [col renderer] > [cell contents]
const renderCell = (row, col) => {	
	const cell = row[col.path];
	if (cell === undefined) {
		throw Error(`Cannot read column path '${col.path}' within row '${JSON.stringify(row)}'`);
	}
	
	const renderer = cell.render || col.rowRender || null;
	
	return renderer ? renderer(cell.value, cell.props) : cell.value;
};

// Sorting mechanism. Uses column sorts if provided, otherwise use a simple sort
const rowsSort = (rows, column, direction) => {
	const { path, sort } = column;
	
	// Use provided sort function otherwise default to generic sort
	const genericSort = row => row[path].value;
	const sortFn = sort || genericSort;
	
	// Remove nulls to attach to end
	const nullRows = _.remove(rows, row => row[path].value === null);
	
	return _(rows)
		.orderBy(sortFn, direction)
		.concat(nullRows)
		.value();
};

// Transform and optionally sort rows
const transformRows = (rows, defaultSort) => {
	let newRows = transformToObjects(rows);
	if (defaultSort) {
		const { column, direction } = defaultSort;

		newRows = rowsSort(newRows, column, direction);
	}
	
	return newRows;
};

export default class EndlessTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sortBy: {
				column: "",
				direction: "asc"
			},
			rows: transformRows(props.rows, props.defaultSort)
		};

		this.sort = this.sort.bind(this);
	};

	sort(col, dir) {
		this.setState(({ sortBy, rows }) => {
			let direction = dir;

			// Click the same column
			if (sortBy.column === col.path) {
				// Toggle direction
				direction = sortBy.direction === 'asc' ? 'desc' : 'asc';
			}
			
			return {
				sortBy: {
					column: col.path,
					direction
				},
				rows: rowsSort(rows, col, direction)
			};
		});
	}

	componentWillReceiveProps({ rows, defaultSort }) {
		const newRows = transformRows(rows, defaultSort);

		this.setState({ rows: newRows });
	}

	render() {
		const { columns, showWarnings = false } = this.props;
		const { sortBy, rows } = this.state;

		return (
			<div className="endless-table">
				<table>
					<thead>
						<tr>
							{columns.map(col => (
								<th
									key={col.path}
									onClick={_.partial(this.sort, col, 'asc')}
								>
									<div style={{ position: "relative" }}>
										{/* Use render function if it is provided */}
										{col.headingRender
											? col.headingRender(col.heading)
											: col.heading}

										{/* Render sort arrows */}
										{/* {col.path === sortBy.column && (
											renderSortArrow(sortBy.direction)
										)} */}
									</div>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
					{rows.map((row, i) => (
						<tr
							key={i}
							className={classNames({
								"endless-table__row--warning": showWarnings && row.showWarning.value
							})}
						>
							{columns.map(col => (
								<td key={col.path}>{renderCell(row, col)}</td>
							))}
						</tr>
					))}
					</tbody>
				</table>
			</div>
		);
	}
}
