import React, { Component } from "react";
import ReactModal from "react-modal";
import validator from 'validator';

// Components
import { FormInput, TypeRadioButtons } from "../FormComponents";

class EditClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      engagementSource: "",
      firstName: "",
      lastName: "",
      middleName: "",
      type: "",
      email:""
    };
  }

  componentDidMount() {
    const {
      engagementSource,
      firstName,
      lastName,
      middleName,
      type,
      email
    } = this.props;

    this.setState({
      engagementSource,
      firstName,
      lastName,
      middleName,
      type,
      email
    });
  }

  onUpdateClick = () => {
    const state = this.state;
    if (!state.firstName || !state.lastName || !state.email) {
      return alert("Please fill all required fields with *.");
    }
    if(!validator.isEmail(state.email)){
      return alert("Invalid email format.");
    }
    const fields = {
      engagement_source: state.engagementSource,
      first_name: state.firstName,
      last_name: state.lastName,
      middle_name: state.middleName,
      type: state.type,
      email : state.email
    };
    this.props.onUpdateClick(fields);
  };

  onInputChange = ev => {
    const { name, value } = ev.target;
    this.setState({ [name]: value });
  };

  render() {
    const customStyles = {
      content: {
        backgroundColor: "white",
        width: "50%",
        left: "30%",
        padding: 0,
        marginTop: 75,
        display: "block",
        width: "40%",
        padding: 20,
        opacity: 1,
        borderColor: "#f5f5f5"
      },
      overlay: {
        zIndex: 2
      }
    };

    const {
      engagementSource,
      firstName,
      isOpen,
      lastName,
      middleName,
      onCloseClick,
      type,
      email
    } = this.props;

    return (
      <ReactModal isOpen={isOpen} style={customStyles}>
        <div className="col-xs-12">
          <button
            className="close pull-right"
            style={{ fontSize: 20 }}
            onClick={onCloseClick}
          >
            &times;
          </button>
        </div>

        <div className="col-xs-12 p-t-8">
          <div className="content">
            <FormInput
              label={
                <span>
                  First Name<star>*</star>
                </span>
              }
              name="firstName"
              onChange={this.onInputChange}
              required
              defaultValue={firstName}
            />

            <FormInput
              label="Middle Name"
              name="middleName"
              defaultValue={middleName || ""}
              onChange={this.onInputChange}
            />

            <FormInput
              label={
                <span>
                  Last Name<star>*</star>
                </span>
              }
              name="lastName"
              onChange={this.onInputChange}
              required
              defaultValue={lastName}
            />
             <FormInput
              label={
                <span>
                  Keap Email Address<star>*</star>
                </span>
              }
              name="email"
              onChange={this.onInputChange}
              required
              defaultValue={email}
              type="email"
            />
            
            <TypeRadioButtons
              checked={type}
              name="type"
              onChange={this.onInputChange}
            />
          </div>

          {/* Form buttons */}
          <div className="col-xs-12 text-center">
            <div className="col-xs-6">
              <button
                className="sweet-confirm btn btn-info btn-fill pull-right"
                onClick={this.onUpdateClick}
              >
                Save
              </button>
            </div>
            <div className="col-xs-5 col-xs-offset-1">
              <button
                className="sweet-cancel btn btn-danger btn-fill pull-left"
                onClick={onCloseClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default EditClient;
