'use strict';
import React from 'react';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import CSVReader from 'react-csv-reader'
import formatNumber from 'format-number';
// TODO this is causing a warning
// Accessing createClass via the main React package is deprecated, and will be removed in React v16.0...
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Modules
import Buy_Price from '../../common/priceList';
import COUNTRY_STATES from '../../common/countryStates';
import { SalesPivotTable, ClientPivotTable } from './pivotTable';
import Brokers from '../../common/financeBrokers';
import Spinner from '../../common/spinner';
import PanelHeader from '../panelHeader/PanelHeader';
import EndlessTable from '../endlessTable/EndlessTable';

export default function(){
    return MapViewComponentRender.call(this);
}

var MapViewComponentRender = function(){
    return (
		<div className="col-md-12">
		    <div className="card ">
		        <div className="header container-fluid">
		        	<div className="col-md-6">
	            		<h4 className="title" style={{ fontFamily: '"Poppins", sans-serif' }}>{this.state.clientPivot ? "Clients By State" : this.state.selectPhase.length > 1 ? `Multiple phases By State` : this.state.selectPhase.length && this.state.selectPhase.length == 1 ? `${this.state.selectPhase[0]} By State` : ''}</h4>
	            		<p className="category">Filter number of properties by phase</p>
		        	</div>
		        	<div className="col-md-6">
			        	{ !this.state.clientPivot &&
				        	<div className="col-sm-4 col-xs-12 right" style={{width : '120px'}}>
								<input name="archived" type="checkbox" className="checkbox-react-dark m-t-10" checked={this.state.archived} onChange={this.handleInputChange} />
								<label className="control-label m-t-10">Archived</label>
							</div>
						}
						{ !this.state.clientPivot &&
							<div className="col-sm-4 col-xs-12 right" style={{width : '135px'}}>
								<input name="unarchived" type="checkbox" className="checkbox-react-dark m-t-10" checked={this.state.unarchived} onChange={this.handleInputChange} />
								<label className="control-label m-t-10">Unarchived</label>
							</div>
						}
		        	</div>
		        </div>
		    	{this.state.spinner ? <div className="spinner-pos z-4"><Spinner /></div> : ''}
		        <div className="content">
		            <div className="row">
		                <div className="col-md-5">
		                    <div className="table-responsive">
		                        <table className="table">
								<tbody>
									<tr>
										<td />
										<td>
											<b>State</b>
										</td>
										<td className="text-right">
											<b>{this.state.clientPivot ? "No. of Clients" : "No. of Properties"}</b>
										</td>
									</tr>
									{this.state.clientPivot
										? renderCells.call(this, this.state.clientList)
										: renderCells.call(this, this.state.stateList)}
								</tbody>
		                        </table>
		                    </div>
		                </div>
		                { !this.state.clientPivot ? 
			                <div className="col-md-6 col-md-offset-1">
			                	<div className="row">
				                	<div className="col-md-6">
				                		<label className="col-md-3 date-field">Phase</label>
				                		<div className="col-md-9 p-r-0">
				                			<Select
						                      name="selectPhase"
						                      placeholder="Select Phase"
						                      loadOptions={true}
						                      value={this.state.selectPhase}
						                      options={this.state.phaseList}
						                      multi={true}
						                      joinValues={true}
						                      onChange={this.handleSelectPhase.bind(this)}
						                    />
				                		</div>
				                	</div>
				                	<div className="col-md-6">
				                		<label className="col-md-3 date-field">Price</label>
				                		<div className="col-md-9 p-r-0">
						                	<select name="priceRange" className="form-control" value={this.state.priceRange} onChange={this.handleInputChange} >
						                		<option value="">All</option>
						                		{ Buy_Price.map((key, index)=>{ 
			                                        return (
			                                            <option key={index} value={key}>{key}</option>
			                                        ) 
			                                    })}
						                	</select>
				                		</div>
				                	</div>
			                	</div>
			                	<div className="row m-t-15">
			                		<div className="col-md-6">
			                			<label className="col-md-3 date-field">From</label>
										<div className="col-md-9 p-r-0">
											<DatePicker
												selected={this.state.fromDate}
												onChange={(moment) => this.handleDateChange(moment, 'fromDate')}
												dateFormat="DD/MM/YYYY"
												className="form-control"
												placeholderText="DD/MM/YYYY"
												scrollableYearDropdown
												yearDropdownItemNumber={15}
											/>
			                			</div>
			                		</div>
			                		<div className="col-md-6">
										<label className="col-md-3 date-field">To</label>
										<div className="col-md-9 p-r-0">
												<DatePicker
													selected={this.state.toDate}
													onChange={(moment) => this.handleDateChange(moment, 'toDate')}
													dateFormat="DD/MM/YYYY"
													className="form-control"
													placeholderText="DD/MM/YYYY"
													scrollableYearDropdown
													yearDropdownItemNumber={15}
												/>
			                			</div>
			                		</div>
			                	</div>
			                	<div className="row m-t-15">
			                		<div className="col-md-6">
			                			<label className="col-md-3 date-field">Buyer</label>
			                			<div className="col-md-9 p-r-0">
				                			<select name="buyer" className="form-control" value={this.state.buyer} onChange={this.handleInputChange} >
				                				<option value="">All</option>
				                				{ (this.props.buyers && this.props.buyers.length) ? this.props.buyers.map((buyer, index)=> {
													return (
														<option key={index} value={buyer._id} >{buyer.nickname || buyer.username.split('@')[0]}</option>
													)
												}) : ''		
												}
				                			</select>
			                			</div>
			                		</div>
			                		<div className="col-md-6">
			                			<label className="col-md-3 date-field">State</label>
			                			<div className="col-md-9 p-r-0">
				                			<select name="countryState" className="form-control" value={this.state.countryState} onChange={this.handleInputChange} >
				                				<option value="">All</option>
				                				{ (COUNTRY_STATES && COUNTRY_STATES.length) ? COUNTRY_STATES.map((state, index)=> {
													return (
														<option key={index} value={state} >{state}</option>
													)
												}) : ''		
												}
				                			</select>
			                			</div>
			                		</div>
			                	</div>
			                	<div className="row m-t-15">
			                		<div className="col-md-6 pull-right">
				                		<button className="sweet-confirm btn pull-right" onClick={this.resetFilter} >Reset</button>
				                		<button className="sweet-confirm btn btn-info btn-fill pull-right m-r-5" onClick={this.filterList} >Filter</button>
			                		</div>
			                	</div>
			                </div> :
			                <div className="col-md-6 col-md-offset-1">
			                	<div className="row">
			                		<div className="col-md-6">
			                			<label className="col-md-3 date-field">Growth</label>
			                			<div className="col-md-9 p-r-0">
			                				<input name="growth" className="form-control" placeholder="Growth" type="text" value={this.state.grwoth} onChange={this.handleInputChange} />
			                			</div>
			                		</div>
			                		<div className="col-md-6">
			                			<label className="col-md-3 date-field">Yield</label>
			                			<div className="col-md-9 p-r-0">
			                				<input name="yield" className="form-control" placeholder="Yield" type="text" value={this.state.yield} onChange={this.handleInputChange} />
			                			</div>
			                		</div>
			                	</div>
			                	<div className="row m-t-15">
			                		<div className="col-md-6">
				                		<label className="col-md-3 date-field">Finance</label>
				                		<div className="col-md-9 p-r-0">
						                	<select name="finance" className="form-control" value={this.state.finance} onChange={this.handleInputChange} >
						                		<option value="">All</option>
						                		{ Brokers.length ? Brokers.map((broker, index) =>{
						                			return (
						                				<option key={index} value={broker}>{broker}</option>
					                				)
						                		}) : ''}
						                	</select>
				                		</div>
				                	</div>
				                	<div className="col-md-6">
				                		<label className="col-md-3 date-field">Status</label>
				                		<div className="col-md-9 p-r-0">
						                	<select name="status" className="form-control" value={this.state.status} onChange={this.handleInputChange} >
						                		<option value="">All</option>
						                		<option value="Hold">Hold</option>
			                                    <option value="Engaged">Engaged</option>
			                                    <option value="Incubation">Incubation</option>
			                                    <option value="GoodToGo">Good To Go</option>
						                	</select>
				                		</div>
				                	</div>
			                	</div>
			                	<div className="row m-t-15">
			                		<div className="col-md-6 pull-right">
				                		<button className="sweet-confirm btn pull-right" onClick={this.resetFilter} >Reset</button>
				                		<button className="sweet-confirm btn btn-info btn-fill pull-right m-r-5" onClick={this.filterList} >Filter</button>
			                		</div>
			                	</div>
			                </div>
		                }
					</div>
					<div className="row">
						<div className="col-md-12">
							{/* <CSVLink data={this.state.purchasedPropertyCsvData}
								filename={"purchased-property.csv"}
								className="sweet-confirm btn btn-info btn-fill"
								target="_blank">
								Download CSV
							</CSVLink> */}
						</div>
					</div>
					<div className="row" style={{marginTop: "15px"}}>
						<div className="col-md-12">
							<div className="col-md-6">
								<CSVReader
									cssClass="csv-input"
									label="Select the CSV to update the purchases"
									onFileLoaded={this.handleCsvUploading}
									onError={this.handleCsvUploadingError}
									inputId="ObiWan"
								/>
							</div>
							<div className="col-md-6">
								<CSVReader
									cssClass="uploadOfEngagementCsv"
									label="Select CSV to update the engagement"
									onFileLoaded={this.handleEngagementCsvUploading}
									onError={this.handleDarkSideForce}
									inputId="ObiWan"
								/>
							</div>
						</div>
					</div>
		            <div className="row m-t-30">
		            	<div className="col-md-12">
		                    <div id="australiaMap" style={{height: '500px'}}></div>
		            	</div>
		            </div>
		        </div>
			</div>
			<div className="col-lg-6">
				<div className="content-panel">
					<PanelHeader title="PWP Purchases">
						<FinancialYear {...this.state.financialYear} />
					</PanelHeader>
					<EndlessTable rows={this.state.pwpPurchases} columns={[
						{
							path: 'total',
							heading: 'YTD Purchases'
						},
						{
							path: 'pwpCount',
							heading: 'PWP Purchases',
							rowRender: this.pwpPurchasesPercentageRender
						},
						{
							path: 'baCount',
							heading: 'BA Purchases',
							rowRender: this.pwpPurchasesPercentageRender
						}
					]} />
				</div>
			</div>

			<div className="col-lg-6">
				<div className="content-panel">
					<PanelHeader title="Regional Mapping">
						<FinancialYear {...this.state.financialYear} />
					</PanelHeader>
					<EndlessTable rows={this.state.regionalMapping} columns={[
						{
							path: 'sua',
							heading: 'Region'
						},
						{
							path: 'count',
							heading: 'Purchases',
							headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
							rowRender: value => <div style={{ textAlign: "right" }}>{value}</div>
						},
						{
							path: 'percentage',
							heading: '%',
							headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
							rowRender: value => <div style={{ textAlign: "right" }}>{formatNumber({ suffix: '%', round: 2 })(value)}</div>
						}
					]} />
				</div>
			</div>
		</div>    	
    )
};

function renderCells(list) {
    const newObject = Object.assign({}, list);

    if (!Object.keys(newObject).length) {
        return (
            <tr>
                <td colSpan={3}>
                    {this.state.clientPivot ? "No Clients" : "No Properties"}
                </td>
            </tr>
        );
    }

    return Object.keys(newObject).map((stateKey, i) => (
        <tr key={i}>
            <td>
                <div className="flag">
                    <i
                        className="ti-more-alt cursor-position"
                        data-toggle="modal"
                        onClick={this.openPivotTable.bind(
                            this,
                            newObject[stateKey],
                            i,
                            stateKey
                        )}
                        aria-hidden="true"
                        data-target={"#pivotTable" + stateKey}
                    />
                </div>
                {this.state.clientPivot
                    ? ClientPivotTable.call(this, this.state.clientPivotTable)
                    : SalesPivotTable.call(this, this.state.statePivotTable)}
            </td>
            <td>{stateKey}</td>
            <td className="text-right">{newObject[stateKey].count}</td>
        </tr>
    ));
}

function FinancialYear({ start, end }) {
	if (!start || !end) return null;
  
	return (
		<span
			title={`${start.format("D MMMM YYYY")} - ${end.format("D MMMM YYYY")}`}
		>
			FY{end.format("YY")}
		</span>
	);
}  
