import { connect } from "react-redux";
import "./Reporting.css";

// Modules
import Render from "./ReportingRender";
import Base from "./ReportingBase";

// Components
import withNotifications from "../withNotifications";

class Reporting extends Base {
  render() {
    return Render.call(this, this.props, this.state);
  }
}

const mapStateToProps = state => ({ buyers: state.buyers || [] });

export default connect(mapStateToProps)(withNotifications(Reporting));
