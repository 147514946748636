import { LOGIN, FILTER, PRICEDETAIL, SOCKETCONNECTION, PAGINATION, BUYERS, PURCHASES } from '../actions/index'

function statechange(state={}, action={}) {
   	switch (action.type) {
	    case 'LOGIN':
	    	return Object.assign({},state, {user : action.result});
	    	break;
    	case 'FILTER':
	    	return Object.assign({},state, {filter : action.result});
	    	break;
    	case 'PRICEDETAIL':
	    	return Object.assign({},state, {priceDetail : action.result});
	    	break;
    	case 'SOCKETCONNECTION':
	    	return Object.assign({},state, {socket : action.result});
	    	break;
    	case 'PAGINATION':
	    	return Object.assign({},state, {pagination : action.result});
	    	break;
    	case 'BUYERS':
	    	return Object.assign({},state, {buyers : action.result});
			break;
		case 'USERS':
			return Object.assign({},state, {users : action.result});
			break;
    	case 'PURCHASES':
	    	return Object.assign({},state, {purchases : action.result});
	    	break;
    	case 'BUSINESSDAYS':
	    	return Object.assign({},state, {businessDays : action.result});
	    	break;
	    default: 
	    	return state;
	}
}

export default statechange;
