import moment from "moment";

// Modules
import { getClientFullName, labelCreator } from "../../common/client-helpers";
import { getOptionalDateString } from "../../common/date-helpers";

function parseDate(value) {
  const date = moment(value, moment.ISO_8601).utc(false);
  return date.isValid() ? date : null;
}

export function mapResponseToEngagement(res) {
  // Assigning [key = values] after declaration allows us to step through each
  // change with a debugger. This cannot be done if we assign [key = values]
  // inline (e.g. { prop: value, ... })
  const engagement = {};

  engagement.client = res.client || {};
  engagement.property = res.property || null;

  engagement.conditions =
    res.conditions && res.conditions.length
      ? res.conditions.map(condition => ({
          condition_date: moment(condition.condition_date, moment.ISO_8601).utc(false),
          condition: condition.condition,
          satisfied: condition.satisfied
        }))
      : [{ condition_date: "", condition: "", satisfied: "" }];

  // Text
  engagement.actualRent = res.actualRent || "";
  engagement.baId = res.business_analyst._id || "";
  engagement.budget = res.budget || "";
  engagement.comment = res.comment || "";
  engagement.contractValue = res.contract_value || "";
  engagement.conveyancer = res.conveyancer || "";
  engagement.engagementSource = res.engagement_source || "";
  engagement.reason = res.reason || "";
  engagement.exactBudget = res.exact_budget || "";
  engagement.fee = res.fee || "";
  engagement.financeBroker = res.finance_broker || "";
  engagement.growth = res.growth || "";
  engagement.incubationType = res.incubation_type || "";
  engagement.numberOfPeopleHelped = res.number_of_people_helped || "";
  engagement.propertyManager = res.property_manager || "";
  engagement.realEstateAgency = res.real_estate_agency || "";
  engagement.realEstateAgent = res.real_estate_agent || "";
  engagement.rentalEstimate = res.rental_estimate || "";
  engagement.service = res.service || "";
  engagement.state = res.state || "";
  engagement.status = res.status || "";
  engagement.yield = res.yield || "";

  // Booleans
  engagement.blueprintCompleted = res.blueprint_completed || false;
  engagement.blueprintSentToClient = res.sent_to_client || false;
  engagement.excludedFromWatermark = res.excluded_from_watermark || false;

  // Dates
  engagement.financeSatisfied = res.finance_satisfied; 
  engagement.bpSatisfied = res.bp_satisfied; 
  engagement.blueprintCompletedDate = parseDate(res.blueprint_completed_date);
  engagement.blueprintSentToClientDate = parseDate(res.sent_to_client_date);
  engagement.bpDate = parseDate(res.bp_date);
  engagement.contractDate = parseDate(res.contract_date);
  engagement.dateAvailableForRent = parseDate(res.dateAvailableForRent);
  engagement.datePromised = parseDate(res.date_promised);
  engagement.engageDate = parseDate(res.engage_date);
  engagement.financeDate = parseDate(res.finance_date);
  engagement.g2gDate = parseDate(res.G2G_date);
  engagement.initialDate = parseDate(res.initial_date);
  engagement.settleDate = parseDate(res.settle_date);
  engagement.setToBuyDate = parseDate(res.set_to_buy_date);
  engagement.tenantedDate = parseDate(res.tenanted_date);

  return engagement;
}

export function mapEngagementToRequest(engagement) {
  // Assigning [key = values] after declaration allows us to step through each
  // change with a debugger. This cannot be done if we assign [key = values]
  // inline (e.g. { prop: value, ... })
  const request = {};

  request.labelForScrapper = labelCreator(
    getClientFullName(engagement.client),
    engagement.budget,
    engagement.g2gDate && engagement.g2gDate
  );

  request.conditions = _(engagement.conditions)
    .map(condition => ({
      condition_date: getOptionalDateString(condition.condition_date),
      condition: condition.condition,
      satisfied: condition.satisfied
    }))
    .compact()
    .value();

  // Text
  request.actualRent = engagement.actualRent;
  request.budget = engagement.budget;
  request.business_analyst = engagement.baId;
  request.comment = engagement.comment;
  request.contract_value = engagement.contractValue;
  request.conveyancer = engagement.conveyancer;
  request.engagement_source = engagement.engagementSource;
  request.reason = engagement.excludedFromWatermark ? engagement.reason : "";
  request.exact_budget = engagement.exactBudget;
  request.fee = engagement.fee;
  request.finance_broker = engagement.financeBroker;
  request.growth = engagement.growth || "N/A";
  request.incubation_type = engagement.incubationType;
  request.number_of_people_helped = engagement.numberOfPeopleHelped;
  request.property_manager = engagement.propertyManager;
  request.real_estate_agency = engagement.realEstateAgency;
  request.real_estate_agent = engagement.realEstateAgent;
  request.rental_estimate = engagement.rentalEstimate;
  request.service = engagement.service;
  request.state = engagement.state;
  request.status = engagement.status;
  request.yield = engagement.yield || "N/A";

  // Booleans
  request.blueprint_completed = engagement.blueprintCompleted;
  request.sent_to_client = engagement.blueprintSentToClient;
  request.excluded_from_watermark = engagement.excludedFromWatermark;

  // Dates
  request.finance_satisfied = engagement.financeSatisfied;  
  request.bp_satisfied = engagement.bpSatisfied;
  request.blueprint_completed_date = getOptionalDateString(
    engagement.blueprintCompletedDate
  );
  request.bp_date = getOptionalDateString(engagement.bpDate);
  request.contract_date = getOptionalDateString(engagement.contractDate);
  request.date_promised = getOptionalDateString(engagement.datePromised);
  request.dateAvailableForRent = getOptionalDateString(
    engagement.dateAvailableForRent
  );
  request.engage_date = getOptionalDateString(engagement.engageDate);
  request.finance_date = getOptionalDateString(engagement.financeDate);
  request.G2G_date = getOptionalDateString(engagement.g2gDate);
  request.initial_date = getOptionalDateString(engagement.initialDate);
  request.sent_to_client_date = getOptionalDateString(
    engagement.blueprintSentToClientDate
  );
  request.set_to_buy_date = getOptionalDateString(engagement.setToBuyDate);
  request.settle_date = getOptionalDateString(engagement.settleDate);
  request.tenanted_date = getOptionalDateString(engagement.tenantedDate);

  return request;
}

// The below validation code doesn't work

/*
// Code from EditClientPurchases
// Validate dates
if (!checkDateValidation(engagement)) {
  const notifyData = {
    title: "Date Validation Error",
    message: "Date format should be DD/MM/YYYY or DD-MMM-YYYY."
  };

  return this.showNotification("error", notifyData);
}

// TODO Check that this correctly validates the date fields
export function checkDateValidation(engagement) {
  const dateIsValid = date => date === null || date.isValid();

  const conditionsAreValid =
    engagement.conditions.length &&
    engagement.conditions.every(x => dateIsValid(x.condition_date));

  return (
    dateIsValid(engagement.bpDate) &&
    dateIsValid(engagement.initialDate) &&
    dateIsValid(engagement.g2gDate) &&
    dateIsValid(engagement.engageDate) &&
    dateIsValid(engagement.dateAvailableForRent) &&
    dateIsValid(engagement.tenantedDate) &&
    dateIsValid(engagement.setToBuyDate) &&
    dateIsValid(engagement.datePromised) &&
    dateIsValid(engagement.financeDate) &&
    dateIsValid(engagement.contractDate) &&
    dateIsValid(engagement.settleDate) &&
    dateIsValid(engagement.blueprintCompletedDate) &&
    dateIsValid(engagement.blueprintSentToClientDate) &&
    conditionsAreValid
  );
}
*/
