import React from "react";
import _ from "lodash";
import './SingleWatermark.css';

export default function SingleWatermark({
  waterMark,
  edit,
  closeExpandableCards,
  month,
  year,
  handleInputChange,
  editUserRole,
  buyers,
  cardMonthOpen,
  userSelect,
  role
}) {
  return (
    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <h4 style={{ marginTop: "0px" }}>
        {month} {year}
      </h4>
      <div className="card">
        <div className="content endless-table singlewatermarktable">
          <table>
            <thead>
              <tr>
                <th>BA</th>
                <th>Water-Mark(#)</th>
                <th>G2G Help me(#)</th>
                <th>4 Month STOP</th>
                <th>Archive</th>
              </tr>
            </thead>
            <tbody>{waterMark}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
