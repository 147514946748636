const budgetMap = new Map([
    ["<400", 1],
    ["400-499", 2],
    ["500-599", 3],
    ["600-699", 4],
    ["700-799", 5],
    ["800-899", 6],
    ["900-999", 7],
    ["1.000M-1.099M", 8],
    ["1.100M-1.199M", 9],
    ["1.200M-1.299M", 10],
    ["1.300M-1.399M", 11],
    ["1.400M-1.499M", 12],
    ["1.5M +", 13]
]);

export const parseBudget = value => {
    return budgetMap.has(value) ? value : null;
};
export const sortByBudget = budget => budgetMap.has(budget) && budgetMap.get(budget);

export default budgetMap;
