'use strict';
import { Component } from 'react';
import { browserHistory } from 'react-router'

// Modules
import api from '../../common/api';

class SignupBase extends Component {
    constructor(props) {
        super(props);
        let self= this;
        this.state = {
            username: '',
            password: '',
            type : "password"
        };
        this.changeType = this.changeType.bind(this);
        this.signup = this.signup.bind(this);  
    }

    componentDidMount() {
        let token = localStorage.getItem('USER_TOKEN');
        if (token) {
            browserHistory.push('home');
            return null;
        } else {
           console.log("token expired...");
        }
    }

    changeType(type,value){
        if(type=="password" && value=="show" ){
            this.setState({type:"text"});
        } else if(type=="password" && value=="hide") {
            this.setState({type:"password"});
        }
    }

    signup(e) {
        e.preventDefault();
        let {username, password } = this.state;
        username = username.trim();
        password = password.trim();
        if (!username || !password) {
            alert('Message','Please fill all fields');
        }
        else {
            // alert('Registration Closed');
            api.post('/api/auth/register', {username, password}).then(result => {
                if (result.notify) {
                    alert(result.notify);
                } else {
                    alert('Successfully registered');
                    browserHistory.push('/login');
                }
            })
        }
    }

}

module.exports = SignupBase;
