'use strict';
import React from 'react';

export default function(){
    return FooterComponentRender.call(this);
}

var FooterComponentRender = function(){
    return (
        <footer className={this.props.user.token ? "footer" : "footer footer-transparent"}>
    	{ this.props.user.token ?
		    <div className="container-fluid">
		        <nav className="pull-left">
		           
		        </nav>
		        <div className="copyright pull-right">
		            &copy;
		            2017, made with <i className="fa fa-heart heart"></i> by <a href="http://www.empowerwealth.com.au">Empower Wealth</a>
		        </div>
		    </div> :
		    <div className="container">
		        <div className="copyright">
		            &copy;
		           	2017, made with <i className="fa fa-heart heart"></i> by <a href="http://www.empowerwealth.com.au">Empower Wealth</a>
		        </div>
		    </div>
    	}
		</footer>
    )
};
