'use strict';
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';

// Modules
import api from '../../common/api';
import * as redux from '../../redux/redux';

class DashboardBase extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    	let isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;
    	if (typeof pdp != 'undefined') {
	    	pdp.initMinimizeSidebar();
    	}
    	if (isWindows && !$('body').hasClass('sidebar-mini')){
    		showScrollBar();
    	}
    }
}

DashboardBase.contextTypes = {
  router: PropTypes.object.isRequired
};

module.exports = DashboardBase;
