'use strict';
import Base  from './ChangePasswordBase';
import Render  from './ChangePasswordRender';
import { connect } from 'react-redux';

class ChangePassword extends Base {
    render() {
        return Render.call(this, this.props, this.state);
    }
}

export default ChangePassword = connect((state)=>{
    return {user:state.user || {}}
})(ChangePassword);