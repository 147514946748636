'use strict';
import React, { Component } from 'react';
import { browserHistory } from 'react-router'
import * as redux from '../../redux/redux';

class HomeBase extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        let clientToken = localStorage.getItem('CLIENT_TOKEN');
        let advocateToken = localStorage.getItem('USER_TOKEN');
        if(!clientToken && !advocateToken) {
        	redux.setUser('');
        }else if(clientToken) {
			browserHistory.push('home/shortlisted-property')    		
    	}
    }
}

module.exports = HomeBase;
