import React from "react";
import { Link } from "react-router";

// Modules
import { formatDate } from "./utils";

// MOVE columns into this file if they are shared by more than 1 table

const maxColumnWidthStyle = {
  display: "inline-block",
  maxWidth: 190,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
};

export const clientCol = {
  path: "client",
  heading: "Client",
  rowRender: (value, props) => (
    <div>
      <Link
        to={`/home/edit-purchase/${props.link}`}
        style={maxColumnWidthStyle}
      >
        {value}
      </Link>
    </div>
  )
};

export const setToBuyDateCol = {
  path: "set_to_buy_date",
  heading: "Set to Buy Date",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: formatDate
};

export const settleDateCol = {
  path: "settle_date",
  heading: "Settle Date",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: formatDate
};

export const propertyCol = {
  path: "property",
  heading: "Property",
  rowRender: (value, props) => (
    <a
      style={Object.assign({}, maxColumnWidthStyle)}
      href={props.property && props.property.url}
      target="_blank"
    >
      {value}
    </a>
  )
};

export const financeCol = {
  path: "finance_broker",
  heading: "Broker"
};
