import React from "react";
import { CSVLink } from "react-csv";

// Modules
import { engagementColumns, propertyColumns } from "./utils";

// Components
import TableRefinement from "./../propertyList/TableRefinement";
import Spinner from "../../common/spinner";

export default function() {
  return ReportingComponentRender.call(this);
}

const ReportingComponentRender = function() {
  const { headerCSV, report } = this.state;

  return (
    <div>
      <h4 className="text-underline">Reporting</h4>

      <div className="card">
        <div className="content">
          {this.state.spinner && (
            <div className="spinner-pos">
              <Spinner />
            </div>
          )}

          <div className="checkbox-group">
            <ReportCheckbox
              label="Purchased Properties Report"
              value="property"
              checked={report.property}
              onChange={this.handlePurchasedPropertiesReportChange}
            />
          </div>

          <header>
            <h4>Property</h4>
          </header>

          <form onSubmit={this.handleTableSearchSubmit} className="clearfix">
            <div style={{ display: "flex" }}>
              {/* NOTE: As a controlled input this lags the application immensely */}
              <input
                type="text"
                className="form-control"
                placeholder="Search by address, suburb, state or postcode"
                ref={element => (this.tableSearchInput = element)}
              />

              <button
                type="submit"
                style={{
                  backgroundColor: "orange",
                  border: "none",
                  padding: "0 1.5em",
                  color: "white",
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  fontSize: "1.2em",
                  borderRadius: 4,
                  marginLeft: "0.6em",
                  whiteSpace: "nowrap",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                Generate Report
              </button>
            </div>

            {/* Refinements */}
            <div
              style={{
                marginTop: 10,
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <a data-toggle="collapse" href="#property-refinement">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    Refinements
                    <i className="ti-angle-down" />
                  </h4>
                </div>
              </a>
            </div>

            <div id="property-refinement" className="panel-collapse collapse">
              <div className="container-group">
                <TableRefinement
                  onFilterChange={this.handleTableStagedFilterChange}
                  // Select initial options
                  basOptions={this.state.basOptions}
                  engagementsOptions={_.sortBy(
                    this.state.engagementOptions,
                    "labelForScrapper"
                  )}
                  phasesOptions={this.state.phasesOptions}
                  priceOptions={this.state.priceOptions}
                  spottersOptions={this.state.basOptions}
                  statesOptions={_.sortBy(this.state.statesOptions, "label")}
                  dateStringType={this.state.dateStringTypeReport}
                  // Select values
                  selectedBAs={this.state.tableStagedFilters.bas}
                  selectedDateMin={this.state.tableStagedFilters.dateMin}
                  selectedDateMax={this.state.tableStagedFilters.dateMax}
                  selectedEngagements={
                    this.state.tableStagedFilters.engagements
                  }
                  selectedPhases={this.state.tableStagedFilters.phases}
                  selectedPrices={this.state.tableStagedFilters.prices}
                  selectedSpotters={this.state.tableStagedFilters.spotters}
                  selectedStates={this.state.tableStagedFilters.states}
                  selectedDateType={this.state.tableStagedFilters.dateString}
                  showArchived={this.state.tableStagedFilters.showArchived}
                  offMarketFilter={
                    this.state.tableStagedFilters.offMarketFilter
                  }
                  //Clear refinements
                  clearFilter={this.clearRefinementsFilter}
                />
              </div>
            </div>
          </form>

          <div className="row">
            <section className="col-sm-6">
              <h5>Property columns</h5>

              <ReportCheckbox
                label="Select All"
                value="property"
                checked={_.every(
                  propertyColumns,
                  (x, column) => headerCSV[column]
                )}
                onChange={this.handlePropertySelectAll}
              />

              <div className="checkbox-group-flex">
                {/* Column */}
                <div className="checkbox-group">
                  <ReportCheckbox
                    label="Address"
                    value="address"
                    checked={headerCSV.address}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Auction Date"
                    value="auction_date"
                    checked={headerCSV.auction_date}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Auto Evaluation"
                    value="auto_val"
                    checked={headerCSV.auto_val}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Bathrooms"
                    value="bath_rooms"
                    checked={headerCSV.bath_rooms}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Beds"
                    value="bed_rooms"
                    checked={headerCSV.bed_rooms}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Car Parkings"
                    value="parking"
                    checked={headerCSV.parking}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="DSR+"
                    value="dsr"
                    checked={headerCSV.dsr}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Flood Check"
                    value="flood_check"
                    checked={headerCSV.flood_check}
                    onChange={this.handlePropertyFilterReport}
                  />
                </div>

                {/* Column */}
                <div className="checkbox-group">
                  <ReportCheckbox
                    label="FSD"
                    value="fsd"
                    checked={headerCSV.fsd}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Growth"
                    value="growth"
                    checked={headerCSV.growth}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Landsize"
                    value="land_size"
                    checked={headerCSV.land_size}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="LSD"
                    value="lsd"
                    checked={headerCSV.lsd}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="LSP"
                    value="lsp"
                    checked={headerCSV.lsp}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Region"
                    value="region"
                    checked={headerCSV.region}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Rent"
                    value="rent"
                    checked={headerCSV.rent}
                    onChange={this.handlePropertyFilterReport}
                  />

                  {/* TODO this is wrong, in MapViewBase this is client.contract_value */}
                  {/* <ReportCheckbox
                    label="Sold Price"
                    value="price"
                    checked={headerCSV.price}
                    onChange={this.handlePropertyFilterReport}
                  /> */}
                </div>

                {/* Column */}
                <div className="checkbox-group">
                  <ReportCheckbox
                    label="Suburb"
                    value="suburb"
                    checked={headerCSV.suburb}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Type"
                    value="type"
                    checked={headerCSV.type}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="URL"
                    value="url"
                    checked={headerCSV.url}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="VR"
                    value="vr"
                    checked={headerCSV.vr}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Walkscore"
                    value="walk_score"
                    checked={headerCSV.walk_score}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Year Built"
                    value="year_build"
                    checked={headerCSV.year_build}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Yield"
                    value="yields"
                    checked={headerCSV.yields}
                    onChange={this.handlePropertyFilterReport}
                  />
                </div>
              </div>
            </section>

            <section className="col-sm-6">
              <h5>Engagement columns</h5>

              <ReportCheckbox
                label="Select All"
                value="engagement"
                checked={_.every(
                  engagementColumns,
                  (x, column) => headerCSV[column]
                )}
                onChange={this.handleEngagementSelectAll}
              />

              <div style={{ display: "flex" }}>
                {/* Column */}
                <div className="checkbox-group">
                  <ReportCheckbox
                    label="Actual Rent"
                    value="actual_rent"
                    checked={headerCSV.actual_rent}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="BA"
                    value="ba"
                    checked={headerCSV.ba}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Budget"
                    value="budget"
                    checked={headerCSV.budget}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Client Name"
                    value="client"
                    checked={headerCSV.client}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Client Type"
                    value="clientType"
                    checked={headerCSV.clientType}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Contract Date"
                    value="contract_date"
                    checked={headerCSV.contract_date}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Contract Value"
                    value="contract_value"
                    checked={headerCSV.contract_value}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Conveyancer"
                    value="conveyancer"
                    checked={headerCSV.conveyancer}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Engagement Date"
                    value="engagement_date"
                    checked={headerCSV.engagement_date}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Estimated Rent"
                    value="rental_estimate"
                    checked={headerCSV.rental_estimate}
                    onChange={this.handlePropertyFilterReport}
                  />
                </div>

                {/* Column */}
                <div className="checkbox-group">
                  <ReportCheckbox
                    label="Estimated Yield"
                    value="estimated_yield"
                    checked={headerCSV.estimated_yield}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Extra Budget"
                    value="extra_budget"
                    checked={headerCSV.extra_budget}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Fee"
                    value="fee"
                    checked={headerCSV.fee}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Financer"
                    value="financer"
                    checked={headerCSV.financer}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Good to Go Date"
                    value="goodTogoDate"
                    checked={headerCSV.goodTogoDate}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Growth"
                    value="client_growth"
                    checked={headerCSV.client_growth}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Initial Date"
                    value="initial_date"
                    checked={headerCSV.initial_date}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Label for Scraper"
                    value="labelForScraper"
                    checked={headerCSV.labelForScraper}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Number of People Helped"
                    value="peopleHelped"
                    checked={headerCSV.peopleHelped}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Property"
                    value="property"
                    checked={headerCSV.property}
                    onChange={this.handlePropertyFilterReport}
                  />
                </div>

                {/* Column */}
                <div className="checkbox-group">
                  <ReportCheckbox
                    label="Property Manager"
                    value="propertyManager"
                    checked={headerCSV.propertyManager}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Real Estate Agency"
                    value="realStateAgency"
                    checked={headerCSV.realStateAgency}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Real Estate Agent"
                    value="realStateAgent"
                    checked={headerCSV.realStateAgent}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Service"
                    value="service"
                    checked={headerCSV.service}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Set to Buy Date"
                    value="setToBuyDate"
                    checked={headerCSV.setToBuyDate}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Settlement Date"
                    value="settle_date"
                    checked={headerCSV.settle_date}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="State"
                    value="state"
                    checked={headerCSV.state}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Status"
                    value="status"
                    checked={headerCSV.status}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Tenanted Date"
                    value="tenanted_date"
                    checked={headerCSV.tenanted_date}
                    onChange={this.handlePropertyFilterReport}
                  />

                  <ReportCheckbox
                    label="Yield"
                    value="yield"
                    checked={headerCSV.yield}
                    onChange={this.handlePropertyFilterReport}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

function ReportCheckbox({ label, ...rest }) {
  return (
    <div className="checkbox-item">
      <label style={{ display: "flex", alignItems: "center" }}>
        <input className="checkbox-react-dark" type="checkbox" {...rest} />
        <span>{label}</span>
      </label>
    </div>
  );
}
