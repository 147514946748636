'use strict';
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import * as redux from '../../redux/redux';

class HeaderBase extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            user : '',
            notification : []
        }
    }


    componentDidMount() {
        redux.getUserAsync().then(user=> {
            if (!user) {
               browserHistory.push('/login');
               return null;
            }
        }).catch((e)=> {
            console.log("error is", e);
        });
    }
}

module.exports = HeaderBase;