'use strict';
import Base  from './SignupBase';
import Render  from './SignupRender';

class Signup extends Base {
    render() {
        return Render.call(this, this.props, this.state);
    }
}

export default Signup;