import React from 'react';
import ReactDOM from 'react-dom';
import './extend.jsx';
import './scrollBar.css'

class App extends React.Component {
    render() {
        return (
        	<div className="wrapper">
            	{this.props.children}
            </div>
        )
    }
}
export default App;
