import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, browserHistory, IndexRoute } from "react-router";
import { Provider } from "react-redux";

// Modules
import store from "./redux/store";

// Components
import AddClientContainer from "./components/addClient/AddClientContainer";
import App from "./app";
import AuditTrailContainer from "./containers/AuditTrailContainer";
import ChangePassword from "./components/changePassword/ChangePassword";
import ClientList from "./components/clientList/ClientList";
import ClientLogin from "./components/clientLogin/ClientLogin";
import Dashboard from "./components/dashboard/Dashboard";
import EditClientPurchases from "./components/editClientPurchases/EditClientPurchases";
import EngagementHistory from "./components/EngagementHistory";
import FeedbackForm from "./components/feedbackForm/FeedbackForm";
import FeedbackList from "./components/feedbackList/FeedbackList";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import Home from "./components/home/Home";
import ManageBusinessDays from "./components/manageBusinessDays/ManageBusinessDays";
import MapView from "./components/mapView/MapView";
import MasterDashboard from "./components/masterDashboard/MasterDashboard";
import MetricsDashboard from "./components/metricsDashboard/MetricsDashboard";
import MyListDashboardContainer from "./containers/MyListDashboardContainer";
import PropertyDetail from "./components/propertyDetail/PropertyDetail";
import PropertyList from "./components/propertyList/PropertyList";
import Reporting from "./components/reporting/Reporting";
import ResetPassword from "./components/resetPassword/ResetPassword";
import UploadCsv from "./containers/UploadCsv.updated";
import UserProfile from "./components/userProfile/UserProfile";

ReactDOM.render(
  <Provider store={store}>
    <Router history={browserHistory}>
      <Route path="/" component={App}>
        <IndexRoute component={Home} />
        <Route path="client-login" component={ClientLogin} />
        <Route path="forgot-password" component={ForgotPassword} />
        <Route path="login" component={Home} />
        <Route
          path="reset-password/:verificationCode"
          component={ResetPassword}
        />
        <Route path="signup" component={Home} />

        {/* Core application components */}
        <Route path="home" component={Dashboard}>
          <IndexRoute component={PropertyList} />
          <Route path="add-client" component={AddClientContainer} />
          <Route path="audit-trail" component={AuditTrailContainer} />
          <Route path="change-password" component={ChangePassword} />
          <Route path="client-list" component={ClientList} />
          <Route path="dashboard" component={MasterDashboard} />
          <Route path="dashboard/metrics" component={MetricsDashboard} />
          <Route
            path="dashboard/my-list"
            component={MyListDashboardContainer}
          />
          <Route path="engagement-history" component={EngagementHistory} />
          <Route path="feedback-list" component={FeedbackList} />
          <Route path="feedback" component={FeedbackForm} />
          <Route path="list" component={PropertyList} />
          <Route path="manage-days" component={ManageBusinessDays} />
          <Route path="map-view" component={MapView} />
          <Route path="profile" component={UserProfile} />
          <Route path="reporting" component={Reporting} />
          <Route path="upload-csv" component={UploadCsv} />
          <Route path="reporting" component={Reporting} />
          {/* Routes with route params */}
          <Route
            path="edit-purchase/:purchase"
            component={EditClientPurchases}
          />
          <Route path="property-detail/:id" component={PropertyDetail} />
        </Route>

        {/* Catch all route */}
        <Route path="*" component={Home} />
      </Route>
    </Router>
  </Provider>,
  document.getElementById("container")
);

