import React, { PureComponent } from "react";
import Datetime from "react-datetime";
import moment from "moment";

// Modules
import { durationFromDateToTodayInDays } from "../../common/date-helpers";

// Components
import {
  FormCardHeader,
  FormDatepicker,
  FormDatepickerStatus,
  FormInput,
  FormReadOnly,
} from "../FormComponents";

function getDaysToSettle(settleDate) {
  if (!settleDate) {
    return "Settle date hasn't been set";
  }

  const today = moment().startOf("day");

  if (settleDate.isAfter(today)) {
    return durationFromDateToTodayInDays(settleDate);
  } else if (settleDate.isSame(today)) {
    return "Settle date is today!";
  } else {
    return "Settle date passed";
  }
}

class ConditionalContract extends PureComponent {
  render() {
    const {
      buildingAndPestDate,
      bpSatisfied,
      conditions,
      contractDate,
      contractSatisfied,
      contractValue,
      conveyancer,
      financeDate,
      financeSatisfied,
      onConditionChange,
      onCreateCondition,
      onDateChange,
      onInputChange,
      onRemoveCondition,
      onCheckBoxChange,
      realEstateAgency,
      realEstateAgent,
      rentalEstimate,
      settleDate,
    } = this.props;

    return (
      <div>
        <FormCardHeader>Conditional Contract</FormCardHeader>

        <div className="content" style={{ zIndex: "initial" }}>
          {/* Conditional date section */}
          {conditions && Array.isArray(conditions) && (
            <div className="form-group">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-right">
                      <i
                        className="ti-plus t-plus-pos"
                        onClick={onCreateCondition}
                      />
                    </th>
                    <th className="text-center">Condition Date</th>
                    <th className="text-center">Condition</th>
                    <th className="text-left">Satisfied</th>
                  </tr>
                </thead>
                <tbody>
                  {renderConditionDateList(
                    conditions,
                    onConditionChange,
                    onRemoveCondition
                  )}
                </tbody>
              </table>
            </div>
          )}

          <FormDatepickerStatus
            label="Finance Date"
            onChange={onDateChange("financeDate")}
            value={financeDate}
            checkCheckbox={financeSatisfied}
            nameCheckbox={"financeSatisfied"}
            onCheckBoxChange={onCheckBoxChange}
          />

          <FormDatepickerStatus
            label="B&P Date"
            onChange={onDateChange("bpDate")}
            value={buildingAndPestDate}
            checkCheckbox={bpSatisfied}
            nameCheckbox={"bpSatisfied"}
            onCheckBoxChange={onCheckBoxChange}
          />

          <FormDatepicker
            label="Contract Date"
            onChange={onDateChange("contractDate")}
            value={contractDate}
          />

          <FormDatepicker
            label="Settle Date"
            onChange={onDateChange("settleDate")}
            value={settleDate}
          />

          <FormInput
            label="Contract Value"
            name="contractValue"
            onChange={onInputChange}
            placeholder="$9,999"
            type="text"
            value={contractValue}
          />

          <FormInput
            label="Rental Estimate"
            name="rentalEstimate"
            onChange={onInputChange}
            placeholder="$9,999"
            type="text"
            value={rentalEstimate}
          />

          <FormInput
            label="Conveyancer"
            name="conveyancer"
            onChange={onInputChange}
            placeholder="con"
            type="text"
            value={conveyancer}
          />

          <FormInput
            label="Real Estate Agent"
            name="realEstateAgent"
            onChange={onInputChange}
            placeholder="Agent"
            type="text"
            value={realEstateAgent}
          />

          <FormInput
            label="Real Estate Agency"
            name="realEstateAgency"
            onChange={onInputChange}
            placeholder="Real Estate Agency"
            type="text"
            value={realEstateAgency}
          />

          <FormReadOnly
            label="Days to Settle"
            value={getDaysToSettle(settleDate)}
          />
        </div>
      </div>
    );
  }
}

function renderConditionDateList(
  conditions,
  onConditionChange,
  onRemoveCondition
) {
  const WhiteSpace = (count) => {
    let space = "";
    for (var i = 0; i < count; i++) space += `-`;
    return space;
  };

  return conditions.map((condition, i) => (
    <tr key={i}>
      <td className="text-center">
        <i
          className="ti-close remove-icon cursor-position"
          onClick={() => {
            onRemoveCondition(i);
          }}
        />
      </td>
      <td className="text-center">
        <Datetime
          closeOnSelect
          dateFormat="Do MMMM YYYY"
          inputProps={{ className: "form-control" }}
          onChange={(date) => {
            const conditionDate =
              moment.isMoment(date) && date.isValid() ? date : null;

            onConditionChange(i, "condition_date", conditionDate);
          }}
          timeFormat={false}
          value={condition.condition_date}
        />
      </td>
      <td className="text-center">
        <input
          className="form-control"
          placeholder="condition"
          type="text"
          value={condition.condition}
          onChange={(ev) => {
            onConditionChange(i, "condition", ev.target.value);
          }}
        />
      </td>

      <td
        className="text-center"
        style={{ fontWeight: "700", fontSize: "12px" }}
      >
        <div className="checkbox-item">
          <td>
            <tr>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              >
                <input
                  type="checkbox"
                  checked={conditions[i].satisfied ? true : false}
                  onChange={(e) => {
                    const satisfiedDate = new Date();
                    const checked = e.target.checked;
                    onConditionChange(
                      i,
                      "satisfied",
                      checked ? satisfiedDate : null
                    );
                  }}
                />
                &nbsp;
                <span>Satisfied</span>
              </label>
            </tr>
            <tr>
              <span
                style={
                  conditions[i].satisfied
                    ? { visibility: "visible" }
                    : { visibility: "hidden" }
                }
              >
                {conditions[i].satisfied
                  ? moment(conditions[i].satisfied).format("DD-MMM-YY")
                  : WhiteSpace(12)}
              </span>
            </tr>
          </td>
        </div>
      </td>
    </tr>
  ));
}

export default ConditionalContract;
