import React from "react";

// Components
import {
  BASelect,
  BudgetSelect,
  EngagementSourceRadioButtons,
  FormDatepicker,
  FormInput,
  FormOptionalPercentageInput,
  ServiceSelect,
  StatusSelect
} from "../FormComponents";

function AddEngagement({
  ba,
  bas,
  budget,
  engageDate,
  engagementSource,
  exactBudget,
  fee,
  growth,
  growthRef,
  initialDate,
  numberOfPeopleHelped,
  onAddClick,
  onDateInputChange,
  onInputChange,
  onInputNaChange,
  onRemoveClick,
  service,
  status,
  yield_,
  yieldRef
}) {
  return (
    <div>
      <div className="form-group">
        <BASelect
          bas={bas}
          name="clientBuyer"
          onChange={onInputChange}
          value={ba}
        />
      </div>

      <div className="form-group">
        <StatusSelect
          shortList
          name="clientStatus"
          onChange={onInputChange}
          value={status}
        />
      </div>

      <BudgetSelect name="budget" onChange={onInputChange} value={budget} />

      <FormInput
        label="Exact Budget"
        name="exactBudget"
        onChange={onInputChange}
        placeholder="9999"
        value={exactBudget}
      />

      <FormOptionalPercentageInput
        label="Growth"
        innerRef={growthRef}
        name="growth"
        onChange={onInputChange}
        onNAChange={() => {
          onInputNaChange("growth");
        }}
        value={growth}
      />

      <FormOptionalPercentageInput
        label="Yield"
        innerRef={yieldRef}
        name="yield"
        onChange={onInputChange}
        onNAChange={() => {
          onInputNaChange("yield");
        }}
        value={yield_}
      />

      <FormDatepicker
        label="Engagement Date"
        onChange={onDateInputChange("engageDate")}
        value={engageDate}
      />

      <FormDatepicker
        label="Initial Date"
        onChange={onDateInputChange("initialDate")}
        value={initialDate}
      />

      <EngagementSourceRadioButtons
        checked={engagementSource}
        name="engagementSource"
        onChange={onInputChange}
      />

      <FormInput
        label="Number of People Helped"
        name="numberOfPeopleHelped"
        onChange={onInputChange}
        placeholder="Number Of People Helped"
        value={numberOfPeopleHelped}
      />

      <ServiceSelect name="service" onChange={onInputChange} value={service} />

      <FormInput
        label="Fee"
        name="fee"
        onChange={onInputChange}
        placeholder="$9,999"
        value={fee}
      />

      {/* Form buttons */}
      <div className="row">
        <div className="col-md-6">
          <button
            className="btn btn-fill btn-danger"
            onClick={onRemoveClick}
            style={{ float: "right" }}
          >
            Clear
          </button>
        </div>

        <div className="col-md-6">
          <button className="btn btn-fill btn-info" onClick={onAddClick}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddEngagement;
