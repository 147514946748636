import React from "react";
import { Link } from "react-router";

// Components
import { StateSelect, StatusSelect } from "../FormComponents";

function ClientListEngagements({
  client,
  clientIndex,
  engagements,
  onEngagementDetailsChange,
  onRemoveEngagementClick
}) {
  return (
    <div className="edit-property-container">
      <div id={`row-${client._id}${clientIndex}`}>
        <table
          id="clientListEngagementTable"
          className="clientListTable table table-no-bordered table-hover"
          cellSpacing="0"
        >
          <thead>
            <tr>
              <th className="text-size">Edit</th>
              <th className="text-size" />
              <th className="text-size">BA</th>
              <th className="text-size">State</th>
              <th className="text-size">Status</th>
              <th className="text-size">Budget</th>
              <th className="text-size">Growth</th>
              <th className="text-size">Yield</th>
            </tr>
          </thead>

          <tbody>
            {engagements.length !== 0 &&
              engagements.map((engagement, i) => (
                <tr key={engagement._id}>
                  {/* Edit button */}
                  <td className="text-size">
                    <Link to={`/home/edit-purchase/${engagement._id}`}>
                      <i className="ti-pencil-alt" />
                    </Link>
                  </td>

                  {/* Delete button */}
                  {/* TODO: This doesn't work correctly.
                 This will remove the item from the list (visually) and decrement the "Number of Engagements" counter without actually deleting the engagement. A warning displays stating that the engagement cannot be deleted, this is correct, however it will not prevent the aforementioned actions from occuring */}
                  <td className="text-size">
                    <a
                      className="cursor-position dis-blo"
                      onClick={() => {
                        onRemoveEngagementClick(engagement);
                      }}
                    >
                      <i className="removeEngagementIcon" />
                    </a>
                  </td>

                  {/* BA */}
                  <td className="text-size">
                    {engagement.business_analyst.nickname}
                  </td>

                  <td className="text-size">
                    <StateSelect
                      label={null}
                      name="clientState"
                      onChange={ev => {
                        onEngagementDetailsChange(engagement, i, ev);
                      }}
                      style={{ width: 100 }}
                      value={engagement.state}
                    />
                  </td>

                  <td className="text-center text-size">
                    <StatusSelect
                      label={null}
                      name="status"
                      onChange={ev => {
                        onEngagementDetailsChange(engagement, i, ev);
                      }}
                      style={{ width: 100 }}
                      value={engagement.status}
                    />
                  </td>

                  {/* Budget */}
                  <td className="text-center text-size">
                    <span>{engagement.budget || ""}</span>
                  </td>

                  {/* Growth */}
                  <td className="text-center text-size">
                    <span>{engagement.growth || "-"}</span>
                  </td>

                  {/* Yield */}
                  <td className="text-center text-size">
                    <span>{engagement.yield || "-"}</span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ClientListEngagements;
