'use strict';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import api from '../../common/api';
import { browserHistory } from 'react-router';
import * as redux from '../../redux/redux';

class UserProfileBase extends Component {

    constructor(props) {
        super(props);
        this.state = {
        	user : null,
            buyerList : [],
            addAssociate : '',
        }
        this.onDrop = this.onDrop.bind(this)
        this.updateUser = this.updateUser.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.changePreferences = this.changePreferences.bind(this)
        this.moveAssociate = this.moveAssociate.bind(this)
        this.removeAssociate = this.removeAssociate.bind(this)
    }

    componentWillMount() {
        let admin = this.props.user ? this.props.user.admin : '';
        let advocateToken = localStorage.getItem('USER_TOKEN');
        let clientToken = localStorage.getItem('CLIENT_TOKEN');
        if (!admin && !advocateToken) {
            browserHistory.push('login');
        } else if (!admin && clientToken) {
            browserHistory.push('/home/shortlisted-property');
        }
    }

    componentDidMount() {
        const user = new Promise((resolve, reject) => {
            api.get('/api/auth/user').then(result => {
                if (result.notify) {
                    reject(result.notify);
                } else {
                    const userRole = result.info.user_role;
                    if(userRole === "super-admin" || userRole === "manager") redux.getBusinessDaysAsync()
                    resolve(result);
                }
            }).catch(error => {
                reject(error);
            })
        });
        const list = new Promise((resolve, reject) => {
            api.get('/api/auth/list').then(result => {
                if (result.notify) {
                    reject(result.notify);
                } else {
                    resolve(result)
                }
            }).catch(error => {
                reject(error);
            })
        });
        console.log("user",user,"list",list);
        Promise.all([user, list]).then(values => { 
            this.setUser(values[0]);
            this.manageAssociates(values);
        }).catch(reason => { 
          console.log("reason iss the following",reason)
        });
        // this.getUserList();
    }

    setUser(data) {
        console.log("data",data);
        this.setState({
        	user : data.info
        })
    }

    manageAssociates(values) {
        console.log("values",values);
        let juniorBuyers = (values[0].info && values[0].info.junior_associates) && values[0].info.junior_associates;
        let associates = juniorBuyers && _.map(juniorBuyers, '_id');
        if(associates.length) {
            let buyerList = values[1].users.filter(value => {
                let index = associates.findIndex(val => val===value._id)
                if(index<0) {
                    return true
                }
            })
            this.setState({
                buyerList
            })
        } else {
            this.setState({
                buyerList : values[1].users
            },()=>{
                console.log("this.state.buyerList is", this.state.buyerList);
            })
        }
    }

    handleInputChange(event) {
    	event.preventDefault();
        const user = this.state.user;
        const target = event.target;
        const value = target.value;
        const name = target.name;
	        user[name] = value;
        this.setState({
        	user
        })
    }

    updateUser(event) {
    	event.preventDefault();
        let user = {}
        let baToken = localStorage.getItem('USER_TOKEN');
    	user.fields = {
    		first_name : this.state.user.first_name,
    		last_name : this.state.user.last_name,
    		nickname : this.state.user.nickname,
    		image : this.state.user.image,
    		preference_list : this.state.user.preference_list,
            notification_preferences : this.state.user.notification_preferences
    	} 
    	api.put('/api/auth/update', user).then(result => {
            if (result.notify) {
                alert('Message', result.notify);
            } else {
                result.token = baToken;
                result.admin = true;
                redux.setUser(result);
                // console.log("result is", result)
                alert("Profile Updated")
            }
        });
    }

    onDrop(files) {
    	const user = this.state.user;
    	this.setState({
    		files
    	})
    	var formData = new FormData();
    	formData.append('file',files[0]);
    	api.post('/api/upload/', formData).then(result => {
            if (result.notify) {
                alert('Message', result.notify);
            } else {
            	user.image = result.path;
            	this.setState({
            		user
            	})
            }
        });
    }

    changePreferences(index, data, event) {
        const user = this.state.user;
        let ind;
        if (event.target.checked) {
            if(data.key == "address") {
                user.preference_list.unshift(data);
            } else {
                user.preference_list.push(data);
            }
        } else {
            ind = _.findIndex(user.preference_list,data)

            user.preference_list.splice(ind, 1)
        }
        this.setState({
            user
        })
    }

    changeNotificationPreferences(index, data, event) {
        const user = this.state.user;
        user.notification_preferences[data] = event.target.checked;

        this.setState({ user })
    }

    moveAssociate(event) {
        event.preventDefault();
        const user = this.state.user;

        if(this.state.addAssociate) {
            let updateAssociate = { addAssociate : this.state.addAssociate };
            api.put('/api/auth/updateAssociates', updateAssociate).then(result => {
                if (result.notify) {
                    alert('Message', result.notify);
                } else {
                    if(result.success) {
                        const buyerList = this.state.buyerList;
                        let index = buyerList.findIndex(buyer=>buyer._id===this.state.addAssociate)
                        buyerList.splice(index, 1);
                        user.junior_associates = result.users.junior_associates;
                        this.setState({
                            user,
                            buyerList,
                            addAssociate : ''
                        })
                        this.props.showNotification("success", {
                            title: "Success!",
                            message: "This is now your Associate BA."
                        });
                    }
                }
            });
        } else {
            this.props.showNotification("warning", {
                title: "Select Associate Buyer!",
                message: "Please select associate buyer first."
            });
        }
    }

    removeAssociate(associate, index, event) {
        event.preventDefault();
        const user = this.state.user;
        const buyerList = this.state.buyerList;
        let updateAssociate = { removeAssociate : associate._id };

        api.put('/api/auth/updateAssociates', updateAssociate).then(result => {
            if (result.notify) {
                alert('Message', result.notify);
            } else {
                if(result.success) {
                    buyerList.push(associate);
                    user.junior_associates.splice(index, 1);
                    this.setState({
                        buyerList,
                        user
                    })
                    this.props.showNotification("success", {
                        title: "Success!",
                        message: "This is now not your associate BA."
                    });
                }
            }
        });
    }

    changeNotificationPreferences(index, data, event) {
        const user = this.state.user;
        user.notification_preferences[data] = event.target.checked;
        this.setState({ user })
    }
}

export default UserProfileBase;
