import React from "react";

// Components
import { BASelect, StatusSelect } from "../FormComponents";

function CreateEngagement({
  ba,
  bas,
  client,
  engagementAlreadyExists,
  labelForScraper,
  onBAChange,
  onRemoveClick,
  onStatusChange,
  onSubmit,
  show,
  status
}) {
  return (
    <div className="edit-property-container row" id={`row-${client._id}`}>
      {show && (
        <div className="col-md-6 m-l-50">
          <div className="card">
            <form className="content" onSubmit={onSubmit}>
              {/* Label for Scraper */}
              <div className="form-group w-100p">
                <label className="fl-l">Label for Scrapper</label>
                <input
                  className="form-control w-100p"
                  readOnly
                  value={labelForScraper}
                />
                <span>
                  {engagementAlreadyExists &&
                    "Please change the label as it already exists"}
                </span>
              </div>

              <div className="form-group w-100p">
                <BASelect
                  className="form-control w-100p"
                  label={<label className="fl-l">Buyer</label>}
                  bas={bas}
                  onChange={onBAChange}
                  value={ba}
                />
              </div>

              <div className="form-group w-100p">
                <StatusSelect
                  className="form-control w-100p"
                  label={<label className="fl-l">Status</label>}
                  onChange={onStatusChange}
                  shortList
                  value={status}
                />
              </div>

              {/* Form buttons */}
              <div className="row p-t-5">
                <div className="col-md-6">
                  <button
					onClick={onRemoveClick}
					type="button"
                    className="btn btn-fill btn-danger"
                    style={{ float: "right" }}
                  >
                    Cancel
                  </button>
                </div>

                <div className="col-md-6">
                  <button className="btn btn-fill btn-info" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateEngagement;
