'use strict';
import React from 'react';
import {Link} from 'react-router';

export default function(){
    return ChangePasswordComponentRender.call(this);
}

var ChangePasswordComponentRender = function(){
    return (
    	<div className="row">
			<div className="col-md-offset-3 col-md-6">
                <div className="card">
                    <form id="changePasswordFormValidation" autoComplete="off">
                        <div className="header">
							<h4 className="title">
								Change Password
							</h4>
						</div>
                        <div className="content">
                            <div className="form-group">
                                <label className="control-label">
									                 Old Password <star>*</star>
								                </label>
                                <input className="form-control"
                                       name="oldPassword"
                                       type="password"
                                       required="true"
                                       value={this.state.oldPassword}
                                       onChange={this.handleInputChange}
								                />
                            </div>
                            <div className="form-group">
                                <label className="control-label">New Password <star>*</star></label>
                                <input className="form-control"
                                       name="newPassword"
                                       type="password"
                                       required="true"
                                       value={this.state.newPassword}
                                       onChange={this.handleInputChange}
								                />
                            </div>
                            <div className="form-group">
                                <label className="control-label">Confirm New Password <star>*</star></label>
                                <input className="form-control"
                                       name="confirmPassword"
                                       type="password"
                                       required="true"
                                       value={this.state.confirmPassword}
                                       onChange={this.handleInputChange}
								                />
                            </div>
                            <div className="category"><star>*</star> Required fields</div>
                        </div>
						<div className="card-footer">
							<button type="submit" className="btn btn-info btn-fill pull-right" onClick={this.changePassword}>Save</button>
							<div className="clearfix"></div>
						</div>
                    </form>
                </div>
            </div>
		</div>
    )
};

