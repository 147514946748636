import React from "react";

// Components
import EndlessTable from "../endlessTable/EndlessTable";
import PanelHeader from "../panelHeader/PanelHeader";
import Spinner from "../../common/spinner";
import TableTitle from "./TableTitle";

function TableTemplate({
  isLoading,
  rows,
  cols,
  title,
  headerContent = "",
  defaultSort,
  waterMark,
  showWarnings
}) {
  return (
    <div>
      {isLoading && <Spinner />}
      <PanelHeader
        title={<TableTitle title={title} entries={rows.length} />}
        waterMark={waterMark}
      >
        {headerContent}
      </PanelHeader>
      {rows.length !== 0 && (
        <EndlessTable
          columns={cols}
          rows={rows}
          defaultSort={defaultSort}
          showWarnings={showWarnings}
        />
      )}
    </div>
  );
}

export default TableTemplate;
