import React, { PureComponent } from "react";
import Select from "react-select";
import Datetime from "react-datetime";

class TableRefinement extends PureComponent {
  render() {
    const {
      basOptions,
      clearFilter,
      engagementsOptions,
      offMarketFilter,
      onFilterChange,
      phasesOptions,
      priceOptions,
      selectedBAs,
      selectedDateMax,
      selectedDateMin,
      selectedInspectionDateMax,
      selectedInspectionDateMin,
      selectedEngagements,
      selectedPhases,
      selectedPrices,
      selectedSpotters,
      selectedStates,
      showArchived,
      spottersOptions,
      statesOptions,
      // Props unique to reporting page
      dateStringType,
      selectedDateType
    } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-xs-6">
            <div className="form-horizontal">
              <FormGroup label="Engagement:">
                <Select
                  multi
                  className="table-refinement"
                  labelKey="labelForScrapper"
                  valueKey="_id"
                  placeholder="All"
                  options={engagementsOptions}
                  onChange={options =>
                    onFilterChange("engagements", _.map(options, "_id"))
                  }
                  value={selectedEngagements}
                />
              </FormGroup>

              <FormGroup label="BA:">
                <Select
                  multi
                  className="table-refinement"
                  labelKey="nickname"
                  valueKey="_id"
                  placeholder="All"
                  options={basOptions}
                  onChange={options =>
                    onFilterChange("bas", _.map(options, "_id"))
                  }
                  value={selectedBAs}
                />
              </FormGroup>

              <FormGroup label="Spotter:">
                <Select
                  multi
                  className="table-refinement"
                  labelKey="nickname"
                  valueKey="_id"
                  placeholder="All"
                  options={spottersOptions}
                  onChange={options =>
                    onFilterChange("spotters", _.map(options, "_id"))
                  }
                  value={selectedSpotters}
                />
              </FormGroup>

              {dateStringType ? (
                <FormGroup label="Date String Type:">
                  <Select
                    multi
                    className="table-refinement"
                    placeholder="All"
                    options={dateStringType}
                    onChange={options =>
                      onFilterChange("dateString", _.map(options, "value"))
                    }
                    value={selectedDateType}
                  />
                </FormGroup>
              ) : (
                <div className="form-group" style={{ marginTop: -12 }}>
                  <label className="col-sm-2 control-label">
                    Scraped Date (from):
                  </label>
                  <div className="col-sm-4 control-label">
                    <Datetime
                      value={selectedDateMin}
                      timeFormat={false}
                      dateFormat="DD/MM/YY"
                      onChange={date => onFilterChange("dateMin", date)}
                      inputProps={{ placeholder: "N/A" }}
                      closeOnSelect
                    />
                  </div>

                  <label className="col-sm-2 control-label">
                    Scraped Date (to):
                  </label>
                  <div className="col-sm-4 control-label">
                    <Datetime
                      value={selectedDateMax}
                      timeFormat={false}
                      dateFormat="DD/MM/YY"
                      onChange={date => onFilterChange("dateMax", date)}
                      inputProps={{ placeholder: "N/A" }}
                      closeOnSelect
                    />
                  </div>
                </div>
              )}

              <div className="form-group" style={{ marginTop: -12 }}>
                <label className="col-sm-2 control-label">
                  Inspection Date (from):
                </label>
                <div className="col-sm-4 control-label">
                  <Datetime
                    value={selectedInspectionDateMin}
                    timeFormat={false}
                    dateFormat="DD/MM/YY"
                    onChange={date => onFilterChange("inspectionDateMin", date)}
                    inputProps={{ placeholder: "N/A" }}
                    closeOnSelect
                  />
                </div>

                <label className="col-sm-2 control-label">
                  Inspection Date (to):
                </label>
                <div className="col-sm-4 control-label">
                  <Datetime
                    value={selectedInspectionDateMax}
                    timeFormat={false}
                    dateFormat="DD/MM/YY"
                    onChange={date => onFilterChange("inspectionDateMax", date)}
                    inputProps={{ placeholder: "N/A" }}
                    closeOnSelect
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-xs-6">
            <div className="form-horizontal">
              <FormGroup label="Phase:">
                <Select
                  multi
                  className="table-refinement"
                  placeholder="All"
                  options={phasesOptions}
                  onChange={options =>
                    onFilterChange("phases", _.map(options, "value"))
                  }
                  value={selectedPhases}
                />
              </FormGroup>

              <FormGroup label="State:">
                <Select
                  multi
                  className="table-refinement"
                  placeholder="All"
                  options={statesOptions}
                  onChange={options =>
                    onFilterChange("states", _.map(options, "value"))
                  }
                  value={selectedStates}
                />
              </FormGroup>

              <FormGroup label="Price:">
                <Select
                  multi
                  className="table-refinement"
                  placeholder="All"
                  options={priceOptions}
                  onChange={options =>
                    onFilterChange("prices", _.map(options, "value"))
                  }
                  value={selectedPrices}
                />
              </FormGroup>

              {dateStringType && (
                <div className="form-group">
                  <label className="col-sm-2 control-label">Date (from):</label>
                  <div className="col-sm-4">
                    <Datetime
                      value={selectedDateMin}
                      timeFormat={false}
                      dateFormat="DD/MM/YY"
                      onChange={date => onFilterChange("dateMin", date)}
                      inputProps={{ placeholder: "N/A" }}
                      closeOnSelect
                    />
                  </div>

                  <label className="col-sm-2 control-label">Date (to):</label>
                  <div className="col-sm-4">
                    <Datetime
                      value={selectedDateMax}
                      timeFormat={false}
                      dateFormat="DD/MM/YY"
                      onChange={date => onFilterChange("dateMax", date)}
                      inputProps={{ placeholder: "N/A" }}
                      closeOnSelect
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Checkbox section */}
        <div className="row">
          <div className="col-sm-offset-1">
            <div className="form-group">
              <input
                type="checkbox"
                className="checkbox-react-dark"
                checked={showArchived ? true : false}
                onChange={event =>
                  onFilterChange("showArchived", event.target.checked)
                }
              />
              <span style={{ display: "inline-block", marginTop: 4 }}>
                Show Archived
              </span>
            </div>

            <div className="form-group">
              <input
                type="checkbox"
                className="checkbox-react-dark"
                checked={false}
                disabled
              />
              <span style={{ display: "inline-block", marginTop: 4 }}>
                Under Contract Filter
              </span>
            </div>

            <div className="form-group">
              <input
                type="checkbox"
                className="checkbox-react-dark"
                checked={offMarketFilter ? true : false}
                onChange={event =>
                  onFilterChange("offMarketFilter", event.target.checked)
                }
              />
              <span style={{ display: "inline-block", marginTop: 4 }}>
                Off-market Filter
              </span>
            </div>
          </div>
        </div>
        <div>
          <button
            className="pull-right"
            onClick={event => clearFilter(event)}
            style={{
              backgroundColor: "#68B3C8",
              border: "none",
              padding: "10px 30px",
              color: "white",
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              fontSize: "1.2em",
              borderRadius: 4,
              marginLeft: "0.6em"
            }}
          >
            Clear
          </button>
        </div>
      </div>
    );
  }
}

function FormGroup({ children, disabled, label }) {
  let contents;

  if (disabled) contents = <Select disabled placeholder="Coming soon" />;
  else contents = children;

  return (
    <div className="form-group">
      <label className="col-sm-2 control-label">{label}</label>
      <div className="col-sm-10">{contents}</div>
    </div>
  );
}

export default TableRefinement;
