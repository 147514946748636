'use strict';
import React, { Component } from 'react';
import api from '../../common/api';
import { browserHistory } from 'react-router';
import * as redux from '../../redux/redux';

class ChangePasswordBase extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
        	oldPassword : '',
        	newPassword : '',
        	confirmPassword : ''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    componentWillMount() {
        const admin = this.props.user ? this.props.user.admin : '';
        const advocateToken = localStorage.getItem('USER_TOKEN');
        const clientToken = localStorage.getItem('CLIENT_TOKEN');
        if(!admin && !clientToken) {
            browserHistory.push('client-login');
        }else if(!admin) {
            browserHistory.push('/home/shortlisted-property');
        } else if (!advocateToken) {
            browserHistory.push('login');
        }
    }

    handleInputChange(event) {
	    const target = event.target;
	    const value =  target.value;
	    const name = target.name;

	    this.setState({
	      [name]: value
	    });
    }

    changePassword(event) {
		event.preventDefault();
    	if(!this.state.oldPassword || !this.state.newPassword || !this.state.confirmPassword) {
    		alert("Please fill all fields");
    	}else if(this.state.newPassword != this.state.confirmPassword) {
    		alert("Passwords don't match ")
    	} else {
    		let user = {};
    		user._id = this.props.user.info.userId ? this.props.user.info.userId : this.props.user.info._id;
    		user.oldPassword = this.state.oldPassword;
    		user.update = {
    			password : this.state.newPassword
    		}

    		api.post('/api/auth/changePassword',user).then(result => {
	            if (result.notify) {
	                alert(result.notify);
	            } else {
	            	alert(result.message)
	            	localStorage.removeItem('USER_TOKEN');
			        localStorage.removeItem('CLIENT_TOKEN');
			        redux.setUser('');
			        browserHistory.push('/login');
	            }
	        })
    	}
    	
    }

}

module.exports = ChangePasswordBase;