'use strict';
import React from 'react';
import Dropzone from 'react-dropzone';

// Modules
import ColumnList from '../../common/columnList';

const NotificationTypeList = [
    "Property Assignment",
    "Property Removal",
    "Queue List Update",
    "Property to Proceed time limit warning",
    "Property watchlist removal",
    "Update Property Details",
    "Settle date and Conditional date passed"
]

export default function(){
    return UserProfileComponentRender.call(this);
}

var UserProfileComponentRender = function(){
    return (
        <div className="col-md-12">
    	{ this.state.user ? 
 			<div className="row">
                <div className="col-lg-4 col-md-5">
                    <div className="card card-user">
                        <div className="image">
                            <img src="/img/background.jpg" alt="..."/>
                        </div>
                        <div className="content">
                            <div className="author">
                            	<Dropzone
                            		name="image"
                            		accept="image/jpeg, image/png"
                            		activeStyle={{border:'1px solid green'}}
                            		rejectStyle={{border:'1px solid red'}}
                            		style={{height : '100px'}}
                            		onDrop={this.onDrop.bind(this)}
                            	>	
                              		<img className="avatar border-white" src={this.state.user.image ? this.state.user.image : "/img/bryce-holdaway.jpg"} alt="..."/>
                              	</Dropzone>
                              	<h4 className="title">{this.state.user.first_name && this.state.user.last_name ? this.state.user.first_name+" "+this.state.user.last_name : "-" }<br />
                                	<a href="#"><small>{this.state.user.first_name && this.state.user.last_name ? "@"+this.state.user.first_name.toLowerCase()+""+this.state.user.last_name.toLowerCase() : "-" }</small></a>
                              	</h4>
                            </div>
                        </div>
                    </div>
                    { (this.state.user.junior_associates && this.state.user.junior_associates.length) ? 
                        <div className="card">
                            <div className="header">
                                <h4 className="title">Team Members</h4>
                            </div>
                            <div className="content">
                                <ul className="list-unstyled team-members">
                                    { this.state.user.junior_associates.map((associate, index) => {
                                        return (
                                            <li key={index} >
                                                <div className="row">
                                                    <div className="col-xs-8">
                                                        { associate.nickname || associate.username.split("@")[0] }
                                                        <br />
                                                        <span className="text-muted"><small>Offline</small></span>
                                                    </div>
                                                    <div className="col-xs-4 text-right">
                                                        <btn className="btn btn-sm btn-icon" onClick={this.removeAssociate.bind(this, associate, index)}><i className="ti-close"></i></btn>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}               
                                </ul>
                            </div>
                        </div> : '' 
                    }
                </div>
                <div className="col-lg-8 col-md-7">
                    <div className="card">
                        <div className="header">
                            <h4 className="title">Edit Profile</h4>
                        </div>
                        <div className="content">
                            <form>
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>Company</label>
                                            <input type="text" className="form-control border-input" placeholder="Company" value="Empower Wealth" readOnly/>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Nickname</label>
                                            <input type="text" name="nickname" className="form-control border-input" placeholder="Nickname" value={this.state.user.nickname ? this.state.user.nickname : ''} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Email address</label>
                                            <input type="email" className="form-control border-input" placeholder="Email" value={this.state.user.username} readOnly/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <input type="text" name="first_name" className="form-control border-input" placeholder="First Name" value={this.state.user.first_name ? this.state.user.first_name : ''} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input type="text" name="last_name" className="form-control border-input" placeholder="Last Name" value={this.state.user.last_name ? this.state.user.last_name : ''} onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                </div>    
                                <div className="row">
                                	<div className="col-md-5">
                                        <label>Select Column Preferences</label>
                                    </div>
                                	<div className="col-md-7">
	                                    <div className="keep-open btn-group" title="Columns">
											<button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false" style={{marginLeft : '100px'}}>
												<i className="glyphicon fa fa-columns"></i>
												<span className="caret"></span>
											</button>
											<ul className="dropdown-menu customize-list" role="menu">
												{ ColumnList ? ColumnList.map((column, index) => {
													let colPresent = this.state.user.preference_list && this.state.user.preference_list.length ? _.find(this.state.user.preference_list, function(col) {
														if(col.key == column.key) {
															return true;
														}
													}) : false;
													return (
													<li key={index}>
													 	<label>
													 		<input type="checkbox" name="preference_list" data-field="id" value={index} checked={colPresent ? "checked" : ""} onChange={this.changePreferences.bind(this, index, column)} />{column.label}
												 		</label>
													</li>
													)
												}) : ( <li>No Data</li> )
											}
											</ul>
										</div>
									</div>	
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-md-5">
                                         <label>Select Notification Preferences</label>
                                    </div>
                                     <div className="col-md-7">
                                         <div className="keep-open btn-group" title="Columns">
                                             <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false" style={{marginLeft : '100px'}} >
                                                <i className="glyphicon fa fa-columns"></i>
                                                <span className="caret"></span>
                                             </button>
                                             <ul className="dropdown-menu customize-list" role="menu">
                                                 { NotificationTypeList && NotificationTypeList.length ? NotificationTypeList.map((notification,index) => {
                                                     return (
                                                       <li key={index}>
                                                           <label>
                                                               <input type="checkbox" name="notification_preference_list" data-field="id" value={index} checked={this.state.user.notification_preferences[notification] ? true : false} onChange={this.changeNotificationPreferences.bind(this, index, notification)} />{notification}
                                                           </label>
                                                       </li>
                                                      )
                                                 }) : ''}
                                             </ul>
                                         </div>
                                     </div>  
                                 </div>
                                <div className="row text-center" style={{marginTop : '5px'}}>
                                    <button type="submit" className="btn btn-info btn-fill btn-wd" onClick={this.updateUser}>Edit Profile</button>
                                </div>
                                <div className="clearfix"></div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 col-md-7" style={{position: 'sticky'}}>
                    <div className="card">
                        <div className="header">
                            <h4 className="title">Manage Associates</h4>
                        </div>
                        <div className="content">
                            <form>
                                <div className="row">
                                    <div className="col-md-10">
                                        <div className="form-group">
                                            <select name="addAssociate" className="form-control" value={this.state.addAssociate} onChange={(event)=>{this.setState({addAssociate : event.target.value})}} >
                                                <option value="">Select Associate</option>
                                                { this.state.buyerList ? this.state.buyerList.map((buyer, index) => { 
                                                    return (
                                                        <option key={index} value={buyer._id} >{buyer.nickname || buyer.username.split("@")[0]}</option>
                                                    )
                                                }) : <option>No Associate</option>}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <button className="btn btn-sm btn-info btn-fill" onClick={this.moveAssociate}>Add</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> : ''
        }
        </div>
    )
};
