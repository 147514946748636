'use strict';
import Base  from './DashboardBase';
import Render  from './DashboardRender';
import { connect } from 'react-redux';

class Dashboard extends Base {
    componentDidUpdate() {
        const element = document.getElementById('scrollToTopContainer');
        if (element) {
            element.scroll(0, 0);
        }
    }
    render() {
        return Render.call(this, this.props, this.state);
    }
}

export default Dashboard = connect((state)=>{
    return {user:state.user || {}}
})(Dashboard);