import React from "react";

// Components
import { FormInput, TypeRadioButtons } from "../FormComponents";

function AddClient({
  firstName,
  lastName,
  middleName,
  onAddClick,
  onInputChange,
  type,
  email
}) {
  return (
    <div>
      <FormInput
        label={
          <span>
            First Name<star>*</star>
          </span>
        }
        name="firstName"
        onChange={onInputChange}
        required
        value={firstName}
      />

      <FormInput
        label="Middle Name"
        name="middleName"
        value={middleName}
        onChange={onInputChange}
      />

      <FormInput
        label={
          <span>
            Last Name<star>*</star>
          </span>
        }
        name="lastName"
        onChange={onInputChange}
        required
        value={lastName}
      />
       <FormInput
        label={
          <span>
            Keap Email Address <star>*</star>
          </span>
        }
        name="email"
        onChange={onInputChange}
        required
        value={email}
        type="email"
      />
      <TypeRadioButtons checked={type} name="type" onChange={onInputChange} />

      {/* Submit button */}
      <div className="text-center">
        <button
          type="submit"
          className="btn btn-fill btn-info"
          onClick={onAddClick}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default AddClient;
