'use strict';
import React from 'react';
import { Link } from 'react-router';

export default function(){
    return LoginComponentRender.call(this);
}

var LoginComponentRender = function(){
    return (
       <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            <div data-color="blue" className="card pages-bgcolor">
                <form id="loginFormValidation" onSubmit={this.login}>
                    <div className="header">
                        <h4 className="title pages-color">
                            BA Login
                        </h4>
                    </div>
                    <div className="content">
                        <div className="form-group">
                            <label className="control-label pages-color">
                                Username
                                <star>*</star>
                            </label>
                            <input className="form-control" ref="username" placeholder="Enter username" name="username" type="text" required="true" autoComplete="off" onChange={(e)=>{this.setState({username:e.target.value})}} value={this.state.username} />
                        </div>
                        <div className="form-group">
                            <label className="control-label pages-color">Password
                                <star>*</star>
                            </label>
                            <input className="form-control" name="password" placeholder="Enter password" id="registerPassword" type={this.state.type} ref="password" required="true" onChange={(e)=>{this.setState({password:e.target.value})}} value={this.state.password} />
                        </div>
                    </div>
                    <div className="footer text-center">
                        <button type="submit" className="btn pages-buttoncolor">Login</button>
                        <div className="forgot">
                            <Link className="pages-color" to="/forgot-password">Forgot your pasword?</Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
};

