import React, { Component } from "react";
import ReactModal from "react-modal";

// Components
class DeleteConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            body: "",
            leftButtonTitle:"",
            rightButtonTitle:""
        }
    }


   

    componentWillReceiveProps(props, state) {
        switch (props.mode) {
            case "delete property":
                this.setState({
                    title: "Are you sure you want to delete this record?",
                    body: "Deleting this record will parmanently delete it and you will not be able to recover this record.",
                    leftButtonTitle:"Cancel",
                    rightButtonTitle:"Delete"
                });
                break;
            case "fetch cagr":
                this.setState({
                    title: "Are you sure you want to fetch CAGR data?",
                    body: "This action will override the existing CAGR data.",
                    leftButtonTitle:"No",
                    rightButtonTitle:"Yes"
                });
                break;
            default:
                break;
        }

    }

    render() {
        const customStyles = {
            content: {
                backgroundColor: "white",
                padding: 0,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                Width: "50%",
                padding: "0px 14px",
                opacity: 1,
                borderColor: "#f5f5f5",
                height: "200px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center"
            },
            overlay: {
                zIndex: 2,
                backgroundColor: "rgba(0, 0, 0, 0.2)"
            }
        };

        const {
            isOpen,
            onCloseClick,
            onSubmit,
            mode
        } = this.props;
        return (
            <ReactModal
                isOpen={isOpen}
                style={customStyles}
            >
                <div className="confirm-modal-head">
                    <h5 className="title"><b>{this.state.title}</b></h5>
                </div>
                <div className="col-xs-12">
                    <div className="content">
                        <p> <small>
                            {this.state.body}
                        </small>
                        </p>
                    </div>
                    <div className="col-xs-12 text-center confirm-modal-down">
                        <div className="col-xs-6">
                            <button
                                className="sweet-confirm btn btn-warning btn-fill pull-right"
                                onClick={onCloseClick}
                            >
                                {this.state.leftButtonTitle}
                            </button>
                        </div>
                        <div className="col-xs-5 col-xs-offset-1">
                            <button
                                className="sweet-cancel btn btn-info btn-fill pull-left"
                                onClick={(e) => {
                                    onSubmit(e)
                                    onCloseClick()
                                }}
                            >
                                {this.state.rightButtonTitle}
                            </button>
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
    }
}

export default DeleteConfirm;
