import React from 'react';
import * as cols from './columns/index';
import TableTemplate from './TableTemplate';

const AwaitingForTenant = ({ isLoading ,rows }) => {
    const columns = [
        cols.clientCol,
        cols.propertyCol,
        cols.baCol,
        cols.settleDateCol,
        cols.daysSinceSettleCol,
        cols.dateAvailableForRentCol,
        cols.daysVacantCol,
        cols.propertyManagerCol
    ];

    const sortColumn = columns.find(x => x.path === 'days_vacant');
    
    return (
        <TableTemplate
            isLoading={isLoading}
            title="Awaiting for Tenant"
            rows={rows}
            cols={columns}
            defaultSort={{ column: sortColumn, direction: 'desc' }}
        />
    );
};

export default AwaitingForTenant;
