import _ from 'lodash';

// Modules
import api from '../../common/api';

const mapCountToPieData = (counts, bas, baIsInArray) => {
    const mapped = counts.map(baCount => {
        // Match buyer off of ID
        // TODO: This should just be as it is below, ask Arjun to fix this
        // HOT and FRWL only have one BA but they are in an array -- this is the issue
        // const ba = _.find(bas, ['_id', baCount._id.buyer[0]]);
        const ba = baIsInArray 
            ? _.find(bas, { '_id': baCount._id.buyer[0] })
            : _.find(bas, { '_id': baCount._id.buyer });

        if (!ba) {
            console.log('Cannot find buyer for:', baCount, 'buyer:', ba);
            return;
        }
        
        return {
            ba,
            count: baCount.count
        };
    });

    return _.compact(mapped);
};

export const fetchCommonCounts = bas => {
    // Presented, Proceed, Purchased, and Missed all together
    const query = encodeURIComponent(JSON.stringify({ phase: "Common" }));
    
    return api.get(`/api/dashboard/phase?filter=${query}`)
        .then(res => {
            const grouped = _.groupBy(res.list, '_id.phase');
            const mapped = _.mapValues(grouped, group => mapCountToPieData(group, bas));

            return mapped;
        });
};

export const fetchHotCounts = bas => {
    const query = encodeURIComponent(JSON.stringify({ phase: "HOT" }));

    return api.get(`/api/dashboard/phase?filter=${query}`)
        .then(res => mapCountToPieData(res.list, bas, true));
};
