'use strict';
import Base  from './FeedbackListBase';
import Render  from './FeedbackListRender';
import { connect } from 'react-redux';

class FeedbackList extends Base {
    render() {
        return Render.call(this, this.props, this.state);
    }
}

export default FeedbackList = connect((state)=>{
    return {user:state.user || {}}
})(FeedbackList);
