import React, { Component } from 'react';
import api from '../../common/api';
import * as cols from '../../components/tables/columns/index';
import { responseToTableValues } from './utils';
import TableTemplate from '../../components/tables/TableTemplate';

export default class ConditionalTable extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            rows: [],
            isLoading: true
        };
    }

    componentDidMount() {
        const query = encodeURIComponent(JSON.stringify({ status: "Conditional" }));
        
        api.get(`/api/clientPurchases/clientsWithDifferentStatus?filter=${query}`)
            .then(({ clients: engagements }) => {

                // Transform into table format yes
                const rows = engagements.map(responseToTableValues);
                this.setState({ rows, isLoading: false });
            });
    }

    render() {
        const { rows, isLoading } = this.state;
        const columns = [
            cols.clientCol,
            cols.baCol,
            cols.propertyCol,
            cols.contractValueCol,
            cols.financeCol,
            cols.financeDateCol,
            cols.bpDateCol,
            cols.condtiion1DateCol, // will show date for 3rd condition
            cols.conveyancerCol,
            cols.contractDateCol,
            cols.settleDateCol,
            cols.feeCol
        ];
       
        return (
            <TableTemplate
                isLoading={isLoading}
                title="Conditional Clients"
                rows={rows}
                cols={columns}
                showWarnings
            />
        );
    }
}
