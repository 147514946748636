import React from "react";
import moment from "moment";
import _ from "lodash";
import format from "format-number";
import './CagrRowsList.css'
const formattedNumberFn = format({ prefix: "$" });

const subTitleStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  padding: 4,
  zIndex: 10,
  fontSize: 11
};

function CagrRowsList({
  cagrPeriods,
  predictedCagrPeriods,
  predictedSale,
  priceHistory
}) {
  if (_.isEmpty(priceHistory)) {
    return (
      <tbody>
        <tr>
          <td colSpan="3" className="text-center">
            No Purchase History
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody>
      {/* Dates row */}
      <tr>
        <td colSpan="2" />
        {priceHistory.map(history => (
          <td key={history.date}>
            {moment(history.date).format("DD / MM / YYYY")}
          </td>
        ))}
      </tr>

      {/* Price history rows */}
      {priceHistory.map(history => (
        <CagrRow
          key={history.date}
          cagrPeriods={cagrPeriods.filter(x =>
            moment(history.date).isSame(x.onDate, "day")
          )}
          date={history.date}
          numOfDates={priceHistory.length}
          price={history.price}
        />
      ))}

      <PredictedCagrRow
        predictedSale={predictedSale}
        priceHistory={priceHistory}
        predictedCagrPeriods={predictedCagrPeriods}
      />
    </tbody>
  );
}

function PredictedCagrRow({
  predictedSale,
  priceHistory,
  predictedCagrPeriods
}) {
  if (!predictedSale) return null;

  if (
    priceHistory.some(x => moment(x.date).isSame(predictedSale.date, "day"))
  ) {
    return (
      <tr className="active">
        <PredictedCagrRowHeaderCell
          className="text-center"
          colSpan={2 + priceHistory.length}
        >
          The predicted sale date is the same as a historical sale date.
        </PredictedCagrRowHeaderCell>
      </tr>
    );
  } else {
    return (
      <tr className="active">
        <PredictedCagrRowHeaderCell className="text-right">
          {formattedNumberFn(predictedSale.price)}
        </PredictedCagrRowHeaderCell>
        <td className="text-right">
          {moment(predictedSale.date).format("DD / MM / YYYY")}
        </td>
        {predictedCagrPeriods.map((x, i) => (
          <CagrReturnCell key={i} returnVal={x.return} />
        ))}
      </tr>
    );
  }
}

function PredictedCagrRowHeaderCell({ children, ...rest }) {
  return (
    <td {...rest} style={{ position: "relative" }}>
      <span style={subTitleStyle}>Predicted</span>
      <span style={{ position: "relative", zIndex: 20 }}>{children}</span>
    </td>
  );
}

function CagrRow({ cagrPeriods, date, numOfDates, price }) {
  // The -1 is for the "-" character
  const numOfEmptyCells = numOfDates - cagrPeriods.length - 1;
  const emptyCells = _.times(numOfEmptyCells, x => <td key={x} />);

  return (
    <tr>
      <td className="text-right">{formattedNumberFn(price)}</td>
      <td className="text-right">{moment(date).format("DD / MM / YYYY")}</td>

      {cagrPeriods.map((x, i) => (
        <CagrReturnCell key={i} returnVal={x.return} />
      ))}

      {/* Divider */}
      <td className="text-center">-</td>

      {emptyCells}
    </tr>
  );
}

function CagrReturnCell({ returnVal }) {
  return (
    <td className="text-center">
      {typeof returnVal === "number" && returnVal.toFixed(2)}
    </td>
  );
}

export default CagrRowsList;
