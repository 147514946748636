import React, { Component } from 'react';
import formatNumber from 'format-number';
import TreeView from 'react-treeview';
import 'react-treeview/react-treeview.css';
import moment from 'moment';
import './EngagementPivot.css';
import EndlessTable from '../endlessTable/EndlessTable';

const mapValuesToTable = value => {
    const { 
        ba,
        client,
        exact_budget,
        G2G_date
    } = value;

    return {
        ba: ba.nickname,
        client: `${client.first_name} ${client.last_name}`,
        exact_budget,
        // TODO: This is probably wrong. The way it is retrieved is abnormal
        G2G_date: moment(G2G_date, moment.ISO_8601)
    };
};

export default class EngagementPivot extends Component {
    constructor(props) {
        super(props);

        const collapsedBudgets = props.data
            .reduce((acc, { budgets }) => {
                acc.push(budgets.map(() => true));
                return acc;
            }, []);

        this.state = {
            collapsedStates: props.data.map(() => true),
            collapsedBudgets
        };

        this.handleStateCollapse = this.handleStateCollapse.bind(this);
        this.handleBudgetCollapse = this.handleBudgetCollapse.bind(this);
        this.expandAll = this.expandAll.bind(this);
        this.collapseAll = this.collapseAll.bind(this);
    }

    handleStateCollapse(i) {
        let [...collapsedStates] = this.state.collapsedStates;
        collapsedStates[i] = !collapsedStates[i];
        this.setState({ collapsedStates });
    }

    handleBudgetCollapse(i, j) {
        const { collapsedBudgets } = this.state;
        
        const budgets = [...collapsedBudgets[i]];
        budgets[j] = !budgets[j];
        
        const rebuild = [
            ...collapsedBudgets.slice(0, i),
            budgets,
            ...collapsedBudgets.slice(i + 1),
        ];

        this.setState({ collapsedBudgets: rebuild });
    }

    expandAll() {
        this.setState({
            collapsedStates: this.state.collapsedStates.map(() => false),
            collapsedBudgets: this.state.collapsedBudgets.map(x => x.map(() => false))
        });
    }

    collapseAll() {
        this.setState({
            collapsedStates: this.state.collapsedStates.map(() => true),
            collapsedBudgets: this.state.collapsedBudgets.map(x => x.map(() => true))
        });
    }

    render() {
        const { data } = this.props;
        const { collapsedStates, collapsedBudgets } = this.state;
        
        return (
            <div>
                <button
                    className="btn btn-primary"
                    onClick={this.expandAll}
                >
                    Expand All
                </button>

                <button
                    className="btn btn-primary"
                    onClick={this.collapseAll}
                >
                    Collapse All
                </button>

                {data.map(({ state, budgets }, i) => {                    
                    const stateLabel = (
                        <div
                            className="engagement-pivot-state__label"
                            onClick={this.handleStateCollapse.bind(null, i)}
                        >
                            {state.name} <small>({state.quantity})</small>
                        </div>
                    );
                    
                    return (
                        // Key as state causes a uniqueness error
                        <TreeView
                            key={i}
                            nodeLabel={stateLabel}
                            collapsed={collapsedStates[i]}
                            onClick={this.handleStateCollapse.bind(null, i)}
                            itemClassName="engagement-pivot-state"
                        >
                            {budgets.map(({ budget, engagements }, j) => {
                                const budgetLabel = (
                                    <span
                                        className="engagement-pivot-budget__label"
                                        onClick={this.handleBudgetCollapse.bind(null, i, j)}
                                    >
                                        {budget.name} <small>({budget.quantity})</small>
                                    </span>
                                );
                                
                                return (
                                    <TreeView
                                        key={j}
                                        nodeLabel={budgetLabel}
                                        collapsed={collapsedBudgets[i][j]}
                                        onClick={this.handleBudgetCollapse.bind(null, i, j)}
                                        itemClassName="engagement-pivot-budget"
                                    >
                                        <div style={{ padding: 15 }}>
                                            <EndlessTable
                                                // Map on the fly as data structure is odd
                                                rows={engagements.map(mapValuesToTable)}
                                                columns={[
                                                    {
                                                        path: "ba",
                                                        heading: "BA"
                                                    },
                                                    {
                                                        path: "client",
                                                        heading: "Client"
                                                    },
                                                    {
                                                        path: "exact_budget",
                                                        heading: "Budget",
                                                        headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
                                                        rowRender: value => (
                                                            <div style={{ textAlign: "right" }}>
                                                                {typeof value === "number"
                                                                    ? formatNumber({ prefix: "$" })(value)
                                                                    : "Unspecified"}
                                                            </div>
                                                        )
                                                    },
                                                    {
                                                        path: "G2G_date",
                                                        heading: "G2G Date",
                                                        headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
                                                        rowRender: value => (
                                                            <div style={{ textAlign: "right" }}>
                                                                {/* TODO: Handle incorrect dates (even if they're borked show message) */}
                                                                {value.isValid() ? value.format("DD-MMM-YY") : "Unspecified"}
                                                            </div>
                                                        )
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </TreeView>
                                );
                            })}
                        </TreeView>
                    );
                })}
            </div>
        );
    }
}
