'use strict';
import Base  from './SideMenuBase';
import Render  from './SideMenuRender';
import { connect } from 'react-redux';

class SideMenu extends Base {
    render() {
        return Render.call(this, this.props, this.state);
    }
}

export default SideMenu = connect((state)=>{
    return {user:state.user || {},
    socket: state.socket || "",}
})(SideMenu);