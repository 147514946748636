import React, { PureComponent } from "react";

// Components
import { FormCardHeader } from "../FormComponents";

class Comments extends PureComponent {
  render() {
    const { comment, onInputChange } = this.props;

    return (
      <div>
        <FormCardHeader>Comments</FormCardHeader>

        <div className="content" style={{ zIndex: "initial" }}>
          <div className="form-group">
            <textarea
              className="form-control"
              name="comment"
              onChange={onInputChange}
              rows="5"
              value={comment}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Comments;
