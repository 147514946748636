const Brokers = [
	"SG",
	"SK",
	"DW",
	"EXT",
	"TL",
	"MS",
	"JN"
]

export default Brokers;
