// socket.io interface for pure websocket

const WEBSOCKET_CONNECTING = 0;
const WEBSOCKET_OPEN = 1;
const WEBSOCKET_CLOSING = 2;
const WEBSOCKET_CLOSED = 3;
const DEBUG_MESSAGES = true;

const log = (...args) => {
  if (DEBUG_MESSAGES)
    console.log.apply(undefined, args);
}

export default class SocketWrapper {
  // socket
  constructor (socketUrl){
    this.socketUrl = socketUrl;
    this.listeners = {};
    this.reconnecting = false;
    this.backoff = 0;
    this.connect();
  }

  connect (){
    this.socket = new WebSocket(this.socketUrl);
    this.queue = [];

    this.socket.onmessage = (event) => {
      let data = {};
      try { data = JSON.parse(event.data); } catch (err){}
      const action = data.action.toLowerCase();
      log("GOT", action);

      if (this.listeners[action])
        this.listeners[action](data);
    }

    this.socket.onopen = () => {
      this.reconnecting = false;
      log("CONNECT");

      for (const item of this.queue){
        this.send(item);
      }

      this.queue = [];
      this.backoff = 0;
    }

    const reconnect = (event) => {
      return () => {
        if (event === "onclose" && this.reconnecting)
          return;

        if (!this.reconnectHandler)
          return;

        if (!this.backoff) this.backoff = 1;
        else this.backoff = Math.min(this.backoff * 1.5, 60);

        log("RECONNECTING", this.backoff);

        setTimeout(() => {  
          this.reconnecting = true;
          this.connect();
          this.reconnectHandler();
        }, this.backoff * 1000);
      };
    }

    this.socket.onerror = reconnect("onerror");
    this.socket.onclose = reconnect("onclose");
  }

  onReconnect (handler){
    this.reconnectHandler = handler;
  }

  isConnected (){
    return (this.socket.readyState == WEBSOCKET_OPEN);
  }

  emit (action, body){
    const payload = { action: action, ...(body || {}) };
    if (this.socket.readyState != WEBSOCKET_OPEN){
      this.queue.push(payload)
    } else {
      this.send(payload);
    }
  }

  send (data){
    log("SEND", data);
    this.socket.send(JSON.stringify(data));
  }

  on (action, handler){
    this.listeners[action.toLowerCase()] = handler;
  }
}