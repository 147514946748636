import React, { Component } from "react";
import moment from "moment";
import { browserHistory } from "react-router";

// Modules
import api from "../../common/api";
import { fetchCagrPeriods, fetchProperty } from "./utils";

// Components
import CagrRowsList from "./CagrRowsList";
import { FormDatepicker, FormInput } from "../FormComponents";
import ManagePriceHistory from "./ManagePriceHistory";
import Spinner from "../../common/spinner";
import withNotification from "../withNotifications";

const tableHeadingStyle = { fontSize: 16 };

class PropertyDetail extends Component {
  state = {
    override: false,
    predictedSaleDate: null,
    predictedSalePrice: "",
    predictedCagrPeriods: [],
    propertyDetail: "",

    spinner: false
  };

  componentDidMount() {
    this.getProperty();
  }

  getProperty = () => {
    fetchProperty(this.props.params.id).then(property => {
      this.setState({
        predictedSaleDate: moment(
          property.potential_purchase_date,
          moment.ISO_8601
        ),
        predictedSalePrice: property.selling_price || "",
        propertyDetail: property
      });

      if (property.potential_purchase_date && property.selling_price) {
        this.fetchPredictedCagrPeriods(
          property.potential_purchase_date,
          property.selling_price,
          property.priceHistory
        ).then(predictedCagrPeriods => this.setState({ predictedCagrPeriods }));
      }
    });
  };

  fetchPredictedCagrPeriods = (
    predictedSaleDate,
    predictedSalePrice,
    priceHistory
  ) => {
    const date = moment(predictedSaleDate);

    const priceHistoryWithPrediction = priceHistory.concat({
      date,
      price: predictedSalePrice
    });

    return fetchCagrPeriods(priceHistoryWithPrediction).then(cagrPeriods =>
      cagrPeriods.filter(x => date.isSame(x.onDate, "day"))
    );
  };

  handleSave = () => {
    const {
      predictedSaleDate,
      predictedSalePrice,
      propertyDetail
    } = this.state;

    if (!this.validateInputs()) return;

    const updatedProperty = {
      filter: propertyDetail._id,
      fields: {
        potential_purchase_date: predictedSaleDate,
        selling_price: predictedSalePrice
      }
    };

    this.setState({ spinner: true });
    return Promise.all([
      api.put("/api/property/update", updatedProperty),
      this.fetchPredictedCagrPeriods(
        predictedSaleDate,
        predictedSalePrice,
        propertyDetail.priceHistory
      )
    ])
      .then(([, predictedCagrPeriods]) => {
        this.setState({ spinner: false });

        // Update local copy of property
        this.setState(prevState => ({
          propertyDetail: {
            ...prevState.propertyDetail,
            potential_purchase_date: predictedSaleDate,
            selling_price: predictedSalePrice
          },
          predictedCagrPeriods
        }));

        this.props.showNotification("success", {
          title: "Success!",
          message: "Purchase detail updated."
        });
      })
      .catch(err => {
        this.props.showNotification("error", {
          title: Error,
          message: err.message
        });
      });
  };

  handleUpdateGrowth = () => {
    const {
      predictedSaleDate,
      predictedSalePrice,
      propertyDetail
    } = this.state;

    if (!this.validateInputs()) return;

    this.setState({ spinner: true });
    this.fetchPredictedCagrPeriods(
      predictedSaleDate,
      predictedSalePrice,
      propertyDetail.priceHistory
    )
      .then(predictedCagrPeriods => {
        this.setState({ spinner: false });

        const oldestRecoredSaleDate = propertyDetail.priceHistory[0].date;
        const predictedCagrPeriod = predictedCagrPeriods.find(
          period =>
            moment(predictedSaleDate).isSame(period.onDate, "day") &&
            moment(oldestRecoredSaleDate).isSame(period.sinceDate, "day")
        );

        const updatedProperty = {
          filter: propertyDetail._id,
          fields: {
            potential_purchase_date: predictedSaleDate,
            selling_price: predictedSalePrice,
            growth: predictedCagrPeriod.return
          }
        };

        api.put("/api/property/update", updatedProperty).then(() => {
          // Update local copy of property
          this.setState(prevState => ({
            propertyDetail: {
              ...prevState.propertyDetail,
              potential_purchase_date: predictedSaleDate,
              selling_price: predictedSalePrice
            },
            predictedCagrPeriods
          }));

          this.props.showNotification("success", {
            title: "Success!",
            message: "Purchase detail and growth updated."
          });
        });
      })
      .catch(err => {
        this.props.showNotification("error", {
          title: Error,
          message: err.message
        });
      });
  };

  // ==========================================================================
  // Helpers
  // ==========================================================================

  validateInputs = () => {
    if (!this.state.predictedSalePrice) {
      this.props.showNotification("error", {
        title: "Selling Price is mandatory!",
        message: "Selling Price is mandatory. Please fill."
      });
      return false;
    } else if (
      !this.state.predictedSaleDate ||
      !this.state.predictedSaleDate.isValid()
    ) {
      this.props.showNotification("error", {
        title: "Potential Purchase Date Format Error!"
      });
      return false;
    }
    return true;
  };

  // ==========================================================================
  // Input handlers
  // ==========================================================================

  onInputChange = ev => {
    const { name, value } = ev.target;
    this.setState({ [name]: value });
  };

  onPredictedSaleDateChange = date => {
    this.setState({ predictedSaleDate: date });
  };

  toggleOverrideScreen = ev => {
    ev.preventDefault();

    this.setState(prevState => ({ override: !prevState.override }));
  };

  cancelOverrideHandler = () => {
    this.setState({ override: false });
    this.getProperty();
  };

  cancelEditing = ev => {
    ev.preventDefault();
    browserHistory.push("/home");

  };

  // ==========================================================================
  // Render
  // ==========================================================================

  render() {
    const {
      _id: id,
      address,
      addressLabel,
      cagr,
      notes,
      potential_purchase_date,
      priceHistory: ph,
      selling_price
    } = this.state.propertyDetail;

    // Sort dates into ascending order
    // priceHistory is set to null on the backend when it has no values (I think)
    const priceHistory = ph ? _.sortBy(ph, "date") : [];

    if (this.state.override) {
      return (
        <div className="row">
          <div className="col-md-12">
            <ManagePriceHistory
              address={address}
              notes={notes}
              onClose={this.cancelOverrideHandler}
              priceHistory={priceHistory}
              propertyId={id}
            />
          </div>
        </div>
      );
    }

    let predictedSale = null;

    if (potential_purchase_date && selling_price) {
      predictedSale = {
        date: potential_purchase_date,
        price: selling_price
      };
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="header">
              <div className="row">
                <div className="col-sm-6">
                  <h4
                    className="title"
                    style={{ fontFamily: '"Poppins", sans-serif' }}
                  >
                    Property Detail
                  </h4>
                </div>
                <div className="col-sm-6 f-15">
                  <label className="control-label right">Override </label>
                  <input
                    checked={this.state.override}
                    className="right"
                    onChange={this.toggleOverrideScreen}
                    style={{ marginRight: "0.3em" }}
                    type="checkbox"
                  />
                </div>
              </div>
              <p className="category">A table for Price Management</p>
              <br />
            </div>

            {/* Core content */}
            <div className="clearfix" style={{ padding: "0 15px 15px 15px" }}>
              {this.state.spinner && (
                <div className="spinner-pos">
                  <Spinner />
                </div>
              )}

              <h4 style={{ marginTop: 0 }}>Address being Scraped : &nbsp; {address}</h4>
              <p>{notes || ""}</p>
              <h4 style={{ marginTop: 0, backgroundColor: 'orange', padding: 8 }}>Address Label from Price History Source : &nbsp;{addressLabel}</h4>

              <div className="table-responsive">
                <table className="table table-bordered cagr-table">
                  <thead>
                    <tr>
                      <th style={tableHeadingStyle}>
                        <strong>Sale Price</strong>
                      </th>
                      <th style={tableHeadingStyle}>
                        <strong>Purchase Date</strong>
                      </th>
                      <th
                        colSpan={priceHistory.length}
                        style={tableHeadingStyle}
                      >
                        <strong>Since</strong>
                      </th>
                    </tr>
                  </thead>

                  <CagrRowsList
                    cagrPeriods={cagr}
                    predictedCagrPeriods={this.state.predictedCagrPeriods}
                    predictedSale={predictedSale}
                    priceHistory={priceHistory}
                  />
                </table>
              </div>

              {/* Predicted property inputs */}
              <div className="row">
                <div className="form-group col-md-6">
                  <FormDatepicker
                    dateFormat="DD/MM/YYYY"
                    label="Predicted Sale Date"
                    onChange={this.onPredictedSaleDateChange}
                    value={this.state.predictedSaleDate}
                  />
                </div>

                <div className="form-group col-md-6">
                  <FormInput
                    label="Predicted Sale Price"
                    name="predictedSalePrice"
                    onChange={this.onInputChange}
                    placeholder="9999"
                    type="text"
                    value={this.state.predictedSalePrice}
                  />
                </div>
              </div>

              {/* Page buttons */}
              <div className="pull-right">
                <button
                  className="btn btn-default"
                  onClick={this.cancelEditing}
                >
                  Close
                </button>{" "}
                <button
                  className="btn btn-primary"
                  onClick={this.handleSave}
                  type="button"
                >
                  Save
                </button>{" "}
                <button
                  className="btn btn-primary"
                  onClick={this.handleUpdateGrowth}
                  type="button"
                >
                  Save and Update Growth
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNotification(PropertyDetail);
