import React from 'react';
import Buy_Price from '../../common/priceList';
const PRICE_RANGE = [].concat(Buy_Price);
PRICE_RANGE.push("NA");

// Properties Sales Pivot Table
export const SalesPivotTable = function(data) {
    return (
        <div className="modal fade modal-top" id={"pivotTable"+data.state} role="dialog">
            <div className="modal-dialog">
            
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Sales Pivot Table</h4>
                    </div>
                    <div className="modal-body">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Price Range</b>
                                    </td>
                                    <td className="text-right">
                                        <b>No. of Properties</b>
                                    </td>
                                    <td className="text-right">
                                        <b>Value of Properties</b>
                                    </td>
                                </tr>
                            { PRICE_RANGE.length ? PRICE_RANGE.map((price, index) => {
                                if(data[price]) {
                                    return (    
                                        <tr key={index}>
                                            <td>{price}</td>
                                            <td className="text-right">
                                                {data[price].count}
                                            </td>
                                            <td className="text-right">
                                                {data[price].soldPrice}
                                            </td>
                                        </tr>
                                    )    
                                }
                            }) : <tr>No Properties</tr>}
                            {data['total'] && <tr>
                                    <td>
                                        <b>Total</b>
                                    </td>
                                    <td className="text-right">
                                        <b>{data['total'].numberOfProperties}</b>
                                    </td>
                                    <td className="text-right">
                                        <b>{data['total'].valueOfProperties}</b>
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>      
                    </div>
                </div>

            </div>
        </div> 
    )
}

// Client Pivot Table
export const ClientPivotTable = function(data) {
    return (
        <div className="modal fade modal-top" id={"pivotTable"+data.state} role="dialog">
            <div className="modal-dialog">
            
                <div className="modal-content z-4" >
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Client Pivot Table</h4>
                    </div>
                    <div className="modal-body">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Price Range</b>
                                    </td>
                                    <td>
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td className="bor-none"><b>Buyers</b></td>
                                                    <td className="right bor-none">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-right bor-none">
                                                                        <b>Clients</b>        
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                {renderCells(data)}
                            </tbody>
                        </table>      
                    </div>
                </div>

            </div>
        </div> 
    )
}

function renderCells(data) {
    let clientPriceList = Object.assign({}, data);
    delete clientPriceList['state']
    delete clientPriceList['count']
    let list = {};
    PRICE_RANGE.length && PRICE_RANGE.map((price) => {
        if(price in clientPriceList) {
            list[price] = clientPriceList[price]
        }
    })     
    var html =  Object.keys(list).map((key, index)=>{
        var buyers = list[key];
        return (
            <tr key={index}>
                <td className="pivot-range">{key}</td>
                <td>
                    <table className="table b-collapse">
                    <tbody>
                    { Object.keys(buyers).map((buyerKey, i)=>{
                        let length = Object.keys(buyers).length;
                        return (
                            <tr key={i} className={(length-1==i) ? "" : "pivot-separator"}><td className={i%2 ? "bor-none pivot-even" : "bor-none pivot-odd"} >{buyerKey}</td><td className="bor-none"><table className={i%2 ? "table pivot-even" : "table pivot-odd"} >
                            <tbody>
                            {
                                buyers[buyerKey].map((client, ind)=>{
                                    return (
                                        <tr key={ind}><td className="text-right bor-none">{client}</td></tr>
                                    )
                                })
                            }
                            </tbody></table></td></tr>
                        )
                    }
                    )}
                    </tbody>
                    </table>
                </td>
            </tr>
        )
    })
    return html;
}

