import moment from "moment";

export function durationFromDateToToday(date) {
  if (date && moment(date).isValid()) {
    // DB dates are start-of-day dates
    const newDate = moment(moment(date).format('YYYY-MM-DDT00:00:00+00:00'));
    const today = moment().startOf("day");
    return moment.duration(newDate.diff(today));
  }
  return null;
}

export function durationFromDateToTodayInDays(date) {
  const duration = durationFromDateToToday(date);
  return duration ? Math.floor(duration.asDays()) : null;
}

export const durationFromTodayToDate = date => {
  if (moment(date).isValid()) {
    // Ignore time for date
    const newDate = moment(moment(date).format('YYYY-MM-DDT00:00:00+00:00'));
    // DB dates are start-of-day dates
    const today = moment().startOf("day");
    return moment.duration(today.diff(newDate));
  }
  return null;
};

export function durationFromTodayToDateInDays(date) {
  const duration = durationFromTodayToDate(date);
  return duration ? Math.floor(duration.asDays()) : null;
}

export function getOptionalDateString(value) {
  return moment.isMoment(value) && value.isValid() ? moment(value).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null;
}
