import React, { Component } from "react";
import ReactModal from "react-modal";

// Components

class WaterMarkInfoModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewport: {
                width: document.documentElement.clientWidth
            }
        }
        this._resize_mixin_callback = this._resize_mixin_callback.bind(this)
    }
    componentDidMount() {
        window.addEventListener('resize', this._resize_mixin_callback);
    }
    _resize_mixin_callback() {
        this.setState({
            viewport: {
                width: document.documentElement.clientWidth
            }
        });
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this._resize_mixin_callback);
    }



    render() {
        let customStyles = {}
        if (this.state.viewport.width >= 2560) {
            customStyles = {
                content: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "white",
                    top: "50%",
                    left: "50%",
                    right: "0%",
                    bottom: "0",
                    transform: "translate(-50%, -50%)",
                    padding: 0,
                    width: "50%",
                    minWidth: "350px",
                    padding: "0px 3%",
                    opacity: 1,
                    borderColor: "#f5f5f5",
                    height: "600px"

                },
                overlay: {
                    zIndex: 2,
                    backgroundColor: "rgba(0, 0, 0, 0.2)"
                }
            };

        } else if (this.state.viewport.width >= 1536) {
            customStyles = {
                content: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "white",
                    top: "50%",
                    left: "50%",
                    right: "0%",
                    bottom: "0",
                    transform: "translate(-50%, -50%)",
                    padding: 0,
                    width: "50%",
                    minWidth: "350px",
                    padding: "0px 3%",
                    opacity: 1,
                    borderColor: "#f5f5f5",
                    height: "650px"

                },
                overlay: {
                    zIndex: 2,
                    backgroundColor: "rgba(0, 0, 0, 0.2)"
                }
            };

        }else if (this.state.viewport.width >= 1440) {
            customStyles = {
                content: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "white",
                    top: "50%",
                    left: "50%",
                    right: "0%",
                    bottom: "0",
                    transform: "translate(-50%, -50%)",
                    padding: 0,
                    width: "50%",
                    minWidth: "350px",
                    padding: "0px 3%",
                    opacity: 1,
                    borderColor: "#f5f5f5",
                    height: "600px"

                },
                overlay: {
                    zIndex: 2,
                    backgroundColor: "rgba(0, 0, 0, 0.2)"
                }
            };

        }else if (this.state.viewport.width >= 1024) {
            customStyles = {
                content: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "white",
                    top: "50%",
                    left: "50%",
                    right: "0%",
                    bottom: "0",
                    transform: "translate(-50%, -50%)",
                    padding: 0,
                    width: "65%",
                    minWidth: "350px",
                    padding: "0px 3%",
                    opacity: 1,
                    borderColor: "#f5f5f5",
                    height: "600px"

                },
                overlay: {
                    zIndex: 2,
                    backgroundColor: "rgba(0, 0, 0, 0.2)"
                }
            };

        }else if (this.state.viewport.width >= 768) {
            customStyles = {
                content: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "white",
                    top: "50%",
                    left: "50%",
                    right: "0%",
                    bottom: "0",
                    transform: "translate(-50%, -50%)",
                    padding: 0,
                    width: "65%",
                    minWidth: "350px",
                    padding: "0px 3%",
                    opacity: 1,
                    borderColor: "#f5f5f5",
                    height: "760px"

                },
                overlay: {
                    zIndex: 2,
                    backgroundColor: "rgba(0, 0, 0, 0.2)"
                }
            };

        }else if (this.state.viewport.width <= 768) {
            customStyles = {
                content: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "white",
                    top: "50%",
                    left: "50%",
                    right: "0%",
                    bottom: "0",
                    transform: "translate(-50%, -50%)",
                    padding: 0,
                    width: "70%",
                    minWidth: "350px",
                    padding: "0px 3%",
                    opacity: 1,
                    borderColor: "#f5f5f5",
                    height: "70%"

                },
                overlay: {
                    zIndex: 2,
                    backgroundColor: "rgba(0, 0, 0, 0.2)"
                }
            };

        }

        const {
            isOpen,
            onCloseClick,
        } = this.props;

        return (
            <ReactModal
                isOpen={isOpen}
                style={customStyles}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { onCloseClick(false) }}
            >
                <div className="modal-head">
                    <h5 className="title"><b>Watermark rules</b></h5>
                    <button
                        className="close closeBtn"
                        style={{ fontSize: 20 }}
                        onClick={onCloseClick}
                    >
                        &times;
                    </button>
                </div>

                <div className="content">
                    <p><b>If</b> – total number of engaged clients, incubation clients & g2g clients is under 3 months’ worth of clients <b>(watermark#)</b>– this is under the watermark and you need more clients.</p>
                    <p><b>If</b> – total number of engaged clients, incubation clients & g2g clients is equal to or over 3 months’ worth of clients <b>(watermark#)</b> – this is over the watermark and you do not need more clients.</p>
                    <p><b>If</b> – total number of g2g clients is equal to or under your current months budget this over rides the above <b>(G2G help me# )</b> and becomes under watermark and you need more clients. Keeping in mind a budget of 6 is 3 and under, a budget of 5 is 2 and under.</p>
                    <p><b>If</b> – total number of engaged clients, incubation clients & g2g clients is over 4 months worth of Clients <b>(4 month stop)</b> - This is over the watermark and you do not need more clients. </p>
                    <br />
                    <p><b>HOWEVER</b> – if a client is in incubation stage has been in the incubation period* for number of days in the <b>(Watermark - Incubation Threshold number<star>*</star>)</b> and over – this will NOT be included in total count of clients towards watermark.</p>
                    <p><b>HOWEVER</b> - if the client has not engaged for a full service they will also be excluded.</p>
                    <p><b>HOWEVER</b> – if a client you have engaged as NO affiliation to Empower Wealth and is from your ‘Own Network’ – this will NOT be included in total count of clients towards watermark.</p>
                    <p><b>However</b> - if a client has been deemed by management to be excluded from watermark - this will NOT be included in the total count of clients toward watermark.</p>
                    <p>Incubation period is now calculated from Set to buy date not engaged date.</p>

                </div>

                <div className="text-center modal-down">
                    <button
                        className="btn btn-info btn-fill okaybtn"
                        onClick={onCloseClick}
                    >
                        Ok
                    </button>
                </div>
            </ReactModal>
        );
    }
}

export default WaterMarkInfoModal;
