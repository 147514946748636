import React, { Component } from 'react';
import api from '../../common/api';
import { responseToTableValues } from './utils';
import AwaitingForTenant from '../../components/tables/AwaitingForTenant';

export default class AwaitingForTenantContainer extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            rows: [], 
            isLoading: true
        };
    }

    componentDidMount() {
        const query = encodeURIComponent(JSON.stringify({ status: "AwaitingTenant" }));

        api.get(`/api/clientPurchases/clientsWithDifferentStatus?filter=${query}`)
            .then(({ clients: engagements }) => {
                // Transform into table format
                const rows = engagements.map(responseToTableValues);

                this.setState({ rows, isLoading: false });
            });
    }

    render() {
        const { rows, isLoading } = this.state;
        return <AwaitingForTenant isLoading={isLoading} rows={rows} />;
    }
}
