'use strict';
import React from 'react';
import {Link} from 'react-router';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import Signup from '../signup/Signup';
import Login from '../login/Login';

export default function(){
    return HomeComponentRender.call(this);
}

var HomeComponentRender = function(){
    
    const divStyle = {
        backgroundImage: 'url(' + "img/background-6.jpg" + ')',
        overflow: 'hidden',
        backgroundSize: 'cover'
    };

    const bannerStyle = {
        margin: '0.5em',
        backgroundColors: 'Yellow',
        fontSize: 18,
        fontWeight: "bold",
        color: "#666",
        textAlign: "center"
    };

    return (
        <div className="wrapper">
            <Header home />
            { (typeof(process.env.INFO_BANNER_MESSAGE) === 'string') ? <div style={bannerStyle} className='info-banner'>{ process.env.INFO_BANNER_MESSAGE }</div> : '' }
            <div className="wrapper wrapper-full-page">
                <div className="full-page login-page" data-color="" data-image="img/background-2.jpg" >
                    <div className="content">
                        <div className="container">
                            <div className="row" id="login">
                                {this.props.location.pathname == '/login' ? <Login /> : <Signup />}
                            </div>
                        </div>    
                    </div>
                    <Footer />
                    <div className="full-page-background" style={divStyle} >
                    </div> 
                </div>
            </div> 
        </div>
    )
};

