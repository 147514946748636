import React from 'react';
import './PrintButton.css';

const PrintButton = props => (
    <button
        className="btn btn-primary print-button hide-during-print"
        onClick={() => window.print()}
    >
        Print <i className="fa fa-print" />
    </button>
);

export default PrintButton;
