export const purchasedPropertiesReportColumns = [
  // Property columns
  "address",
  // same as "Actual Growth" in current report
  "growth",
  "region",
  "suburb",
  "url",
  // Engagement columns
  "actual_rent",
  // Below don't exist currently
  // "Actual Yield"
  // "Sold Price"
  "ba",
  "client",
  "clientType",
  "contract_date",
  "estimated_yield",
  "rental_estimate",
  "settle_date",
  "state"
];

export const engagementColumns = {
  actual_rent: false,
  ba: false,
  budget: false,
  client_growth: false,
  client: false,
  clientType: false,
  contract_date: false,
  contract_value: false,
  conveyancer: false,
  engagement_date: false,
  estimated_yield: false,
  extra_budget: false,
  fee: false,
  financer: false,
  goodTogoDate: false,
  initial_date: false,
  labelForScraper: false,
  peopleHelped: false,
  property: false,
  propertyManager: false,
  realStateAgency: false,
  realStateAgent: false,
  rental_estimate: false,
  service: false,
  setToBuyDate: false,
  settle_date: false,
  state: false,
  status: false,
  tenanted_date: false,
  yield: false
};

export const propertyColumns = {
  address: false,
  auction_date: false,
  auto_val: false,
  bath_rooms: false,
  bed_rooms: false,
  dsr: false,
  flood_check: false,
  fsd: false,
  growth: false,
  land_size: false,
  lsd: false,
  parking: false,
  price: false,
  region: false,
  rent: false,
  suburb: false,
  type: false,
  url: false,
  vr: false,
  walk_score: false,
  year_build: false,
  yields: false
};
