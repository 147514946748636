import React from "react";

// Modules
import { formatDaysDuration } from "./utils";

export const daysToSettleCol = {
  path: "days_to_settle",
  heading: "Days to Settle",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: formatDaysDuration
};
