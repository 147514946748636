import React from 'react';
import validator from 'validator';
// TODO this is causing a warning
// Accessing PropTypes via the main React package is deprecated, and will be removed in  React v16.0...
import Validation from 'react-validation';

Object.assign(Validation.rules, {
    api: {
        hint: value => (
            <button
                className="form-error is-visible"
            >
                API Error on "{value}" value. Focus to hide.
            </button>
        )
    },

    required: {

        rule: value => value.toString().trim(),
        hint: () => <span style={{color:'red',fontSize:"12px"}} className="form-error is-visible">Required</span>
    },

    terms: {
        rule: (value, components) => {
        return components.terms.state.isChecked},
        hint: () => <span className="form-error is-visible">Please accept terms and conditions<span style={{color:'red'}}> *</span></span>
    },

    email: {
        rule: value => validator.isEmail(value),
        hint: value => <span className="form-error is-visible" style={{color:'red',fontSize:"12px"}}>{value} is not an Email.</span>
    },

     points: {
        rule: value => (value<11),
        hint: value => <span className="form-error is-visible" style={{color:'red',fontSize:"12px"}}>Maximum points should not exceed 10</span>
    },

    zero: {
        rule: value => (value>=1),
        hint: value => <span className="form-error is-visible" style={{color:'red',fontSize:"12px"}}>Sorry, Zero not allowed</span>
    },

    alpha: {
        rule: value => {
            var regex = /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/;             
            if(value.match(regex))   
            return value;
        },
        hint: () => (
            <span className="form-error is-visible" style={{color:'red',fontSize:"12px"}}>
                Please enter a valid username.
            </span>
        )
    },

    beta : {
        rule: value => validator.isNumeric(value),
        hint: () => (
            <span className="form-error is-visible" style={{color:'red',fontSize:"12px"}}>
                String should only contain numbers 0-9
            </span>
        )
    },

    password: {
        rule: (value, components) => {
            
            const password = components.password.state;
            const passwordConfirm = components.passwordConfirm.state;
            const isBothUsed = password
                && passwordConfirm
                && password.isUsed
                && passwordConfirm.isUsed;
            const isBothChanged = isBothUsed && password.isChanged && passwordConfirm.isChanged;    

            if (!isBothUsed || !isBothChanged) {
                return true;
            }



            return password.value === passwordConfirm.value;
        },
        hint: () => <span className="form-error is-visible" style={{color:'red',fontSize:"12px"}}>Passwords should be equal.</span>
    },

    passwordLength: {
        rule: (value, components) => {
            const password = components.password.state;
            var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;  
            if(password.value.match(passw))   
            return password.value;
        },
        hint: () => <span className="form-error is-visible" style={{color:'red',fontSize:"12px"}}>Password must be 8 characters including 1 uppercase letter, 1 special character, alphanumeric character</span>
    }


});
