import React, { Component } from "react";
import { Link } from "react-router";
import classNames from "classnames";

// Modules
import editPropertyBody from "./editPropertyBody";

// Components
import StarRatings from "../../components/startRating/index";
import api from "../../common/api";

// This cannot be a PureComponent with its current use of the editPropertyBody
// function. When this is a PureComponent it does not forward the PropertyListBase
// state updates to the editPropertyBody function, causing the controlled inputs
// within to not receive the state updates
class TableRows extends Component {
  state = {
    isAdmin : false
  };
  async componentWillMount() {
    const user = await api.get('/api/users/getUserData');
    this.setState({
      isAdmin :  user.user_role === "manager" ? true : false
    })
}
  handleAcceptAssigned = ev =>
    this.props.onAcceptAssigned(this.props.property, ev);

  handleArchive = ev =>
    this.props.onArchive(this.props.property, this.props.index, ev);

  handleArchiveDoubleClick = ev =>
    this.props.onArhciveDoubleClick(this.props.property, this.props.index, ev);

  handleCagrClick = ev =>
    this.props.onCagrClick(this.props.property.propertyId, ev);

  handleChangeRating = (startRating, ev) => {
    ev.preventDefault();
    this.props.onChangeRating(
      startRating,
      this.props.property,
      this.props.index
    );
  };

  handleDetailsEditOpen = () =>
    this.props.onSubComponentOpen(
      this.props.property,
      this.props.index,
      "edit"
    );

  handleInspect = ev =>
    this.props.onInspect(this.props.property, this.props.index, ev);

  handlePhaseEditOpen = () =>
    this.props.onSubComponentOpen(
      this.props.property,
      this.props.index,
      "editPhase"
    );

  handleRefuse = ev =>
    this.props.onRefuse(this.props.property, this.props.index, ev);

  handleSelectProperty = () => this.props.onSelectProperty(this.props.property);

  handleSold = () => this.props.onSold(this.props.property, this.props.index);
  handleDelete = () => this.props.onDelete(this.props.property, this.props.index);

  render() {
    const {
      clickedCollapse,
      editPhase,
      index,
      phaseColor,
      property,
      propertyListBase,
      seen,
      stateIndex,
      isSelected
    } = this.props;

    return (
      <tr
        className={classNames({
          "off-market": property.flag,
          property_sold:
            property.sold ||
            property.soldPrice ||
            property.icon === " property_sold ",
          under_contract_exp: property.icon === "under_contract_exp"
        })}
      >
        {/* Collapse button */}
        <td
          className="text-size"
          id={"collapse-col-" + property.id}
          onClick={clickedCollapse}
        />

        {/* Selection and phase management dropdown */}
        <td className="text-size" id={"hidden-col-" + property.id}>
          {seen && (
            <i
              className="ti-eye p-r-10 cursor-position"
              onClick={this.handleAcceptAssigned}
            />
          )}
          <input
            type="checkbox"
            name="selectProperty"
            onClick={this.handleSelectProperty}
            checked={isSelected}
          />
          <div style={{ display: "none" }} className="hidden-td">
            {" "}
            {/* TODO replace this with a real component */}
            {editPropertyBody(propertyListBase, property, index)}
          </div>
        </td>

        {/* Phase */}
        <td className="text-size text-center">
          <button
            className={
              property.propertyPhase === "PIG"
                ? "btn btn-xs btn-fill pig-color"
                : "btn btn-xs btn-fill"
            }
            style={{
              backgroundColor: phaseColor,
              borderColor: phaseColor,
              padding: "2px 10px"
            }}
          >
            {property.propertyPhase || "-"}
          </button>
          <span style={{ display: "block" }}>{property.daysleft}</span>
        </td>

        {/* Actions */}
        <td className="td-actions text-right" style={{ fontSize: "13px" }}>
          <a rel="tooltip" title="Property Detail" className="p-a-7">
            <i
              className={classNames({
                "ti-face-sad red-col": property.propertyDog,
                "ti-face-smile prop-detail-color": property.dueDiligence,
                "ti-image t-image-pos pig-color":
                  !property.propertyDog && !property.dueDiligence
              })}
              onClick={this.handleDetailsEditOpen}
            />
          </a>
          <a rel="tooltip" title="Manage Phases" className="p-r-7">
            <i
              name="editPhase"
              className={
                editPhase && stateIndex === index
                  ? "ti-arrow-circle-up cursor-position pig-color"
                  : "ti-arrow-circle-down cursor-position pig-color"
              }
              onClick={this.handlePhaseEditOpen}
            />
          </a>
          <a
            rel="tooltip"
            title={
              property.archive === "archived"
                ? "Archive Property"
                : "UnArchive Property"
            }
            className="p-r-7"
          >
            <i
              name="archive"
              className={classNames("ti-flag-alt", {
                archive: property.archive === "archived",
                "donot-archive": property.archive === "donot-archive",
                "non-archive":
                  property.archive !== "archived" &&
                  property.archive !== "donot-archive"
              })}
              onClick={this.handleArchive}
              onDoubleClick={this.handleArchiveDoubleClick}
            />
          </a>
          <a rel="tooltip" title="Refuse" className="p-r-7">
            <i
              name="refuse"
              className="ti-alert cursor-position pig-color"
              onClick={this.handleRefuse}
            />
          </a>
          <a
            rel="tooltip"
            title={
              property.inspectedBy
                ? `Inspected By ${property.inspectedBy}`
                : "Not inspected yet"
            }
          >
            <i
              name="inspected"
              className={
                property.inspectedBy
                  ? "ti-direction-alt cursor-position prop-inspected"
                  : "ti-direction-alt cursor-position pig-color f-15"
              }
              onClick={this.handleInspect}
            />
          </a>
          <Link
            to={"/home/property-detail/" + property.propertyId}
            rel="tooltip"
            title="Manage Price"
          >
            <i
              name="managePrice"
              className={
                property.growthFilled
                  ? "ti-money cursor-position prop-inspected"
                  : "ti-money cursor-position pig-color"
              }
              onClick={this.handleCagrClick}
            />
          </Link>
          <a
            rel="tooltip"
            title={property.sold ? "Sold" : "Not sold"}
            onClick={this.handleSold}
            className={
              property.sold || property.soldPrice
                ? "soldProperty p-r-7"
                : "notSoldProperty p-r-7"
            }
          >
            <i className="fas fa-gavel" />
          </a>
          {
            this.state.isAdmin && <a
              rel="tooltip"
              title={"Delete Property"}
              className={"deleteProperty "}
            >
              <i className="fas fa-trash"
                onClick={this.handleDelete} />
            </a>
          }
        </td>

        {/* Rating */}
        <td className="text-size">
          <StarRatings
            rating={property.rating}
            starRatedColor="#5cb85c"
            changeRating={this.handleChangeRating}
            numberOfStars={5}
            starDimension="2rem"
          />
        </td>

        <td className="text-size">{property.g2gDate}</td>
        <td className="text-size">{property.client}</td>
        <td className="text-size">{property.suburb}</td>

        {/* Address */}
        <td className="text-size">
          <a
            href={
              property.link || `/home/map-view?property=${property.propertyId}`
            }
            target="_blank"
          >
            {property.address}
          </a>
        </td>

        {/* Price */}
        <td className="text-size text-center">{property.price || "-"}</td>

        {/* Buy price */}
        <td className="text-size text-center">{property.buyPrice || "-"}</td>

        {/* Sold price */}
        <td className="text-size text-center">
          {getSoldPriceAndExpirationDate(
            property.soldPrice,
            property.under_contract_exp_date
          )}
        </td>

        <td className="text-size text-center">{property.beds}</td>
        <td className="text-size">{property.baths}</td>
        <td className="text-size">{property.parking}</td>
        <td className="text-right text-size">{property.buyerAdvocate}</td>
        <td className="text-right text-size">{property.spotter}</td>
        <td className="text-size">{property.addedAt}</td>
      </tr>
    );
  }
}

const getSoldPriceAndExpirationDate = (soldPrice, expirationDate) => {
  if (soldPrice && expirationDate)
    return `${soldPrice}\n / \n${expirationDate}`;
  else if (expirationDate) return expirationDate;
  else if (soldPrice) return soldPrice;
  else return "-";
};

export default TableRows;
