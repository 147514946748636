import React, { Component } from "react";
import NotificationSystem from "react-notification-system";

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

// This component was previously in common/notification.js
class Notifications extends Component {
  state = { notificationSystem: null };

  componentDidMount() {
    this.state.notificationSystem = this.refs.notificationSystem;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show && Object.keys(nextProps.data).length > 0) {
      this.state.notificationSystem.addNotification(nextProps.data);
    }
  }

  render() {
    return <NotificationSystem ref="notificationSystem" />;
  }
}

function withNotifications(WrappedComponent) {
  return class extends Component {
    state = { data: null, show: false };

    static displayName = `WithNotifications(${getDisplayName(
      WrappedComponent
    )})`;

    handleShowNotification = (type, data) => {
      const defaultData = {
        title: "",
        message: "",
        level: "success",
        position: "tr",
        autoDismiss: 7,
        children: null
      };

      this.setState(
        { show: true, data: { ...defaultData, ...data, level: type } },
        () => {
          this.setState({ show: false });
        }
      );
    };

    render() {
      return (
        <div>
          <WrappedComponent
            showNotification={this.handleShowNotification}
            {...this.props}
          />

          <Notifications data={this.state.data} show={this.state.show} />
        </div>
      );
    }
  };
}

export default withNotifications;
