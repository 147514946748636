import { connect } from "react-redux";

// Modules
import Base from "./UserProfileBase";
import Render from "./UserProfileRender";

// Components
import withNotifications from "../withNotifications";

class UserProfile extends Base {
  render() {
    return Render.call(this, this.props, this.state);
  }
}

const mapStateToProps = state => ({ user: state.user || {} });

export default connect(mapStateToProps)(withNotifications(UserProfile));
