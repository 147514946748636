'use strict';
import React, { Component } from 'react';
import moment from 'moment';
import formatNumber from 'format-number';
import _ from 'lodash';
import qs from 'qs';

// Modules
import api from '../../common/api';
import Phase_List from '../../common/phaseList';
import mapStyle from './MapStyles'
import MapInfoWindow from './MapInfoWindow';
import { sortByBudget } from '../../common/budget-tiers';
import significantUrbanAreasList from '../../common/suburbToSua.json';

function fetchPurchasedProperties() {
	const filter = {
		phases: ["Purchased"],
		showArchived: true
	};
  
	return api
		.get(`/api/properties?${qs.stringify(filter)}`)
		.then(res => res.items);
}

class MapViewBase extends Component {
    
    constructor(props) {
		super(props);

        this.state = {
        	stateList : {},
        	clientList : {},
        	phaseList: [{value:'All', label:'All'}, {value:'PIG', label:'PIG'}, {value:'HOT', label:'HOT'}, {value:'Presented', label:'Presented'}, {value:'Proceed', label:'Proceed'}, {value:'Purchased', label:'Purchased'}],
        	selectPhase: ['HOT'],
        	priceRange : '',
        	fromDate : '',
        	toDate : '',
        	buyer : '',
        	countryState : '',
        	statePivotTable : '',
        	clientPivotTable : '',
        	clientPivot : false,
        	growth : '',
        	yield : '',
        	finance : '',
        	status : 'GoodToGo',
        	archived : true,
        	unarchived : true,
			spinner : true,
			purchasedPropertyCsvData: [], // CSV data of purchased Property, to be downloaded by clicking on the Download CSV button
			pwpPurchases: [],
			regionalMapping: [],
			financialYear: {
				start: null,
				end: null
			}
		};

        this.styles = mapStyle;
	    this.openPivotTable = this.openPivotTable.bind(this); 
	    this.handleInputChange = this.handleInputChange.bind(this); 
	    this.filterList = this.filterList.bind(this); 
	    this.resetFilter = this.resetFilter.bind(this); 
		this.renderPivotTable = this.renderPivotTable.bind(this); 
		/* Method is setting up data for CSV */
		this.downloadPurchasedPropertiesCsv = this.downloadPurchasedPropertiesCsv.bind(this); 
		/* Set Data i.e making rows for the CSV except the header row */
		this.setDataForTheCsv = this.setDataForTheCsv.bind(this);		
		/* Handle the CSV uploading  */
		this.handleCsvUploading = this.handleCsvUploading.bind(this);
		/* Handle the CSV uploading erros  */
		this.handleCsvUploadingError = this.handleCsvUploadingError.bind(this);
		/* Handle the engagement uploading  */
		this.handleEngagementCsvUploading = this.handleEngagementCsvUploading.bind(this);

		/* Method for getting the region from the SUA  */
		this.getSUAForProperty = this.getSUAForProperty.bind(this); 
		/* Method handle from and to dates */
		this.handleDateChange = this.handleDateChange.bind(this);

    }

    componentDidMount() {
		if (this.props.location.query) {
			this.getList(this.props.location.query);
		}
		/* Set data for downloading the CSV */
		// this.downloadPurchasedPropertiesCsv();

		// Regional Mapping and PWP Purchases
		api.get('/api/property/currentFinancialYearPurchases')
			.then(res => {
				this.setState({
					financialYear: {
						start: moment(res.year.start, moment.ISO_8601),
						end: moment(res.year.end, moment.ISO_8601)
					}
				});

				const pwpPurchases = [this.getPWPPurchases(res.properties)];
				this.setState({ pwpPurchases });

				const regionalMapping = this.getRegionalMapping(res.properties);
				const sorted = _.sortBy(regionalMapping, 'count').reverse();
				this.setState({ regionalMapping: sorted });
			});

		// Engagement Pivot Table
		const query = encodeURIComponent(JSON.stringify({ status: "GoodToGo" }));
		api.get(`/api/clientPurchases/clientPivotTable?filter=${query}`)
			.then(g2gEngagements => {
				// Tranform into a managable format
				const addBudget = g2gEngagements.purchases
					.map(({ _id: budget, purchase }) =>
						purchase.map(x => Object.assign(x, { budget })));

				const allEngagements = _.flatten(addBudget);

				const mergeStates = (acc, val) => {
					// Normalize falsey values
					if (!val.state) val.state = Boolean(val.state);

					const found = _.find(acc, x => x.state === val.state);

					if (found) {
						found.engagements = found.engagements.concat(val.engagements);
					} else {
						// First occurrence of entry
						acc.push(val);
					}

					return acc;
				};

				// Group and sort by state
				const states = _
					.chain(allEngagements)
					.groupBy('state')
					.sortBy('[0].state')
					.map(x => ({ state: x[0].state, engagements: x }))
					.reduce(mergeStates, [])
					.value();

				const defaultSectionName = "Unspecified";

				// Transform to state > budget > table (sorted by BA) 
				const statesWithBudgets = _.map(states, ({ state, engagements }) => {
					// Group and sort state's engagments by budget
					const budgets = _
						.chain(engagements)
						.groupBy('budget')
						.sortBy(x => sortByBudget(x[0].budget))
						.map(x => ({ budget: x[0].budget, engagements: x }))
						.value();

					// Sort budget's engagements by BA nickname
					const sortEngagements = _.map(budgets,
						({ budget, engagements }) => {
							const sorted = _.sortBy(engagements, 'ba.nickname');

							const budgetObj = {
								name: budget || defaultSectionName,
								quantity: engagements.length
							};

							return { budget: budgetObj, engagements: sorted };
						}
					);

					const stateObj = {
						name: state || defaultSectionName,
						quantity: _.sumBy(sortEngagements, 'engagements.length')
					};

					return { state: stateObj, budgets: sortEngagements };
				});
				this.setState({ engagementPivotTable: statesWithBudgets });
			});
	}
	
	getPWPPurchases = currentYearPurchases => {
		const counts = _.countBy(currentYearPurchases, 'client.client.type');
		const pwpCount = counts.PWP || 0;
		const baCount = counts.BA || 0;

		const total = pwpCount + baCount;

		const calculatePercentage = (total, count) => {
			const percentage = count / total;

			if (percentage === Infinity) return 0;
			return percentage;
		};

		return {
			total,
			pwpCount: {
				value: pwpCount,
				props: { percentage: calculatePercentage(total, pwpCount) }
			},
			baCount: {
				value: baCount,
				props: { percentage: calculatePercentage(total, baCount) }
			}
		};
	};

	getRegionalMapping = currentYearPurchases => {
		const suas = currentYearPurchases.map(this.getSUAForProperty);
		const suaCounts = _.countBy(suas, sua => sua ? sua.SUA_NAME : 'Non SUA');

		return _.transform(suaCounts, (result, value, key) => {
			const row = {
				sua: key,
				count: value,
				percentage: value * 100 / currentYearPurchases.length
			};
			result.push(row);
		}, []);
	};

	pwpPurchasesPercentageRender = (value, { percentage }) => (
		<span>
			{value}
			{" "}
			<span style={{
				marginLeft: "0.5em",
				color: "#777777"
			}}>
				({formatNumber({ suffix: '%', round:2 })(percentage * 100)})</span>
		</span>
	);

    getList(query) {
    	let filter = {}
    	if(query && Object.keys(query).length) {
    		filter = query;
    	} else {
	    	filter['property_phase'] = {'$in': this.state.selectPhase.indexOf('All') >=0 ? [] : this.state.selectPhase };
	    	/*if(this.state.selectPhase!="All") {
		    	filter = {property_phase : this.state.phase};
	    	}*/
	    	if(this.state.priceRange) {
	    		filter['buy_price'] = this.state.priceRange; 
	    	}
	    	if(this.state.buyer) {
	    		filter['buyer'] = this.state.buyer; 
	    	}
	    	if(this.state.countryState) {
	    		filter['state'] = this.state.countryState; 
	    	}
	    	if(this.state.fromDate && this.state.toDate) {
	    		filter['fromDate'] = this.state.fromDate.toISOString();
				filter['toDate'] = this.state.toDate.toISOString();
	    	}
	    	if(this.state.archived && this.state.unarchived) {
					delete filter['unarchived']; 
					delete filter['archived'];
			} else if(this.state.archived) {
				filter['archived'] = this.state.archived;
				delete filter['unarchived']; 
			} else {
				filter['unarchived'] = this.state.unarchived;
				delete filter['archived'];
			}
    	}


    	if((!this.state.archived) && (!this.state.unarchived)) {
			alert("Please select at least one checkbox - Archive, Unarchive or both.")
		} else {
	    	api.get('/api/property/mapView?filter=' + encodeURIComponent(JSON.stringify(filter)))
	    	.then(result => {
	    		let stateList={};
				result.list.map((elem)=>{
					stateList[elem._id.state] = stateList[elem._id.state] || {}
					stateList[elem._id.state].count = (stateList[elem._id.state].count || 0)  + elem.properties.length 
					stateList[elem._id.state]["total"] = stateList[elem._id.state]["total"] || {};
					stateList[elem._id.state]["total"]["numberOfProperties"] = stateList[elem._id.state].count 
					stateList[elem._id.state][elem._id.buy_price || "NA"] = stateList[elem._id.state][elem._id.buy_price || "NA"] || {}
					stateList[elem._id.state][elem._id.buy_price || "NA"]["count"] = (stateList[elem._id.state][elem._id.buy_price || "NA"]["count"] || 0)  + elem.properties.length
			        for(var i=0;i<elem.properties.length;i++) {
			        	stateList[elem._id.state][elem._id.buy_price || "NA"]["soldPrice"] = (stateList[elem._id.state][elem._id.buy_price || "NA"]["soldPrice"] || 0)
			        	if(elem.properties[i].sold_price) {
					        stateList[elem._id.state][elem._id.buy_price || "NA"]["soldPrice"] = (stateList[elem._id.state][elem._id.buy_price || "NA"]["soldPrice"] || 0)  + (+elem.properties[i].sold_price)
			        	}
			        }
					stateList[elem._id.state]["total"]["valueOfProperties"] = (stateList[elem._id.state]["total"]["valueOfProperties"] || 0) + stateList[elem._id.state][elem._id.buy_price || "NA"]["soldPrice"] 
			        // stateList[elem._id.state][elem._id.buy_price || "NA"] = (stateList[elem._id.state][elem._id.buy_price || "NA"] || 0)  + elem.properties.length
				})
				this.setState({
					stateList
				})
				
	    		let markers = []
	    		let allList = _.map(result.list,'properties')
	    		allList = [].concat.apply([], allList);
	    		allList.forEach(function(property) {
	    			if(property.location && property.location.lat) {
		    			let marker = [];
		    			marker.push(property.address)
		    			marker.push(parseFloat(property.location.lat))
		    			marker.push(parseFloat(property.location.lng))
		    			marker.push(property.url)
		    			marker.push(property.property_phase)
		    			marker['details'] = {
		    				_id : property._id,
		    				phase : property.property_phase,
		    				address : property.address,
		    				advPrice : property.price || '',
		    				buyPrice : property.buy_price || '',
		    				beds : property.bed_rooms || 0,
		    				baths : property.bath_rooms || 0,
		    				cars : property.parking || 0,
		    				client : (property.client && property.client.client) ? property.client.client.first_name : '',
		    				buyer : (property.client && property.client.business_analyst) ? property.client.business_analyst.nickname : '',
		    				url : property.url
		    			}
		    			markers.push(marker);
	    			}
	    		})
	    		this.setState({
	    			spinner : false
	    		})
	    		console.log("markers", markers);
	    		this.initMap(markers);
	    	})
	    	.catch(error => {
	    		console.log("error is", error);
	    	})	
		}
    }


	handleDateChange =(momentDate, date) => {
		if(date === 'fromDate') {
			this.setState({
				fromDate: moment(momentDate)
			})
		} else {
			this.setState({
				toDate: moment(momentDate)
			})
		}
	}


    handleInputChange(event, moment) {
    	const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
        this.setState({
        	[name] : value
        })
    }


    //Method for handling of selection of phase for searching
    handleSelectPhase(val, event) {
    	const list = (val && val.length) ? val.map((val) => { return val.value }) : [];
    	this.setState({
    		selectPhase: list
    	})
    }

    filterList(event) {
		event.preventDefault();
    	if(this.state.clientPivot) {
    		this.fetchClientList();
    	} else {
	    	let validDate = true;
			if(this.state.fromDate && (!this.state.fromDate.isValid())) {
				validDate = false;
			}
			if(this.state.toDate && (!this.state.toDate.isValid())) {
				validDate = false;
			}
			if(!validDate) {
				alert("Date format should be DD/MM/YYYY.")
			} else {
				if((this.state.fromDate && !this.state.toDate) || (!this.state.fromDate && this.state.toDate)){
					alert("Both From and To Date should be there.")
				} else {
					this.setState({
						spinner: true
					})
			    	this.getList();
				}
			}
    	}
    }

    customIcon (opts) {
	    return Object.assign({
		    path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
		    fillColor: '#34495e',
		    fillOpacity: 1,
		    strokeColor: '#fff',
		    strokeWeight: 3,
		    scale: 1,
	    }, opts);
	}

	initMap(markers) {
		
		var uluru = {lat: -37.832916, lng: 144.870447};
		var map = new google.maps.Map(document.getElementById('australiaMap'), {
			zoom: 8,
			center: new google.maps.LatLng(-37.832916, 144.870447),
			gestureHandling: 'cooperative'
		});
        map.setTilt(45);
        var infoWindow = new google.maps.InfoWindow();
        var bounds = new google.maps.LatLngBounds();
		for(var i = 0; i < markers.length; i++ ) {
		    var position = new google.maps.LatLng(markers[i][1], markers[i][2]);
		    bounds.extend(position);
		    var marker = new google.maps.Marker({
				position,
				map: map,
		        icon: this.customIcon({
				    fillColor: this.state.phase ? Phase_List[markers[i][4]] : '#34495e'
			    }),
				title: markers[i][0]
		    });
		    
		    //Attach click event to the marker.
            (function (map, marker, details) {
                marker.addListener("click", function (e) {
                    var contentString = MapInfoWindow(details);
    				infoWindow.setContent(contentString);
    				var colr = {
						    path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
						    fillColor: 'black',
						    fillOpacity: 1,
						    strokeColor: '#fff',
						    strokeWeight: 3,
						    scale: 1,
					    }
    				this.setIcon(colr)
                    //Wrap the content inside an HTML DIV in order to set height and width of InfoWindow.
                    infoWindow.open(map, marker);
                });
            })(map, marker, markers[i]['details']);
		    // this.attachLink(map, marker, markers[i]['details'])

		    map.fitBounds(bounds);
		}
        
        // Set the map's style to the initial value of the selector.
        // map.setOptions({styles: this.styles['retro']});

    }

    openPivotTable(stateDetail, index, stateKey, event) {
    	stateDetail["state"] = stateKey
    	let name = this.state.clientPivot ? "clientPivotTable" : "statePivotTable";
    	this.setState({
    		[name] : stateDetail
    	})
    }

  //   attachLink(map, marker, details) {
  //   	// var a = directedLink
  //   	var contentString = MapInfoWindow(details);
  //   	infoWindow.setContent(contentString);
  //   	// var infowindow = new google.maps.InfoWindow({
  //    //      content: contentString
  //    //    });
		// marker.addListener('click', function() {
  //   		infoWindow.open(map, marker);
		// });
  //   }

  	manipulateFilter(fields) {
  		let newFilter = {}
  		fields.map((field) => {
  			if(this.state[field]) {
  				newFilter[field] = this.state[field];
  			}
  		})
  		return newFilter;
  	}

    fetchClientList() {
    	let filter = this.manipulateFilter(['growth','yield','finance','status']);
    	api.get('/api/client/pivotTable?filter=' + encodeURIComponent(JSON.stringify(filter)))
    	.then(result => {
    		let clientList = {};
    		result.list.map((elem) => {
    			if(elem._id.state) {
	    			clientList[elem._id.state] = clientList[elem._id.state] || {}
	    			clientList[elem._id.state].count = (clientList[elem._id.state].count || 0)  + elem.clients.length
	    			clientList[elem._id.state][elem._id.price_range || "NA"] = clientList[elem._id.state][elem._id.price_range || "NA"] || {}
	    			elem.clients.map((client) => {
	    				// clientList[elem._id.state][elem._id.price_range || "NA"].count = (clientList[elem._id.state][elem._id.price_range || "NA"].count || 0) + 1;
	    				clientList[elem._id.state][elem._id.price_range || "NA"][client.business_analyst.nickname || client.business_analyst.first_name] = clientList[elem._id.state][elem._id.price_range || "NA"][client.business_analyst.nickname || client.business_analyst.first_name] || []	
	    				clientList[elem._id.state][elem._id.price_range || "NA"][client.business_analyst.nickname || client.business_analyst.first_name].push(`${client.first_name} ${client.middle_name || ''} ${client.last_name}`)
	    			})
    			}
    		})
    		this.setState({
    			clientList,
    			spinner : false
    		})
    	})	
    	.catch(error => {
    		console.log("error is", error);
    	})
    }

    renderPivotTable(event) {
    	this.setState({
    		clientPivot : !this.state.clientPivot,
    		spinner : true
    	}, () => {
    		if(this.state.clientPivot) {
    			this.fetchClientList();
    		} else {
	    		this.getList();
    		}
    	})
	}
	
	//Method is setting the data for CSV
	async downloadPurchasedPropertiesCsv() {
		const csvHeaders = [
			"Contract Date",
			"Settlement Date",
			"BA",
			"State",
			"Suburb",
			"Client Name",
			"Type",
			"Address",
			"Region",
			"Sold Price",
			"Estimated Rent",
			"Estimated Yield",
			"Actual Rent",
			"Actual Growth",
			"Actual Yield",
			"URL",
			"_id(DO NOT EDIT THIS )",
			"engagement_id(DO NOT EDIT THIS )"
		];
		
		const properties = await fetchPurchasedProperties();
		
		const restRows = properties.map(this.setDataForTheCsv);
		restRows.unshift(csvHeaders);
		
		this.setState({ purchasedPropertyCsvData: restRows });
	}

	setDataForTheCsv(property) {
		const soldPrice = _.get(property, "client.contract_value");
		const estimatedRent = _.get(property, "client.rental_estimate");
		const actualRent = _.get(property, "client.actualRent");

		const region = this.getSUAForProperty(property);

		const calculateYield = rent => {
			if (!soldPrice) return "";
		
			const yield_ = ((rent * 52) / soldPrice) * 100;
			return yield_.toFixed(2);
		};		

		return [
			_.has(property, "client.contract_date")
				? moment(property.client.contract_date).format("DD-MMM-YYYY")
				: "",
			_.has(property, "client.settle_date")
				? moment(property.client.settle_date).format("DD-MMM-YYYY")
				: "",
			_.get(property, "client.business_analyst.nickname"),
			property.state || "",
			property.suburb || "",
			// This could be written better
			// prettier-ignore
			property.client && property.client.client ? property.client.client.first_name + (property.client.client.middle_name ? " " + property.client.client.middle_name : "") + " " + property.client.client.last_name : "",
			_.get(property, "client.client.type"),
			property.address || "",
			region ? region.SUA_NAME : "",
			soldPrice,
			estimatedRent,
			estimatedRent && calculateYield(estimatedRent),
			actualRent,
			property.growth || "",
			actualRent && calculateYield(actualRent),
			property.url || "",
			property._id,
			_.get(property, "client._id")
		];
	}

	handleCsvUploading(data) {
		this.setState({
			spinner: true
		})
		console.log("data", data);
		data.forEach(async (row, index) => {
			if(index > 0 && row && row.length) {
				let ba = "";
				if (row[2] && row[2]!="") {
					ba = this.props.buyers.find(buyer => buyer.nickname == row[2].trim());
				}
				const updateEngagement = {};
					updateEngagement.fields = {};
					updateEngagement.fields['contract_date'] = row[0] && moment(row[0], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "DD/MMM/YYYY", "DD-MMM-YYYY", "D-MMM-YY"], true)._isValid ? moment(row[0], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true).toISOString() : "";
					updateEngagement.fields['settle_date'] = row[1] && moment(row[1], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "DD/MMM/YYYY", "DD-MMM-YYYY", "D-MMM-YY"], true)._isValid ? moment(row[1], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true).toISOString() : "";
					updateEngagement.fields['rental_estimate'] = row[10] ? Number(row[10].replace(/[^\w\s]/gi, '')) : "";
					// updateEngagement.fields['yield'] = row[11] ? row[11] : "";
					updateEngagement.fields['actualRent'] = row[12] ? row[12] : "";
					updateEngagement.fields['business_analyst'] = (ba != "") ? ba._id : "";
					updateEngagement.filter = row[14];
				
					if(row[2] == "") {
						delete updateEngagement.fields['business_analyst'];
					}
					/* API call to update the engagement */
					await api.put('/api/clientPurchases/', updateEngagement)
						.then(res => console.log("Res", res))
						.catch(err => console.log("err", err))


				const updateProperty = {};
					updateProperty.fields = {};
					updateProperty.fields['sold_price'] = row[9] ? row[9] : "";
					updateProperty.fields['suburb'] = row[8] ? row[8] : "";
					updateProperty.fields['address'] = row[7] ? row[7] : "";
					updateProperty.fields['suburb'] = row[4] ? row[4] : "";
					updateProperty.fields['state'] = row[3] ? row[3] : "";
					updateProperty.filter = row[13];

					await api.put('/api/property/update', updateProperty)
						.then(res => console.log("Res", res))
						.catch(err => console.log("err", err))

					this.setState({ spinner: false }, () => {
						this.props.showNotification("success", {
							title: "Success!",
							message: "Uploaded successfully."
						});
					})
			}
		})
	}

	handleCsvUploadingError(error) {
		console.log("Error", error)
		this.props.showNotification("error", {
			title: "Error!",
			message: "Error in uploading the CSV."
		});
	}

	getSUAForProperty = property => {
		const formattedAddress = property.formatted_address || '';

		if (formattedAddress) {
			const address_group = formattedAddress.split(", ");
			const suburb_state_post = !_.isNil(address_group[1]) ? address_group[1].split(' ') : "";
			const postcode = !_.isNil(address_group[suburb_state_post.length-1]) ? suburb_state_post[suburb_state_post.length-1] : "";
			const state = !_.isNil(address_group[suburb_state_post.length-1]) ? suburb_state_post[suburb_state_post.length-2] : "";
			
			return significantUrbanAreasList.find(area => 
				(area.POST_CODE === postcode &&  
				area.STATE === state) )
		}
		else {
			const suburb = property.suburb || '';
			const state = property.state || '';

			return significantUrbanAreasList.find(area => (
				area.LOCALITY.toLowerCase() === suburb.toLowerCase() && 
				area.STATE.toLowerCase() === state.toLowerCase()
			))
		}
	};

	handleEngagementCsvUploading(data) {
			console.log("this.props", this.props);
			console.log("data", data);
			const columns = ['Client', 'Property', 'BA', 'Status', 'Label For Scrapper', 'State', 'Select Financer', 'Service', 'Budget', 'Engagement Date', 'Exact Budget', 'GoodToGo date', 'Growth', 'Yield', 'Days Since Settle', 'Property Manager', 'lease start date', 'Days Vacant', 'Date Available for rent', 'Set to Buy Date', 'Days Till Delivery', 'Date Promised', 'Blueprint Completed Date', 'Sent To Client Date', 'Completed(flag)', 'Sent to client (flag)', 'Settle Date', 'Conditional Date (can be many)', 'Conditon (can be many)', 'Finance Date', 'Conveyancer', 'Contract Date', 'Contract Value', 'Fee', 'Rental Estimate', 'Real Estate Agent', 'Real Estate Agency', 'Actual Rent', 'Lease To', 'Number OF People Helped', 'Comments', 'Initial Date', 'PWP', 'e_id(Do Not Modify this)']
			const mapFields = ['client', 'property', 'ba', 'status', 'labelForScrapper', 'state', 'finance_broker', 'service', 'budget', 'engage_date', 'exact_budget', 'G2G_date', 'growth', 'yield', 'days_since_settle', 'property_manager', 'tenanted_date', 'days_vacant', 'dateAvailableForRent', 'set_to_buy_date', 'days_till_delivery', 'date_promised', 'blueprint_completed_date', 'sent_to_client_date', 'completed_flag', 'sent_to_client', 'settle_date', 'conditional_date', 'conditon', 'finance_date', 'conveyancer', 'contract_date', 'contract_value', 'fee', 'rental_estimate', 'real_estate_agent', 'real_estate_agency', 'actualRent', 'leaseTo', 'number_of_people_helped', 'comment', 'initial_date', 'pwp', '_id']
			data.forEach(async (row, index) => {
				console.log("row", row);
				const updatePurchase = {};
				updatePurchase.fields = {};
				if (index > 0) {
					row && row.length
						?
						row.map((col, index) => {
							if (mapFields[index] == "_id") {
								updatePurchase.filter = col;
							} else {
								updatePurchase.fields[`${mapFields[index]}`] = col;
							}
						})
						: ""

					updatePurchase.fields['business_analyst'] = updatePurchase.fields['ba'];
					const ba = this.props.buyers.find(buyer => buyer.nickname == updatePurchase.fields['business_analyst']);
					console.log("ba", ba, updatePurchase.filter);
					console.log("updatePurchase.fields['fee']", updatePurchase.fields['fee']);

					delete updatePurchase.fields['client'];
					delete updatePurchase.fields['ba'];
					delete updatePurchase.fields['property'];
					delete updatePurchase.fields['completed_flag'];
					// delete updatePurchase.fields['labelForScrapper'];
					const checkTypeValue = (type) => {
						if (type.search(/false/i) > -1 || type.search(/ba/i) > -1) {
							return "BA";
						} else if (type.search(/true/i) > -1 || type.search(/pwp/i) > -1) {
							return "PWP"
						}
					}
					updatePurchase.fields['engage_date'] = updatePurchase.fields['engage_date'] && moment(updatePurchase.fields['engage_date'], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true)._isValid ? moment(updatePurchase.fields.engage_date, ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true).toISOString() : "";
					updatePurchase.fields['exact_budget'] = updatePurchase.fields['exact_budget'] ? Number(updatePurchase.fields['exact_budget']) : ""
					updatePurchase.fields['days_since_settle'] = updatePurchase.fields['days_since_settle'] && updatePurchase.fields['days_since_settle'] != "Not Yet Settled" ? Number(updatePurchase.fields['days_since_settle']) : ""
					updatePurchase.fields['days_vacant'] = updatePurchase.fields['days_vacant'] ? Number(updatePurchase.fields['days_vacant']) : ""
					updatePurchase.fields['number_of_people_helped'] = updatePurchase.fields['number_of_people_helped'] ? Number(updatePurchase.fields['number_of_people_helped']) : ""
					updatePurchase.fields['rental_estimate'] = updatePurchase.fields['rental_estimate'] ? Number(updatePurchase.fields['rental_estimate'].replace(/[^\w\s]/gi, '')) : ""
					updatePurchase.fields['days_till_delivery'] = updatePurchase.fields['days_till_delivery'] ? Number(updatePurchase.fields['days_till_delivery']) : ""
					updatePurchase.fields['G2G_date'] = updatePurchase.fields['G2G_date'] && moment(updatePurchase.fields['G2G_date'], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true)._isValid ? moment(updatePurchase.fields.G2G_date, ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true).toISOString() : ""
					updatePurchase.fields['dateAvailableForRent'] = updatePurchase.fields['dateAvailableForRent'] && moment(updatePurchase.fields['dateAvailableForRent'], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true)._isValid ? moment(updatePurchase.fields.dateAvailableForRent, ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true).toISOString() : "";
					updatePurchase.fields['set_to_buy_date'] = updatePurchase.fields['set_to_buy_date'] && moment(updatePurchase.fields['set_to_buy_date'], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true)._isValid ? moment(updatePurchase.fields.set_to_buy_date, ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true).toISOString() : "";
					updatePurchase.fields['date_promised'] = updatePurchase.fields['date_promised'] && moment(updatePurchase.fields['date_promised'], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true)._isValid ? moment(updatePurchase.fields.date_promised, ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true).toISOString() : "";
					updatePurchase.fields['blueprint_completed_date'] = updatePurchase.fields['blueprint_completed_date'] && moment(updatePurchase.fields['blueprint_completed_date'], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true)._isValid ? moment(updatePurchase.fields.blueprint_completed_date, ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true).toISOString() : "";
					updatePurchase.fields['sent_to_client_date'] = updatePurchase.fields['sent_to_client_date'] && moment(updatePurchase.fields['sent_to_client_date'], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true)._isValid ? moment(updatePurchase.fields.sent_to_client_date, ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true).toISOString() : "";
					updatePurchase.fields['settle_date'] = updatePurchase.fields['settle_date'] && moment(updatePurchase.fields['settle_date'], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true)._isValid ? moment(updatePurchase.fields.settle_date, ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true).toISOString() : "";
					updatePurchase.fields['finance_date'] = updatePurchase.fields['finance_date'] && moment(updatePurchase.fields['finance_date'], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true)._isValid ? moment(updatePurchase.fields.finance_date, ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true).toISOString() : "";
					updatePurchase.fields['contract_date'] = updatePurchase.fields['contract_date'] && moment(updatePurchase.fields['contract_date'], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true)._isValid ? moment(updatePurchase.fields.contract_date, ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true).toISOString() : "";
					updatePurchase.fields['initial_date'] = updatePurchase.fields['initial_date'] && moment(updatePurchase.fields['initial_date'], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true)._isValid ? moment(updatePurchase.fields.initial_date, ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true).toISOString() : "";
					updatePurchase.fields['tenanted_date'] = updatePurchase.fields['tenanted_date'] && moment(updatePurchase.fields['tenanted_date'], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true)._isValid ? moment(updatePurchase.fields.tenanted_date, ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true).toISOString() : "";
					updatePurchase.fields['leaseTo'] = updatePurchase.fields['leaseTo'] && moment(updatePurchase.fields['leaseTo'], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true)._isValid ? moment(updatePurchase.fields.leaseTo, ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true).toISOString() : "";
					updatePurchase.fields['sent_to_client'] = updatePurchase.fields['sent_to_client'] ? Boolean(updatePurchase.fields['sent_to_client']) : "";
					updatePurchase.fields['type'] = updatePurchase.fields['type'] ? checkTypeValue(updatePurchase.fields['type']) : "";
					updatePurchase.fields['business_analyst'] = ba._id;
					updatePurchase.fields['contract_value'] = updatePurchase.fields['contract_value'] && Number(updatePurchase.fields['contract_value'].replace(/[^\w\s]/gi, ''));
					updatePurchase.fields['fee'] = updatePurchase.fields['fee'] && Number(updatePurchase.fields['fee'].replace(/[^\w\s]/gi, ''));
					updatePurchase.fields['conditions'] = [{ "condition": updatePurchase.fields['condition'] ? updatePurchase.fields['condition'] : "", "condition_date": updatePurchase.fields['conditional_date'] && moment(updatePurchase.fields['conditional_date'], ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true)._isValid ? moment(updatePurchase.fields.conditional_date, ["DD/MM/YY", "DD-MMM-YY", "D/MMM/YY", "D/MM/YYYY", "DD/MM/YYYY", "D-MMM-YY"], true).toISOString() : "" }]
					updatePurchase.fields['actualRent'] = updatePurchase.fields['actualRent'] && !Number.isNaN(Number(updatePurchase.fields['actualRent'])) ? Number(updatePurchase.fields['actualRent']) : "";

					delete updatePurchase.fields['pwp'];
					delete updatePurchase.fields['conditional_date'];
					delete updatePurchase.fields['conditon'];
					//'conditional_date', 'conditon', '_id'
					console.log("updatePurchase", updatePurchase);

					await api.put('/api/clientPurchases/', updatePurchase)
						.then(res => console.log("Res", res))
						.catch(err => console.log("err", err))
				}

			})
	}

    resetFilter(event) {
    	event.preventDefault();
    	if(this.state.clientPivot) {
    		this.setState({
    			growth : '',
	        	yield : '',
	        	finance : '',
	        	status : 'GoodToGo'
    		},()=>{
    			this.fetchClientList();
    		})
    	} else {
	    	this.setState({
	    		phase : "HOT",
	        	priceRange : '',
	        	fromDate : '',
	        	toDate : '',
	        	buyer : '',
	        	countryState : '',
	        	archived : true,
        		unarchived : true,
        		spinner : true
	    	}, () => {
	    		this.getList();
	    	})	
    	}

    }
}


export default MapViewBase;
