import React from "react";

// Modules
import { formatDate, formatDaysDuration } from "./utils";

export const daysSinceSettleCol = {
  path: "days_since_settle",
  heading: "Days Since Settled",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: formatDaysDuration
};

export const dateAvailableForRentCol = {
  path: "dateAvailableForRent",
  heading: "Date Available for Rent",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: formatDate
};

export const daysVacantCol = {
  path: "days_vacant",
  heading: "Days Vacant",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: formatDaysDuration
};

export const propertyManagerCol = {
  path: "property_manager",
  heading: "Property Manager"
};
