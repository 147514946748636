import React from "react";
import _ from "lodash";
import SingleWaterMark from "./SingleWatermark";
import WaterMarkInfoModal from "./WaterMarkInfoModal";

export default function() {
  return ManageBusinessDaysComponentRender.call(this);
}

var ManageBusinessDaysComponentRender = function() {
  // Setup single Watermark settings
  const newDate = new Date(this.state.startDateWaterMark); // Current date
  let currentMonthInteger = newDate.getMonth(); // 0 = January 11 = December
  const monthsInteger = []; // 0 = January 11 = December
  const years = []; // 0 = January 11 = December
  let currentYear = newDate.getFullYear(); // 0 = January 11 = December
  let yearForLoop = currentYear; // 0 = January 11 = December

  // Loop to arrange the integer value of month from the
  // Current Financial Year Start date for Water Mark
  for (let i = 0; i < 12; i++) {
    monthsInteger.push(currentMonthInteger);
    years.push(yearForLoop);
    currentMonthInteger++;
    if (currentMonthInteger == 12) {
      currentMonthInteger = 0;
      yearForLoop++;
    }
  }

  // Array of month in String
  const monthsString = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  // Array and loop to store the final result of months arrangement (in string)
  // of Current Financial Year Start date for Water Mark
  let months = [];

  for (let i = 0; i < 12; i++) {
    let monthInteger = monthsInteger[i];
    months.push(monthsString[monthInteger]);
  }

  // Array for SingleWaterMark component
  const waterMark = [];

  // Setup single watermark and pass the settings that created above
  for (let index = 0; index < 12; index++) {
    waterMark.push(
      <div className="row">
        <SingleWaterMark
          key={index}
          waterMark={this.renderWaterMarkRows(months[index], years[index])}
          edit={this.editBusinessDays}
          closeExpandableCards={() =>
            this.closeExpandableCards(months[index - 1], years[index - 1])
          }
          month={months[index]}
          year={years[index]}
          handleInputChange={this.handleInputChange}
          editUserRole={this.editUserRole}
          {...this.props}
          {...this.state}
        />
        <SingleWaterMark
          key={index + 1}
          waterMark={this.renderWaterMarkRows(
            months[index + 1],
            years[index + 1]
          )}
          edit={this.editBusinessDays}
          closeExpandableCards={() =>
            this.closeExpandableCards(months[index], years[index])
          }
          month={months[index + 1]}
          year={years[index + 1]}
          handleInputChange={this.handleInputChange}
          editUserRole={this.editUserRole}
          {...this.props}
          {...this.state}
        />
      </div>
    );
    index++;
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="header">
              <h4 className="title">Manage Business Days</h4>
            </div>
            <div className="content">
              <form className="form-horizontal" method="#" action="#">
                <fieldset>
                  <div className="form-group">
                    <label className="col-xs-9 control-label text-left">
                      Number of days for a property in <i>presented</i> phase
                      <star>*</star>
                    </label>
                    <div className="col-xs-3">
                      <input
                        type="text"
                        name="presentedDays"
                        className="form-control"
                        placeholder="in presented days"
                        value={this.state.presentedDays}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <div className="form-group">
                    <label className="col-xs-9 control-label text-left">
                      Number of properties presented per client<star>*</star>
                    </label>
                    <div className="col-xs-3">
                      <input
                        type="text"
                        name="clientPresented"
                        className="form-control"
                        placeholder="client presented"
                        value={this.state.clientPresented}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <div className="form-group">
                    <label className="col-xs-9 control-label text-left">
                      Number of days for a property in <i>waiting</i> phase
                      <star>*</star>
                    </label>
                    <div className="col-xs-3">
                      <input
                        type="text"
                        name="waitingDays"
                        className="form-control"
                        placeholder="waiting days"
                        value={this.state.waitingDays}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <div className="form-group">
                    <label className="col-xs-9 control-label text-left">
                      Number of days a client can be in FRWL for a property
                      <star>*</star>
                    </label>
                    <div className="col-xs-3">
                      <input
                        type="text"
                        name="watchlistDays"
                        className="form-control"
                        placeholder="watchlist client"
                        value={this.state.watchlistDays}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <div className="form-group">
                    <label className="col-xs-9 control-label text-left">
                      Number of days a client can be in HOT for a property
                      <star>*</star>
                    </label>
                    <div className="col-xs-3">
                      <input
                        type="text"
                        name="hotlistDays"
                        className="form-control"
                        placeholder="hotlist client"
                        value={this.state.hotlistDays}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <div className="form-group">
                    <label className="col-xs-9 control-label text-left">
                      Number of days for properties in system before archived
                      <star>*</star>
                    </label>
                    <div className="col-xs-3">
                      <input
                        type="text"
                        name="beforeArchive"
                        className="form-control"
                        placeholder="unarchive days"
                        value={this.state.beforeArchive}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <div className="form-group">
                    <label className="col-xs-9 control-label text-left">
                      Current Financial Year Start date for Water Mark
                      <star>*</star>
                    </label>
                    <div className="col-xs-3">
                      <input
                        type="date"
                        name="startDateWaterMark"
                        className="form-control"
                        placeholder="water mark start date"
                        value={this.state.startDateWaterMark}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <div className="form-group">
                    <label className="col-xs-9 control-label text-left">
                      Watermark - Incubation Threshold number
                      <star>*</star>
                    </label>
                    <div className="col-xs-3">
                      <input
                        type="text"
                        name="watermarkIncubationThreshold"
                        className="form-control"
                        placeholder="threshold number"
                        value={this.state.watermarkIncubationThreshold}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <div className="form-group">
                    <label className="col-xs-9 control-label text-left">
                      Registration<star>*</star>
                    </label>
                    <div className="col-xs-3">
                      <div className="col-xs-6">
                        <input
                          className="rad-button"
                          type="radio"
                          name="registration"
                          value="on"
                          checked={this.state.registration === "on"}
                          onChange={this.handleInputChange}
                        />
                        <label className="control-label p-l-28">On</label>
                      </div>
                      <div className="col-xs-6">
                        <input
                          className="rad-button"
                          type="radio"
                          name="registration"
                          value="off"
                          checked={this.state.registration === "off"}
                          onChange={this.handleInputChange}
                        />
                        <label className="control-label p-l-28">Off</label>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <div className="form-group">
                    <label className="col-xs-9 control-label text-left">
                      Water Mark Explanatory
                      <star className="watermarkIcon" onClick={() =>
                            this.toggleUpdateWaterMarkModal(true)
                          }></star>
                    </label>
                  </div>
                </fieldset>
              </form>
              <div className="row">
                <div className="modal-footer cntr col-xs-12 m-t-15">
                  <button
                    className="sweet-confirm btn btn-info btn-fill"
                    onClick={this.editBusinessDays}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="content">
              <form className="form-horizontal" method="#" action="#">
                <fieldset>
                  <div className="form-group">
                    <label className="col-xs-9 control-label text-left">
                      Manage Roles<star>*</star>
                    </label>
                    <div className="col-xs-3">
                      <div className="col-xs-6">
                        <select
                          name="userSelect"
                          onChange={this.handleInputChange}
                          value={this.state.userSelect}
                        >
                          <option>Select a user</option>
                          {_(this.props.users)
                            .sortBy("nickname")
                            .map(user => (
                              <option key={user._id} value={user._id}>
                                {user.nickname}
                              </option>
                            ))
                            .value()}
                        </select>
                      </div>
                      <div className="col-xs-6">
                        <select
                          name="role"
                          onChange={this.handleInputChange}
                          value={this.state.role}
                        >
                          <option value="">Select a role</option>
                          <option value="associate">Associate</option>
                          <option value="BA">BA</option>
                          <option value="reviewer">Reviewer</option>
                          <option value="super-admin">Super Admin</option>
                          <option value="manager">Manager</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
              <div className="row">
                <div className="modal-footer cntr col-xs-12 m-t-15">
                  <button
                    className="sweet-confirm btn btn-info btn-fill"
                    onClick={this.editUserRole}
                  >
                    Save Role
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Show the water mark cards here */}
      {waterMark}
      <WaterMarkInfoModal
        isOpen={this.state.isOpenWaterMarkModal}
        onCloseClick={() => {
          this.toggleUpdateWaterMarkModal(false);
        }}
      />
    </div>
  );
};
