import React from 'react';

const TableTitle = ({ title, entries }) => (
    <span style={{
        display: 'flex',
        alignItems: 'center'
    }}>
        {title}
        {' '}
        <small style={{ 
            marginLeft: '0.6em',
            color: '#777',
            fontSize: 14
        }}>({entries})</small>
    </span>
);

export default TableTitle;