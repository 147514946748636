import React from "react";
import formatNumber from "format-number";

// Modules
import { formatDate } from "./utils";

export const baCol = {
  path: "ba",
  heading: "BA"
};

export const contractValueCol = {
  path: "contract_value",
  heading: "Contract Value",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: value => (
    <div style={{ textAlign: "right" }}>
      {formatNumber({ prefix: "$", padRight: 2 })(value)}
    </div>
  )
};

export const financeDateCol = {
  path: "finance_date",
  heading: "Finance Date",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: formatDate
};

export const bpDateCol = {
  path: "bp_date",
  heading: "B&P Date",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: formatDate
};

export const condtiion1DateCol = {
  path: "condition_date1",
  heading: "3rd Condition",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: (value, props) => (
    <div title={props ? props.condition1 : ""}>{formatDate(value)}</div>
  )
};

export const conveyancerCol = {
  path: "conveyancer",
  heading: "Conveyancer"
};

export const contractDateCol = {
  path: "contract_date",
  heading: "Contract Date",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: formatDate
};

export const feeCol = {
  path: "fee",
  heading: "Fee",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: value => (
    <div style={{ textAlign: "right" }}>
      {formatNumber({ prefix: "$", padRight: 2 })(value)}
    </div>
  )
};

// Old columns

// export const condition1Col = {
//     path: "condition1",
//     heading: "Condition 1"
// };

// export const condition2Col = {
//     path: "condition2",
//     heading: "Condition 2"
// };

// export const condtiion2DateCol = {
//     path: "condition_date2",
//     heading: "Con Date 2",
//     rowRender: formatDate
// };
