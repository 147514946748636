import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import api from '../common/api';
import * as cols from '../components/tables/columns/index';
import { responseToTableValues, setBlueprint } from './tables/utils';
import { sortByStatus } from '../common/client-status-settings';
import PrintButton from '../components/printElement/PrintButton';
import TableTemplate from '../components/tables/TableTemplate';

// Component tables
import AwaitingForTenant from '../components/tables/AwaitingForTenant';
import YetToSettle from '../components/tables/YetToSettle';
import Blueprints from '../components/tables/Blueprints';
import BATable from '../components/tables/BATable';

const Conditional = ({ isLoading ,rows }) => {
    const columns = [
        cols.clientCol,
        cols.propertyCol,
        cols.contractValueCol,
        cols.financeCol,
        cols.financeDateCol,
        cols.bpDateCol,
        cols.condtiion1DateCol, // will show date for 3rd condition
        cols.conveyancerCol,
        cols.contractDateCol,
        cols.settleDateCol,
        cols.feeCol
    ];
    return <TableTemplate isLoading={isLoading}  rows={rows} cols={columns} title="Conditional Clients" />;
};

class MyListDashboardContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            conditional: [],
            awaitingTenant: [],
            yetToSettle: [],
            blueprints: [],
            baRows: []
        };
        this.handleCompletedBlueprint = this.handleCompletedBlueprint.bind(this);
    }

    componentDidMount() {

        api.get('/api/clientPurchases/clientOfMyList')
            .then(res => {
                this.setState({ isLoading: false });
                const groups = res
                    .map(({ _id: status, purchases: engagements }) => {
                        const rows = engagements.map(responseToTableValues);
                        return { status, rows };
                    })
                    .reduce((acc, { status, rows }) => {
                        acc[status] = rows
                        return acc;
                    }, {});

                // Getting all of *my* engagements
                const baRows = _.reduce(groups, (result, value, key) => {
                    // Ignore completed values
                    if (key === "Completed") return result;
                    return result.concat(...value);
                }, []);

                const sortedBARows = _.sortBy(baRows, ({ status }) => sortByStatus(status.value));

                const newState = Object.assign({},
                    sortedBARows             && { baRows:   sortedBARows },
                    groups.Blueprint         && { blueprints: groups.Blueprint },
                    groups.Conditional       && { conditional:    groups.Conditional },
                    groups.YetToSettle       && { yetToSettle: groups.YetToSettle },
                    groups.AwaitingTenant && { awaitingTenant: groups.AwaitingTenant }
                );

                setBlueprint(this.handleCompletedBlueprint, true).then(blueprints => {
                    newState.blueprints = blueprints;
                    this.setState(newState);
                })
            });
    }

    handleCompletedBlueprint = (id, engagement, event) =>  {
        const name = event.target.name;
        const value = event.target.checked;
        const self = this;
        const updatePurchase = {};
        updatePurchase.filter = id;
        updatePurchase.fields = {};
        if(name == "completed" ) {
            updatePurchase.fields = {
                blueprint_completed: !engagement.blueprint_completed,
                blueprint_completed_date: new Date().toISOString()
            }
        } else if(name == "sentToClient" && engagement.blueprint_completed) {
            updatePurchase.fields = {
                sent_to_client: !engagement.sent_to_client,
                sent_to_client_date: new Date().toISOString()
            }
        }
        api.put(`/api/clientPurchases/`, updatePurchase)
        .then((response) => {
            setBlueprint(this.handleCompletedBlueprint, true).then((blueprints) => self.setState({blueprints}))
        })
        .catch(error => console.log("error", error))
    }


    render() {
        const { user } = this.props;
        const {
            isLoading,
            conditional,
            awaitingTenant,
            yetToSettle,
            blueprints,
            baRows
        } = this.state;

        return (
            <div>
                {/* Title to show during printing */}
                <h5 className="col-lg-12 show-during-print">
                    {user && user.info
                        ? `${!_.isNil(user.info.nickname) ? user.info.nickname.trim() : ""}'s List: ${moment().format('dddd Do MMM YYYY')}`
                        : `My List: ${moment().format('dddd Do MMM YYYY')}`
                    }
                </h5>
                
                <PrintButton />

                <div className="col-lg-12">
                    <div className="content-panel">
                        <Conditional isLoading={isLoading} rows={conditional} />
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="content-panel blueprints-table">
                        <Blueprints isLoading={isLoading} rows={blueprints} />
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="content-panel">
                        <AwaitingForTenant isLoading={isLoading} rows={awaitingTenant} />
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="content-panel yet-to-settle-table">
                        <YetToSettle isLoading={isLoading} rows={yetToSettle} />
                    </div>
                </div>

                <div className="col-xs-12">
                    <div className="content-panel">
                        <BATable
                            isLoading={isLoading}
                            rows={baRows}
                            defaultFilters={{
                                GoodToGo: true,
                                Incubation: true,
                                Engaged: true,
                                Hold: false,
                                Conditional: false,
                                AwaitingTenant: false,
                                YetToSettle: false,
                                Blueprint: false
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const ConnectedMyListDashboardContainer = connect(state => {
	const user = state.user || {};

	return { user };
})(MyListDashboardContainer);

export default ConnectedMyListDashboardContainer;
