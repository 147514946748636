import React from "react";
import { Link } from "react-router";

// Modules
import Notification from "../notification/Notification";

export default function() {
  return HeaderComponentRender.call(this);
}

var HeaderComponentRender = function() {
  const { home } = this.props;
  const token = this.props.user.token;

  return (
    <nav
      className={
        token
          ? "navbar navbar-default"
          : "navbar navbar-transparent navbar-absolute"
      }
    >
      <div className={token ? "container-fluid" : "container"}>
        <div className="navbar-header">
          {/* Empower Wealth logo */}
          <img
            src="/img/ew_logo.png"
            width="32"
            height="31"
            style={{
              float: "left",
              margin: 15,
              marginTop: 21,
              cursor: "pointer"
            }}
            id={this.props.user.token && "minimizeSidebar"}
          />

          <span
            className="navbar-brand"
            style={{ fontFamily: '"Poppins", sans-serif' }}
          >
            Empower Wealth BA Toolkit
          </span>
        </div>

        {/* Right-side menu items */}
        <div className="collapse navbar-collapse">
          {!home && this.props.user && this.props.user.info ? (
            <Notification
              dashboard={this.props.dashboard}
              changeView={this.props.changeView}
            />
          ) : (
            <ul className="nav navbar-nav navbar-right">
              <li>
                <Link to="login">BA Login</Link>
              </li>
              <li>
                <Link to="client-login">Client Login</Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};
