'use strict';
import Base  from './HeaderBase';
import Render  from './HeaderRender';
import { connect } from 'react-redux';

class Header extends Base {
    render() {
        return Render.call(this, this.props, this.state);
    }
}

// export default Header
export default Header = connect((state)=>{
    return {user:state.user || {}}
})(Header);
