'use strict';
import React from 'react';
import {Link} from 'react-router';
import Header from '../header/Header';
import Footer from '../footer/Footer';

export default function(){
    return ResetPasswordRender.call(this);
}

var ResetPasswordRender = function(){
	
	const divStyle = {
        backgroundImage: 'url("img/background-2.jpg")',
        overflow: 'hidden',
        backgroundSize: 'cover'
    };

    return (
 		<div className="wrapper">
            <Header home />
            <div className="wrapper wrapper-full-page">
                <div className="full-page login-page" data-color="" data-image="img/background-2.jpg" >
                    <div className="content">
                        <div className="container">
                            <div className="row" id="login">
						    	<div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
						            <div className="card" data-color="blue">
						                <form id="loginFormValidation">
						                    <div className="header">
						                        <h4 className="title">
						                            Password Reset
						                        </h4>
						                        <p>Enter your new password for your account</p>
						                    </div>
						                    <div className="content">
						                        <div className="form-group">
					                                <label className="control-label">New Password <star>*</star></label>
					                                <input className="form-control"
				                                       name="newPassword"
				                                       type="password"
				                                       required="true"
				                                       value={this.state.newPassword}
				                                       onChange={this.handleInputChange}
									                />
					                            </div>
					                            <div className="form-group">
					                                <label className="control-label">Confirm New Password <star>*</star></label>
					                                <input className="form-control"
				                                       name="confirmPassword"
				                                       type="password"
				                                       required="true"
				                                       value={this.state.confirmPassword}
				                                       onChange={this.handleInputChange}
									                />
					                            </div>
						                    </div>
						                    <div className="footer text-center">
						                        <button type="submit" className="btn btn-info btn-fill" onClick={this.reset}>Change my password</button>
						                    </div>
						                </form>
						            </div>
						        </div>
         					</div>
                        </div>    
                    </div>
                    <Footer />
                    <div className="full-page-background" style={divStyle} >
                    </div> 
                </div>
            </div> 
        </div>      
    )
};
