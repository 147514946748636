'use strict';
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import api from '../../common/api';
import * as redux from '../../redux/redux';
import SocketWrapper from '../../common/socketWrapper';

class NotificationBase extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            socket : new SocketWrapper(process.env.SOCKET_SERVER_URL), // io.connect(process.env.SOCKET_SERVER_URL, { secure: true }),
            notification : [],
            presentedNotification : []
        }
        this.logout = this.logout.bind(this);
        this.updateNotificationStatus = this.updateNotificationStatus.bind(this);
        this.updateAllNotification = this.updateAllNotification.bind(this);
        this.updatePresentedNotification = this.updatePresentedNotification.bind(this);

        setInterval(() => {
            if (this.state.socket.isConnected()){
                this.state.socket.emit("extend-locked-properties", {});
            }
        }, 2 * 60 * 1000);

        // this.updateProperties = this.updateProperties.bind(this);
    }

    componentWillMount() {
    	if(this.props.user.admin) {
            // console.log("this.state.socketthis.state.socketthis.state.socket", this.state.socket)
            // let socketValue = Object.keys(this.state.socket).reduce((obj, key) => {obj[key] = this.state.socket[key]; return obj;}, {});
            // console.log("socketValuesocketValue", socketValue);
            redux.setSocketConnection(this.state.socket);
        	this.setSocketConnection(this.props.user.info);
            this.state.socket.onReconnect(() => {
                this.setSocketConnection(this.props.user.info)
            });
    	}
    }

    componentWillReceiveProps(props) {
    	// console.log("props><><>>>>>>>>>>>>>>>>>>");
    }

    // Socket Connection -(i.e. - this will established Socket Connection)
    setSocketConnection(user) {
        // Get Token
        const token = localStorage.getItem("USER_TOKEN");
        this.state.socket.emit("register-user", { userId: user._id, token });
        this.state.socket.on("notification", (data) => {
            this.state.socket.emit("extend-locked-properties", {});
            this.setNotification(data);
        })
    }

    setNotification(data) {
        let notification = this.state.notification;
        let presentedNotification = this.state.presentedNotification;
        data.map((notify) =>{
            if(notify.presented_notified) {
                presentedNotification.push(notify);
            } else {
                notification.push(notify);
            }
        })
        this.setState({
            notification,
            presentedNotification
        })
        if(data.length) {
            this.pushNotification(data);
        }
    }

    updateNotificationStatus(notify, index, event) {
        let notification = this.state.notification;
    	let updatedNotification = {};
        updatedNotification.id = notify._id
    	updatedNotification.fields = {"userId.0.seen" : true}
    	api.put('/api/notification/', updatedNotification).then(result => {
                if (result.notify) {
                    alert(result.notify);
                } else {
                    notification.splice(index, 1);
                    this.setState({
                        notification
                    })
                }
            })
    	// "ratings.0.rating":
    }

    updateAllNotification(data) {
        let notification = {};
        notification.fields = {"userId.0.seen" : true}
        api.put('/api/notification/', notification).then(result => {
            if (result.notify) {
                alert(result.notify);
            } else {
                console.log("result is", result)   
                this.setState({
                    notification : []
                })             
            }  
        })      
    }

    pushNotification(data) {
        var that =  this;
        if (!("Notification" in window)) {
          alert("This browser does not support desktop notification.");
        } else if (Notification.permission === "granted") {
              var options = {
                      title :' Property Notification',
                      body: "You have "+ data.length + " Notification.",
                      icon: "https://empowerwealth.com.au/wp-content/themes/empower-wealth/images/logo.png",
                      dir : "ltr"
                   };
                var notification = new Notification("New Notification",options);
                setTimeout(notification.close.bind(notification), 5000);
                notification.onclick = function(event) {
                    event.preventDefault(); // prevent the browser from focusing the Notification's tab
                    window.focus();
                    // that.getSelectedItem(data);
                }
        }
        else if (Notification.permission !== 'denied') {
            Notification.requestPermission(function (permission) {
                if (!('permission' in Notification)) {
                  Notification.permission = permission;
                }
              
                if (permission === "granted") {
                  var options = {
                          body: "You have "+ data.length + " Notification.",
                          title :' Messanger Notification',
                          icon: "https://empowerwealth.com.au/wp/wp-content/themes/empower-wealth/images/logo.png",
                          dir : "ltr"
                       };
                  var notification = window.webkitNotifications.createNotification("New Notification",options);
                  notification.onclick = function() {
                        event.preventDefault(); // prevent the browser from focusing the Notification's tab
                        window.focus()
                        // that.getSelectedItem(data.by);
                  }
                  setTimeout(notification.close.bind(notification), 5000);
                }
            });
        }
    }

    // this function will update the presented Notification List (i.e. - this.state.presentedNotification)
    updatePresentedNotification(notify) {
        let presentedNotification = this.state.presentedNotification;
        let removedIndex = _.findIndex(presentedNotification,{"_id":notify._id});
        console.log("removedIndex", removedIndex)
        // splice the notification by matching the property
        presentedNotification.splice(removedIndex,1);
        this.setState({
            presentedNotification
        })
    }

    logout() {
        this.state.socket.emit('deregister-user',this.props.user.info);
        this.setState({socket : ''})
        localStorage.removeItem('USER_TOKEN');
        localStorage.removeItem('USER_ID');
        localStorage.removeItem('CLIENT_TOKEN');
        redux.setUser('');
        redux.setSocketConnection('');
        browserHistory.push('/login');
    }

}

export default NotificationBase;
