import { connect } from "react-redux";

// Modules
import Base from "./ManageBusinessDaysBase";
import Render from "./ManageBusinessDaysRender";

// Components
import withNotifications from "../withNotifications";

class ManageBusinessDays extends Base {
  render() {
    return Render.call(this, this.props, this.state);
  }
}

const mapStateToProps = state => ({
  user: state.user || {},
  buyers: state.buyers || [],
  users: state.users || [],
});

export default connect(mapStateToProps)(withNotifications(ManageBusinessDays));
