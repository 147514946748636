import React from "react";
import moment from "moment";

export const formatDate = (value) =>
  value && (
    <div style={{ textAlign: "right" }}>{value.format("DD-MMM-YY")}</div>
  );

export const formatDaysDuration = (value) => {
  const asDays = moment.isDuration(value) ? value.asDays() : 0;
  const roundNegative = Math.round(asDays);
  const ceilNegative = Math.ceil(asDays) - 1;
  return moment.isDuration(value) ? (
    <div style={{ textAlign: "right" }}>
      {Math.sign(value.asDays()) === 1
        ? Math.round(value.asDays())
        : roundNegative !== ceilNegative
        ? Math.ceil(value.asDays())
        : Math.ceil(value.asDays()) - 1}
    </div>
  ) : null;
};
