'use strict';
import Base  from './FeedbackViewBase';
import Render  from './FeedbackViewRender';
import { connect } from 'react-redux';

class FeedbackView extends Base {
    render() {
        return Render.call(this, this.props, this.state);
    }
}

export default FeedbackView = connect((state)=>{
    return {user:state.user || {}}
})(FeedbackView);
