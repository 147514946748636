import React, { PureComponent } from "react";

// Modules
import { durationFromDateToTodayInDays } from "../../common/date-helpers";

// Components
import {
  FormCardHeader,
  FormDatepicker,
  FormReadOnly
} from "../FormComponents";

function getDaysUntillDelivery(datePromised, sentToClient) {
  if (sentToClient) {
    return "Blueprint has been delivered";
  } else if (!datePromised) {
    return "Date promised hasn't been set";
  } else {
    return durationFromDateToTodayInDays(datePromised);
  }
}

class SetToBuyMeetingAndAwaitingBlueprint extends PureComponent {
  render() {
    const {
      blueprintCompleted,
      blueprintCompletedDate,
      blueprintSentToClient,
      blueprintSentToClientDate,
      datePromised,
      onBlueprintCheckboxChange,
      onDateChange,
      setToBuyDate
    } = this.props;

    return (
      <div>
        <FormCardHeader>
          Set to Buy Meeting And Awaiting Blueprint
        </FormCardHeader>

        <div className="content" style={{ zIndex: "initial" }}>
          <FormDatepicker
            label="Set to Buy Date"
            onChange={onDateChange("setToBuyDate")}
            value={setToBuyDate}
          />

          <FormReadOnly
            label="Days Until Delivery"
            value={getDaysUntillDelivery(datePromised, blueprintSentToClient)}
          />

          <FormDatepicker
            label="Date Promised"
            onChange={onDateChange("datePromised")}
            value={datePromised}
          />

          <FormDatepicker
            label="Blueprint Completed Date"
            onChange={onDateChange("blueprintCompletedDate")}
            value={blueprintCompletedDate}
            disabled={!blueprintCompleted}
          />

          <FormDatepicker
            label="Sent to Client Date"
            onChange={onDateChange("blueprintSentToClientDate")}
            value={blueprintSentToClientDate}
            disabled={!blueprintSentToClient}
          />

          {/* Blueprints status checkboxes */}
          <div
            className="form-group"
            style={{ display: "grid", gridTemplateColumns: "auto auto auto" }}
          >
            <div>
              <input
                checked={blueprintCompleted}
                id="completedCheckBox"
                name="blueprintCompleted"
                onChange={onBlueprintCheckboxChange}
                type="checkbox"
              />
            </div>

            <div>
              <input
                checked={blueprintSentToClient}
                id="sentToClient"
                name="blueprintSentToClient"
                onChange={onBlueprintCheckboxChange}
                type="checkbox"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SetToBuyMeetingAndAwaitingBlueprint;
