import _ from "lodash";
import { browserHistory } from 'react-router';
// Modules
import api from "../../common/api";

export function fetchProperty(id) {
  const query = encodeURIComponent(JSON.stringify({ id }));

  return api.get(`/api/property/?filter=${query}`).then(result => {
    if (result.notify) {
      return alert(result.notify);
    }

    return result.properties[0];
  });
}

export function fetchPriceHistory(address) {
  return api.get(`/api/property/priceHistory?address=${address}`).then(res => {
    if (res.notify) {
      throw Error(res.notify);
    } else if (res.message || res.error) {
      throw Error(`${res.message || res.error} @ AWS`);
    }

    return { priceHistory: res.priceHistory, cagrPeriods: res.cagrPeriods, addressLabel: res.addressLabel};
  });
}

export function fetchCagrPeriods(priceHistory) {
  return api.post("/api/property/cagrPeriods", { priceHistory }).then(res => {
    if (res.notify) {
      throw Error(res.notify);
    } else if (res.message || res.error) {
      throw Error(`${res.message || res.error} @ AWS`);
    }

    return res;
  });
}

// export function addQuery(query, removeQueries = false) {
//   const location = Object.assign({}, browserHistory.getCurrentLocation());
//   Object.assign(location.query, query);
//   browserHistory.push(location);
//   removeQueries && setTimeout(() => {
//     removeQuery("cagrPropertyAddress", "propertyId", "serverURL");
//   }, 3000);
// };

const removeQuery = (...queryNames) => {
  const location = Object.assign({}, browserHistory.getCurrentLocation());
  queryNames.forEach(q => delete location.query[q]);
  browserHistory.push(location);
};