const MapInfoWindow = (details) => {
	return `<div id="content" style="overflow: hidden;">
	        <div style="position: relative; width: 417px; color: #b68729;">
	            <h4 class="cntr" style="color : cadetblue; margin-top:15px;">
	            	<a id="address-url" title=${details.url ? "Click-to-View" : 
	            	"Off-market-property"} href=${details.url ? details.url : "javascript:void(0);"} target=${details.url ? "blank" : ""}>	
	            		${details.address}
	            	</a>
	            </h4>
	            <div style="margin-bottom: 10px; text-align: center;">
	            	<span style="font-size: 15px;">${details.advPrice}</span>
	            </div>
	            <div class="row">
	                <div class="col-md-6">
	                    <ul class="info-window" style="list-style: none; padding-left: 20px">
	                    	<li><span>BA: </span>${details.buyer}</li>
	                    	<li><span>Buy price: </span>${details.buyPrice}</li>
	                    	<li><span>Phase: </span>${details.phase}</li>
	                    </ul>
	                </div>
	                <div class="col-md-6">
	                	<ul class="info-window" style="list-style: none;">
		                    <li><span>Client: </span> ${details.client}</li>
		                    <li style="display: inline;"><span><i class="fa fa-bed" style="color: brown;" aria-hidden="true"></i></span> ${details.beds}</li>
		                    <li style="display: inline;"><span><i class="fa fa-bath" style="color: blue;" aria-hidden="true"></i></span> ${details.baths}</li>
		                    <li style="display: inline;"><span><i class="fa fa-car" style="color: red;" aria-hidden="true"></i></span> ${details.cars}</li>
	                    <ul>
	                </div>               
	            </div>
	            <div class="row">
                    <div class="col-md-offset-6 col-md-6">
                        <a href="/home/list?_id=${details._id}" style="bottom: 2px; float: right">View Details
                        <i class="ti-angle-right" style="font-size: 8px; padding-left: 3px;"></i></a>
                    </div>
                </div> 
	        </div>
	    </div>`
}

export default MapInfoWindow;
