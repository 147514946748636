'use strict';
import React from 'react';
import {Link} from 'react-router';
import moment from 'moment';
import Comment from './Comments';

export default function(){
    return FeedbackViewComponentRender.call(this);
}

var FeedbackViewComponentRender = function(){
	let loggedUser = this.props.user.info.userId ? this.props.user.info.userId : this.props.user.info._id;
    return (
    	<div className="col-md-12">
            <div className="card ">
            	<div className="row">
	                <div className="header col-xs-6">
	                    <h4 className="title m-l-15"><b>{this.props.feedback.business_analyst.first_name}</b> shared some feedback</h4>
	                    <p className="category m-l-15">{moment(this.props.feedback.created_on).format('DD-MMM-YY hh:MM')}</p>
	                </div>
	                <div className="header col-xs-6">
	                	<select className="form-control right m-r-15" style={{width:'30%', height : '5%'}} type="text" value={this.state.status} onChange={this.updateFeedback} >
	                		<option value="open">Open</option>
	                		<option value="progress">In Progress</option>
	                		<option value="closed">Closed</option>
	                	</select>
	                	<select className="form-control right m-r-15" style={{width:'30%', height : '5%'}} type="text" value={this.state.severity} onChange={this.updateSeverity} >
	                		<option value="urgent">Urgent</option>
	                		<option value="high">High</option>
	                		<option value="low">Low</option>
	                		<option value="suggestion">Suggestion</option>
	                	</select>
	                </div>
                </div>
                <div className="content">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="table-responsive">
                                <img src={this.props.feedback.image ? this.props.feedback.image : "/img/default-feedback.jpg"} alt="..."/>
                            </div>
                        </div>
                        <div className="col-md-6 col-md-offset-1">
                            <div><p className="des-wordwrap">{this.props.feedback.description}</p></div>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                    	<div className="col-md-12">
                    	<span className="comment-res"><i className="ti-themify-favicon"></i> Add Comment</span>
                    	</div>
                    </div>
                    <div className="row">
                    	<div className="col-md-12">
                    		<input name="description" className="form-control" type="text" placeholder="Write a comment..." value={this.state.description} onChange={(event)=>{this.setState({description : event.target.value})}} />
                    	</div>
                    </div>
                    <br></br>
                    <div className="row">
                    	<div className="col-md-6">
                    		<button className="btn btn-success btn-fill" type="submit" onClick={this.addComment.bind(this)}>Save</button>
                    		<button className="btn m-l-5" type="submit" onClick={(event)=>{ this.setState({description:''})}}>Clear</button>
                    	</div>
                    	<div className="col-md-6">
                    		<span className="comment-res right">
                    			<a data-toggle="collapse" href={"#showComments-"+this.props.feedback._id} onClick={this.renderComments}>{this.state.showComments ? "Hide Comments" : `[${this.state.comments.length && this.state.comments.length}] Show Comments`}</a>
                			</span>
                    	</div>
                    </div>
                   	<br></br>
                	<div className="row">
                    	<div id={"showComments-"+this.props.feedback._id} className="col-md-12 panel-collapse collapse">
                    		<ul className="comment-ul">
                    			{ this.state.comments && this.state.comments.length ? this.state.comments.map((comment, index) => {
                    				return <Comment comment={comment} loggedUser={loggedUser} removeComment={this.removeComment.bind(this, index, comment)} key={index} />
                    			}) : <li>No comments for now</li>
                    			}
                    		</ul>
                    	</div>
                	</div>
                </div>
            </div>
        </div>
    )
};
