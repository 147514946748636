'use strict';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Modules
import api from '../../common/api';
import * as redux from '../../redux/redux';

class SideMenuBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignedProperties : [],
            usersLists : [],
            buyersList : [],
            activeFilter : '',
            refresh: false, // Refresh the page on click of New Property Modal appear from Socket,
            socket: "", // to get the instance of the socket
      
        };
        this.logout = this.logout.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.unseenNotification = this.unseenNotification.bind(this);
        this.setFilterProperties = this.setFilterProperties.bind(this);
    }
    listenNewProperties() {
        this.state.socket &&
          this.state.socket.on("add-property", data => {
            if (data.status == "Done") {
              this.setState({
                refresh: true
              });
            }
          });
      }
      refreshPropertyList(event) {
          location.reload();
      }
    componentDidMount() {
        let buyerCount = this.state.buyersList.length;
        if (!buyerCount) {
            api.get('/api/auth/list').then(result => {
                if (result.notify) {
                    alert(result.notify);
                } else {
                    this.setState({
                        usersLists: result.users,
                        buyersList: result.users.filter(ba => ba.user_role==="BA")
                    },()=>{
                        redux.setUsers(this.state.usersLists)
                        redux.setBuyers(this.state.buyersList)
                    })
                }
            })
        }
        
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.socket != prevProps.socket) {
            this.setState(
            {
                socket: this.props.socket
            },
            () => {
                this.listenNewProperties();
            }
            );
        }
    }

    setFilterProperties(value, event) {
        if(value) {
            this.setState({
                activeFilter : value
            })
        }
        redux.setFilter(this.state.assignedProperties);
    }

    unseenNotification() {
        if(this.props.user.admin) {
            api.get('/api/notification/').then(result => {
                if (result.notify) {
                    alert(result.notify);
                } else {
                    if(result.notifications.length) {
                        let assignedProperties = this.state.assignedProperties;
                        result.notifications.map((notify) =>{
                            assignedProperties.push(notify.property._id);
                        })
                        this.setState({
                            assignedProperties
                        })
                    } else {
                        this.setState({
                            assignedProperties : []
                        })
                    }
                }
            })
        }
    }

    resetFilter(value, event) {
        if(value) {
            this.setState({
                activeFilter : value
            })
        } else {
            this.setState({
                activeFilter : ''
            })
        }
        redux.setFilter('');
    }

    logout() {
        localStorage.removeItem('USER_TOKEN');
        localStorage.removeItem('CLIENT_TOKEN');
        redux.setUser('');
        browserHistory.push('/login');
    }
}

SideMenuBase.contextTypes = {
  router: PropTypes.object.isRequired
};

module.exports = SideMenuBase;
