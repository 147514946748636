import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router';
import qs from 'qs';

// Modules
import api from '../../common/api';
import { parseBudget } from '../../common/budget-tiers';
import { durationFromDateToToday, durationFromTodayToDate } from '../../common/date-helpers';

// NOTE: Response values are "parsed" to normalise its data. This reduces
// the number of possible data types for a column

const parseString = value => {
    return (typeof value !== 'string' || value === "")
        ? null : value;
};

const parseNumber = value => {
    const number = Number.parseFloat(value);
    return isNaN(number) ? null : value;
};

const parseDate = value => {
    const date = moment(value, moment.ISO_8601).utc(false);
    return date.isValid() ? date : null;
};

const parseOptionalNumber = value => {
    return value === "N/A" ? value : parseNumber(value);
};

// Depending on status, determine if a warning needs to be shown
const getWarningForOverdueField = (
    status,
    settleDate,
    financeDate,
    finance_satisfied,
    bpDate,
    bp_satisfied,
    conditionDate,
    conditions
) => {
    const isValidMoment = date => date && moment(date).isValid();
    //Change conditional check to is before or same day
    const isBeforeToday = date => 
        isValidMoment(date) && (moment(date).local()).isSameOrBefore(moment(), "days");

    let isbefore = false;   

   conditions.map((e, i) => {
            console.log(`index ${i}`);
            console.log(`condition date ${parseDate(e.condition_date)}`);
            console.log(`satisfied date ${parseDate(e.satisfied)}`);
            if (isBeforeToday(parseDate(e.condition_date)) && e.satisfied == null){
                isbefore = true
            }
        }
    )
       
    switch (status) {
        case "Conditional":
            return (
                (isBeforeToday(financeDate) && (finance_satisfied == null)) ||
                (isBeforeToday(bpDate) && (bp_satisfied == null)) ||
                isbefore
            );

        case "YetToSettle":
            return (
                isValidMoment(settleDate) &&
                settleDate.isSameOrBefore(moment(), "days")
            );

        default:
            return false;
    }
};

// DB dates are start-of-day dates
const durationBetweenDates = (date1, date2) => {
    if (moment(date1).isValid() && moment(date2).isValid()) {
        return moment.duration(date1.diff(date2));
    }
    return null;
};

const calculateDaysInIncubation = (status, setToBuyDate, g2gDate) => {
    if (status === "Engaged" || status === "Incubation") {
        return durationFromTodayToDate(setToBuyDate);
    } else {
        return durationBetweenDates(g2gDate, setToBuyDate);
    }
}

export const responseToTableValues = response => {
    const {
        // Destructure what we need
        // Common
        _id,
        client = { first_name: "", last_name: "" },
        business_analyst: ba,
        property,
        finance_broker,
        set_to_buy_date,
        // Conditional
        conditions,
        finance_date,
        finance_satisfied,
        conveyancer,
        contract_value,
        contract_date,
        settle_date,
        fee,
        // Blueprints
        date_promised,
        blueprint_completed,
        // Awaiting for Tenant
        dateAvailableForRent,
        property_manager,
        // BA Tables
        engagement_source,
        excluded_from_watermark,
        status,
        service,
        state,
        budget,
        exact_budget,
        growth,
        "yield": yieldPercent,
        G2G_date,
        engage_date,
        audit_trail,
        noAttachedProperties,
        bp_date,
        bp_satisfied
    } = response;

    const settleDate = parseDate(settle_date);
    const dateAvailableForRentMoment = parseDate(dateAvailableForRent);
    const datePromised = parseDate(date_promised);
    const g2gDate = parseDate(G2G_date);
    const setToBuyDate = parseDate(set_to_buy_date);
    const financeDate = parseDate(finance_date);
    const bpDate = parseDate(bp_date);
    const conditionDate = parseDate(_.get(conditions, '[0].condition_date'));

    return {
        // Common
        _id,
        // TODO: No parsing as we expect it to always have a value
        client: {
            value: `${client.first_name} ${client.last_name}`,
            props: { link: _id }
        },
        // TODO: No parsing as we expect it to always have a value
        ba: {
            value: ba ? ba.nickname : 'Unspecified',
            props: { ba }
        },
        property: {
            value: parseString(_.get(property, 'formatted_address')),
            props: { property }
        },
        finance_broker:       parseString(finance_broker),
        set_to_buy_date:      setToBuyDate,
        bp_date:              bpDate,
        showWarning: getWarningForOverdueField(
            status,
            settleDate,
            financeDate,
            finance_satisfied,
            bpDate,
            bp_satisfied,
            conditionDate,
            conditions,
        ), 
        // Conditional
        condition_date1:      {
            value: conditionDate,
            props: { condition1: parseString(_.get(conditions, '[0].condition'))}
        },
        finance_date:         financeDate,
        conveyancer:          parseString(conveyancer),
        contract_value:       parseNumber(contract_value),
        contract_date:        parseDate(contract_date),
        settle_date:          settleDate,
        fee:                  parseNumber(fee),
        // Blueprints
        date_promised:        datePromised,
        days_till_delivery:   durationFromDateToToday(datePromised),
        completedCol:         "",
        blueprint_completed,
        // Yet to Settle
        days_to_settle:       durationFromDateToToday(settleDate),
        // Awaiting for Tenant
        days_since_settle:    durationFromTodayToDate(settleDate),
        dateAvailableForRent: dateAvailableForRentMoment,
        days_vacant:          durationFromTodayToDate(dateAvailableForRentMoment),
        property_manager:     parseString(property_manager),
        // BA Tables
        // TODO: No parsing as we expect it to always have a value
        status: {
            value: status,
            props: {
                engagementSource: engagement_source,
                service: parseString(service),
                ...(excluded_from_watermark !== undefined && { excludedFromWatermark:excluded_from_watermark}) 
            }
        },
        service:              parseString(service),
        state:                parseString(state),
        budget: {
            value: parseNumber(exact_budget),
            props: { budgetRange: parseBudget(budget) }
        },
        growth:               parseOptionalNumber(growth),
        yieldPercent:         parseOptionalNumber(yieldPercent),
        G2G_date:             {
            value: g2gDate,
            props: { status}
        },
        engageToInc:          calculateDaysInIncubation(status, setToBuyDate, g2gDate),
        engage_date:          parseDate(engage_date),
        audit_trail,
        attachedProperties: <Link to={`/home/list?${qs.stringify({ engagements: [_id], showArchived: true })}`} target="_blank">{noAttachedProperties}</Link>
    };
};

export const setBlueprint = async (handleCompletedBlueprint, myList) => {
    // Maintain the normal height of rows
    const checkboxStyle = { margin: "-2px 0" };
    
    const query = encodeURIComponent(JSON.stringify({ selectEngagement: 'Blueprints' }));
    const rows = await api.get(`/api/clientPurchases/list?filter=${query}&&options=${myList}`)
    .then(({ purchases: engagements }) => {
        // Transform into table format
        let rows = engagements.map(responseToTableValues);
        rows.map((engagement) => {
            engagement.completedCol = (
                <input
                    style={checkboxStyle}
                    type="checkbox"
                    name="completed"
                    onChange={ev => handleCompletedBlueprint(engagement._id, engagement, ev)}
                    checked={engagement.blueprint_completed}
                />
            );
            engagement.sentToClientCol = (
                <input
                    style={checkboxStyle}
                    type="checkbox"
                    name="sentToClient"
                    onChange={ev => handleCompletedBlueprint(engagement._id, engagement, ev)}
                    checked={engagement.sent_to_client == true ? true: false}
                />
            );
        });
        return rows;
    });
    return rows;
}