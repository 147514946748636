'use strict';
import React, { Component } from 'react';
import api from '../../common/api';
import { browserHistory } from 'react-router'

class ForgotPasswordBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
        	username : ''
        }
        this.forgotPassword = this.forgotPassword.bind(this);

    }

    forgotPassword(e) {
        e.preventDefault();
        if (this.state.username) {
            api.put('/api/auth/forgotPassword', this.state).then((result) => {
                if (result.notify) {
                    alert(result.notify);
                } else {
                	this.setState({
                		username : ''
                	});
                	alert(result.message)
                }
            })

        } else {
            alert("Please enter username you used to sign in");
        }

    }

}

module.exports = ForgotPasswordBase;
