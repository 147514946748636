import React from "react";
import Datetime from "react-datetime";
import moment from "moment";
import _ from "lodash";

// Modules
import countryStates from "../common/countryStates";
import priceRangeList from "../common/priceList";
import store from "./../redux/store.js";

const radioButtonStyle = { marginTop: "0.5em", marginRight: "1.5em" };

function parseOptionalPercentageValue(value) {
  if (value === "N/A") return "";
  else if (Number(value) !== NaN) return value;
  // Value should be one of the above, if not default to ""
  else return "";
}

export function BASelect(props) {
  const { bas, label: labelProp, ...rest } = props;

  const label = labelProp !== undefined ? labelProp : <label>BA</label>;

  return (
    <div>
      {label}
      <select className="form-control" {...rest}>
        <option value="">Select BA</option>
        {_(bas)
          .sortBy("nickname")
          .map((ba) => (
            <option key={ba._id} value={ba._id}>
              {ba.nickname}
            </option>
          ))
          .value()}
      </select>
    </div>
  );
}

export function BudgetSelect(props) {
  return (
    <div className="form-group">
      <label>Budget</label>
      <select className="form-control" {...props}>
        <option value="">Select Price</option>
        {priceRangeList.map((priceRange) => (
          <option key={priceRange} value={priceRange}>
            {priceRange}
          </option>
        ))}
      </select>
    </div>
  );
}

export function EngagementSourceRadioButtons(props) {
  const { checked, ...rest } = props;

  return (
    <div className="form-group">
      <label>Engagement Source</label>

      <div>
        <label style={radioButtonStyle}>
          <input
            checked={checked === "CPS"}
            type="radio"
            value="CPS"
            {...rest}
          />{" "}
          CPS
        </label>

        <label style={radioButtonStyle}>
          <input checked={checked === "BA"} type="radio" value="BA" {...rest} />{" "}
          BA
        </label>

        <label style={radioButtonStyle}>
          <input
            checked={checked === "ownNetwork"}
            type="radio"
            value="ownNetwork"
            {...rest}
          />{" "}
          Own Network
        </label>
      </div>
    </div>
  );
}

export function FormCardHeader({ children }) {
  return (
    <div className="header">
      <h4
        className="title cntr"
        style={{ fontFamily: '"Poppins", sans-serif' }}
      >
        <b>{children}</b>
      </h4>
    </div>
  );
}

export function FormDatepicker(props) {
  const { label, className, onChange, disabled, ...rest } = props;

  return (
    <div className="form-group">
      <label>{label}</label>
      <Datetime
        closeOnSelect
        dateFormat="Do MMMM YYYY"
        inputProps={{ className: `form-control ${className}`, disabled }}
        onChange={(date) => {
          const validatedDate =
            moment.isMoment(date) && date.isValid() ? date : null;
          onChange(validatedDate);
        }}
        {...rest}
        timeFormat={false}
      />
    </div>
  );
}

export function FormDatepickerStatus(props) {
  const {
    label,
    className,
    onChange,
    disabled,
    checkCheckbox,
    nameCheckbox,
    onCheckBoxChange,
    ...rest
  } = props;

  const WhiteSpace = (count) => {
    let space = "";
    for (var i = 0; i < count; i++) space += `-`;
    return space;
  };

  return (
    <div className="form-group">
      <label>{label}</label>
      <div className="form-group row">
    
          
            <Datetime
              className="col-sm-10"
              closeOnSelect
              dateFormat="Do MMMM YYYY"
              inputProps={{ className: `form-control ${className}`, disabled }}
              onChange={(date) => {
                const validatedDate =
                  moment.isMoment(date) && date.isValid() ? date : null;
                onChange(validatedDate);
              }}
              {...rest}
              timeFormat={false}
            />
          
          <td className="text-center" style={{color: 'dimgrey', fontSize: '12px'}}>
            <tr>
              <FormCheckbox
                className="col-sm-10"
                label="Satisfied"
                name={nameCheckbox}
                onChange={onCheckBoxChange}
                checked={checkCheckbox ? true : false}
              />
            </tr>
            <tr>
              <label>
                <span style={checkCheckbox ? { visibility: "visible" } : { visibility: "hidden" } }>
                  {checkCheckbox
                    ? moment(checkCheckbox).format("DD-MMM-YY")
                    : WhiteSpace(12)}
                </span>
              </label>
            </tr>
          </td>
     
      </div> 
    </div>
  );
}

function FormCheckbox({ label, ...rest }) {
  return (
    <div className="checkbox-item" style={{ margin: 0}}>
      <label style={{ display: "flex", alignItems: "center" }}>
        <input type="checkbox" {...rest} />
          &nbsp;
        <span>{label}</span>
      </label>
    </div>
  );
}

export function FormInput(props) {
  const { label, className, ...rest } = props;

  return (
    rest.type === "excludedfromWatermark"
      ? (

        <div className="form-group">
          <div style={{ display: "flex", flexDirection: "row", marginBottom: "8px" }}>
            <input
              onChange={rest.onChange}
              name="excludedFromWatermark"
              type="checkbox"
              checked={rest.excludedFromWatermark}
              style={{ margin: 0, display: "inline-block", justifyContent: "center", alignContent: "center", width: "16px" }}
            />
            <label style={{ margin: 0, marginLeft: "13px", color: "gray" }}>Excluded from Watermark</label>
          </div>
          {rest.excludedFromWatermark && <div>
            <label>{label}</label>
            <input className={`form-control ${className}`} name={rest.name} value={rest.reason} onChange={rest.onChange} />
          </div>
          }

        </div>
      )
      : <div className="form-group">
        <label>{label}</label>
        <input className={`form-control ${className}`} {...rest} />
      </div>
  );
}

export function FormOptionalPercentageInput(props) {
  const { label, className, onNAChange, innerRef, value, ...rest } = props;

  return (
    <div className="form-group">
      <label>{label}</label>
      <div
        className="input-group"
        style={{ display: "grid", gridTemplateColumns: "80% 10% 10%" }}
      >
        <input
          className="form-control"
          type="number"
          ref={innerRef}
          value={parseOptionalPercentageValue(value)}
          {...rest}
        />

        <div className="input-group-append percent-symbol">
          <span className="input-group-text">%</span>
        </div>

        <div className="na-box">
          <div className="form-check">
            <input
              className="form-check-input"
              onChange={onNAChange}
              type="checkbox"
            />
            <label className="form-check-label">N/A</label>
          </div>
        </div>
      </div>
    </div>
  );
}

export function FormReadOnly(props) {
  const { label, className, value } = props;

  return (
    <div className="form-group">
      <label>{label}</label>
      <input className={`form-control ${className}`} value={value} readOnly />
    </div>
  );
}

export function ServiceSelect(props) {
  return (
    <div className="form-group">
      <label>Service</label>
      <select className="form-control" {...props}>
        <option value="">Select Service</option>
        <option value="Full">Full</option>
        <option value="SR">SR</option>
        <option value="Negotiate">Negotiate</option>
      </select>
    </div>
  );
}

export function StateSelect(props) {
  const { label: labelProp, ...rest } = props;

  const label = labelProp !== undefined ? labelProp : <label>State</label>;

  return (
    <div className="form-group">
      {label}

      <select className="form-control" {...rest}>
        <option value="">Select State</option>
        {countryStates.map((state) => (
          <option key={state} value={state}>
            {state}
          </option>
        ))}
      </select>
    </div>
  );
}

export function StatusSelect(props) {
  const { label: labelProp, shortList = false, ...rest } = props;
  const label = labelProp !== undefined ? labelProp : <label>Status</label>;
  const selectProps = { className: "form-control", ...rest };
  const { user } = store.getState();

  return (
    <div>
      {label}

      {shortList ? (
        <select {...selectProps}>
          <option value="">Select Status</option>
          <option value="Engaged">Engaged</option>
          <option value="Incubation">Incubation</option>
          <option value="GoodToGo">Good To Go</option>
          {(_.get(user, "info.user_role") === "super-admin" || _.get(user, "info.user_role") === "manager") && (
            <option value="Hold">Hold</option>
          )}
        </select>
      ) : (
        <select {...selectProps}>
          <option value="">Select Status</option>
          <option value="Engaged">Engaged</option>
          <option value="Incubation">Incubation</option>
          <option value="GoodToGo">Good To Go</option>
          <option value="Conditional">Conditional</option>
          <option value="YetToSettle">Yet To Settle</option>
          <option value="AwaitingTenant">Awaiting Tenant</option>
          <option value="Completed">Completed</option>
          {(_.get(user, "info.user_role") === "super-admin" || _.get(user, "info.user_role") === "manager") && (
            <option value="Hold">Hold</option>
          )}
        </select>
      )}
    </div>
  );
}

export function TypeRadioButtons(props) {
  const { checked, ...rest } = props;

  return (
    <div className="form-group">
      <label>Type</label>

      <div>
        <label style={radioButtonStyle}>
          <input
            defaultChecked={checked === "BA"}
            type="radio"
            value="BA"
            {...rest}
          />{" "}
          BA
        </label>

        <label style={radioButtonStyle}>
          <input
            defaultChecked={checked === "PWP"}
            type="radio"
            value="PWP"
            {...rest}
          />{" "}
          PWP
        </label>
      </div>
    </div>
  );
}
