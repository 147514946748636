'use strict';
import Base  from './LoginBase';
import Render  from './LoginRender';
import { connect } from 'react-redux';

class Login extends Base {
    render() {
        return Render.call(this, this.props, this.state);
    }
}

export default Login = connect((state)=>{
    return {user:state.user || {}}
})(Login);
