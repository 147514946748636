import React, { Component } from "react";
import _ from "lodash";
import "./BATable.css";

// Modules
import * as cols from "./columns/index";
import { statusMap, sortByStatus } from "../../common/client-status-settings";

// Components
import TableTemplate from "./TableTemplate";

class BATable extends Component {
  state = {
    // Below uses the same enum values as seen in client-status-settings
    // Ingore "*-LT" statuses as it would take up too much space
    // Inc-LT: true,
    // Hold-LT: true,
    // See here for explanation of "default" props:
    // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
    filters: { ...this.props.defaultFilters }
  };

  handleChange = ev => {
    const { name, checked } = ev.target;
    this.setState(prevState => ({
      filters: { ...prevState.filters, [name]: checked }
    }));
  };

  handleSelectAll = areAllFiltersSelected =>
    this.setState(prevState => {
      const newFilters = areAllFiltersSelected
        ? _.mapValues(prevState.filters, () => false)
        : _.mapValues(prevState.filters, () => true);

      return { filters: newFilters };
    });

  render() {
    const { isLoading, rows, name, waterMark } = this.props;
    const { filters } = this.state;

    const columns = [
      cols.attachedProperties,
      cols.clientCol,
      cols.statusCol,
      // cols.serviceCol,
      cols.stateCol,
      cols.financeCol,
      cols.budgetCol,
      cols.growthCol,
      cols.yieldCol,
      cols.goodToGoDateCol,
      cols.engageToIncCol,
      cols.engageDateCol,
      cols.setToBuyDateCol
    ];

    // Cast to pairs, filter by statuses within rows, and sort according to settings
    const sortedFilteredPairs = _(filters)
      .toPairs()
      .filter(([status]) => rows.some(row => row.status.value === status))
      .sortBy(([status]) => sortByStatus(status))
      .value();

    let headerContent;

    // Only show status checkboxes if rows contains one of the statuses
    if (sortedFilteredPairs.length !== 0) {
      const areAllFiltersSelected = _.every(
        sortedFilteredPairs,
        ([, checked]) => checked
      );

      headerContent = (
        <div className="table-filter">
          {/* Don't show "Show all" if there is only 1 filter */}
          {sortedFilteredPairs.length > 1 && (
            <label className="table-filter__checkbox">
              <input
                type="checkbox"
                onChange={() => this.handleSelectAll(areAllFiltersSelected)}
                checked={areAllFiltersSelected}
              />{" "}
              Show All
            </label>
          )}

          {sortedFilteredPairs.map(([status, checked]) => (
            <label key={status} className="table-filter__checkbox">
              <input
                type="checkbox"
                name={status}
                onChange={this.handleChange}
                checked={checked}
              />{" "}
              {statusMap.get(status).displayName}
            </label>
          ))}
        </div>
      );
    }

    const filteredRows = rows.filter(row =>
      // If row is a status with checked, keep that row
      sortedFilteredPairs.some(
        ([status, checked]) => row.status.value === status && checked
      )
    );

    const heading = name ? `${name.trim()}'s` : "My";

    return (
      <TableTemplate
        isLoading={isLoading}
        title={`${heading} Clients`}
        headerContent={headerContent}
        rows={filteredRows}
        cols={columns}
        waterMark={waterMark}
      />
    );
  }
}

export default BATable;
