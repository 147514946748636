/* State List (used in Add/Edit Client Form) */

module.exports = [
	"VIC",
	"NSW",
	"QLD",
	"WA",
	"SA",
	"TAS",
	"ACT"
]
