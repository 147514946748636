import { Component } from "react";
import moment from "moment";
import _ from "lodash";
import FileSaver from "file-saver";

// Modules
import api from "../../common/api";
import countryStates from "../../common/countryStates";
import phaseList from "../../common/phaseList";
import priceList from "../../common/priceList";
import suaList from "../../common/suburbToSua.json";
import {
  engagementColumns,
  propertyColumns,
  purchasedPropertiesReportColumns
} from "./utils";

const defaultTableFilters = {
  bas: [],
  dateMax: "",
  dateMin: "",
  engagements: [],
  offMarketFilter: false,
  phases: [],
  prices: [],
  showArchived: false,
  spotters: [],
  states: [],
  dateString: ""
};

const defaultReport = { property: false };

const defaultColumns = { ...engagementColumns, ...propertyColumns };

function fetchPurchasedProperties(query) {
  const filter = encodeURIComponent(JSON.stringify(query));
  return api.get(`/api/property?filter=${filter}`, undefined, true);
}

class ReportingBase extends Component {
  state = {
    spinner: false,
    basOptions: [],
    engagementOptions: [],
    phasesOptions: [],
    spottersOptions: [],
    statesOptions: [],
    dateStringTypeReport: [],
    tableStagedFilters: { ...defaultTableFilters },
    report: { ...defaultReport },
    headerCSV: { ...defaultColumns },
    query: {}
  };

  componentDidMount() {
    this.setPurchasedPropertiesReportState();

    // Load the engagements for the refinements filter
    this.setState({ spinner: true });
    this.handleRefinementTableOptions().then(() =>
      this.setState({ spinner: false })
    );
  }

  setPurchasedPropertiesReportState = () =>
    this.setState({
      report: { ...defaultReport, property: true },
      headerCSV: _.mapValues(this.state.headerCSV, (x, column) =>
        purchasedPropertiesReportColumns.some(y => y === column)
      ),
      tableStagedFilters: { ...defaultTableFilters, phases: ["Purchased"] }
    });

  handlePurchasedPropertiesReportChange = ev => {
    const { checked } = ev.target;

    if (checked) {
      this.setPurchasedPropertiesReportState();
    } else {
      // Revert to defaults
      this.setState({
        report: { ...defaultReport },
        headerCSV: { ...defaultColumns },
        tableStagedFilters: { ...defaultTableFilters }
      });
    }
  };

  // ==========================================================================
  // Report column input handlers
  // ==========================================================================

  handlePropertyFilterReport = event => {
    const { value } = event.target;
    this.setState(prevState => ({
      report: { ...defaultReport },
      headerCSV: {
        ...prevState.headerCSV,
        [value]: !prevState.headerCSV[value]
      }
    }));
  };

  handleEngagementSelectAll = ev => {
    const { checked } = ev.target;

    const isEngagementColumn = value =>
      _.some(engagementColumns, (x, column) => column === value);

    this.setState(prevState => ({
      report: { ...defaultReport },
      headerCSV: {
        ...prevState.headerCSV,
        ..._.mapValues(prevState.headerCSV, (value, column) =>
          isEngagementColumn(column) ? checked : value
        )
      }
    }));
  };

  handlePropertySelectAll = ev => {
    const { checked } = ev.target;

    const isPropertyColumn = value =>
      _.some(propertyColumns, (x, column) => column === value);

    this.setState(prevState => ({
      report: { ...defaultReport },
      headerCSV: {
        ...prevState.headerCSV,
        ..._.mapValues(prevState.headerCSV, (value, column) =>
          isPropertyColumn(column) ? checked : value
        )
      }
    }));
  };

  // ==========================================================================
  // CSV generation
  // ==========================================================================

  fetchPropertiesReport = () => {
    const csvHeaders = this.getCSVReportHeaders();

    return fetchPurchasedProperties(this.state.query).then(res => {
      this.props.showNotification("success", {
        title: "Property search complete",
        message: `${res.count} properties match your search parameters.`
      });

      return [csvHeaders, ...res.properties.map(this.mapPropertyToCSVValue)];
    });
  };

  getCSVReportHeaders = () => {
    const { headerCSV } = this.state;

    const csvHeaders = [
      headerCSV.address ? "Address" : null,
      headerCSV.suburb ? "Suburb" : null,
      headerCSV.region ? "Region" : null,
      headerCSV.bed_rooms ? "Beds" : null,
      headerCSV.bath_rooms ? "Bathrooms" : null,
      headerCSV.parking ? "Car Parkings" : null,
      headerCSV.type ? "Type" : null,
      headerCSV.walk_score ? "Walkscore" : null,
      headerCSV.year_build ? "Year Built" : null,
      headerCSV.growth ? "Growth" : null,
      headerCSV.rent ? "Rent" : null,
      headerCSV.yields ? "Yield" : null,
      headerCSV.land_size ? "Landsize" : null,
      headerCSV.vr ? "VR" : null,
      headerCSV.flood_check ? "Flood Check" : null,
      headerCSV.auto_val ? "AutoVal" : null,
      headerCSV.fsd ? "FSD" : null,
      headerCSV.price ? "Sold Price" : null,
      headerCSV.auction_date ? "AucDate" : null,
      headerCSV.lsd ? "LSD" : null,
      headerCSV.lsp ? "LSP" : null,
      headerCSV.dsr ? "DSR+" : null,
      headerCSV.url ? "URL" : null,
      headerCSV.client ? "Client" : null,
      headerCSV.labelForScraper ? "Label for Scraper" : null,
      headerCSV.property ? "Property" : null,
      headerCSV.ba ? "BA" : null,
      headerCSV.status ? "Status" : null,
      headerCSV.budget ? "Budget" : null,
      headerCSV.extra_budget ? "Extra Budget" : null,
      headerCSV.client_growth ? "Growth" : null,
      headerCSV.yield ? "Actual Yield" : null,
      headerCSV.state ? "State" : null,
      headerCSV.financer ? "Financer" : null,
      headerCSV.goodTogoDate ? "Good to go Date" : null,
      headerCSV.contract_date ? "Contract Date" : null,
      headerCSV.engagement_date ? "Engagement Date" : null,
      headerCSV.initial_date ? "Initial Date" : null,
      headerCSV.settle_date ? "Settle Date" : null,
      headerCSV.contract_value ? "Contract Value" : null,
      headerCSV.service ? "Service" : null,
      headerCSV.tenanted_date ? "Tenanted Date" : null,
      headerCSV.setToBuyDate ? "Set to buy Date" : null,
      headerCSV.rental_estimate ? "Rental Estimate" : null,
      headerCSV.estimated_yield ? "Estimated Yield" : null,
      headerCSV.conveyancer ? "Conveyancer" : null,
      headerCSV.realStateAgency ? "Real State Agency" : null,
      headerCSV.realStateAgent ? "Real State Agent" : null,
      headerCSV.clientType ? "Client Type" : null,
      headerCSV.peopleHelped ? "Number of People Helped" : null,
      headerCSV.fee ? "Fee" : null,
      headerCSV.propertyManager ? "Property Manager" : null,
      headerCSV.actual_rent ? "Actual Rent" : null
    ];

    return csvHeaders.filter(e => e !== null);
  };

  mapPropertyToCSVValue = property => {
    const { headerCSV } = this.state;
    const soldPrice = _.get(property, "client.contract_value");
    const estimatedRent = _.get(property, "client.rental_estimate");
    const actualRent = _.get(property, "client.actualRent");
    const region = this.getSUAForProperty(property);

    const calculateYield = rent => {
      if (!soldPrice) return "";

      const yield_ = ((rent * 52) / soldPrice) * 100;
      return yield_.toFixed(2);
    };

    const csvRow = [
      headerCSV.address ? property.address || "" : null,
      headerCSV.suburb ? property.suburb || "" : null,
      headerCSV.region ? (region ? region.SUA_NAME : "") : null,
      headerCSV.bed_rooms ? property.bed_rooms || "" : null,
      headerCSV.bath_rooms ? property.bath_rooms || "" : null,
      headerCSV.parking ? property.parking || "" : null,
      headerCSV.type ? property.type || "" : null,
      headerCSV.walk_score ? property.walk_score || "" : null,
      headerCSV.year_build ? property.year_build || "" : null,
      headerCSV.growth ? property.growth || "" : null,
      headerCSV.rent ? property.rent || "" : null,
      headerCSV.yields ? property.yields || "" : null,
      headerCSV.land_size ? property.land_size || "" : null,
      headerCSV.vr ? property.VR || "" : null,
      headerCSV.flood_check ? property.flood_check || "" : null,
      headerCSV.auto_val ? property.auto_val || "" : null,
      headerCSV.fsd ? property.FSD || "" : null,
      headerCSV.price ? property.price || "" : null,
      headerCSV.auction_date
        ? property.auction_date
          ? moment(property.auction_date).format("DD-MMM-YYYY")
          : ""
        : null,
      headerCSV.lsd ? property.LSD || "" : null,
      headerCSV.lsp ? property.LSP || "" : null,
      headerCSV.dsr ? property.DSR || "" : null,
      headerCSV.url ? property.url || "" : null,
      headerCSV.client
        ? _.has(property, "client.client.first_name") &&
          property.client.client.first_name
          ? property.client.client.first_name +
            " " +
            property.client.client.last_name
          : ""
        : null,
      headerCSV.labelForScraper
        ? _.has(property, "client.labelForScrapper") &&
          (property.client.labelForScrapper || "")
        : null,
      headerCSV.property
        ? _.has(property, "client.property.address") &&
          (property.client.property.address || "")
        : null,
      headerCSV.ba
        ? _.has(property, "client.business_analyst.nickname") &&
          (property.client.business_analyst.nickname || "")
        : null,
      headerCSV.status
        ? _.has(property, "client.status") && (property.client.status || "")
        : null,
      headerCSV.budget
        ? _.has(property, "client.budget") && (property.client.budget || "")
        : null,
      headerCSV.extra_budget
        ? _.has(property, "client.exact_budget") &&
          (property.client.exact_budget || "")
        : null,
      headerCSV.client_growth
        ? _.has(property, "client.growth") && (property.client.growth || "")
        : null,
      headerCSV.yield
        ? actualRent
          ? actualRent && calculateYield(actualRent)
          : ""
        : null,
      headerCSV.state
        ? _.has(property, "client.state") && (property.client.state || "")
        : null,
      headerCSV.financer
        ? _.has(property, "client.finance_broker") &&
          (property.client.finance_broker || "")
        : null,
      headerCSV.goodTogoDate
        ? _.has(property, "client.G2G_date") && property.client.G2G_date
          ? moment(property.client.G2G_date).format("DD-MMM-YYYY")
          : ""
        : null,
      headerCSV.contract_date
        ? _.has(property, "client.contract_date") &&
          property.client.contract_date
          ? moment(property.client.contract_date).format("DD-MMM-YYYY")
          : ""
        : null,
      headerCSV.engagement_date
        ? _.has(property, "client.engage_date") && property.client.engage_date
          ? moment(property.client.engage_date).format("DD-MMM-YYYY")
          : ""
        : null,
      headerCSV.initial_date
        ? _.has(property, "client.initial_date") && property.client.initial_date
          ? moment(property.client.initial_date).format("DD-MMM-YYYY")
          : ""
        : null,
      headerCSV.settle_date
        ? _.has(property, "client.settle_date") && property.client.settle_date
          ? moment(property.client.settle_date).format("DD-MMM-YYYY")
          : ""
        : null,
      headerCSV.contract_value
        ? _.has(property, "client.contract_value") &&
          (property.client.contract_value || "")
        : null,
      headerCSV.service
        ? _.has(property, "client.service") && (property.client.service || "")
        : null,
      headerCSV.tenanted_date
        ? _.has(property, "client.tenanted_date") &&
          property.client.tenanted_date
          ? moment(property.client.tenanted_date).format("DD-MMM-YYYY")
          : ""
        : null,
      headerCSV.setToBuyDate
        ? _.has(property, "client.set_to_buy_date") &&
          property.client.set_to_buy_date
          ? moment(property.client.set_to_buy_date).format("DD-MMM-YYYY")
          : ""
        : null,
      headerCSV.rental_estimate
        ? _.has(property, "client.rental_estimate") &&
          (property.client.rental_estimate || "")
        : null,
      headerCSV.estimated_yield
        ? estimatedRent
          ? estimatedRent && calculateYield(estimatedRent)
          : ""
        : null,
      headerCSV.conveyancer
        ? _.has(property, "client.conveyancer") &&
          (property.client.conveyancer || "")
        : null,
      headerCSV.realStateAgency
        ? _.has(property, "client.real_estate_agency") &&
          (property.client.real_estate_agency || "")
        : null,
      headerCSV.realStateAgent
        ? _.has(property, "client.real_estate_agent") &&
          (property.client.real_estate_agent || "")
        : null,
      headerCSV.clientType
        ? _.has(property, "client.type") && (property.client.type || "")
        : null,
      headerCSV.peopleHelped
        ? _.has(property, "client.number_of_people_helped") &&
          (property.client.number_of_people_helped || "")
        : null,
      headerCSV.fee
        ? _.has(property, "client.fee") && (property.client.fee || "")
        : null,
      headerCSV.propertyManager
        ? _.has(property, "client.property_manager") &&
          (property.client.property_manager || "")
        : null,
      headerCSV.actual_rent
        ? _.has(property, "client.actualRent") &&
          (property.client.actualRent || "")
        : null
    ];

    return csvRow.filter(e => e !== null);
  };

  getSUAForProperty = property => {
    let postcode = "";
    const suburb = property.suburb || "";
    const formattedAddress = property.formatted_address || "";

    if (formattedAddress) {
      // Extract postcode
      const words = formattedAddress.split(" ");
      postcode = words[words.length - 2].replace(",", "");
    }

    // Find property's SUA
    // Returns 'undefined' if SUA cannot be found
    return !isNaN(postcode) ? 
			suaList.find(x => x.POST_CODE === postcode && x.LOCALITY.toLowerCase() === suburb.toLowerCase()) : 
			suaList.find(x => x.LOCALITY.toLowerCase() === suburb.toLowerCase() )
  };

  // ==========================================================================
  // Property search
  // ==========================================================================

  handleRefinementTableOptions = async () => {
    const basOptions = _(this.props.buyers)
      .filter(
        x =>
          x.user_role === "BA" ||
          x.user_role === "buyer" ||
          x.user_role === "associate"
      )
      .sortBy("nickname")
      .value();

    const filter = encodeURIComponent(
      JSON.stringify({ selectEngagement: "All" })
    );

    await api
      .get(`/api/clientPurchases/list?filter=${filter}`)
      .then(({ purchases: engagements }) => {
        this.setState({ engagementOptions: engagements });
      })
      .catch(console.error);

    const phasesOptions = _.map(phaseList, (val, key) => ({
      value: key,
      label: key
    }));

    const priceOptions = _.map(priceList, val => ({
      value: val,
      label: val
    }));

    const dateStringTypeReport = [
      {
        value: "scrapped date",
        label: "Scrapped Date"
      },
      {
        value: "purchased date",
        label: "Purchased Date"
      }
    ];

    const statesOptions = countryStates.map(x => ({ value: x, label: x }));
    this.setState({
      basOptions,
      phasesOptions,
      priceOptions,
      statesOptions,
      dateStringTypeReport
    });
  };

  // Fetch and generate CSV
  handleTableSearchSubmit = ev => {
    ev.preventDefault();

    this.handleRefinementFilter().then(() => {
      // Generate the report!
      this.setState({ spinner: true });
      this.fetchPropertiesReport(this.state.query).then(csvData => {
        this.setState({ spinner: false });

        const data = csvData
          .map(line => line.map(cell => `"${cell}"`))
          .join("\n");
        const blob = new Blob([data], { type: "text/csv" });

        FileSaver.saveAs(
          blob,
          // prettier-ignore
          `BA Toolkit - Purchased Properties Report - ${moment().format("DD-MM-YYYY")}.csv`
        );
      });
    });
  };

  handleTableStagedFilterChange = (name, value) => {
    this.setState(
      prevState => ({
        tableStagedFilters: { ...prevState.tableStagedFilters, [name]: value }
      }),
      this.handleRefinementFilter
    );
  };

  handleRefinementFilter = () => {
    const { tableStagedFilters } = this.state;

    const minMax = {
      min: tableStagedFilters.dateMin || "",
      max: tableStagedFilters.dateMax || ""
    };

    const query = {
      bas: tableStagedFilters.bas,
      engagements: tableStagedFilters.engagements,
      phases: tableStagedFilters.phases,
      prices: tableStagedFilters.prices,
      spotters: tableStagedFilters.spotters,
      states: tableStagedFilters.states,
      dates: JSON.stringify(minMax),
      dateStrings: tableStagedFilters.dateString,
      searchs: this.tableSearchInput.value,
      archived: tableStagedFilters.showArchived,
      offMarketFilter: tableStagedFilters.offMarketFilter,
      refinements: true
    };

    return new Promise(resolve => this.setState({ query }, resolve));
  };

  clearRefinementsFilter = e => {
    e.preventDefault();

    this.setState(
      prevState => {
        const { tableStagedFilters } = prevState;
        this.tableSearchInput.value = "";
        Object.keys(tableStagedFilters).forEach(key => {
          switch (key) {
            case "bas":
            case "engagements":
            case "phases":
            case "prices":
            case "spotters":
            case "states":
              tableStagedFilters[key] = [];
              break;

            case "dateMin":
              tableStagedFilters[key] = moment()
                .subtract(6, "months")
                .format("DD/MM/YY");
              break;

            case "search":
              tableStagedFilters[key] = "";
              break;

            default:
              tableStagedFilters[key] = false;
          }
        });

        return { tableStagedFilters, query: {} };
      },
      () => {
        const { tableStagedFilters, query } = this.state;
        const minMax = {
          min: tableStagedFilters.dateMin
        };
        query.dates = JSON.stringify(minMax);
        query.refinements = true;

        this.setState({ query });
      }
    );
  };
}

module.exports = ReportingBase;
