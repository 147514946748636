import React, { Component } from "react";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import validator from 'validator';

// Modules
import api from "../../common/api";
import { getClientFullName, labelCreator } from "../../common/client-helpers";
import { getOptionalDateString } from "../../common/date-helpers";

// Components
import AddClient from "./AddClient";
import AddEngagement from "./AddEngagement";
import withNotifications from "../withNotifications";

class AddClientContainer extends Component {
  state = {
    client: null,
    engagement: null,

    budget: "",
    clientBuyer: localStorage.getItem("USER_ID"),
    clientStatus: "Engaged",
    engagementSource: "",
    exactBudget: "",
    fee: "",
    firstName: "",
    growth: "",
    lastName: "",
    middleName: "",
    numberOfPeopleHelped: "",
    service: "",
    type: "",
    yield: "",
    engageDate: null,
    initialDate: null,
    email:"",
    // Builk upload state
    excel: null,

    showBottomForm: false
  };

  growth = null;
  yield = null;

  componentWillMount() {
    const admin = this.props.user ? this.props.user.admin : "";
    const advocateToken = localStorage.getItem("USER_TOKEN");
    const clientToken = localStorage.getItem("CLIENT_TOKEN");

    if (!admin && !clientToken) {
      browserHistory.push("client-login");
    } else if (!admin) {
      browserHistory.push("/home/shortlisted-property");
    } else if (!advocateToken) {
      browserHistory.push("login");
    }
  }

  // ========================================================================
  // Input handlers
  // ========================================================================

  handleInputChange = ev => {
    const { name, value } = ev.target;

    this.setState({ [name]: value });
  };

  // ==========================================================================
  // Single client/engagement methods
  // ==========================================================================

  handleCreateClient = async () => {
    if (!this.props.user.token) {
      return alert(
        "Please login to add your client. Your session has been expired."
      );
    }

    if (!this.state.firstName || !this.state.lastName || !this.state.email) {
      return alert("Please fill all required fields with *.");
    }
    if(!validator.isEmail(this.state.email)){
      return alert("Please enter valid email.");
    }

    // Create a client
    const { clientData: client, notify, message } = await api.post(
      "/api/client/register",
      {
        first_name: this.state.firstName.trim(),
        middle_name: this.state.middleName
          ? this.state.middleName.trim()
          : null,
        last_name: this.state.lastName.trim(),
        type: this.state.type,
        email:this.state.email
      }
    );

    // Guard against errors
    if (notify) return alert(message);

    this.setState({ client }, () => {
      // Creating an engagement for the new client
      api
        .post("/api/clientPurchases/add", { client: client._id })
        .then(({ purchase: engagement, notify, message }) => {
          // Guard against errors
          if (notify) return alert(message);

          this.setState({ engagement, showBottomForm: true }, () => {
            const engagement = {};
            engagement.engagement_source = this.state.engagementSource;
            api
              .put("/api/clientPurchases/", {
                filter: this.state.engagement._id,
                fields: engagement
              })
              .then(() => {
                // Show success notification
                this.props.showNotification("success", {
                  title: "Successfully added client",
                  message: "Client has been added successfully"
                });
              });
          });

          return api.put("/api/client", {
            filter: client._id,
            purpose: "numberOfEngagements",
            fields: { numberOfEngagements: 1 }
          });
        });
    });
  };

  handleEngagementUpdate = () => {
    const engagement = {};

    engagement.labelForScrapper = labelCreator(
      getClientFullName(this.state.client),
      this.state.budget,
      null
    );

    engagement.budget = this.state.budget;
    engagement.business_analyst = this.state.clientBuyer;
    engagement.exact_budget = this.state.exactBudget;
    engagement.fee = this.state.fee;
    engagement.growth = this.state.growth || "N/A";
    engagement.number_of_people_helped = this.state.numberOfPeopleHelped;
    engagement.service = this.state.service;
    engagement.status = this.state.clientStatus;
    engagement.yield = this.state.yield || "N/A";
    engagement.engagement_source = this.state.engagementSource;

    engagement.engage_date = getOptionalDateString(this.state.engageDate);
    engagement.initial_date = getOptionalDateString(this.state.initialDate);

    api
      .put("/api/clientPurchases/", {
        filter: this.state.engagement._id,
        fields: engagement
      })
      .then(({ notify }) => {
        // Guard against errors
        if (notify) throw Error(notify);

        // Show success notification
        this.props.showNotification("success", {
          title: "Successfully created Engagment",
          message: "Client engagement has been created"
        });

        this.setState({ showBottomForm: false });

        return api.put("/api/client/", {
          filter: this.state.client._id,
          fields: { business_analyst: this.state.clientBuyer }
        });
      })
      .catch(err => {
        console.error("Error updating engagement", err);
      });
  };

  handleRemoveClientAndEngagement = () => {
    api
      .delete("/api/client/removeClient", { filter: this.state.client._id })
      .then(() => {
        // Show success notification
        this.props.showNotification("success", {
          title: "Successfully Removed Client",
          message: "Client has been removed"
        });
      })
      .catch(err => {
        console.error("Error removing client", err);
      });

    const removeEngagementQuery = encodeURIComponent(
      JSON.stringify({ _id: this.state.engagement._id })
    );

    api
      .delete(`/api/clientPurchases/remove?filter=${removeEngagementQuery}`)
      .then(() => {
        // Show success notification
        this.props.showNotification("success", {
          title: "Successfully Removed Client Engagement",
          message: "Client engagement has been removed"
        });

        this.setState({ showBottomForm: false });
      })
      .catch(err => {
        console.error("Error removing engagement", err);
      });
  };

  // ==========================================================================
  // Bulk upload
  // ==========================================================================

  handleFile = ev => {
    const { files } = ev.target;

    files.forEach(file => {
      const reader = new FileReader();

      reader.onload = ev => {
        const { result: data } = ev.target;

        const workbook = XLSX.read(data, { type: "binary" });

        workbook.SheetNames.map(sheetName => {
          const worksheet = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName],
            {
              header: [
                "firstname",
                "middlename",
                "lastname",
                "g2gDate",
                "priceRange"
              ],
              range: 1,
              cellDates: true
            }
          );

          if (this.state.excel) {
            this.setState({ excel: this.state.excel.concat(worksheet) });
          } else {
            document.getElementById("show_file").innerHTML = "File Uploaded";

            this.setState({ excel: worksheet });
          }
        });
      };

      reader.readAsBinaryString(file);
    });
  };

  handleUploadBulkClients = () => {
    const authtoken = this.props.user.token;

    if (!authtoken) {
      return alert(
        "Please login to add your client. Your session has been expired."
      );
    }

    api
      .post(
        `/api/client/addBulk?authtoken=${encodeURIComponent(authtoken)}`,
        this.state.excel
      )
      .then(result => {
        // Guard against errors
        if (result.notify) return alert(result.notify);

        alert(JSON.stringify(result.res));
        this.setState({ excel: null });
        document.getElementById("show_file").innerHTML = "";
      });
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  render() {
    return (
      <div className="row">
        <div className="col-md-6">
          <div className="card">
            <div className="header">
              <h4
                className="title text-center"
                style={{ fontFamily: '"Poppins", sans-serif' }}
              >
                ADD CLIENT
              </h4>
            </div>

            <div className="content">
              <AddClient
                firstName={this.state.firstName}
                lastName={this.state.lastName}
                email={this.state.email}
                middleName={this.state.middleName}
                onAddClick={this.handleCreateClient}
                onInputChange={this.handleInputChange}
                type={this.state.type}
              />

              {this.state.showBottomForm && (
                <AddEngagement
                  ba={this.state.clientBuyer}
                  bas={this.props.buyers}
                  budget={this.state.budget}
                  engageDate={this.state.engageDate}
                  engagementSource={this.state.engagementSource}
                  exactBudget={this.state.exactBudget}
                  fee={this.state.fee}
                  growth={this.state.growth}
                  growthRef={ele => (this.growth = ele)}
                  initialDate={this.state.initialDate}
                  numberOfPeopleHelped={this.state.numberOfPeopleHelped}
                  onAddClick={this.handleEngagementUpdate}
                  onDateInputChange={name => date => {
                    this.setState({ [name]: date });
                  }}
                  onInputChange={this.handleInputChange}
                  onInputNaChange={name => {
                    this[name].disabled = !this[name].disabled;

                    this.setState({ [name]: "" });
                  }}
                  onRemoveClick={this.handleRemoveClientAndEngagement}
                  service={this.state.service}
                  status={this.state.clientStatus}
                  yield_={this.state.yield}
                  yieldRef={ele => (this.yield = ele)}
                />
              )}
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card">
            <div className="header">
              <h4
                className="title text-center"
                style={{ fontFamily: '"Poppins", sans-serif' }}
              >
                UPLOAD CLIENT DETAILS
              </h4>
            </div>

            {/* Upload clients in bulk section */}
            <div className="content">
              <div className="form-group">
                <h5>Click to upload client details</h5>

                <button htmlFor="excel" className="btn btn-wd">
                  Custom Upload
                </button>

                <input
                  id="excel"
                  type="file"
                  onChange={this.handleFile}
                  style={{ display: "none" }}
                />
                <div id="show_file" />
              </div>

              {/* Submit button */}
              <div className="text-center">
                <button
                  className="btn btn-fill btn-info"
                  onClick={this.handleUploadBulkClients}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user || {},
  buyers: state.buyers || []
});

export default connect(mapStateToProps)(withNotifications(AddClientContainer));
