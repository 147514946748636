import React from "react";
import moment from "moment";

const renderFixedNumber = value => {
  // Value is empty
  if (!value) return "-";

  const asNumber = Number(value);

  if (!isNaN(asNumber)) {
    return asNumber.toFixed(2);
  } else {
    return value || "-";
  }
};

export const propertyCompareFields = [
  { label: "Property", key: "addressLink" },
  { label: "Auction Date", key: "auctionDate" },
  { label: "EW Inspection Pics & Vids", key: "googleDocLink" },
  { label: "Comparable Sales", key: "comparable_sales" },
  { label: "Advertised Price", key: "price" },
  { label: "Predicted Sale Price", key: "sellingPrice" },
  { label: "Auto Valuation", key: "autoValue" },
  { label: "Last Sale Date", key: "lsd" },
  { label: "Last Sale Price", key: "lsp" },
  { label: "Land Size", key: "landSize" },
  { label: "Beds", key: "beds" },
  { label: "Baths", key: "baths" },
  { label: "Parking", key: "parking" },
  { label: "Type", key: "propertyType" },
  {
    label: "Year Built",
    key: "yearBuild",
    render: value =>
      value && moment(value).isValid() ? moment(value).format("YYYY") : "-"
  },
  { label: "Flood Check", key: "floodCheck" },
  {
    label: "Historical Growth Performance",
    key: "growth",
    render: renderFixedNumber
  },
  { label: "Rental Estimate", key: "rent" },
  { label: "Gross Rental Yield", key: "yield", render: renderFixedNumber },
  { label: "Walk Score", key: "walkScore" },
  { label: "Demand Supply Ratio", key: "dsr" },
  { label: "Vacancy Rate", key: "vr" },
  { label: "Data", key: "data" },
  { label: "Notes", key: "notes" },
  { label: "Comments", key: "comments" }
];

export const renderPropertyField = (field, property) =>
  field.render
    ? field.render(property[field.key], property)
    : property[field.key];

export const mapPropertyToComparisonDisplay = property => {
  const addressLink = property.link ? (
    <a href={property.link}>{property.address}</a>
  ) : (
    <span>{property.address} (off-market)</span>
  );
  return { addressLink, ...property };
};
