import React, { Component } from "react";
import moment from "moment";

// Modules
import {
  propertyCompareFields,
  renderPropertyField,
  mapPropertyToComparisonDisplay
} from "../../common/propertyCompareFields";

function CompareTable({ properties, fields }) {
  return (
    <div
      className="col-md-12"
      id="table_wrapper"
      style={{ overflowY: "scroll" }}
    >
      {!properties.length ? (
        <h4>Please select Properties for comparing.</h4>
      ) : (
        <table className="diff_table" style={{ width: "100%" }}>
          <tbody>
            {fields.map(field => (
              <tr key={field.key} className="diff_table">
                <td className="diff_table">
                  <b>{field.label}</b>
                </td>
                {properties.map((property, i) => (
                  <td key={i} className="diff_table text-center" style={{whiteSpace: 'pre-line'}}>
                    {renderPropertyField(field, property) || "-"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

class CompareProperties extends Component {
  generateExcel(ev) {
    ev.preventDefault();

    const dataType = "data:application/vnd.ms-excel";
    // Getting data from the table
    const tableDiv = document.getElementById("table_wrapper");
    const tableHtml = encodeURIComponent(tableDiv.outerHTML);

    const a = document.createElement("a");
    a.href = `${dataType}, ${tableHtml}`;
    a.download = `ISpyIBuy - Properties Compare - ${moment().format(
      "DD-MM-YYYY"
    )}.xls`;
    a.click();
  }

  render() {
    return (
      <div className="row comparePropertyBox">
        <div className="col-md-12">
          <div className="row">
            {/* Header */}
            <div className="modal-header col-md-12">
              <button
                type="button"
                className="close m-t-15"
                data-toggle="collapse"
                href="#collapseCompareBody"
              >
                &times;
              </button>
              <h4 className="cntr">
                Shortlisted Property Review Modelling Assessment Calculator
              </h4>
            </div>

            {/* Table */}
            <CompareTable
              properties={this.props.properties.map(
                mapPropertyToComparisonDisplay
              )}
              fields={propertyCompareFields}
            />

            {/* Footer */}
            <div className="modal-footer cntr ">
              <button
                className="sweet-confirm btn btn-info btn-fill m-t-15"
                onClick={this.generateExcel}
              >
                Save
              </button>
              <button
                className="sweet-cancel btn btn-default m-t-15"
                data-toggle="collapse"
                href="#collapseCompareBody"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompareProperties;
