'use strict';
import { Component } from 'react';
import { browserHistory } from 'react-router';

// Modules
import api from '../../common/api';
import * as redux from '../../redux/redux';

class LoginBase extends Component {
    constructor(props) {
        super(props);
        this.state = {username:'',password:'', type:"password"}
        this.login = this.login.bind(this);
        this.changeType = this.changeType.bind(this);
    }

    componentDidMount() {
        let token = localStorage.getItem('USER_TOKEN');
        if (token) {
            browserHistory.push('home');
            return null;
        } else {
           console.log("token expired...");
        }
    }

    changeType(e){
        e.preventDefault();
        if(this.state.type=="password"){
         this.setState({type:"text"});
        }
        else
        this.setState({type:"password"});
    }


    login(e) {
        e.preventDefault();
        let {username, password} = this.state;
        username = username.trim();
        password = password.trim();
        if (!username || !password) {
            alert('Please fill all fields');
        } else {
            api.post('/api/auth/login', {username, password}).then(result => {
                if (result.notify) {
                    alert(result.notify);
                } else {
                    redux.setUser(result);
                    // alert("login Successfully.")
                    // showSwal('login Successfully')
                    if (result.token){
                        localStorage.setItem('USER_TOKEN', result.token);
                    }
                    localStorage.setItem('USER_ID', result.info.userId)
                    browserHistory.push('home');
                    const userRole = result.info.user_role;
                    if(userRole === "super-admin" || userRole === "manager") redux.getBusinessDaysAsync()
                }
            });
        }
    }

}

module.exports = LoginBase;