import React, { Component } from "react";
import _ from "lodash";
import Promise from "bluebird";
import moment from "moment-timezone";
import { connect } from "react-redux";
import CSVReader from "react-csv-reader";

// Modules
import api from "../common/api";

class UploadCsv extends Component {
  state = { status: "" };

  handleFileLoaded = async csvRows => {
    // Throw away header, this is encoded in the csvColumnsAsEngagementProperties array
    const csvHeader = csvRows.shift();

    const rawEngagements = csvRows
      .map(row =>
        // Build object from CSV row
        row.reduce((accum, curr, i) => {
          const engagementProperty = csvColumnsAsEngagementProperties[i];
          accum[engagementProperty] = curr;
          return accum;
        }, {})
      )
      .filter(rawEngagement =>
        // Don't operate on those marked as remove, instead, remove them from the toolkit
        typeof rawEngagement.EXTERNAL_PROPERTY__REMOVE === "string"
          ? rawEngagement.EXTERNAL_PROPERTY__REMOVE.trim().toLowerCase() !==
            "remove"
          : true
      );
    console.log("rawEngagements:", rawEngagements);

    // Validate that all BAs exist
    rawEngagements.forEach(rawEngagement => {
      const ba = this.props.buyers.some(
        ba =>
          ba.nickname.toLowerCase() ===
          rawEngagement.EXTERNAL_PROPERTY__BA.trim().toLowerCase()
      );

      if (!ba) {
        throw new Error(
          `No BA found for ${rawEngagement.EXTERNAL_PROPERTY__BA}`
        );
      }
    });

    this.setState({ status: "Creating clients..." });

    // Create new clients from engagements
    const clientsWithDuplicates = rawEngagements.map(rawEngagement => {
      const first_name = toString(rawEngagement.EXTERNAL_PROPERTY__CLIENT);
      const last_name = "UPLOADED_FROM_SPREADSHEET";
      const type =
        typeof rawEngagement.EXTERNAL_PROPERTY__CLIENT_TYPE === "string" &&
        rawEngagement.EXTERNAL_PROPERTY__CLIENT_TYPE
          ? rawEngagement.EXTERNAL_PROPERTY__CLIENT_TYPE.toUpperCase()
          : undefined;

      const client = { first_name, last_name, type };
      return client;
    });
    const clients = _.uniqBy(clientsWithDuplicates, "first_name");

    const clientsWithIds = await Promise.mapSeries(clients, client =>
      api
        .post("/api/client/register", client)
        .then(json => json.clientData)
        // ignore errors
        .catch(err => {})
    );
    console.log("clientsWithIds:", clientsWithIds);

    this.setState({ status: "Creating or updating engagements..." });

    // Create new engagement updates
    const engagements = rawEngagements.map(rawEngagement => {
      const actualRent = toNumber(rawEngagement.actualRent);
      const blueprint_completed = toBoolean(rawEngagement.blueprint_completed);
      const blueprint_completed_date = toISOString(
        rawEngagement.blueprint_completed_date
      );
      const bp_date = toISOString(rawEngagement.bp_date);
      const budget = toString(rawEngagement.budget);
      const comment = toString(rawEngagement.comment);
      const contract_date = toISOString(rawEngagement.contract_date);
      const contract_value = toNumber(rawEngagement.contract_value);
      const conveyancer = toString(rawEngagement.conveyancer);
      const date_promised = toISOString(rawEngagement.date_promised);
      const dateAvailableForRent = toISOString(
        rawEngagement.dateAvailableForRent
      );
      const engage_date = toISOString(rawEngagement.engage_date);
      const exact_budget = toNumber(rawEngagement.exact_budget);
      const fee = toNumber(rawEngagement.fee);
      const finance_broker = toString(rawEngagement.finance_broker);
      const finance_date = toISOString(rawEngagement.finance_date);
      const G2G_date = toISOString(rawEngagement.G2G_date);
      const growth = toString(rawEngagement.growth);
      const incubation_type =
        typeof rawEngagement.incubation_type === "string" &&
        rawEngagement.incubation_type
          ? rawEngagement.incubation_type.toLowerCase()
          : undefined;
      const initial_date = toISOString(rawEngagement.initial_date);
      const number_of_people_helped = toNumber(
        rawEngagement.number_of_people_helped
      );
      const property_manager = toString(rawEngagement.property_manager);
      const real_estate_agency = toString(rawEngagement.real_estate_agency);
      const real_estate_agent = toString(rawEngagement.real_estate_agent);
      const rental_estimate = toNumber(rawEngagement.rental_estimate);
      const sent_to_client = toBoolean(rawEngagement.sent_to_client);
      const sent_to_client_date = toISOString(
        rawEngagement.sent_to_client_date
      );
      const service = toString(rawEngagement.service);
      const set_to_buy_date = toISOString(rawEngagement.set_to_buy_date);
      const settle_date = toISOString(rawEngagement.settle_date);
      const state =
        typeof rawEngagement.state === "string" && rawEngagement.state
          ? rawEngagement.state.toUpperCase()
          : undefined;
      const status = toString(rawEngagement.status);
      const tenanted_date = toISOString(rawEngagement.tenanted_date);
      const UPLOADED_FROM_SPREADSHEET_PROPERTY_ADDRESS = toString(
        rawEngagement.EXTERNAL_PROPERTY__PROPERTY
      );
      const _yield = toString(rawEngagement.yield);

      const _id =
        typeof rawEngagement._id === "string" &&
        rawEngagement._id.trim().toLowerCase() !== "na yet"
          ? rawEngagement._id.replace(/"/g, "")
          : undefined;

      const ba = this.props.buyers.find(
        ba =>
          ba.nickname.toLowerCase() ===
          rawEngagement.EXTERNAL_PROPERTY__BA.trim().toLowerCase()
      );

      const client = clientsWithIds.find(
        client => client.first_name === rawEngagement.EXTERNAL_PROPERTY__CLIENT
      )._id;

      const engagement = {
        _id,
        actualRent,
        blueprint_completed_date,
        blueprint_completed,
        bp_date,
        budget,
        business_analyst: ba._id,
        client,
        comment,
        contract_date,
        contract_value,
        conveyancer,
        date_promised,
        dateAvailableForRent,
        engage_date,
        exact_budget,
        fee,
        finance_broker,
        finance_date,
        G2G_date,
        growth,
        incubation_type,
        initial_date,
        number_of_people_helped,
        property_manager,
        real_estate_agency,
        real_estate_agent,
        rental_estimate,
        sent_to_client_date,
        sent_to_client,
        service,
        set_to_buy_date,
        settle_date,
        state,
        status,
        tenanted_date,
        UPLOADED_FROM_SPREADSHEET_PROPERTY_ADDRESS,
        yield: _yield
      };

      return engagement;
    });
    console.log("engagements:", engagements);

    await Promise.each(engagements, engagement => {
      const { _id, ...rest } = engagement;

      // Update or create an engagement
      if (_id) {
        return api.put("/api/clientPurchases/", { filter: _id, fields: rest });
      } else {
        return api.post("/api/clientPurchases/add", rest);
      }
    });

    this.setState({ status: "Upload complete" });
    alert("Upload complete");
  };

  render() {
    return (
      <div className="col-md-12">
        <CSVReader
          cssClass="csv-input"
          label="Select CSV"
          onFileLoaded={this.handleFileLoaded}
        />

        {this.state.status && this.state.status}
      </div>
    );
  }
}

// ============================================================================
// Helpers
// ============================================================================

const csvColumnsAsEngagementProperties = [
  "EXTERNAL_PROPERTY__CLIENT",
  "EXTERNAL_PROPERTY__PROPERTY",
  "EXTERNAL_PROPERTY__BA",
  "status",
  "EXTERNAL_PROPERTY__CLIENT_TYPE",
  "incubation_type",
  "number_of_people_helped",
  "state",
  "service",
  "budget",
  "exact_budget",
  "growth",
  "yield",
  "contract_value",
  "fee",
  "finance_broker",
  "conveyancer",
  "rental_estimate",
  "actualRent",
  "property_manager",
  "real_estate_agent",
  "real_estate_agency",
  "comment",
  "blueprint_completed",
  "sent_to_client",
  "G2G_date",
  "engage_date",
  "initial_date",
  "set_to_buy_date",
  "date_promised",
  "blueprint_completed_date",
  "sent_to_client_date",
  "finance_date",
  "bp_date",
  "contract_date",
  "settle_date",
  "dateAvailableForRent",
  "tenanted_date",
  "_id",
  "EXTERNAL_PROPERTY__REMOVE"
];

const toString = value => {
  if (typeof value !== "string" || !value) return undefined;
  return value;
};

const toNumber = value => {
  if (typeof value !== "string" || !value) return undefined;
  const asNumber = Number(value);
  return Number.isNaN(asNumber) ? undefined : asNumber;
};

const toBoolean = value => {
  if (typeof value !== "string" || !value) return undefined;
  else if (value.trim().toLowerCase() === "yes") return true;
  else if (value.trim().toLowerCase() === "no") return false;
  else return undefined;
};

const toISOString = value => {
  if (typeof value !== "string" || !value) return undefined;
  const asMoment = moment.tz(
    value,
    ["D/M/YY", "DD/MM/YY"],
    true,
    "Australia/Melbourne"
  );
  return asMoment.isValid() ? asMoment.toISOString() : undefined;
};

const mapStateToProps = state => ({ buyers: state.buyers || [] });

export default connect(mapStateToProps)(UploadCsv);
