'use strict';
import React from 'react';
import {Link} from 'react-router';
import Header from '../header/Header';
import Footer from '../footer/Footer';

export default function(){
    return ClientLoginComponentRender.call(this);
}


var ClientLoginComponentRender = function(){
	
	/* Set Login, Signup Background Image */
    const divStyle = {
        backgroundImage: 'url(' + "img/background-6.jpg" + ')',
        overflow: 'hidden',
        backgroundSize: 'cover'
    };
	
    return (
		<div className="wrapper">
            <Header />
            <div className="wrapper wrapper-full-page">
                <div className="full-page login-page" data-color="" data-image="img/background-6.jpg" >
                    <div className="content">
				    	<div className="container">
				            <div className="row">
				                <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
								    <form method="#" action="#">
								        <div className="card pages-bgcolor" data-background="color" data-color="blue">
								            <div className="header">
								                <h3 className="title pages-color">Client Login</h3>
								            </div>
								            <div className="content">
								                <div className="form-group">
								                    <label className="pages-color">Username</label>
								                    <input type="text" placeholder="Enter username" className="form-control input-no-border" ref="username" required="true" />
								                </div>
								                <div className="form-group">
								                    <label className="pages-color">Password</label>
								                    <input type="password" placeholder="Password" className="form-control input-no-border" ref="password" required="true" />
								                </div>
								            </div>
								            <div className="footer text-center">
								                <button type="submit" className="btn pages-buttoncolor" onClick={this.login}>Let's go</button>
				                                <div className="forgot">
				                                    <a className="pages-color">Forgot your password?</a>
				                                </div>
								            </div>
								        </div>
								    </form>
								</div>
							</div>
						</div>
                    </div>
                    <Footer />
                    <div className="full-page-background" style={divStyle} >
                    </div> 
                </div>
            </div> 
        </div>
    )
};

