// Customise fields to be reported on (vertical and horizontal) csv downloads
// (use in User Profile Component)

module.exports = [
    {key : "address",label:"Property Address"},
	{key : "link",label:"Property URL"},
	{key : "spotter",label:"Spotter"},
	{key : "client",label:"Client"},
	{key : "buyerAdvocate",label:"BA"},
	{key : "addedAt",label:"Spotted At"},
	{key : "auctionDate",label:"Auction Date"},
	{key : "suburb",label:"Suburb"},
	{key : "state",label:"State"},
	{key : "price",label:"Advertised Price"},
	{key : "buyPrice",label:"Buy Price"},
	{key : "soldPrice",label:"Sold Price"},
	{key : "sellingPrice",label:"Selling Price"},
	{key : "landSize",label:"Land Size"},
    {key : "beds",label:"Beds"},
    {key : "baths",label:"Baths"},
    {key : "parking",label:"Parking"},
    {key : "propertyType",label:"Type"},
    {key : "googleDocLink",label:"Google Docs"},
    {key : "growth",label:"Growth"},
    {key : "autoValue",label:"Auto Valuation"},
    {key : "fsd",label:"FSD"},
    {key : "lsd",label:"Last Sale Date"},
    {key : "rent",label:"Rent"},
    {key : "yield",label:"Yield"},
    {key : "walkScore",label:"Walk Score"},
    {key : "dsr",label:"Demand Supply Ratio"},
    {key : "vr",label:"Vacancy Rate"},
    {key : "yearBuild",label:"Year Built"},
    {key : "g2gDate",label:"G2G Date"},
    {key : "notes",label:"Notes"},
    {key : "comments", label:"Comments"},
    {key : "data", label:"Data"},
    {key : "comparable_sales", label:"Comparable Sales"}
]
