import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { browserHistory } from "react-router";
import { connect } from "react-redux";

// Modules
import api from "../../common/api";
import { getClientFullName, labelCreator } from "../../common/client-helpers";

// Components
import CreateEngagement from "./CreateEngagement";
import ClientListEngagements from "./ClientListEngagements";
import EditClient from "./EditClient";
import Spinner from "../../common/spinner";
import withNotifications from "../withNotifications";

function createEngagement(newEngagement) {
  return api
    .post("/api/clientPurchases/add", newEngagement)
    .then(res => (res.notify ? Promise.reject({ error: res.message }) : res));
}

class ClientList extends Component {
  state = {
    _rows: [],
    isActive: true,
    offset: 0, // current offset for Property List Pagination
    limit: 10, // current limit(number of records ona page) for Property List Pagination
    pageCount: 0,

    isModalOpen: false, // To open the modal, for editing client details
    spinner: true,
    showNewEngagementForm: false,
    // label: "",
    clientStatus: "",
    clientBuyer: localStorage.getItem("USER_ID"),
    recentlyAddedEngagement: "",
    clientEngagements: [],
    updateClient: {} //State of client being edited,
  };

  componentWillMount() {
    // const admin = this.props.user ? this.props.user.admin : "";
    // const advocateToken = localStorage.getItem("USER_TOKEN");
    // const clientToken = localStorage.getItem("CLIENT_TOKEN");

    // if (!admin && !clientToken) {
    //   browserHistory.push("client-login");
    // } else if (!admin) {
    //   browserHistory.push("/home/shortlisted-property");
    // } else if (!advocateToken) {
    //   browserHistory.push("login");
    // }
  }

  componentDidMount() {
    this.fetchAllClients();
  }

  handlePageClick = data => {
    const offset = Math.ceil(data.selected * this.state.limit);

    this.setState({ offset }, () => {
      this.fetchAllClients();
    });
  };

  showCreateEngagementForm = async (client, index) => {
    clientTableformat(client, "#datatables", index, $(`#row-${client._id}`));

    const labelForScrapper = labelCreator(getClientFullName(client));

    // Second, create new engagement with the above data
    const newEngagement = await createEngagement({
      status: "Engaged",
      client: client._id,
      purchasedOn: null,
      labelForScrapper
    });

    if (newEngagement.error) {
      return this.props.showNotification("error", {
        title: "Error!",
        message: newEngagement.error
      });
    }

    this.props.showNotification("success", {
      title: "Engagement Created!",
      message: "Engagement has been created"
    });

    // Third, update the clients numberofengagement field
    const updatedClient = await api.put("/api/client", {
      filter: client._id,
      fields: { numberOfEngagements: 1 },
      purpose: "incrementNumberOfEngagements"
    });

    if (updatedClient.error) {
      return this.props.showNotification("error", {
        title: "Error!",
        message: updatedClient.error
      });
    }

    this.setState({
      showNewEngagementForm: true,
      label: labelForScrapper,
      recentlyAddedEngagement: newEngagement.purchase
    });
  };

  // ==========================================================================
  // Input handlers
  // ==========================================================================

  toggleUpdateClientModal = (shouldOpen, client, index) => {
    const query = encodeURIComponent(JSON.stringify({ client: client._id }));
    api
      .get(`/api/clientPurchases/list?filter=${query}`)
      .then(clientEngagements => {
        if (clientEngagements.notify) {
          return alert(clientEngagements.notify);
        }else{
          const { purchases } = clientEngagements;
          client.engagement_source = purchases.length ? purchases[0].engagement_source : "";
          
          this.setState({
            isModalOpen: shouldOpen,
            updateClient: client,
            index
          });
        }
      }).catch(console.error);

   
  };

  handleClientSearch = ev => {
    const { value } = ev.target;

    const filterQuery = encodeURIComponent(
      JSON.stringify({ selectBy: "search" })
    );
    const searchQuery = encodeURIComponent(JSON.stringify(value));

    this.fetchClients(filterQuery, searchQuery);
  };


  handleInputChange = ev => {
    const { name, type, checked } = ev.target;

    const value =
      type === "checkbox" || type === "radio" ? checked : ev.target.value;

    switch (name) {
      case "active":
        this.setState({ isActive: value }, () => {
          this.fetchAllClients();
        });
        break;

      case "inactive":
        this.setState({ isActive: !value }, () => {
          this.fetchAllClients();
        });
        break;

      default:
        if (name === "limit") {
          this.setState({ limit: Number(value) }, () => {
            this.fetchAllClients();
          });
        } else {
          this.setState({ [name]: value });
        }
    }
  };

  // ==========================================================================
  // Client methods
  // ==========================================================================

  fetchAllClients = () => {
    this.fetchClients(encodeURIComponent(JSON.stringify({ show: "all" })));
  };

  fetchClients = (filterQuery, searchQuery) => {
    const { isActive, limit, offset } = this.state;

    const activeQuery = encodeURIComponent(JSON.stringify(isActive));
    const optionsQuery = encodeURIComponent(JSON.stringify({ limit, offset }));

    let url = `/api/client/filter?active=${activeQuery}&&options=${optionsQuery}&&filter=${filterQuery}`;

    if (searchQuery) {
      url += `&&searchQuery=${searchQuery}`;
    }

    this.setState({ spinner: true });

    api
      .get(url)
      .then(result => {
        if (result.notify) {
          return alert(result.notify);
        }

        this.setState({ spinner: false });
        destroyBootstrapSwitches("#datatables");

        this.setState(
          {
            _rows: _.sortBy(result.clients, "G2G_date"),
            pageCount: Math.ceil(Number(result.count) / limit)
          },
          () => {
           
            addBootstrapDataTables("#datatables", false);
          }
        );
      })
      .catch(console.error);
  };

  updateClient = (update) => {
    const { engagement_source } = update;

    api
      .put("/api/client/clientDetailsEdit", {
        filter: this.state.updateClient._id,
        fields: update
      })
      .then(({ error, clients: client }) => {
        if (error) {
          return this.props.showNotification("error", {
            title: "Error!",
            message: error
          });
        }

        // Update the label of all the client's engagements
        api
          .put("/api/clientPurchases/labels", {
            filter: client._id,
            fields: { label: labelCreator(getClientFullName(client)), engagementSource: engagement_source }
          })
          .catch(err => {
            console.error(
              "Error updating the label for all the client's engagemenets",
              err
            );
          });
        this.setState({ isModalOpen: false }, () => {
          const { _rows: rows, index } = this.state;

          rows[index].engagement_source = engagement_source || "";
          rows[index].first_name = client.first_name || "";
          rows[index].last_name = client.last_name || "";
          rows[index].middle_name = client.middle_name || "";
          rows[index].status = client.status || "";
          rows[index].type = client.type || "";
          rows[index].email = client.email || "";

          rows[index].buyerId = client.business_analyst
            ? client.business_analyst._id
            : null;

          this.setState({ _rows: rows });
        });
      });
  };

  toggleClientActiveState = (client, index) => {
    const { _rows } = this.state;

    this.setState({ spinner: true });

    api
      .put("/api/client/", {
        filter: client._id,
        fields: { is_active: !client.is_active }
      })
      .then(result => {
        if (result.notify) {
          return alert("Message", result.notify);
        }

        destroyBootstrapSwitches("#datatables");

        _rows.splice(index, 1);
        this.setState({ _rows, spinner: false }, () => {
          addBootstrapDataTables("#datatables", false);
        });
      })
      .catch(console.error);
  };

  // ==========================================================================
  // Engagement methods
  // ==========================================================================

  fetchEngagements = (client, index) => {
    const query = encodeURIComponent(JSON.stringify({ client: client._id }));

    this.setState({ spinner: true });

    api
      .get(`/api/clientPurchases/list?filter=${query}`)
      .then(clientEngagements => {
        if (clientEngagements.notify) {
          return alert(clientEngagements.notify);
        }

        this.setState({
          spinner: false,
          clientEngagements: clientEngagements.purchases
        });

        clientTableformat(
          client,
          "#datatables",
          index,
          $(`#row-${client._id}${index}`)
        );
      })
      .catch(console.error);
  };

  saveEngagegment = client => {
    api
      .put("/api/clientPurchases", {
        filter: this.state.recentlyAddedEngagement._id,
        fields: {
          status: this.state.clientStatus,
          purchasedOn: null,
          labelForScrapper: labelCreator(
            getClientFullName({
              first_name: client.firstName,
              middle_name: client.middleName,
              last_name: client.lastName
            })
          ),
          business_analyst: this.state.clientBuyer
        }
      })
      .then(response => {
        if (response.notify) {
          return alert("Message", response.notify);
        }

        this.props.showNotification("success", {
          title: "Success",
          message: "Successfully saved the details"
        });

        this.setState({ showNewEngagementForm: false });
      });
  };

  updateEngagement = (engagement, index, ev) => {
    const { name, value } = ev.target;

    const updateEngagement = { filter: engagement._id };

    this.setState(
      prevState => {
        const newEngagement = { ...prevState.clientEngagements[index] };

        if (name == "clientState") {
          updateEngagement.fields = { state: value };
          newEngagement.state = value;
        } else if (name == "status") {
          updateEngagement.fields = { status: value };
          newEngagement.status = value;
        }

        return {
          clientEngagements: [
            ...prevState.clientEngagements.slice(0, index),
            newEngagement,
            ...prevState.clientEngagements.slice(index + 1)
          ]
        };
      },
      () => {
        api
          .put("/api/clientPurchases", updateEngagement)
          .then(res => {
            if (res.notify) {
              alert("Message", res.notify);
            }
          })
          .catch(console.error);
      }
    );
  };

  removeEngagement = engagement => {
    const query = encodeURIComponent(
      JSON.stringify({ engagement: engagement._id })
    );

    api
      .get(`/api/property?filter=${query}`)
      .then(result => {
        const numberOfPropeties = result.properties.length;

        if (numberOfPropeties) {
          return this.props.showNotification("error", {
            title: `Cannot delete engagment: the engagment is attached to (${numberOfPropeties}) propert${
              numberOfPropeties === 1 ? "y" : "ies"
            }`,
            message:
              "The engagement cannot be deleted while it is still attached to a property. Please detach the engagment from all properties before attempting to delete the engagment.",
            autoDismiss: 12
          });
        }

        const deleteQuery = encodeURIComponent(
          JSON.stringify({ _id: engagement._id })
        );

        return Promise.all([
          api.delete(`/api/clientPurchases/remove?filter=${deleteQuery}`),
          api.put("/api/client", {
            filter: engagement.client._id,
            fields: { numberOfEngagements: -1 },
            purpose: "decrementNumberOfEngagements"
          })
        ]).then(() => {
          this.setState(prevState => ({
            // Filter out selected engagement (i.e. remove it)
            clientEngagements: prevState.clientEngagements.filter(
              x => engagement._id !== x._id
            ),
            showNewEngagementForm: true
          }));

          return this.props.showNotification("success", {
            title: `Engagment successfully removed`
          });
        });
      })
      .catch(console.error);
  };

  removeRecentlyAddedEngagement = () => {
    const query = encodeURIComponent(
      JSON.stringify({ _id: this.state.recentlyAddedEngagement._id })
    );

    api
      .delete(`/api/clientPurchases/remove?filter=${query}`)
      .then(response => {
        this.props.showNotification("info", {
          title: "Removed successfully",
          message: "Client Engagement has been removed successfully"
        });

        return api
          .put("/api/client", {
            filter: response.removed.client,
            fields: { numberOfEngagements: -1 },
            purpose: "decrementNumberOfEngagements"
          })
          .then(() => {
            this.setState({ showNewEngagementForm: false });
          });
      })
      .catch(console.error);
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  render() {
    return (
      <div className="col-md-12">
        <div className="card">
          <div className="header container-fluid">
            <div className="col-md-6">
              <h4
                className="title"
                style={{ fontFamily: '"Poppins", sans-serif' }}
              >
                Manage Clients
              </h4>
            </div>
          </div>

          <div className="content p-t-0">
            {/* Active / inactive buttons */}
            <div className="toolbar">
              <div className="col-xs-12">
                <label className="pull-right f-20 m-l-10">
                  <input
                    type="radio"
                    name="inactive"
                    onChange={this.handleInputChange}
                    checked={!this.state.isActive}
                  />{" "}
                  Inactive
                </label>
                <label className="pull-right f-20">
                  <input
                    type="radio"
                    name="active"
                    onChange={this.handleInputChange}
                    checked={this.state.isActive}
                  />{" "}
                  Active
                </label>
              </div>
            </div>

            <div className="fresh-datatables">
              <div className="row">
                {/* Number of visible items */}
                <div className="col-sm-6">
                  <div className="dataTables_length" id="datatables_length">
                    <label>
                      Show
                      <select
                        name="limit"
                        aria-controls="datatables"
                        className="form-control input-sm"
                        onChange={this.handleInputChange}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </label>
                  </div>
                </div>

                {/* Searchbar */}
                <div className="col-sm-6" id="searchBar">
                  <input
                    type="text"
                    className="form-control search-input w-378-imp"
                    placeholder="Search Clients"
                    onChange={this.handleClientSearch}
                  />
                </div>
              </div>

              {this.state.spinner && (
                <div className="spinner-pos">
                  <Spinner />
                </div>
              )}

              {/* Clients / engagements table */}
              <table
                id="datatables"
                className="clientListTable table table-striped table-no-bordered table-hover"
                cellSpacing="0"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th className="text-size">Create</th>
                    <th className="text-size">Edit</th>
                    <th className="text-size">Name</th>
                    <th className="text-size">Number of Engagement</th>
                    <th className="text-size">Active</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state._rows.map((client, i) => (
                    <tr key={i}>
                      {/* Create engagement button */}
                      <td className="text-size" id={`hidden-col-${client._id}`}>
                        <div style={{ display: "none" }} className="hidden-td">
                          <CreateEngagement
                            ba={this.state.clientBuyer}
                            bas={this.props.buyers}
                            client={client}
                            engagementAlreadyExists={
                              this.state.labelAlreadyExist
                            }
                            labelForScraper={labelCreator(
                              getClientFullName(client)
                            )}
                            onBAChange={ev => {
                              this.setState({ clientBuyer: ev.target.value });
                            }}
                            onRemoveClick={this.removeRecentlyAddedEngagement}
                            onStatusChange={ev => {
                              this.setState({ clientStatus: ev.target.value });
                            }}
                            onSubmit={ev => {
                              this.saveEngagegment(client);
                            }}
                            show={this.state.showNewEngagementForm}
                            status={this.state.clientStatus}
                          />
                        </div>

                        <div rel="tooltip" title="Create Engagement">
                          <button
                            className="sweet-cancel btn btn-default fl-l createEngagementBtn"
                            onClick={() => {
                              this.showCreateEngagementForm(client, i);
                            }}
                          >
                            Create engagement
                          </button>
                        </div>
                      </td>

                      {/* Edit client button */}
                      <td className="text-size">
                        <i
                          className="ti-pencil-alt"
                          onClick={() =>
                            this.toggleUpdateClientModal(true, client, i)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </td>

                      {/* Client name */}
                      <td className="text-size">{getClientFullName(client)}</td>

                      {/* Number of engagements */}
                      <td className="text-size">
                        <div style={{ display: "none" }} className="hidden-td">
                          <ClientListEngagements
                            client={client}
                            engagements={
                              this.state.clientEngagements.length
                                ? this.state.clientEngagements
                                : []
                            }
                            clientIndex={i}
                            onEngagementDetailsChange={this.updateEngagement}
                            onRemoveEngagementClick={this.removeEngagement}
                          />
                        </div>

                        <a
                          rel="tooltip"
                          className="cursor-position"
                          title="Client Engagements"
                          onClick={() => {
                            this.fetchEngagements(client, i);
                          }}
                        >
                          {client.numberOfEngagements}
                        </a>
                      </td>

                      {/* Active switch */}
                      <td className="text-center text-size">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={client.is_active}
                            onChange={() => {
                              this.toggleClientActiveState(client, i);
                            }}
                          />
                          <div className="slider round" />
                        </label>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="row">
                <ReactPaginate
                  previousLabel="previous"
                  nextLabel="next"
                  breakLabel={<a href="">...</a>}
                  breakClassName="break-me"
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  initialPage={
                    this.state.offset ? this.state.offset / this.state.limit : 0
                  }
                  forcePage={
                    this.state.offset ? this.state.offset / this.state.limit : 0
                  }
                  disableInitialCallback={true}
                  onPageChange={this.handlePageClick}
                  containerClassName="pagination pagination-position"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          {this.state.isModalOpen && (
            this.editClient()
          )}
        </div>
      </div>
    );
  }

  editClient(){
    const { _rows: row, index, isModalOpen } = this.state;
    const client = row[index];
    return( 
      <EditClient
        engagementSource={client.engagement_source}
        firstName={client.first_name}
        isOpen={isModalOpen}
        lastName={client.last_name}
        middleName={client.middle_name}
        onCloseClick={() => {
          this.toggleUpdateClientModal(false, client, index);
        }}
        email={client.email}
        onUpdateClick={this.updateClient}
        type={client.type}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.user || {},
  buyers: state.buyers || []
});

export default connect(mapStateToProps)(withNotifications(ClientList));
