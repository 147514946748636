import React from "react";
import formatNumber from "format-number";

// Modules
import { formatDate, formatDaysDuration } from "./utils";
import {
  statusMap,
  sortByStatus
} from "../../../common/client-status-settings";

export const attachedProperties = {
  path: "attachedProperties",
  heading: <i className="fa fa-home" />,
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: value => <div style={{ textAlign: "right" }}>{value}</div>
};

export const statusCol = {
  path: "status",
  heading: "Status",
  rowRender: (value, { engagementSource, service }) => (
    <div
      style={{
        fontWeight: "bold",
        color: statusMap.get(value).colour
      }}
    >
      {statusMap.get(value).displayName}{" "}
      {engagementSource === "ownNetwork" && "*"}{" "}
      {service === "Negotiate" && <span>&loz;</span>}
    </div>
  ),
  sort: ({ status }) => sortByStatus(status.value)
};

export const stateCol = {
  path: "state",
  heading: "State"
};

export const budgetCol = {
  path: "budget",
  heading: "Budget",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: (value, { budgetRange }) => {
    if (typeof value === "number") {
      return (
        <div style={{ textAlign: "right" }}>
          {formatNumber({ prefix: "$" })(value)}
        </div>
      );
    } else if (typeof budgetRange === "string") {
      return <div style={{ textAlign: "right" }}>{budgetRange}</div>;
    }
    return null;
  }
};

export const growthCol = {
  path: "growth",
  heading: "Growth",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: value => {
    return (
      <div style={{ textAlign: "right" }}>
        {value === "N/A"
          ? value
          : formatNumber({ round: 2, padRight: 2, suffix: "%" })(value)}
      </div>
    );
  }
  // TODO: "N/A"s are sorted to the bottom but jump to the top on reverse sort.
  // Ideally, a custom sort function should be used the keeps "N/A"s at the bottom
  // sort: ...
};

export const yieldCol = {
  path: "yieldPercent",
  heading: "Yield",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: value => {
    return (
      <div style={{ textAlign: "right" }}>
        {value === "N/A"
          ? value
          : formatNumber({ round: 2, padRight: 2, suffix: "%" })(value)}
      </div>
    );
  }
  // TODO: "N/A"s are sorted to the bottom but jump to the top on reverse sort.
  // Ideally, a custom sort function should be used the keeps "N/A"s at the bottom
  // sort: ...
};

export const goodToGoDateCol = {
  path: "G2G_date",
  heading: "G2G Date",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: (value, props) => {
    if (props && props.status === "Incubation") {
      return <b>{formatDate(value)}</b>;
    } else {
      return formatDate(value);
    }
  }
};

export const engageToG2GCol = {
  path: "engageToG2G",
  heading: "Days to G2G",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: formatDaysDuration
};

export const engageToIncCol = {
  path: "engageToInc",
  heading: "Days in Inc",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: formatDaysDuration
};

export const engageDateCol = {
  path: "engage_date",
  heading: "Engage Date",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: formatDate
};

// Old columns

// export const serviceCol = {
//   path: "service",
//   heading: "Service"
// };
