import React from "react";
import { Pie } from "react-chartjs-2";
import { browserHistory } from "react-router";
import Spinner from "../../common/spinner";
import qs from "qs";
import _ from "lodash";
// Modified spinner style
import "./ChartSpinner.css";

const PropertiesPieChart = ({ data, colourMap, phase, isLoading }) => {
  if (!data.length && isLoading) {
    return <Spinner />;
  }

  const colours = data.map(x => colourMap.get(x.ba._id));

  const pieData = {
    labels: data.map(x => x.ba.nickname),
    datasets: [
      {
        data: data.map(x => x.count),
        backgroundColor: colours,
        hoverBackgroundColor: colours
      }
    ]
  };

  const handleClickPieChart = ({ _index }) => {
    const ba = data[_index].ba._id;

    const query = {
      phases: [phase],
      bas: [ba],
      showArchived: true
    };

    browserHistory.push({
      pathname: "/home/list",
      search: `?${qs.stringify(query)}`
    });
  };

  const title = `${phase} Properties`;

  if (_.isEmpty(data)) {
    return (
      <div style={{ textAlign: "center" }}>
        <div
          // Emulate the styles used in Chart.js titles
          // https://www.chartjs.org/docs/latest/configuration/title.html#title-configuration
          style={{
            fontSize: 12,
            fontWeight: "bold",
            fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            color: "#666",
            padding: 10,
            lineHeight: 1.2
          }}
        >
          {title}
        </div>
        No properties are in this phase.
      </div>
    );
  } else {
    return (
      // MaintainAspectRatio, width & height were determined through trial and error
      <Pie
        data={pieData}
        onElementsClick={([first, ...rest]) => handleClickPieChart(first)}
        options={{
          maintainAspectRatio: false,
          title: {
            display: true,
            text: title
          },
          legend: { display: false }
        }}
        height={220}
        width={220}
      />
    );
  }
};

export default PropertiesPieChart;
