'use strict';
import React from 'react';
import { Link } from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import Modal from "../Modal";
import "./SideMenu.css";

export default function(){
    return SideMenuComponentRender.call(this);
}

var SideMenuComponentRender = function(){
    const { user = {} } = this.props;
	const { admin, info } = user;

	let userId;
	let userRole;

	if (admin && info) {
		userId = info.userId || info._id || "";
		userRole = info.user_role || "";
    }

    const selectedBAIds = [userId, ..._.get(user, 'info.senior_associates', [])];

    const propertiesMasterDefaultQuery = qs.stringify({
        date: {
            min: moment()
                .subtract(6, "months")
                .startOf("day")
                .toISOString()
        }
    });
    const propertiesMyListQuery = qs.stringify({ bas: [...selectedBAIds] });
	
    return (
    	 <div className="sidebar sidebar-color" data-background-color="brown" data-active-color="danger">
			 {this.state.refresh && (
				<Modal>
				<div
					style={{
					position: "fixed",
					left: "50%",
					top: "20%",
					backgroundColor: "#3289c8",
					zIndex: 3,
					borderRadius: 10,
					color: "#fff",
					padding: "0px 5px",
					cursor: "pointer"
					}}
					onClick={this.refreshPropertyList}
				>
					New Property
				</div>
				</Modal>
			)}
			<div className="logo">
				<a className="simple-text" style={{ fontFamily: '"Poppins", sans-serif' }}>
					Buyers Agent
				</a>
			</div>
			<div className="logo logo-mini">
				<a className="simple-text">
					BA
				</a>
			</div>
	    	<div className="sidebar-wrapper">
				<div className="user">
	                <div className="photo">
	                    <img src={info && info.image || "/img/default_profile_pic.png"} />
	                </div>
	                <div className="info">
	                    <a data-toggle="collapse" href="#collapseExample" className="collapsed">
	                        {info && info.nickname || "Bryce Holdaway"}
	                        <b className="caret"></b>
	                    </a>
	                    <div className="collapse" id="collapseExample">
	                        <ul className="nav">
	                            <li><Link to="/home/profile">Edit Profile</Link></li>
	                        </ul>
	                    </div>
	                </div>
	            </div>
	            
				<nav className="side-menu">
					{/* Dashboard */}
					<a data-toggle="collapse" href="#submenu-dashboard">
						<div className="side-menu-submenu">
							<span className="side-menu-submenu__title-wrapper">
								<i className="ti-user side-menu-submenu__icon" />
								Dashboard
							</span>
							<strong className="caret" />
						</div>
					</a>
					<div className="collapse in" id="submenu-dashboard">
						<Link to="/home/dashboard" className="side-menu-submenu__item">Master Dash</Link>
						<Link to="/home/dashboard/my-list" className="side-menu-submenu__item">My Dash</Link>
					</div>

					{/* Properties */}
					<a data-toggle="collapse" href="#submenu-properties">
						<div className="side-menu-submenu">
							<span className="side-menu-submenu__title-wrapper">
								<i className="ti-world side-menu-submenu__icon" />
								Properties
							</span>
							<strong className="caret" />
						</div>
					</a>
					<div className="collapse in" id="submenu-properties">
						<Link to={`/home/list?${propertiesMasterDefaultQuery}`} className="side-menu-submenu__item">Master Properties</Link>
						<Link to={`/home/list?${propertiesMyListQuery}`} className="side-menu-submenu__item">My Properties</Link>
						<Link to="/home/list?queue=true" className="side-menu-submenu__item">
							Queued
						</Link>
						<Link to="/home/list?assigned=true" className="side-menu-submenu__item">
							Assigned
						</Link>
					</div>

					{/* Trello */}
					<a data-toggle="collapse" href="#submenu-trello">
						<div className="side-menu-submenu">
							<span className="side-menu-submenu__title-wrapper">
								<i className="ti-trello side-menu-submenu__icon" />
								Trello
							</span>
							<strong className="caret" />
						</div>
					</a>
					<div className="collapse" id="submenu-trello">
						<a href="https://trello.com/b/TpAo0KI7" target="blank" className="side-menu-submenu__item">
							Monthly Scorecard
						</a>
					</div>

					{/* Management */}
					<a data-toggle="collapse" href="#submenu-management">
						<div className="side-menu-submenu">
							<span className="side-menu-submenu__title-wrapper">
								<i className="ti-map side-menu-submenu__icon" />
								Management
							</span>
							<strong className="caret" />
						</div>
					</a>
					<div className="collapse" id="submenu-management">
						<Link to="/home/add-client" className="side-menu-submenu__item">Add Client</Link>
						<Link to="/home/client-list" className="side-menu-submenu__item">Client List</Link>
						<Link to="/home/map-view" className="side-menu-submenu__item">Map</Link>
						<Link to="/home/audit-trail" className="side-menu-submenu__item">Tracking</Link>
						<Link to="/home/dashboard/metrics" className="side-menu-submenu__item">Metrics</Link>
						<Link to="/home/reporting" className="side-menu-submenu__item">Reporting</Link>
					</div>

					{/* Admin */}
					{
						(userRole === "super-admin" || userRole === "manager") && (
							<div>
								<a data-toggle="collapse" href="#submenu-admin">
									<div className="side-menu-submenu">
										<span className="side-menu-submenu__title-wrapper">
											<i className="ti-user side-menu-submenu__icon" />
											Admin
										</span>
										<strong className="caret" />
									</div>
								</a>
								<div className="collapse" id="submenu-admin">
									<Link to="/home/manage-days" className="side-menu-submenu__item">Manage Business Days</Link>
									<Link to="/home/engagement-history" className="side-menu-submenu__item">Engagement History</Link>
									<Link to="/home/upload-csv" className="side-menu-submenu__item">Upload CSV</Link>
								</div>
							</div>
						)
					}
				</nav>

			</div>
	    	<div className="sidebar-background sidemenu-css"></div>
	    </div>
    );
};
