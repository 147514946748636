import React from "react";

// Components
import PropertyPhaseManager from "./PropertyPhaseManager";
import PropertyTableRowDetails from "./PropertyTableRowDetails";

// This is *not* a React component
function editPropertyBody(propertyListBase, propertyDetail, rowIndex) {
  const { state } = propertyListBase;
  const property = propertyDetail;
  return (
    <div className="edit-property-container">
      <div className="row" id={`row-${propertyDetail.id}`}>
        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
          {state.editPhase ? (
            <PropertyPhaseManager
              engagementsToSelect={state.engagementsToSelect}
              grid={state.grid}
              handlePhaseChange={propertyListBase.handlePhaseChange}
              onCellsChanged={changes => {
                const grid = state.grid.map(row => [...row]);

                changes.forEach(({ cell, row, col, value }) => {
                  grid[row][col] = { ...grid[row][col], value };
                });

                propertyListBase.setState({ grid });
              }}
              onEngagementSelect={ev => propertyListBase.handleAddingRow(ev)}
              propertyDetail={propertyDetail}
              rowIndex={rowIndex}
            />
          ) : property ? (
            <PropertyTableRowDetails
              address={property.address}
              auctionDate={property.auctionDate}
              autoValue={property.autoValue}
              baths={property.baths}
              beds={property.beds}
              buyPrice={property.buyPrice}
              changeRating={propertyListBase.changeRating}
              comments={property.comments}
              comparable_sales={property.comparable_sales}
              data={property.data}
              dsr={property.dsr}
              editNotes={propertyListBase.editNotes}
              floodCheck={property.floodCheck}
              fsd={property.fsd}
              googleDocLink={property.googleDocLink}
              growth={property.growth}
              handleInputChange={propertyListBase.handleInputChange}
              handleDatePickerChange={propertyListBase.handleDatePickerChange}
              landSize={property.landSize}
              locked_ba={property.locked_ba}
              locked={state.locked}
              lsd={property.lsd}
              lsp={property.lsp}
              notes={property.notes}
              parking={property.parking}
              price={property.price}
              propertyDetail={propertyDetail}
              propertyDog={property.propertyDog}
              propertyType={property.propertyType}
              rating={property.rating}
              rent={property.rent}
              rowIndex={rowIndex}
              showRow={propertyListBase.showRow}
              soldPrice={property.soldPrice}
              under_contract_exp_date={property.under_contract_exp_date}
              unlockTheProperty={propertyListBase.unlockTheProperty}
              updateBuyPriceAndPropertyDog={
                propertyListBase.updateBuyPriceAndPropertyDog
              }
              updateProperty={propertyListBase.updateProperty}
              updatePropertyDate = {propertyListBase.updatePropertyDate}
              vr={property.vr}
              walkScore={property.walkScore}
              yearBuild={property.yearBuild}
              yield={property.yield}
            />
          ) : ""}
        </div>
      </div>
    </div>
  );
}

export default editPropertyBody;
