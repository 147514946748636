//Action Creator
export const LOGIN = 'LOGIN'
export const FILTER = 'FILTER'
export const PRICEDETAIL = 'PRICEDETAIL'
export const SOCKETCONNECTION = 'SOCKETCONNECTION'
export const PAGINATION = 'PAGINATION'
export const BUYERS = 'BUYERS'
export const PURCHASES = 'PURCHASES'
export const USERS = 'USERS'
export const BUSINESSDAYS = 'BUSINESSDAYS'

export function login(result) {
   return { type: LOGIN, result };
}
export function businessDays(result) {
   return { type: BUSINESSDAYS, result };
}

export function filter(result) {
   return { type: FILTER, result };
}

export function propertyPriceDetail(result) {
   return { type: PRICEDETAIL, result };
}

export function socketConnection(result) {
   return { type: SOCKETCONNECTION, result };
}

export function pagination(result) {
   return { type: PAGINATION, result };
}

export function buyers(result) {
   return { type: BUYERS, result };
}

export function users(result) {
   return { type: USERS, result };
}

export function purchases(result) {
   return { type: PURCHASES, result };
}
