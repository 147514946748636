'use strict';
import React, { Component } from 'react';
import api from '../../common/api';
import { browserHistory } from 'react-router';
import * as redux from '../../redux/redux';
import moment from 'moment';

class ManageBusinessDaysBase extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
        	presentedDays : '',
        	clientPresented : '',
            beforeArchive : '',
            watermarkIncubationThreshold : '',
            startDateWaterMark : '',
        	waitingDays : '',
            watchlistDays : '',
            hotlistDays : '',
            userSelect: '', // Select the user for changing the role
            role: '',
            waterMarkMonth: '',
            waterMarkYear: '',
            registration : "off",
            waterMarkData: [],
            archives: [],
            cardMonthOpen: '',
            buyers : '',
            isOpenWaterMarkModal:false
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.editBusinessDays = this.editBusinessDays.bind(this)
        this.editUserRole = this.editUserRole.bind(this);
        this.deleteUserRole = this.deleteUserRole.bind(this);
        this.closeExpandableCards = this.closeExpandableCards.bind(this);
        // Render table rows from water mark details
        this.renderWaterMarkRows = this.renderWaterMarkRows.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
    }

    async componentWillMount() {
        const user = await api.get('/api/users/getUserData');
        const user_role = (user.user_role == "super-admin"  || user.user_role == "manager") ? true : false;
        const admin = this.props.user ? this.props.user.admin : '';
        const advocateToken = localStorage.getItem('USER_TOKEN');
        const clientToken = localStorage.getItem('CLIENT_TOKEN');
        if(!user_role && !clientToken) {
            browserHistory.push('/home');
        } else if(!user_role) {
            browserHistory.push('/home/shortlisted-property');
        } else if (!advocateToken) {
            browserHistory.push('login');
        }
    }

    componentDidMount() {
    	api.get('/api/business/').then(result => {
            if (result.notify) {
                alert(result.notify);
            } else {
                const businessDays = result.days[0];
                let archives = [];
                this.setState({
         			presentedDays : businessDays.presented_days,
		        	clientPresented : businessDays.client_presented,
                    beforeArchive : businessDays.before_archive,
                    watermarkIncubationThreshold : businessDays.watermark_incubation_threshold,
                    startDateWaterMark : moment(businessDays.start_date_water_mark).format('YYYY-MM-DD'),
		        	waitingDays : businessDays.waiting_days,
                    watchlistDays : businessDays.watchlist_days,
                    hotlistDays : businessDays.hotlist_days,
                    registration :  businessDays.registration ? "on" : "off",
                    buyers : this.props.buyers
                })
            }
        }).catch(error => {
            console.log("error is ", error);
        })
    }

    // Method to geneare table rows from water mark data
    renderWaterMarkRows(month, year) {
        if (this.state.buyers && this.state.buyers.length) {
            return this.state.buyers.map((buyer, index) => {
                let waterMark = {
                    water_mark_days : 0,
                    g2g_help_me_days : 0,
                    four_month_stop: 0
                };
                if(buyer.water_mark.length > 0) {
                    let hasWaterMark = buyer.water_mark.find(wm => {
                        return wm.monthyear === [month, year].join(' ')
                    });
                    if(hasWaterMark) {
                        waterMark = hasWaterMark
                    } 
                }
                return (
                    <tr key={index}>
                        <td>{buyer.nickname}</td>
                        <td><input type="text" value={waterMark.water_mark_days} onChange={this.handleInputChange} name="water_mark_days" onBlur={this.handleInputBlur} data-monthyear={[month, year].join(' ')} data-index={index} data-ba={buyer._id} data-ba-index={index} style={{ border: "none", 'textAlign': 'center' }}/></td>
                        <td><input type="text" value={waterMark.g2g_help_me_days} onChange={this.handleInputChange} name="g2g_help_me_days" onBlur={this.handleInputBlur} data-monthyear={[month, year].join(' ')} data-index={index} data-ba={buyer._id} style={{border:"none", 'textAlign': 'center'}}/></td>
                        <td><input type="text" value={waterMark.four_month_stop} onChange={this.handleInputChange} name="four_month_stop" onBlur={this.handleInputBlur} data-monthyear={[month, year].join(' ')} data-index={index} data-ba={buyer._id} style={{border:"none", 'textAlign': 'center'}}/></td>
                        <td><input type="checkbox" name="ba_archive" checked={buyer.isArchive} onBlur={this.handleInputChange} data-index={index} data-ba={buyer._id} style={{border:"none", 'textAlign': 'center'}}/></td>
                        {/* I think we should remove this since there is no more add button or perhaps replace this with clear to make all inputs value to 0 
                        <td><span onClick={() => this.deleteUserRole(index)}><i className="ti-trash" style={{cursor: 'pointer', color: '#ff0000'}}></i></span></td> */}
                    </tr>
                )
            })
        } else {
            return (
                    <tr>
                        <td>Water Mark table data is loading...</td>
                    </tr>
                )
        }
    }

    deleteUserRole(index) {
        const waterMarkData = this.state.waterMarkData.map(elem => ({...elem}));
        waterMarkData.splice(index, 1)
        this.setState({
            waterMarkData: waterMarkData
        }, () => this.editBusinessDays())
    }

    closeExpandableCards(month, year) {
        if(this.state.cardMonthOpen == month) {
            this.setState({
                cardMonthOpen: '',
                userSelect: '',
                role: '',
                waterMarkMonth: '',
                waterMarkYear: ''
            })
        } else {
            this.setState({
                cardMonthOpen: month,
                userSelect: '',
                role: '',
                waterMarkMonth: month,
                waterMarkYear: year
            })
        }
    }
    handleInputBlur(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        
        if (name == "water_mark_days" || name == "g2g_help_me_days" || name === "four_month_stop") {
            const index = target.getAttribute('data-index');
            const ba = target.getAttribute('data-ba');
            const monthyear = target.getAttribute('data-monthyear');
            
            api.put('/api/users/saveWatermark', { bas : ba, prop: name, val : value, monthyear })
            .then( () => {
                this.props.showNotification("success", {
                    title: "Success!",
                    message: "BA's "+name+" saved."
                });
            })
            
        }
    }
    handleInputChange(event) {
    	const target = event.target;
        const value = target.value;
        const name = target.name;
        if(name == "userSelect") {
            const foundBuyer = this.props.buyers.find(buyer => buyer._id == value);
            this.setState({
                [name]: value,
                role: foundBuyer ? foundBuyer.user_role : ""
            }, () => {
            })
        } else if (name == "water_mark_days" || name == "g2g_help_me_days" || name === "four_month_stop") {
            
            const index = target.getAttribute('data-index');
            const ba = target.getAttribute('data-ba');
            const monthyear = target.getAttribute('data-monthyear');
            const baIndex = target.getAttribute('data-ba-index');
            
            let buyers = [ ...this.state.buyers ].map((buyer, index)=> {
                if(buyer._id === ba) {
                    if(buyer.water_mark.length > 0) {
                        let buyerWaterMark = buyer.water_mark.find( buye => buye.monthyear === monthyear )
                        if(buyerWaterMark) {
                            buyerWaterMark[name] = value;
                        }
                        else {
                            buyer.water_mark.push({ [name] : value, monthyear })
                        }
                    }
                    else {
                        buyer.water_mark.push({ [name] : value, monthyear })
                    }
                }
                return buyer;
            })
            this.setState({buyers})
        } else if(name == "ba_archive"){
            const { waterMarkData } = this.state;

            const ba = target.getAttribute('data-ba');
            waterMarkData.map(row => {
                if(row.ba){
                    if(row.ba._id === ba){
                        let isArchive = !row.ba.isArchive;

                        if(isArchive){
                            api.put('/api/users/setUserArchived', { bas : ba })
                            .then(() => {
                                this.props.showNotification("success", {
                                    title: "Success!",
                                    message: "BA's been archived."
                                });
                            })
                        }else{
                            api.put('/api/users/setUserUnArchived', { bas : ba })
                            .then(() => {
                                this.props.showNotification("success", {
                                    title: "Success!",
                                    message: "BA's been unarchived."
                                });  
                            })
                        }
                        return row.ba.isArchive=isArchive;
                    }      
                }
            });

            this.setState(prevState => {
                const { archives } = prevState;
                    return { archives : [...archives, ba], waterMarkData }
            });
        } else {
            this.setState({
                [name]: value
            })
        }
    }

    editUserRole() {
        if(this.state.role !== '' && this.state.userSelect !== '') {
        api.put('/api/auth/update', {
            fields: {
                user_role: this.state.role
            },
            filter: this.state.userSelect
        })
            .then(res => {
                
                this.props.showNotification("success", {
                    title: "Success!",
                    message: "Updated the role."
                });

                this.refreshBALists(res);
                
            })
            .catch(console.error)

        } else {
            this.props.showNotification("error", {
                title: "Error!",
                message: "Please select User and Role."
            });
        }
    }
    // Refreshes BA lists 
    refreshBALists = () => {
        api.get('/api/auth/list').then(result => {
            if (result.notify) {
                alert(result.notify);
            } else {
                this.setState({
                    buyers: result.users.filter(ba => ba.user_role==="BA")
                },()=>{
                    redux.setBuyers(this.state.buyers)
                })
            }
        })
    }
    
    editBusinessDays() {
    	const newParams = {};
        let registration = this.state.registration=="on" ? true : false;
        if(this.state.presentedDays && this.state.clientPresented && this.state.beforeArchive && this.state.watermarkIncubationThreshold && this.state.startDateWaterMark && this.state.waitingDays && this.state.watchlistDays && this.state.hotlistDays) {
            newParams.fields = {
                presented_days          : parseInt(this.state.presentedDays), 
                client_presented        : parseInt(this.state.clientPresented),
                before_archive          : parseInt(this.state.beforeArchive),
                watermark_incubation_threshold: parseInt(this.state.watermarkIncubationThreshold),
                start_date_water_mark   : this.state.startDateWaterMark,
                waiting_days            : parseInt(this.state.waitingDays),
                watchlist_days          : parseInt(this.state.watchlistDays),
                hotlist_days            : parseInt(this.state.hotlistDays),
                registration            : registration,
                water_mark              : this.state.waterMarkData ? this.state.waterMarkData : null
            }

            api.put('/api/business/', newParams).then(result => {
                if (result.notify) {
                    alert(result.notify);
                } else {
                    redux.updateBusinessDaysAsync()
                    this.updateBusinessDays(result);
                }
            })

        } else {
            this.props.showNotification("error", {
                title: "Error!",
                message: "All fields are required."
            });
    	}
    }

    updateBusinessDays(result) {
        const businessDays = result.days;
        const waterMarkData = businessDays.water_mark && businessDays.water_mark.length ? businessDays.water_mark.filter(waterMarkRow => waterMarkRow.ba.user_role === 'BA') : []
        const waterMarkDetails = _.sortBy(waterMarkData, (elem) => elem.ba.nickname)

        this.setState({
            presentedDays : businessDays.presented_days,
            clientPresented : businessDays.client_presented,
            beforeArchive : businessDays.before_archive,
            watermarkIncubationThreshold : businessDays.watermark_incubation_threshold,
            waitingDays : businessDays.waiting_days,
            watchlistDays : businessDays.watchlist_days,
            hotlistDays : businessDays.hotlist_days ,    
            waterMarkDetails
        })

        this.props.showNotification("success", {
            title: "Success!",
            message: "Days has been updated."
        });
    }
    toggleUpdateWaterMarkModal(shouldOpen){
        this.setState({
            isOpenWaterMarkModal:shouldOpen
        }) 
    }
}

export default ManageBusinessDaysBase;
