import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";

// Modules
import api from "../../common/api";
import { sortByStatus } from "../../common/client-status-settings";
import { durationFromTodayToDate } from "../../common/date-helpers";

// Components
import BATable from "../../components/tables/BATable";

// Exception number to exclude incubation engagements from watermark count.
// Incubation engagements number of days since "set to buy" date must be below
// this to be counted towards the watermark

const DEFAULT_FILTERS = {
  GoodToGo: true,
  Incubation: true,
  Engaged: true,
  Hold: false,
  Conditional: false,
  AwaitingTenant: false,
  YetToSettle: false,
  Blueprint: false
};

class BaTablesContainer extends Component {
  render() {
    const INCUBATION_EXCEPTION_DAYS = this.props.businessDays.watermark_incubation_threshold || 90;
    const tables = _(this.props.users)
      // Remove archived BAs
      .filter(x => (x.user_role === "BA" ? !x.isArchive : x))
      .sortBy("nickname")
      .map(user => {
        const engagements = _.find(
          this.props.engagements,
          (x, id) => id === user._id
        );
        const watermark = user.water_mark.find(waterMark => waterMark.monthyear === moment().format('MMMM Y'));

        // Display any user with engagements
        if (engagements) {
          const sorted = _.sortBy(engagements, x =>
            sortByStatus(x.status.value)
          );

          return (
            <div key={user._id} className="col-xs-12">
              <div className="content-panel">
                <BATable
                  rows={sorted}
                  name={user.nickname}
                  waterMark={getWaterMarkClassName(sorted, watermark, INCUBATION_EXCEPTION_DAYS)}
                  defaultFilters={DEFAULT_FILTERS}
                />
              </div>
            </div>
          );
        } else if (user.user_role === "BA") {
          // Display any BAs without engagements as empty
          return (
            <div key={user._id} className="col-xs-12">
              <div className="content-panel">
                <BATable
                  rows={[]}
                  name={user.nickname}
                  waterMark={getWaterMarkClassName([], watermark, INCUBATION_EXCEPTION_DAYS)}
                  defaultFilters={DEFAULT_FILTERS}
                />
              </div>
            </div>
          );
        }
      })
      .value();

    return <div>{tables}</div>;
  }
}

function shouldCountIncEngagement(engagement, INCUBATION_EXCEPTION_DAYS) {
  const duration = durationFromTodayToDate(engagement.set_to_buy_date);
  const days = moment.isDuration(duration)
    ? moment.duration(duration).asDays()
    : 0;
  return Math.ceil(days) < INCUBATION_EXCEPTION_DAYS;
}
function getWaterMarkClassName(engagements, watermark, INCUBATION_EXCEPTION_DAYS) {
  // Could not find watermark data relating to the user.
  // This will happen if the user is an associate
  if (!watermark) return;

  const numOfValidEngagements = engagements.reduce((acc, curr) => {
    const { props, value: status } = curr.status;

    if (
      // Don't count own network
      props.engagementSource === "ownNetwork" ||
      // Don't count non-full service engagements
      props.service !== "Full" ||
      // Don't count if clinet excluded from watermark
      props.excludedFromWatermark
    ) {
      return acc;
    } else if (status === "GoodToGo" || status === "Engaged") {
      return acc + 1;
    } else if (status === "Incubation" && shouldCountIncEngagement(curr, INCUBATION_EXCEPTION_DAYS)) {
      return acc + 1;
    } else {
      return acc;
    }
  }, 0);

  const numOfG2GEngagements = engagements.filter(
    x => x.status.value === "GoodToGo"
  ).length;

  // Determine what CSS class should apply

  let ret = "";
  if (numOfValidEngagements < watermark.water_mark_days) {
    ret = "water-mark-red";
  }
  if(numOfValidEngagements >= watermark.water_mark_days) {
    ret = "water-mark-green";
  }
  if(numOfG2GEngagements <= watermark.g2g_help_me_days) {
    ret = "water-mark-red";
  }
  if(numOfValidEngagements >= watermark.four_month_stop) {
    ret = "water-mark-green";
  }
  return ret;
  
}

export default BaTablesContainer;
