import React, { Component } from "react";
import moment from "moment";
import { browserHistory } from 'react-router';
// Modules
import api from "../../common/api";
import { fetchCagrPeriods } from "./utils";

// Components
import { FormDatepicker, FormInput } from "../FormComponents";
import Spinner from "../../common/spinner";
import withNotifications from "../withNotifications";
import DeleteConfirm from "../deleteConfirmModal/DeleteConfirm";

class ManagePriceHistory extends Component {
  state = {
    priceHistory: this.props.priceHistory.map(history => ({
      ...history,
      date: moment(history.date),
    })),
    addressLabel: "Price History manually entered",
    spinner: false,
    isOverrideModalOpen: false,
    mode:""
  };

  handleSaveAndUpdate = () => {
    this.updatePriceHistoy((priceHistory, cagrPeriods, addressLabel) => ({
      filter: this.props.propertyId,
      fields: {
        addressLabel,
        priceHistory,
        cagr: cagrPeriods,
        LSD: _.last(priceHistory).date
      }
    }));
  };

  handleSave = () => {
    this.updatePriceHistoy((priceHistory, cagrPeriods, addressLabel) => ({
      filter: this.props.propertyId,
      fields: {
        addressLabel,
        priceHistory,
        cagr: cagrPeriods
      }
    }));
  };

  updatePriceHistoy = updatePropertyCallback => {
    const { priceHistory, addressLabel } = this.state;

    if (!this.validatePriceHistory()) return;

    const sortedPriceHistory = _.sortBy(priceHistory, "date");


    this.setState({ spinner: true });
    fetchCagrPeriods(sortedPriceHistory)
      .then(cagrPeriods => {
        const updatedProperty = updatePropertyCallback(
          sortedPriceHistory,
          cagrPeriods,
          addressLabel
        );
        return api.put("/api/property/update", updatedProperty);
      })
      .then(() => {
        this.setState({ spinner: false });

        this.props.onClose();
        return null;
      })
      .catch(err => {
        this.props.showNotification("error", {
          title: `Error: ${err.message}.`
        });
      });
  };
  handleRevertChangesClick = event => {
    event.preventDefault();
    this.setState({ spinner: true });
    const AUSTRALIA_REGEX = /,?\s*Australia\s*/i;
    // addQuery({ cagrPropertyAddress: this.props.address.replace(AUSTRALIA_REGEX, ""), propertyId: this.props.propertyId, serverURL: process.env.SERVER_URL });
    window.postMessage(
      {
        type: "getPropertyData",
        cagrPropertyAddress: this.props.address.replace(AUSTRALIA_REGEX, ""),
        propertyId: this.props.propertyId,
        serverURL: process.env.SERVER_URL
      }, "*");
    setTimeout(() => {      
      this.setState({ spinner: false });
      this.props.onClose();
    }, 3000);
  };
  toggleoverrideConfirmModal = (shouldModelOpen,mode) => {
    this.setState({
      isOverrideModalOpen: shouldModelOpen,
      mode
    })
  }
  // ==========================================================================
  // Helpers
  // ==========================================================================

  validatePriceHistory = () => {
    const { priceHistory } = this.state;

    const hasInvalidDate = priceHistory.some(x => !x.date || !x.date.isValid());
    const hasInvalidPrice = priceHistory.some(x => !x.price);

    // Validation
    if (hasInvalidDate) {
      this.props.showNotification("error", {
        title: "Date format should be DD/MM/YYYY."
      });
      return false;
    } else if (hasInvalidPrice) {
      this.props.showNotification("error", {
        title: "Price field cannot be empty for an entry."
      });
      return false;
    } else {
      return true;
    }
  };

  // ==========================================================================
  // Manipulate price history list methods
  // ==========================================================================

  addNewRecord = ev => {
    ev.preventDefault();

    this.setState(prevState => ({
      priceHistory: [...prevState.priceHistory, { price: "", date: null }]
    }));
  };

  removeItem = index => {
    this.setState(prevState => ({
      priceHistory: prevState.priceHistory.filter((x, i) => i !== index)
    }));
  };

  // ==========================================================================
  // Input handlers
  // ==========================================================================

  handleDateChange = index => date => {
    this.setState(prevState => ({
      priceHistory: [
        ...prevState.priceHistory.slice(0, index),
        {
          ...prevState.priceHistory[index],
          date
        },
        ...prevState.priceHistory.slice(index + 1)
      ]
    }));
  };

  handleNumberInputChange = index => ev => {
    const { value } = ev.target;
    const validNumberRegex = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;

    if (value.match(validNumberRegex)) {
      this.setState(prevState => ({
        priceHistory: [
          ...prevState.priceHistory.slice(0, index),
          {
            ...prevState.priceHistory[index],
            price: value
          },
          ...prevState.priceHistory.slice(index + 1)
        ]
      }));
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  render() {
    return (
      <div>
        <div className="card">
          <div className="header">
            <div className="row">
              <div className="col-sm-6">
                <h4 className="title">Property Price List</h4>
              </div>
              <div className="col-sm-6 f-15">
                <button
                  className="btn btn-primary right m-r-3"
                  onClick={()=>this.toggleoverrideConfirmModal(true, "fetch cagr")}
                >
                  Fetch CAGR
                </button>
              </div>
            </div>
            <p className="category">Manage Property Price</p>
          </div>

          <h4 style={{ margin: 10 }}>Address being Scraped : &nbsp; {this.props.address}</h4>
          <p>{this.state.notes || ""}</p>
          {this.state.addressLabel && (
            <h4 style={{ margin: 10, backgroundColor: 'orange', padding: 8 }}>Address Label from Price History Source : &nbsp;{this.state.addressLabel}</h4>
          )}

          {/* Core content */}
          {this.state.spinner && (
                <div className="spinner-pos">
                  <Spinner />
                </div>
          )}
          {this.state.priceHistory && this.state.priceHistory.length !== 0 && (
            <div className="content table-responsive table-full-width">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">Purchase Date</th>
                    <th className="text-center">Price</th>
                    <th className="text-right" />
                  </tr>
                </thead>
                <tbody>
                  {this.state.priceHistory.map((purchaseData, index) => (
                    <tr key={index}>
                      <td className="text-center">
                        <FormDatepicker
                          dateFormat="DD/MM/YYYY"
                          label={null}
                          onChange={this.handleDateChange(index)}
                          value={purchaseData.date}
                        />
                      </td>

                      <td className="text-center">
                        <FormInput
                          label={null}
                          onChange={this.handleNumberInputChange(index)}
                          placeholder="9999"
                          type="text"
                          value={purchaseData.price}
                        />
                      </td>

                      <td className="text-right">
                        <i
                          className="ti-close remove-icon cursor-position"
                          onClick={() => {
                            this.removeItem(index);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="p-b-15 p-l-10">
            <button className="btn btn-fill btn-info" onClick={this.addNewRecord}>
              Add Record
            </button>

            <button
              className="btn btn-primary right m-r-3"
              onClick={this.handleSaveAndUpdate}
            >
              Save and Update LSD
            </button>
            <button
              className="btn btn-primary right m-r-3"
              onClick={this.handleSave}
            >
              Save
            </button>
            <button
              className="btn btn-default right m-r-3"
              onClick={this.props.onClose}
            >
              Close
            </button>
          </div>
        </div>
        <DeleteConfirm
          isOpen={this.state.isOverrideModalOpen}
          onSubmit={this.handleRevertChangesClick}
          mode={this.state.mode}
          onCloseClick={() => {
            this.toggleoverrideConfirmModal(false, "");
          }}
        />
      </div>
    );
  }
}

export default withNotifications(ManagePriceHistory);
