/* Buy Price List (used in Property List Page) */

const Price_Range = [
	"<400",
	"400-499",
	"500-599",
	"600-699",
	"700-799",
	"800-899",
	"900-999",
	"1.000M-1.099M",
	"1.100M-1.199M",
	"1.200M-1.299M",
	"1.300M-1.399M",
	"1.400M-1.499M",
	"1.5M +"
]

export default Price_Range;

export const Price_Range_K = new Map([
	[
		1, "<400"
	],
	[
		400, "400-499"
	],
	[
		499, "400-499"
	],
	[
		500, "500-599"
	],
	[
		599, "500-599"
	],
	[
		600, "600-699"
	],
	[
		699, "600-699"
	],
	[
		700, "700-799"
	],
	[
		799, "700-799"
	],
	[
		800, "800-899"
	],
	[
		899, "800-899"
	],
	[
		900, "900-999"
	],
	[
		999, "900-999"
	]
])

export const Price_Range_M = new Map([
	[
		1, "1.000M-1.099M",
	],
	[
		1.0, "1.000M-1.099M",
	],
	[
		1.099, "1.000M-1.099M",
	],
	[
		1.1, "1.100M-1.199M",
	],
	[
		1.199, "1.100M-1.199M",
	],
	[
		1.2, "1.200M-1.299M",
	],
	[
		1.299, "1.200M-1.299M",
	],
	[
		1.3, "1.300M-1.399M",
	],
	[
		1.399, "1.300M-1.399M",
	],
	[
		1.4, "1.400M-1.499M",
	],
	[
		1.499, "1.400M-1.499M",
	],
	[
		1.5, "1.5M +",
	]
])