'use strict';
import React, { Component } from 'react';
import api from '../../common/api';
import moment from 'moment';

export default class Comments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editComment : false,
			comment : ''
		}
        this.editComment = this.editComment.bind(this)
		this.modifyComment = this.modifyComment.bind(this)
	}

	modifyComment(event) {
		event.preventDefault();
		this.setState({
			editComment : true,
			comment : this.props.comment.comment_desc
		})
	}


    editComment(commentId, event) {
        event.preventDefault();
        let updatedComment = {};
        updatedComment.filter = commentId;
        updatedComment.fields = {
        	"comments.$.comment_desc" : this.state.comment,
        	"comments.$.commented_on" : new Date(),
        	"comments.$.edited" : true
        }
        // console.log("updatedComment is", updatedComment);
        api.put('/api/feedback/updateComment', updatedComment).then(result => {
            if (result.notify) {
                alert(result.notify);
            } else {
            	this.props.comment.comment_desc = this.state.comment;
            	this.props.comment.commented_on = new Date();
            	this.props.comment.edited = true;
            	this.setState({
            		editComment : false,
            		comment : ''
            	})
            }
        })
    }

	render() {
		return(
			<li>
				{ !this.state.editComment ?
				<div>
					<span><b>{this.props.comment.comment_by.first_name}</b></span>
					<div className="action-comment">
						<div>
							<p>{this.props.comment.comment_desc}</p>
						</div>
					</div>
						<div className="phenom-meta">
							<span>{moment(this.props.comment.commented_on).calendar()}</span> &nbsp;
							{this.props.comment.edited ? <span>(edited)</span> : ''}&nbsp;
							{ this.props.loggedUser == this.props.comment.comment_by._id ? 
								<div style={{display: 'inline-block'}}>
									-<a className="comment-edit" onClick={this.modifyComment}>Edit</a>&nbsp; 
									-<a className="comment-edit" onClick={this.props.removeComment}>Delete</a>
								</div> : ''
							}
						</div> 
				</div> :
					<div> 
						<div className="row">
	                    	<div className="col-md-12">
	                    		<input className="form-control" type="text" placeholder="Write a Comment..." value={this.state.comment} onChange={(event) => {this.setState({comment : event.target.value})}} />
	                    	</div>
	                    </div>
	                    <br></br>
	                    <div className="row">
	                    	<div className="col-md-6">
	                    		<button className="btn btn-success btn-fill" type="submit" onClick={this.editComment.bind(this, this.props.comment._id)}>Save</button>
	                    		<button className="btn m-l-5" type="submit" onClick={(event)=>{ this.setState({editComment : false})}}>Clear</button>
	                    	</div>
	                    </div>
                    </div>
				}
				<hr style={{margin: '7px 0px'}}></hr>
			</li>
		)
	}
}	
