"use strict";
import React from "react";
import { CSVLink } from "react-csv";

// Modules
import FeedbackView from "../feedbackView/FeedbackView";

export default function() {
    return FeedbackListComponentRender.call(this);
}

var FeedbackListComponentRender = function() {
    return (
        <div className="col-md-12">
            <div className="row">
                <h3 className="col-xs-6 m-l-16">Feedback List</h3>
                <CSVLink
                    separator={(";", ",")}
                    data={
                        this.state.csvList.length
                            ? this.state.csvList
                            : [{ message: "No feedback list" }]
                    }
                    filename={"feedback-list.csv"}
                    className="btn btn-info btn-fill btn-wd right m-r-30"
                    target="_blank"
                >
                    Generate CSV
                </CSVLink>
            </div>
            <div className="row">
                <label className="pull-right m-r-30">
                    <input
                        type="checkbox"
                        className="checkbox-react-dark m-l-16"
                        name="status"
                        checked={this.state.hideClosed}
                        onChange={this.filterFeedbackList.bind(this)}
                    />
                    Hide Closed Feedbacks
                </label>
            </div>
            {this.state.feedbackList && this.state.feedbackList.length ? (
                this.state.feedbackList.map((feedback, index) => {
                    return <FeedbackView feedback={feedback} key={index} />;
                })
            ) : (
                <h2>No feedbacks</h2>
            )}
        </div>
    );
};
