import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import Datetime from "react-datetime";

// Modules
import api from "../common/api";
import { responseToTableValues } from "../containers/tables/utils";

// Components
import BaTablesContainer from "../containers/tables/BATablesContainer";
import Spinner from "../common/spinner";
import withNotifications from "./withNotifications";

class EngagementHistory extends Component {
  state = {
    engagementDate: "",
    engagements: [],
    isLoading: false
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    api.get("/api/engagements").then(engagements => {
      this.setState({ isLoading: false });
      this.setEngagements(engagements.items);
    });
  }

  setEngagements = engagements =>
    this.setState({
      engagements: engagements.map(responseToTableValues)
    });

  handleOnChange = date => this.setState({ engagementDate: date });

  searchEngagement = event => {
    event.preventDefault();

    // Validation
    if (!this.state.engagementDate) {
      return this.props.showNotification("warning", {
        title: "Please select a date to jump to"
      });
    } else if (this.state.engagementDate.isAfter(moment())) {
      return this.props.showNotification("warning", {
        title: "We cannot predict the future!"
      });
    }

    const filter = encodeURIComponent(
      JSON.stringify({
        engagementDate: this.state.engagementDate
      })
    );

    this.setState({ isLoading: true });
    api.get(`/api/history/engagementHistory?filter=${filter}`).then(res => {
      this.setState({ isLoading: false });

      if (_.isEmpty(res.clientEngagements)) {
        this.props.showNotification("error", {
          title: "No records found",
          message: res.message
        });
      } else {
        this.props.showNotification("success", {
          title: "Engagements retrieved successfully"
        });

        const engagements = res.clientEngagements.engagements.map(
          x => x.engagement
        );

        this.setEngagements(engagements);
      }
    });
  };

  render() {
    const groupedByBa = _.groupBy(this.state.engagements, "ba.props.ba._id");

    return (
      <div>
        {/* This component sits within a .row, so col-xs-12 nullifies that */}
        <div className="col-xs-12">
          <div className="content-panel col-sm-6">
            <div>
              <label className="control-label">Engagement History</label>
            </div>

            <div className="row">
              <div className="col-sm-9">
                <Datetime
                  value={this.state.engagementDate}
                  timeFormat
                  dateFormat="DD/MM/YY hh:mm a"
                  showTimeSelect
                  onChange={this.handleOnChange}
                  inputProps={{ placeholder: "N/A" }}
                  disableOnClickOutside
                />
              </div>
              <div className="col-sm-3">
                <button
                  className="btn btn-primary"
                  onClick={this.searchEngagement}
                >
                  Search
                </button>
              </div>
            </div>

            <a
              data-toggle="collapse"
              href="#engagement-history-dropdown"
              style={{ display: "inline-block", marginTop: "1em" }}
            >
              <small>Information</small>
            </a>
            <div id="engagement-history-dropdown" className="collapse">
              <small>
                Engagement history tracks the changes to each engagement's
                status.
                <br />
                When navigating to a previous time the status and watermark will
                update to reflect the values at the selected time. All other
                information (budget, growth, engage date...) will reflect the
                current value.
              </small>
            </div>
          </div>

          <div className="row">
            {/* Assuming this.props.users is empty is semi-dangerous, as if anything
                goes wrong it will incorrectly convey that it is still loading */}
            {this.state.isLoading || _.isEmpty(this.props.users) ? (
              <div style={{ margin: "2em" }}>
                <Spinner />
              </div>
            ) : (
              <BaTablesContainer
                users={this.props.users}
                engagements={groupedByBa}
                businessDays={this.props.businessDays}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ users: state.buyers || [] , businessDays: state.businessDays|| {} });

export default connect(mapStateToProps)(withNotifications(EngagementHistory));
