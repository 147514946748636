import React, { Component } from 'react';
import api from '../../common/api';

const styles={
    container:{
        position:"fixed", left:"30%", top:"20%", backgroundColor:"#f3ee45fa", zIndex:3, borderRadius:10,
        color:"#fff", padding: "0px 5px", cursor :"pointer", width : "50%", height : "30%",border: "4p solid yellow"
    }
}

export default class PresentedNotificationModal extends Component {
	
	updateTimestampAndNotification(notify, value, event) {
		// console.log("notify, value, event", notify, value, event)
		let notification = {};
        notification.filter = notify.property._id;
        notification.fields = {"userId.0.read" : true, "userId.0.seen" : true, presented_notified : false}
        api.put('/api/notification/?filter=' + encodeURIComponent(JSON.stringify({property:notify.property._id,presented_notified : true})), notification).then(result => {
			this.props.updatePresentedNotification(notify);
        })
        // if value "Yes", then update the timestamp
        if(value=="Yes") {
	        let filter = notify.property._id;
	        api.put('/api/property/updateTimeStamp?filter='+encodeURIComponent(filter))
	        .then(result => {
	        	console.log("result is", result)
	        })
	        .catch(error => {
	        	console.log("error is", error);
	        })
        }
	}

	render() {
		return (
			<div style={styles.container}>
				<p className="f-20 cntr color-grey">This property has 72 hours left in the Presented Phase. Please click <b>Yes</b> to extend your time, <b>or</b> <b>No</b> to leave as is. </p>
				<table className="table">
					<tr className="f-w-900">
						<td>
							Property
						</td>
						<td>
							Action
						</td>
					</tr>
					{ this.props.presentedList && this.props.presentedList.length>0 &&
						this.props.presentedList.map((notify, index)=>{
							return (
								<tr key={index}>
									<td>
										{notify.property.address}
									</td>
									<td>
										<button className="btn btn-xs" onClick={this.updateTimestampAndNotification.bind(this, notify, "Yes")} > Yes</button>
										<button className="btn btn-xs m-l-10" onClick={this.updateTimestampAndNotification.bind(this, notify, "No")} > No</button>
									</td>
								</tr>
							)
						})
					}
				</table>
			</div>	
		)
	}
}
