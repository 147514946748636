import React from "react";
import _ from "lodash";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
import qs from "qs";

// Modules
import countryStates from "../../common/countryStates";
import phaseList from "../../common/phaseList";
import priceList from "../../common/priceList";

// Components
import AddPropertyContainer from "./AddPropertyContainer";
import CompareProperties from "./CompareProperties";
import Spinner from "../../common/spinner";
import TableRefinement from "./TableRefinement";
import TableRow from "./TableRow";
import DeleteConfirm from "../deleteConfirmModal/DeleteConfirm";

const statesOptions = countryStates.map(x => ({ value: x, label: x }));
const priceOptions = _.map(priceList, val => ({ value: val, label: val }));
const phasesOptions = _.map(phaseList, (val, key) => ({
  value: key,
  label: key
}));

const memoizedSortBas = _.memoize(users =>
  _(users)
    .filter(
      x =>
        x.user_role === "BA" ||
        x.user_role === "buyer" ||
        x.user_role === "associate"
    )
    .sortBy("nickname")
    .value()
);

const memoizedSortEngagements = _.memoize(engagements =>
  _.sortBy(engagements, "labelForScrapper")
);

const memoizedSortStatesOptions = _.memoize(statesOptions =>
  _.sortBy(statesOptions, "label")
);

export default function() {
  return PropertyListComponentRender.call(this);
}

const PropertyListComponentRender = function() {
  const showRefinements =
    !_.get(this.props, "location.query.queue") ||
    !_.get(this.props, "location.query.assigned");

  const { limit = 10, page: pageAsString } = qs.parse(
    this.props.location.search,
    { ignoreQueryPrefix: true }
  );

  // Cast page query to number
  const pageAsNumber = Number(pageAsString);
  const page = Number.isInteger(pageAsNumber) ? pageAsNumber : 0;

  return (
    <div className="col-md-12">
      <div className="row">
        <h4
          className="col-xs-6 m-a-0"
          style={{ fontFamily: '"Poppins", sans-serif' }}
        >
          Properties List
        </h4>

        <button
          className="btn btn-success btn-fill right"
          data-toggle="collapse"
          href="#collapseAddBody"
          id="addPropertyButton"
          onClick={this.handleAddPropertyPanelToggle}
        >
          <i
            className={
              this.state.plusIcon ? "ti-plus t-plus-pos" : "ti-minus t-plus-pos"
            }
            style={{ color: "white" }}
          />
          <span style={{ marginLeft: 5 }}>Property</span>
        </button>
        <CSVLink
          data={
            this.state.selectedProperties.length
              ? this.state.selectedProperties
              : this.state.defaultCSVData
          }
          filename="properties-list.csv"
          className="btn btn-info btn-fill btn-wd right m-l-4"
          target="_blank"
        >
          Generate CSV
        </CSVLink>

        <button
          className="btn btn-info btn-fill right m-l-4"
          data-toggle="collapse"
          href="#collapseCompareBody"
        >
          Compare
        </button>
      </div>

      <div className="panel-collapse collapse" id="collapseCompareBody">
        <CompareProperties properties={this.state.selectedProperties} />
      </div>

      <br />

      <div className="card">
        <div className="content">
          <div className="panel-collapse collapse" id="collapseAddBody">
            <AddPropertyContainer
              propertyListBase={this}
              onClose={this.handleAddPropertyPanelToggle}
            />
          </div>

          {showRefinements && (
            <form onSubmit={this.handleTableSearchSubmit} className="clearfix">
              <div style={{ display: "flex" }}>
                {/* NOTE: As a controlled input this lags the application immensely */}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by address, suburb, state or postcode"
                  ref={element => (this.tableSearchInput = element)}
                />

                <button
                  type="submit"
                  style={{
                    backgroundColor: "orange",
                    border: "none",
                    padding: "0 1.5em",
                    color: "white",
                    fontFamily:
                      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    fontSize: "1.2em",
                    borderRadius: 4,
                    marginLeft: "0.6em"
                  }}
                >
                  Search
                </button>
              </div>

              <div style={{ marginTop: 10 }}>
                {/* Page size control */}
                <label>Page Size:</label>
                <select
                  aria-controls="datatables"
                  className="form-control input-sm"
                  style={{
                    width: "auto",
                    display: "inline-block",
                    marginLeft: 10
                  }}
                  value={this.state.limit ? this.state.limit : limit}
                  onChange={this.handleTablePageSizeChange}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                {/* Refinements */}
                <div className="pull-right">
                  <a
                    data-toggle="collapse"
                    href="#collapseOne"
                    onClick={this.handleTableFiltersDropdownClick}
                  >
                    <div className="panel-heading right">
                      <h4 className="panel-title">
                        Refinements
                        <i className="ti-angle-down" />
                      </h4>
                    </div>
                  </a>
                </div>
              </div>

              {/* Refinement dropdown body */}
              <div className="col-xs-12">
                <div id="collapseOne" className="panel-collapse collapse">
                  <TableRefinement
                    basOptions={memoizedSortBas(this.props.buyers)}
                    clearFilter={this.clearRefinementsFilter}
                    engagementsOptions={memoizedSortEngagements(
                      this.state.engagements
                    )}
                    offMarketFilter={
                      this.state.tableStagedFilters.offMarketFilter
                    }
                    onFilterChange={this.handleTableStagedFilterChange}
                    phasesOptions={phasesOptions}
                    priceOptions={priceOptions}
                    selectedBAs={this.state.tableStagedFilters.bas}
                    selectedDateMax={this.state.tableStagedFilters.dateMax}
                    selectedDateMin={this.state.tableStagedFilters.dateMin}
                    selectedInspectionDateMax={this.state.tableStagedFilters.inspectionDateMax}
                    selectedInspectionDateMin={this.state.tableStagedFilters.inspectionDateMin}
                    selectedEngagements={
                      this.state.tableStagedFilters.engagements
                    }
                    selectedPhases={this.state.tableStagedFilters.phases}
                    selectedPrices={this.state.tableStagedFilters.prices}
                    selectedSpotters={this.state.tableStagedFilters.spotters}
                    selectedStates={this.state.tableStagedFilters.states}
                    showArchived={this.state.tableStagedFilters.showArchived}
                    spottersOptions={memoizedSortBas(this.props.buyers)}
                    statesOptions={memoizedSortStatesOptions(statesOptions)}
                  />
                </div>
              </div>
            </form>
          )}

          {/* Loading spinner */}
          {this.state.spinner && (
            <div className="spinner-pos">
              <Spinner />
            </div>
          )}

          <div className="fresh-datatables clearfix">
            <table
              id="datatables"
              className="table table-striped table-no-bordered table-hover"
              cellSpacing="0"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="text-size" />
                  <th className="text-size" />
                  <th className="text-right text-size">Phase</th>
                  <th className="text-center text-size">Actions</th>
                  <th className="text-center text-size">Rating</th>
                  <th className="text-center text-size">Good to Go Date</th>
                  <th className="text-size">Client</th>
                  <th className="text-center text-size">Suburb</th>
                  <th className="text-size">Address</th>
                  <th className="text-center text-size">Adv Price</th>
                  <th className="text-center text-size">Buy Price</th>
                  <th className="text-center text-size">
                    Sold Price / Expiration Date
                  </th>
                  <th className="text-right text-size">Beds</th>
                  <th className="text-right text-size">Baths</th>
                  <th className="text-right text-size">Cars</th>
                  <th className="text-right text-size">BA</th>
                  <th className="text-right text-size">Spotter</th>
                  <th className="text-size">
                    Spotted At <i className="fa fa-sort-down" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state._rows.map((property, i) => (
                  <TableRow
                    clickedCollapse={clickedCollapse}
                    editPhase={this.state.editPhase}
                    index={i}
                    key={property.propertyId}
                    onAcceptAssigned={this.updateNotification}
                    onArchive={this.actionProperty}
                    onArhciveDoubleClick={this.onDoubleClicked}
                    onCagrClick={this.maintainPagination}
                    onChangeRating={this.changeRating}
                    onInspect={this.updatePropertyInspector}
                    onRefuse={this.ChangePropertyClient}
                    onSelectProperty={this.handleSelectProperty}
                    onSold={this.handleSoldProperty}
                    onDelete={this.handleDeleteProperty}
                    onSubComponentOpen={this.showRow}
                    phaseColor={phaseList[property.propertyPhase]}
                    property={property}
                    propertyListBase={this}
                    seen={this.state.seen}
                    stateIndex={this.state.index}
                    isSelected={this.state.selectedProperties.some(
                      x => x.propertyId === property.propertyId
                    )}
                  />
                ))}
              </tbody>
            </table>

            <div className="row">
              <ReactPaginate
                activeClassName="active"
                breakClassName="break-me"
                breakLabel={<a href="">...</a>}
                containerClassName="pagination pagination-position"
                forcePage={page}
                marginPagesDisplayed={2}
                nextLabel="Next"
                onPageChange={this.handleTablePageChange}
                pageCount={this.state.pageCount}
                pageRangeDisplayed={5}
                previousLabel="Prev"
                subContainerClassName="pages pagination"
              />
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirm
        isOpen={this.state.isDeleteModalOpen}
        onSubmit={this.deleteProperty}
        mode={this.state.mode}
        onCloseClick={() => {
          this.toggleUpdateDeleteConfirmModal(false);
        }}
      />
    </div>
  );
};
