import React from "react";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";

function PropertyPhaseManager({
  engagementsToSelect,
  grid,
  handlePhaseChange,
  onCellsChanged,
  onEngagementSelect,
  propertyDetail,
  rowIndex
}) {
  return (
    <form method="get" action="/" className="form-horizontal">
      <div>
        <div className="manage-phases-ui">
          <div className="engagement-list">
            <select
              name="presentClient"
              className="form-control width-force-100"
              onChange={onEngagementSelect}
            >
              <option value="">Select Engagement</option>
              {engagementsToSelect &&
                engagementsToSelect.map(engagementBaGroup => (
                  <optgroup
                    key={engagementBaGroup.groupBy}
                    label={engagementBaGroup.groupBy}
                  >
                    {engagementBaGroup.options.map(engagment => (
                      <option key={engagment.value} value={engagment.value}>
                        {engagment.label}
                      </option>
                    ))}
                  </optgroup>
                ))}
            </select>
          </div>

          <div className="grid-ui">
            <ReactDataSheet
              data={grid}
              valueRenderer={cell => cell.value}
              onContextMenu={(ev, cell) =>
                cell.readOnly ? ev.preventDefault() : null
              }
              onCellsChanged={onCellsChanged}
              cellRenderer={props => (
                <td
                  onDoubleClick={() =>
                    handlePhaseChange(props, propertyDetail, rowIndex)
                  }
                  style={{
                    backgroundColor:
                      props.row > 0 &&
                      props.col === 1 &&
                      Object.keys(props.cell).length
                        ? "#6198a9"
                        : props.row > 0 &&
                          props.col === 2 &&
                          Object.keys(props.cell).length
                        ? "#e60505"
                        : props.row > 0 &&
                          props.col === 3 &&
                          Object.keys(props.cell).length
                        ? "#fdcb01"
                        : props.row > 0 &&
                          props.col === 4 &&
                          Object.keys(props.cell).length
                        ? "#a1c78a"
                        : props.row > 0 &&
                          props.col === 5 &&
                          Object.keys(props.cell).length
                        ? "#048804"
                        : props.row > 0 &&
                          props.col === 6 &&
                          Object.keys(props.cell).length
                        ? "brown"
                        : props.row > 0 &&
                          props.col === 7 &&
                          Object.keys(props.cell).length
                        ? "darkblue"
                        : props.row > 0 &&
                          props.col === 8 &&
                          Object.keys(props.cell).length
                        ? "darkblue"
                        : ""
                  }}
                >
                  {props.children}
                </td>
              )}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default PropertyPhaseManager;
