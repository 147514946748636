import moment from "moment";

export function getClientFullName(client) {
  if (!client) {
    return "";
  }

  const middlename = client.middle_name;

  if (middlename) {
    return `${client.first_name} ${middlename} ${client.last_name}`;
  } else {
    return `${client.first_name} ${client.last_name}`;
  }
}

export function labelCreator(clientName, budget, g2gDate) {
  if (!clientName) {
    throw Error("Client name is required");
  }

  if (g2gDate && (!moment.isMoment(g2gDate) || !g2gDate.isValid())) {
    throw Error("G2G date is not a valid moment object");
  }

  const g2gDateDisplay = g2gDate ? g2gDate.format("DD-MMM-YYYY") : "";
  let budgetDisplay = "";

  if (budget) {
    const budgetDashIndex = budget.indexOf("-");
    // prettier-ignore
    budgetDisplay =
        budgetDashIndex > 0
          ? `$${budget.slice(0, budgetDashIndex)} to $${budget.slice(budgetDashIndex + 1)}`
          : `$${budget}`;
  }

  return `${clientName}-(${budgetDisplay})-(${g2gDateDisplay})`;
}
