import React, { Component } from "react";

// Modules
import api from "../../common/api";
import { setBlueprint } from "./utils";

// Components
import Blueprints from "../../components/tables/Blueprints";

export default class BlueprintsContainer extends Component {
  state = {
    rows: [],
    isLoading: true
  };

  componentDidMount() {
    setBlueprint(this.handleCompletedBlueprint, false).then(rows => {
      this.setState({ rows, isLoading: false });
    });
  }

  handleCompletedBlueprint = (id, engagement, event) => {
    const { name } = event.target;
    const updatePurchase = { filter: id, fields: {} };

    if (name === "completed") {
      updatePurchase.fields = {
        blueprint_completed: !engagement.blueprint_completed,
        blueprint_completed_date: new Date().toISOString()
      };
    } else if (name === "sentToClient") {
      if (engagement.blueprint_completed) {
        updatePurchase.fields = {
          sent_to_client: !engagement.sent_to_client,
          sent_to_client_date: new Date().toISOString()
        };
      } else {
        event.preventDefault();
        updatePurchase.fields = {
          sent_to_client: engagement.sent_to_client
        };
      }
    }

    api
      .put(`/api/clientPurchases/`, updatePurchase)
      .then(() => {
        setBlueprint(this.handleCompletedBlueprint, false).then(rows =>
          this.setState({ rows })
        );
      })
      .catch(console.error);
  };

  render() {
    return (
      <Blueprints isLoading={this.state.isLoading} rows={this.state.rows} />
    );
  }
}
