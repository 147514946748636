import React from "react";

// Modules
import { formatDate, formatDaysDuration } from "./utils";

export const completedCol = {
  path: "completedCol",
  heading: "Completed",
  headingRender: value => <div style={{ textAlign: "center" }}>{value}</div>,
  rowRender: value => <div style={{ textAlign: "center" }}>{value}</div>
};

export const sentToClientCol = {
  path: "sentToClientCol",
  heading: "Sent to Client",
  headingRender: value => <div style={{ textAlign: "center" }}>{value}</div>,
  rowRender: value => <div style={{ textAlign: "center" }}>{value}</div>
};

export const datePromisedCol = {
  path: "date_promised",
  heading: "Date Promised",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: formatDate
};

export const daysUntilDeliveryCol = {
  path: "days_till_delivery",
  heading: "Days until Delivery",
  headingRender: value => <div style={{ textAlign: "right" }}>{value}</div>,
  rowRender: formatDaysDuration
};
