import { connect } from "react-redux";

// Modules
import Base from "./MapViewBase";
import Render from "./MapViewRender";

// Components
import withNotifications from "../withNotifications";

class MapView extends Base {
  render() {
    return Render.call(this, this.props, this.state);
  }
}

const mapStateToProps = state => ({
  user: state.user || {},
  buyers: state.buyers || []
});

export default connect(mapStateToProps)(withNotifications(MapView));
