'use strict';
import React, { Component } from 'react';
import api from '../../common/api';
import { browserHistory } from 'react-router';

class FeedbackViewBase extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            status : "open",
            showComments : false,
            description : '',
            comments : [],
            severity : "low"
        }
        this.addComment = this.addComment.bind(this)
        this.removeComment = this.removeComment.bind(this)
        this.updateFeedback = this.updateFeedback.bind(this)
        this.updateSeverity = this.updateSeverity.bind(this)
        this.renderComments = this.renderComments.bind(this)
    }

    componentWillMount() {
        const admin = this.props.user ? this.props.user.admin : '';
        const advocateToken = localStorage.getItem('USER_TOKEN');
        const clientToken = localStorage.getItem('CLIENT_TOKEN');
        if(!admin && !clientToken) {
            browserHistory.push('client-login');
        }else if(!admin) {
            browserHistory.push('/home/shortlisted-property');
        } else if (!advocateToken) {
            browserHistory.push('login');
        }
    }

    componentDidMount() {
        if(this.props.feedback) {
            this.setState({
                status   : this.props.feedback.status,
                severity : this.props.feedback.severity,
                comments : this.props.feedback.comments.reverse()
            })
        }
    }

    componentWillReceiveProps(props) {
        if(props.feedback) {
            this.setState({
                status   : props.feedback.status,
                severity : props.feedback.severity,
                comments : props.feedback.comments.reverse()
            })
        }
    }

    updateFeedback(event) {
        event.preventDefault();
        this.setState({
            status : event.target.value
        },() => {
            let feedback = {}
            feedback.filter = this.props.feedback._id;
            feedback.fields = {
                status : this.state.status
            }
            this.apiCall(feedback);
        })
    }

    updateSeverity(event) {
    	event.preventDefault();
        this.setState({
            severity : event.target.value
        },() => {
            let feedback = {}
            feedback.filter = this.props.feedback._id;
            feedback.fields = {
                severity : this.state.severity
            }
            this.apiCall(feedback);
        })
    }

    apiCall(feedback) {
    	api.put('/api/feedback/update', feedback).then(result => {
            if (result.notify) {
                alert(result.notify);
            } else {
                console.log("updated");
            }
        })
    }

    renderComments() {
        this.setState({
            showComments : !this.state.showComments
        })
    }

    addComment(event) {
        event.preventDefault();
        let comments={};
        comments.comment_desc = this.state.description;
        comments.comment_by = this.props.user.info.userId ? this.props.user.info.userId : this.props.user.info._id;;
        api.put('/api/feedback/addComment',{'filter':this.props.feedback._id,comments}).then(result => {
            this.setState({ comments : result.feedbacks.comments.reverse(), description : ''});
        });
    }

    removeComment(index, comment, event) {
        event.preventDefault();
        let comments = this.state.comments;
        let removedComment = {}
        removedComment.filter = this.props.feedback._id;
        removedComment.fields = {
            _id : comment._id
        }
        api.put('/api/feedback/removeComment', removedComment).then(result => {
            if (result.notify) {
                alert(result.notify);
            } else {
                comments.splice(index,1);
                this.setState({
                    comments
                })
            }
        })
    }

}

module.exports = FeedbackViewBase;
