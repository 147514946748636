import { connect } from "react-redux";

// Modules
import Base from "./NotificationBase";
import Render from "./NotificationRender";

class Notification extends Base {
  render() {
    return Render.call(this, this.props, this.state);
  }
}

const mapStateToProps = state => ({ user: state.user || {} });

export default connect(mapStateToProps)(Notification);
