"use strict";
import React, { Component } from "react";
import { browserHistory } from "react-router";
import moment from "moment-timezone";
import Autolinker from "autolinker";
import qs from "qs";
import _ from "lodash";

// Modules
import api from "../../common/api";
import ColumnList from "../../common/columnList";
import suaList from "../../common/suburbToSua.json";

class PropertyListBase extends Component {
  constructor(props) {
    super(props);
    let pagination = JSON.parse(localStorage.getItem("pagination"));
    /**
     * @type {object}
     * @property {Array} _rows properties list
     * @property {String} authtoken user login token
     * @property {object} modaldata edit property details
     * @property {Array} clientList filter property on the basis of client
     * @property {Array} buyersList filter property on the basis of buyerAgent(BA)/ Spotter
     * @property {Array} defaultCSVData Generate CSV of Preferences field
     * @property {Array} selectedProperties Compare selected Properties or generate CSV of selected Properties
     * @property {String} notes editable property field
     * @property {String} growth editable property field
     * @property {Boolean} seen  manage assigned properties through Notification
     * @property {String} presentClient  name of client to whom property is presented
     * @property {String} clientId  client to whom add to watchlist or queuelist
     */

    this.state = {
      _walkScore: [],
      _dsr: [],
      _rows: [],
      authtoken: localStorage.getItem("USER_TOKEN"),
      modaldata: null,
      floodCheck: "",
      parking: "",
      beds: "",
      tempHotlist: [],
      baths: "",
      price: "",
      landSize: "",
      yearBuild: "",
      manageEngagementsList: [],
      clientList: null,
      buyersList: [],
      defaultCSVData: [],
      selectedPhase: "",
      user: "",
      selectedProperties: [],
      notes: "",
      address: "",
      currentlySelectedProperty: {},
      currentlySelectedPropertyIndex: "",
      growth: "",
      business_days: "",
      off_market_flag: false,
      engagementList: [],
      engagementsToSelect: [], // Engagements to select from the drop down on phase change
      selectedEngagementsForOffMarketProperty: [],
      presentClient: "",
      clientId: "",
      rating: 0, // property rating
      comments: "",
      data: "",
      comparable_sales: "",
      // notesOwnerShipissue: false, // when a BA tries to change the notes of another BA it becames true
      editPhase: false, // on click of action button manage phase of the property
      selectedDualList: [], // maintain client watchlist/queuelist
      allClients: [], // contain list of all Clients
      modalIsOpen: false, // Open on click of Edit Phase of Property
      archive: true, // Filter for Querying Archived or UnArchived Properties
      underContract: false, // Filter for searching only under contract properties
      locked: false, // Disabled fields if a property is locked i.e a property is being edited by another bA
      locked_ba: "", //ba who has locked the property
      locked_ba_id: "", // _id of locked ba
      sortByExpDate: false, // Sort the properties list by UnderContract expiration date
      updatePropertyNotification: true, // Boolean, whether user wants the notification, while updating property details
      socket: "", // to get the instance of the socket
      offset: pagination && pagination.offset ? pagination.offset : 0, // current offset for Property List Pagination
      filter: null, // filter on Property List
      limit: pagination && pagination.limit ? pagination.limit : 10, // current limit(number of records ona page) for Property List Pagination
      sortField: "scraped_date", // Default Sorting of the Records
      sort: "desc", // state to show the sign of the Sort(Asc or Desc)
      spinner: true, // show spinner while loading
      queryTimer: false, // show spinner while loading

      hotList: false, // Show hotlist if the Property Phase is "HOT"
      override: false, //  Override Adddres While adding Property if the Google Suggest does not appear
      plusIcon: true, // Icon Appear on click of Add Property(default- "Plus" and onclick - "Minus")
      State: "",
      affectedEngagements: [], // State of the Property while Adding Property Manually
      suburb: "", // Suburb of the Property while Adding Property Manually
      customStyles: {
        content: {
          backgroundColor: "white",
          width: "50%",
          height: "35%",
          top: "1%",
          left: "25%",
          padding: "0px"
        },
        overlay: {
          zIndex: 2
        }
      },
      modules: {
        toolbar: [["link"]]
      },
      grid: [
        [
          { readOnly: true, value: "", name: "label" },
          { value: "PIG", readOnly: true, name: "phase" },
          { value: "HOT", readOnly: true, name: "phase" },
          { value: "Presented", readOnly: true, name: "phase" },
          { value: "Proceed", readOnly: true, name: "phase" },
          { value: "Purchased", readOnly: true, name: "phase" },
          { value: "Queue", readOnly: true, name: "phase" },
          { value: "Waiting", readOnly: true, name: "phase" },
          { value: "Missed", readOnly: true, name: "phase" }
        ]
      ],

      // ================================================================
      // NEW CODE FOR NEW ROUTE
      // ================================================================

      engagements: [],
      pageCount: 0,
      tableStagedFilters: {
        bas: [],
        dateMax: "",
        dateMin: "",
        inspectionDateMax: "",
        inspectionDateMin: "",
        engagements: [],
        offMarketFilter: false,
        phases: [],
        prices: [],
        showArchived: false,
        spotters: [],
        states: []
      },

      // Temporary
      showAssigned: false,
      seen: false,
      showQueued: false,
      propertyIds: [],

      // ================================================================
      // END OF NEW CODE FOR NEW ROUTE
      // ================================================================

      isDeleteModalOpen: false,
      mode: "",
      dataToDelete: {}
    };

    this.autolinker = new Autolinker({
      urls: {
        schemeMatches: true,
        wwwMatches: true,
        tldMatches: true
      },
      email: true,
      mention: false,
      hashtag: false,
      stripPrefix: true,
      stripTrailingSlash: true,
      newWindow: true,
      truncate: {
        length: 0,
        location: "end"
      }
    });
    this.updateProperty = this.updateProperty.bind(this);
    this.updatePropertyDate = this.updatePropertyDate.bind(this);
    this.savePropertyUpdate = this.savePropertyUpdate.bind(this);
    this.manageWatchlist = this.manageWatchlist.bind(this);
    this.manageHotlist = this.manageHotlist.bind(this);
    this.checkQueueList = this.checkQueueList.bind(this);
    this.setAddModal = this.setAddModal.bind(this);
    this.handleSoldProperty = this.handleSoldProperty.bind(this);
    this.handleDeleteProperty = this.handleDeleteProperty.bind(this);
    this.deleteProperty = this.deleteProperty.bind(this);
    this.toggleUpdateDeleteConfirmModal = this.toggleUpdateDeleteConfirmModal.bind(this);
    this.actionProperty = this.actionProperty.bind(this);
    this.presentProperty = this.presentProperty.bind(this);
    this.ChangePropertyClient = this.ChangePropertyClient.bind(this);
    this.updateNotification = this.updateNotification.bind(this);
    this.showRow = this.showRow.bind(this);
    this.onDoubleClicked = this.onDoubleClicked.bind(this);
    this.handlePropertyPhase = this.handlePropertyPhase.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.refreshPropertyList = this.refreshPropertyList.bind(this);
    this.updatePropertyInspector = this.updatePropertyInspector.bind(this);
    this.editNotes = this.editNotes.bind(this);
    this.maintainPagination = this.maintainPagination.bind(this);
    this.checkConditionDatesPassed = this.checkConditionDatesPassed.bind(this);
    this.addAuditHistory = this.addAuditHistory.bind(this);
    this.changeRating = this.changeRating.bind(this);
    this.handleEngagementUi = this.handleEngagementUi.bind(this);
    this.updateEngagementUi = this.updateEngagementUi.bind(this);
    this.setEngagementsForSelection = this.setEngagementsForSelection.bind(
      this
    );
    // handles phase change clickings
    this.handlePhaseChange = this.handlePhaseChange.bind(this);
    this.handleAddingRow = this.handleAddingRow.bind(this);
    this.unlockTheProperty = this.unlockTheProperty.bind(this);
    this.listenUnlockProperty = this.listenUnlockProperty.bind(this);
    this.updatePropertyDetails = this.updatePropertyDetails.bind(this);
    this.checkEngagementIsAbleToPurchased = this.checkEngagementIsAbleToPurchased.bind(
      this
    );
    this.checkEngagementIsAbleToProceed = this.checkEngagementIsAbleToProceed.bind(
      this
    );
    this.updateClientEngagement = this.updateClientEngagement.bind(this);
    this.updateClient = this.updateClient.bind(this);
    this.getSUAForProperty = this.getSUAForProperty.bind(this);
    // returns the locked property details object
    this.setLockedPropertyUtility = this.setLockedPropertyUtility.bind(this);

    // ====================================================================
    // NEW CODE FOR NEW ROUTE
    // ====================================================================

    // Ideally the search is a controlled component, however this causes
    // a huge amount of lag when repeatedly calling setState(). This is why
    // search is an uncontrolled component
    this.tableSearchInput;

    this.handleTableFiltersDropdownClick = this.handleTableFiltersDropdownClick.bind(
      this
    );
    this.handleTableSearchSubmit = this.handleTableSearchSubmit.bind(this);
    this.handleTableStagedFilterChange = this.handleTableStagedFilterChange.bind(
      this
    );
    this.handleTableSort = this.handleTableSort.bind(this);
    this.handleTablePageChange = this.handleTablePageChange.bind(this);
    this.handleTablePageSizeChange = this.handleTablePageSizeChange.bind(this);
    this.clearRefinementsFilter = this.clearRefinementsFilter.bind(this);

    this.updateBuyPriceAndPropertyDog = this.updateBuyPriceAndPropertyDog.bind(
      this
    );
    this.fetchEngagements = this.fetchEngagements.bind(this);
    // ====================================================================
    // END OF NEW CODE FOR NEW ROUTE
    // ====================================================================
  }

  componentWillMount() {
    const { user } = this.props;

    const admin = _.get(user, "admin", "");
    const advocateToken = localStorage.getItem("USER_TOKEN");
    const clientToken = localStorage.getItem("CLIENT_TOKEN");

    // If no token - redirect to login page
    if (!admin && !advocateToken) {
      browserHistory.push("login");
    } else if (!admin && clientToken) {
      browserHistory.push("/home/shortlisted-property");
    }
  }

  componentDidMount() {

    // set up a listener for the SRP connection
    window.addEventListener("checkedSRPResults", (evt) => {
      console.log("checked SRP connection", evt);
      if (evt.detail.srp_status != true || evt == undefined) {
        this.props.showNotification("warning", {
          title: "SRP not connected",
          message: "There seems to be a problem connecting to SRP, maybe you are not connnected to the VPN. You will still be able to upload a property but SRP data will not be available."
        });
      }
    });

    window.addEventListener("propertyDataReceived", (evt) => {
      console.log(evt);
      this.props.showNotification("success", {
        title: "Success!",
        message: "Added Successfully."
      });
      this.handleAssignedOrQueuedScreens(() => {
        this.updateStagedFiltersFromQueryParams();
        this.fetchProperties();
      });
    });

    Promise.all([
      api.get("/api/users/getUserData"),
      api.get("/api/business?allowedTemporary=yes"),
      api.get("/api/users/getNotifications")
    ])
      .then(([user, business_days, notifications]) => {
        const updatePropertyNotification = _.get(
          notifications,
          "notificationsPreference.Update Property Details",
          ""
        );

        const state = {
          user,
          business_days: business_days.days[0],
          updatePropertyNotification
        };

        this.setState(state, () => {
          // Temporary
          this.handleAssignedOrQueuedScreens(() => {
            this.updateStagedFiltersFromQueryParams();
            this.fetchProperties();
          });
        });
      })
      .catch(console.error);
  }

  componentWillReceiveProps(nextProps) {
    // Only reload the properties list/state when we have a new query
    if (this.props.location.query !== nextProps.location.query) {
      // Temporary
      this.handleAssignedOrQueuedScreens(() => {
        this.updateStagedFiltersFromQueryParams();
        this.fetchProperties();
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.socket != prevProps.socket) {
        this.setState(
        {
            socket: this.props.socket
        },
        () => {
            this.listenUnlockProperty();
        }
        );
    }
  }
  

  // ========================================================================
  // NEW CODE FOR NEW ROUTE
  // ========================================================================

  fetchEngagements(setStateCallback) {
    const filter = encodeURIComponent(
      JSON.stringify({ selectEngagement: "All" })
    );

    api
      .get(`/api/clientPurchases/list?filter=${filter}`)
      .then(({ purchases: engagements }) => {
        this.setState({ engagements }, setStateCallback);
      })
      .catch(console.error);
  }

  fetchProperties() {
    // Temporary
    const { showAssigned, showQueued, propertyIds } = this.state;

    const { search } = browserHistory.getCurrentLocation();
    const query = qs.parse(search, { ignoreQueryPrefix: true });

    // Defaults (requires specifiing here and anywhere a default is used (fixed by passing as props?))
    query.limit = query.limit || 10;
    query.page = query.page || 0;
    query.sort = { scraped_date: "desc" };

    // Temporary
    if (showAssigned) {
      query.showAssigned = true;
      query.ids = propertyIds;
    }

    // Temporary
    if (showQueued) query.showQueued = true;

    this.setState({ spinner: true });

    api
      .get(`/api/properties?${qs.stringify(query)}`)
      .then(response => {
        // REVERT FOR THE DSR AND WALKSCORE FETCH AS THEY DO NOT RUN AS DESIRED
        this.setState({ spinner: false, prevCount: this.state.count }, () => {
          destroyBootstrapSwitches("#datatables");
          this.setState({ count: response.count });
        });

        if (
          this.state.count &&
          this.state.count != this.state.prevCount &&
          this.state.updatePropertyNotification
        ) {
          this.props.showNotification("success", {
            title: "Property search complete",
            message: `${this.state.count} properties match your search parameters.`
          });
        }
        this.createPropertyList(response.items, response.count);
      })
      .catch(console.error);
  }

  // Temporary handling of assigned and queued screens
  handleAssignedOrQueuedScreens(callback) {
    const { search } = browserHistory.getCurrentLocation();
    const { assigned, queue } = qs.parse(search, {
      ignoreQueryPrefix: true
    });

    // Reset to default
    const defaultState = {
      propertyIds: [],
      seen: false,
      showAssigned: false,
      showQueued: false
    };

    if (assigned) {
      // Handle showing assigned
      return api.get("/api/notification").then(response => {
        if (response.notify) return alert(response.notify);

        const assignedPropertiesIds = _.map(
          response.notifications,
          "property._id"
        );

        this.setState(
          {
            ...defaultState,
            propertyIds: [...assignedPropertiesIds],
            // Triggers assigned
            seen: true,
            showAssigned: true
          },
          callback
        );
      });
    } else if (queue) {
      // Handle showing queued
      this.setState({ ...defaultState, showQueued: true }, callback);
    } else {
      // Remove both filters
      this.setState(defaultState, callback);
    }
  }

  updateStagedFiltersFromQueryParams() {
    const { search } = browserHistory.getCurrentLocation();
    const query = qs.parse(search, { ignoreQueryPrefix: true });

    const newState = {
      bas: query.bas,
      engagements: query.engagements,
      phases: query.phases,
      prices: query.prices,
      search: query.search,
      spotters: query.spotters,
      states: query.states
    };

    const getDateMoment = date => {
      const dateMoment = moment(date, moment.ISO_8601);
      return dateMoment.isValid() ? dateMoment : "";
    };

    if (_.isObject(query.date)) {
      newState.dateMin = getDateMoment(query.date.min);
      newState.dateMax = getDateMoment(query.date.max);
    } else if (query.date) {
      newState.dateMin = moment()
        .subtract(6, "months")
        .format("DD/MM/YY");
    }

    if (_.isObject(query.inspectionDate)) {
      newState.inspectionDateMin = getDateMoment(query.inspectionDate.min);
      newState.inspectionDateMax = getDateMoment(query.inspectionDate.max);
    }

    this.tableSearchInput.value = query.search || "";

    if (_.isEmpty(query)) {
      newState.dateMin = moment()
        .subtract(6, "months")
        .format("DD/MM/YY");
    }

    // Boolean values
    if (query.showArchived !== undefined) {
      newState.showArchived = JSON.parse(query.showArchived);
    }
    if (query.offMarketFilter !== undefined) {
      newState.offMarketFilter = JSON.parse(query.offMarketFilter);
    }

    this.setState({ tableStagedFilters: newState, limit: query.limit });
  }

  updateQueryParamsFromStagedFilters() {
    const { tableStagedFilters: staged } = this.state;

    const { pathname, search } = browserHistory.getCurrentLocation();
    const { limit } = qs.parse(search, { ignoreQueryPrefix: true });

    // Build filter values to save in query params
    const urlState = {
      bas: staged.bas,
      engagements: staged.engagements,
      phases: staged.phases,
      prices: staged.prices,
      // Search is an uncontrolled component
      search: this.tableSearchInput.value || undefined,
      spotters: staged.spotters,
      states: staged.states,
      // Boolean values
      showArchived: staged.showArchived,
      offMarketFilter: staged.offMarketFilter
    };

    const getDateISO = date => (moment.isMoment(date) ? date.toISOString() : "");

    _.set(urlState, "date.min", getDateISO(staged.dateMin));
    _.set(urlState, "date.max", getDateISO(staged.dateMax));

    _.set(urlState, "inspectionDate.min", getDateISO(staged.inspectionDateMin));
    _.set(urlState, "inspectionDate.max", getDateISO(staged.inspectionDateMax));

    const newQuery = { ...urlState, limit: this.state.limit || limit, page: 0 };

    browserHistory.replace({
      pathname,
      search: `?${qs.stringify(newQuery)}`
    });
  }

  handleTableFiltersDropdownClick() {
    const { engagements } = this.state;

    if (_.isEmpty(engagements)) this.fetchEngagements();
  }

  handleTableSearchSubmit(event) {
    event.preventDefault();

    this.updateQueryParamsFromStagedFilters();
  }

  handleTableStagedFilterChange(name, value) {
    this.setState(prevState => {
      const filters = { ...prevState.tableStagedFilters, [name]: value };

      return { tableStagedFilters: filters };
    });
  }

  // Change sort and fetch
  handleTableSort(name) {
    // this.setState(prevState => {
    //     const { tableSort } = prevState;
    //     let sortDirection = "asc";
    //     if (name in tableSort) {
    //         sortDirection = tableSort[name] === "asc" ? "desc" : "asc";
    //     }
    //     return { tablePage: 0, tableSort: { [name]: sortDirection } };
    // }, this.fetchProperties);
  }

  // Update page query param
  handleTablePageChange(page) {
    const { pathname, search } = browserHistory.getCurrentLocation();
    const currentQuery = qs.parse(search, { ignoreQueryPrefix: true });

    const newQuery = { ...currentQuery, page: page.selected };

    browserHistory.replace({
      pathname,
      search: `?${qs.stringify(newQuery)}`
    });
  }

  // Update limit (page size) query param
  handleTablePageSizeChange(ev) {
    const { value: limit } = ev.target;
    this.setState({ limit });
  }

  // ========================================================================
  // END OF NEW CODE FOR NEW ROUTE
  // ========================================================================

  // socket listening for the event "unlock the property"
  listenUnlockProperty() {
    this.state.socket &&
      this.state.socket.on("unlock-property", data => {
        if (data.status == "Done") {
          this.refreshPropertyList();
        }
      });
  }

  refreshPropertyList(event) {
    this.fetchProperties();
  }

  // Handle rating change in property
  changeRating(rating, data, index) {
    const updateProperty = {};
    updateProperty.filter = data.propertyId;
    updateProperty.fields = {
      rating: rating
    };
    api
      .put("/api/property/update", updateProperty)
      .then(result => {
        if (this.state.updatePropertyNotification) {
          this.props.showNotification("success", {
            title: "Success!",
            message: "Edit Successfully."
          });
        }

        this.updateRowIndex(result.properties, index);
      })
      .catch(error => console.log("error", error));

  }
  // Delete property
  deleteProperty() {
    const { data } = this.state.dataToDelete
    const { propertyId } = data;
    api
      .put("/api/property/deleteProperty", { propertyId })
      .then(result => {
        if (this.state.updatePropertyNotification) {
          this.props.showNotification("success", {
            title: "Success!",
            message: "Delete Successfully."
          });
          this.fetchProperties();

        }

        // this.updateRowIndex(result.properties, index);
      })
      .catch(error => console.log("error", error));

  }

  //Method runs on clicking of unlock property and emit the event
  unlockTheProperty(propertyDetail, index, e) {
    console.log("index", index);
    e.preventDefault();
    /* Emits the event */
    this.state.socket &&
    (this.state.locked_ba_id || this.state.locked_ba) &&
      this.state.socket.emit("unlock-property", {
        lockingUserId: this.state.locked_ba_id ? this.state.locked_ba_id._id : undefined,
        lockingNickname: this.state.locked_ba
      });

    /* Update the property regards to the ba who clicked the button */
    const updateProperty = {};

    //set data for update the property,
    //that is unlocked from a BA and being locked for this BA

    updateProperty.filter = propertyDetail.propertyId;
    updateProperty.fields = {};
    updateProperty.fields.locked = true;
    updateProperty.fields.locked_ba = this.state.user.nickname;
    updateProperty.fields.locked_at = new Date();
    
    console.log("LOCK!");

    api
      .put("/api/property/update", updateProperty)
      .then(res => {
        const _rows = this.state._rows;
        _rows[index].locked = res.properties.locked;
        _rows[index].locked_ba = res.properties.locked_ba;
        this.setState(
          {
            _rows,
            locked: false,
            locked_ba: "",
            locked_at: null
          },
          async () => {
            const query = encodeURIComponent(
              JSON.stringify({ id: propertyDetail.propertyId })
            );
            const {
              properties: [fetchedProperty]
            } = await api.get(`/api/property?filter=${query}`);
            this.updateRowIndex(fetchedProperty, index);

            const latestUpdateProperty = this.state._rows[index];
            this.setLockedPropertyUtility(latestUpdateProperty, index);
          }
        );
      })
      .catch(console.error);
  }

  // Code for finding the rating of particular BA
  findTheRating(propertyData) {
    let baFoundForRating =
      propertyData.property_rating && propertyData.property_rating.length
        ? propertyData.property_rating.map(({ ba, rating }) => {
          if (ba == this.state.user._id) {
            return rating;
          }
        })
        : [];
    baFoundForRating = baFoundForRating
      .filter(rating => {
        return rating != undefined;
      })
      .sort();

    if (
      !baFoundForRating.length &&
      propertyData.property_rating &&
      propertyData.property_rating.length &&
      this.state.user.senior_associates &&
      this.state.user.senior_associates.length
    ) {
      const { senior_associates } = this.state.user;
      baFoundForRating = propertyData.property_rating.map(({ ba, rating }) => {
        if (senior_associates.indexOf(ba) >= 0) {
          return rating;
        }
      });
    }

    baFoundForRating = baFoundForRating.filter(rating => {
      return rating != undefined;
    });
    return baFoundForRating.sort()[baFoundForRating.length - 1];
  }

  // Not in use
  getWalkScore = property => {
    const location = `location=${JSON.stringify(property.location)}`;
    const address = `address=${JSON.stringify(property.address)}`;

    return api
      .get(`/api/property/getWalkScore?${location}&${address}`)
      .then(({ walkscore }) => JSON.parse(walkscore).walkscore);
  };

  // Not in use
  getPropertyDsr = property => {
    const sua = this.getSUAForProperty(property);

    if (!sua) return;

    return api
      .get(`/api/property/getdsr?filter=${sua.SSC}`)
      .then(({ response }) => {
        const parsedResponse = JSON.parse(response);

        if (_.has(parsedResponse, "rows.length")) {
          return parsedResponse["rows"][0][12];
        }

        return Promise.reject("Not found DSR");
      });
  };

  /**
   * Create Property list for Render in table and Generate CSV -- Manipulate Property Data
   * @param {object} list - properties list
   */

  async createPropertyList(properties, count) {
    let walkScoreArr = [];
    let dsrArr = [];
    let propertiesList = [];
    let timezone = "Australia/Sydney";

    properties.forEach(async (propertyData, index) => {
      /*
            DISABLE THE DSR AND WALKSCORE FETCH AS THEY DO NOT RUN AS DESIRED

            this.getPropertyDsr(propertyData).then(dsr => {
                dsrArr[index] = dsr;
                if (this.state._dsr.length === properties.length) {
                    this.setState({ spinner: false });
                }
            });

            this.getWalkScore(propertyData).then(walkScore => {
                walkScoreArr[index] = walkScore;
            });
            
            // I don't think this will work as the values are fetched asynchronously
            this.setState({ _walkScore: walkScoreArr, _dsr: dsrArr });
            */

      let presentedProperty =
        propertyData.presented && propertyData.presented.length
          ? propertyData.presented.find((presentedClient, index) => {
            let client = propertyData.client ? propertyData.client._id : "";
            let baId = this.props.user.info.userId
              ? this.props.user.info.userId
              : this.props.user.info._id;
            if (
              client &&
              client == presentedClient &&
              propertyData.client.business_analyst._id == baId
            ) {
              return true;
            } else {
              return false;
            }
          })
          : false;
      let masterClient = "",
        daysleft = "";
      if (propertyData.property_phase === "HOT") {
        const engagementWithoutClient =
          propertyData.hotlist && propertyData.hotlist.length
            ? propertyData.hotlist.filter(hotlistElement => {
              return (
                hotlistElement.clientEngagement == null ||
                !Object.keys(hotlistElement.clientEngagement).length
              );
            })
            : [];

        if (engagementWithoutClient.length) {
          const updateProperty = {
            filter: propertyData._id,
            fields: {
              _id: engagementWithoutClient[0]._id,
              interest: "PULL"
            }
          };
          await api
            .put("/api/property/update", updateProperty)
            .then(res => {
              // propertyData.hotlist = res.properties.hotlist;
              this.setState({
                tempHotlist: res.properties.hotlist
              });
            })
            .catch(err => console.log("err", err));
        } else {
          const hotlistEngagement =
            propertyData.hotlist && propertyData.hotlist.length
              ? propertyData.hotlist.filter(hotlistElement => {
                return (
                  hotlistElement.clientEngagement &&
                  Object.keys(hotlistElement.clientEngagement).length
                );
              })
              : "";
          masterClient =
            hotlistEngagement && hotlistEngagement.length
              ? hotlistEngagement[0].clientEngagement.client.first_name +
              " " +
              (hotlistEngagement[0].clientEngagement.client.middle_name ||
                "") +
              " " +
              hotlistEngagement[0].clientEngagement.client.last_name
              : "";

          const compareDate = moment().subtract(
            this.state.business_days.hotlist_days,
            "day"
          )._d;
          daysleft = moment(hotlistEngagement[0].added_date).diff(
            moment(compareDate),
            "days"
          );
        }
      } else if (propertyData.property_phase === "PIG") {
        if (
          propertyData.piglist &&
          propertyData.piglist.length &&
          Object.keys(propertyData.piglist[0].clientEngagement).length
        ) {
          const engagement = propertyData.piglist[0].clientEngagement;
          const engagements_business_analyst =
            engagement && engagement.business_analyst;
          // if (this.state.user._id === engagements_business_analyst._id || _.includes(this.state.user.senior_associates, engagements_business_analyst._id)) {
          masterClient =
            engagement.client.first_name +
            " " +
            (engagement.client.middle_name || "") +
            " " +
            engagement.client.last_name;
          // }
        }
      } else if (propertyData.property_phase === "Presented") {
        if (propertyData.client) {
          // const compareDate = moment().subtract(this.state.business_days.presented_days, 'day')._d;
          daysleft = moment(propertyData.timestamp).diff(moment(), "days");
        }
      }
      let prEngagement = "";
      if (
        propertyData.property_phase === "Presented" ||
        propertyData.property_phase === "Proceed" ||
        propertyData.property_phase === "Purchased" ||
        propertyData.property_phase === "Waiting" ||
        propertyData.property_phase === "Missed"
      ) {
        prEngagement = propertyData.client;
      }

      let state = propertyData.state.toUpperCase();
      timezone = this.calculateTimeZone(state);
      let growthFilled = propertyData.growth ? true : false;

      // Alias lodash's get and has methods
      const get = _.get.bind(null, propertyData);
      const has = _.has.bind(null, propertyData);
      const hasValidDate = path => has(path) && moment(get(path)).isValid();
      const getNullable = (path, defaultVal) =>
        has(path) && (get(path) || defaultVal);

      const clientFirstName = get("client.client.first_name");
      const clientMiddleName = getNullable("client.client.middle_name", "");
      const clientLastName = get("client.client.last_name");
      const clientName = `${clientFirstName} ${clientMiddleName} ${clientLastName}`;

      // Take property values and transform to row values
      const row = {
        id: index + 1,
        prEngagement,
        daysleft,
        growthFilled,
        propertyId: propertyData._id,
        link: propertyData.url,
        price: propertyData.price,
        floodCheck: propertyData.flood_check,
        sold: propertyData.sold,
        beds: propertyData.bed_rooms,
        baths: propertyData.bath_rooms,
        parking: propertyData.parking,
        address: propertyData.address.replace(/,/g, "").trim(),
        flag: propertyData.off_market_flag,
        archive: propertyData.archive,
        presented: presentedProperty,
        editNotes: false,
        client: propertyData.client ? clientName : masterClient,
        propertyPhase: get("property_phase", ""),
        clientId: get("client._id", ""),
        baId: get("client.business_analyst._id", ""),
        buyerAdvocate: get("client.business_analyst.nickname", ""),
        interestedClients: get("interested_client", ""),
        spotter: get("spotter.nickname", ""),
        buyPrice: get("buy_price", ""),
        soldPrice: get("sold_price", ""),
        googleDocLink: get("google_doc", ""),
        sellingPrice: get("selling_price", ""),
        yearBuild: get("year_build", ""),
        inspectedBy: get("inspected_by.nickname", ""),
        purchasedOn: get("purchasedOn", ""),
        engagement_added_date: get("engagement_added_date", ""),
        presented_property_date: get("presented_property_date"), // added line, get the presented date for the property
        proceed_property_date: get("proceed_property_date"), // added line, get the proceed date for the property
        autoValue: get("auto_val", ""),
        fsd: get("FSD", ""),
        lsd: hasValidDate("LSD")
          ? moment(propertyData.LSD).format("DD-MMM-YY")
          : "",
        lsp: get("LSP", ""),
        rent: get("rent", ""),
        yield: get("yields", ""),
        walkScore: get("walk_score", ""),
        dsr: get("DSR", ""),
        vr: get("VR", ""),
        landSize: get("land_size", ""),
        propertyType: get("type", ""),
        timestamp: get("timestamp", ""),
        growth: get("growth", ""),
        suburb: get("suburb", "-"),
        state: get("state", "-").toUpperCase(),
        watchList: get("watchlist", []),
        pigList: get("piglist", []),
        presentedList: get("presented", []),
        hotList: get("tempHotlist") || get("hotlist", []),
        dueDiligence: get("due_diligence", false),
        propertyDog: get("property_dog", false),
        rating: this.findTheRating(propertyData),
        notes: has("notes")
          ? propertyData.notes
          : null,
        comments: has("comments")
          ? propertyData.comments
          : null,
        data: has("data") ? propertyData.data : null,
        comparable_sales: has("comparable_sales")
          ? propertyData.comparable_sales
          : null,
        addedAt: hasValidDate("scraped_date")
          ? moment(propertyData.scraped_date)
            .format("DD-MMM-YY")
            .replace(/,/g, "")
          : "-",
        auctionDate: hasValidDate("auction_date")
          ? moment(propertyData.auction_date)
            .tz(timezone)
            .format("DD-MMM-YY hh:mm a")
            .replace(/,/g, "")
          : "",
        g2gDate: hasValidDate("client.G2G_date")
          ? moment(propertyData.client.G2G_date)
            .format("DD-MMM-YY")
            .replace(/,/g, "")
          : "-",
        under_contract_exp_date: hasValidDate("under_contract_exp_date")
          ? moment(propertyData.under_contract_exp_date).format("DD-MMM-YY")
          : ""
      };

      propertiesList.push(row);

      if (
        propertyData.property_phase === "Purchased" &&
        (propertyData.client && propertyData.client.status == "Conditional")
      ) {
        if (this.checkConditionDatesPassed(propertyData.client)) {
          propertiesList[
            propertiesList.length - 1
          ].conditionalDateExpire = true;
        } else {
          propertiesList[
            propertiesList.length - 1
          ].conditionalDateExpire = false;
        }
      }

      if (
        propertyData.under_contract_exp_date &&
        new Date(propertyData.under_contract_exp_date) > new Date()
      ) {
        propertiesList[propertiesList.length - 1].isUnConExp = false;
      } else if (
        propertyData.under_contract_exp_date &&
        new Date(propertyData.under_contract_exp_date) < new Date()
      ) {
        propertiesList[propertiesList.length - 1].isUnConExp = true;
      }

      if (
        (propertiesList[propertiesList.length - 1].hasOwnProperty(
          "conditionalDateExpire"
        ) &&
          !propertiesList[propertiesList.length - 1].conditionalDateExpire) ||
        (propertiesList[propertiesList.length - 1].hasOwnProperty(
          "isUnConExp"
        ) &&
          !propertiesList[propertiesList.length - 1].isUnConExp)
      ) {
        propertiesList[propertiesList.length - 1].icon = "under_contract_exp";
      } else if (
        propertiesList[propertiesList.length - 1].hasOwnProperty(
          "conditionalDateExpire"
        ) &&
        propertiesList[propertiesList.length - 1].conditionalDateExpire
      ) {
        if (
          propertiesList[propertiesList.length - 1].hasOwnProperty(
            "isUnConExp"
          ) &&
          propertiesList[propertiesList.length - 1].isUnConExp
        ) {
          propertiesList[propertiesList.length - 1].icon = "property_sold";
        } else if (
          propertiesList[propertiesList.length - 1].hasOwnProperty(
            "isUnConExp"
          ) &&
          !propertiesList[propertiesList.length - 1].isUnConExp
        ) {
          propertiesList[propertiesList.length - 1].icon = "under_contract_exp";
        } else if (
          !propertiesList[propertiesList.length - 1].hasOwnProperty(
            "isUnConExp"
          )
        ) {
          propertiesList[propertiesList.length - 1].icon = "property_sold";
        }
      } else if (
        propertiesList[propertiesList.length - 1].hasOwnProperty(
          "isUnConExp"
        ) &&
        propertiesList[propertiesList.length - 1].isUnConExp
      ) {
        if (
          propertiesList[propertiesList.length - 1].hasOwnProperty(
            "conditionalDateExpire"
          ) &&
          propertiesList[propertiesList.length - 1].conditionalDateExpire
        ) {
          propertiesList[propertiesList.length - 1].icon = "property_sold";
        } else if (
          propertiesList[propertiesList.length - 1].hasOwnProperty(
            "conditionalDateExpire"
          ) &&
          !propertiesList[propertiesList.length - 1].conditionalDateExpire
        ) {
          propertiesList[propertiesList.length - 1].icon = "under_contract_exp";
        } else if (
          !propertiesList[propertiesList.length - 1].hasOwnProperty(
            "conditionalDateExpire"
          )
        ) {
          propertiesList[propertiesList.length - 1].icon = "property_sold";
        }
      } else if (
        !propertiesList[propertiesList.length - 1].hasOwnProperty(
          "conditionalDateExpire"
        )
      ) {
        if (
          propertiesList[propertiesList.length - 1].hasOwnProperty(
            "isUnConExp"
          ) &&
          propertiesList[propertiesList.length - 1].isUnConExp
        ) {
          propertiesList[propertiesList.length - 1].icon = "property_sold";
        } else if (
          propertiesList[propertiesList.length - 1].hasOwnProperty(
            "isUnConExp"
          ) &&
          !propertiesList[propertiesList.length - 1].isUnConExp
        ) {
          propertiesList[propertiesList.length - 1].icon = "under_contract_exp";
        } else if (
          !propertiesList[propertiesList.length - 1].hasOwnProperty(
            "isUnConExp"
          )
        ) {
          propertiesList[propertiesList.length - 1].icon = "";
        }
      }
    });

    let pagination = JSON.parse(localStorage.getItem("pagination"));
    let storedProperty =
      pagination && pagination.property ? pagination.property : "";
    localStorage.removeItem("pagination");

    const { search } = browserHistory.getCurrentLocation();
    const { limit = 10 } = qs.parse(search, { ignoreQueryPrefix: true });

    const pageCount = Math.ceil(count / limit);

    this.setState({ _rows: propertiesList, pageCount }, () => {
      destroyBootstrapSwitches("#datatables");
      addBootstrapDataTables("#datatables", false);

      this.defaultCSVGeneration(this.state._rows);

      if (storedProperty) {
        let index = _.findIndex(this.state._rows, function (o) {
          return o.propertyId == storedProperty;
        });
        let property = _.find(this.state._rows, function (o) {
          return o.propertyId == storedProperty;
        });
        if (property) {
          this.showRow(property, index, "edit", this);
        }
      }
      // this.testGeolocation()
    });
  }

  //Method to check wether the conditional dates and Financial dates have been passed or not
  checkConditionDatesPassed({
    conditions: conditionalDate,
    finance_date: financeDate
  }) {
    let areConditionalDatesPassed = false;
    let isFinanceDatePassed = false;
    let formatedDate;
    //Check conditional dates, if they are passed
    if (conditionalDate.length) {
      for (var i = conditionalDate.length - 1; i >= 0; i--) {
        let date = conditionalDate[i];
        formatedDate = moment(date.condition_date).format("DD/MM/YYYY");
        if (
          moment(formatedDate, ["DD/MM/YYYY", "DD-MMM-YYYY"], true)._isValid &&
          new Date() > new Date(date.condition_date)
        ) {
          areConditionalDatesPassed = true;
        } else {
          areConditionalDatesPassed = false;
          break;
        }
      }
      /*conditionalDate.map((date, index) => {
            })*/
    }
    formatedDate = moment(financeDate).format("DD/MM/YYYY");
    if (
      financeDate &&
      moment(formatedDate, ["DD/MM/YYYY", "DD-MMM-YYYY"], true)._isValid &&
      new Date() > new Date(financeDate)
    ) {
      isFinanceDatePassed = true;
    }

    if (financeDate && isFinanceDatePassed && areConditionalDatesPassed) {
      return true;
    } else if (
      financeDate &&
      !isFinanceDatePassed &&
      conditionalDate.length &&
      areConditionalDatesPassed
    ) {
      return false;
    } else if (
      financeDate &&
      !isFinanceDatePassed &&
      conditionalDate.length &&
      !areConditionalDatesPassed
    ) {
      return false;
    } else if (
      financeDate &&
      isFinanceDatePassed &&
      conditionalDate.length &&
      !areConditionalDatesPassed
    ) {
      return false;
    } else if (
      !financeDate &&
      conditionalDate.length &&
      areConditionalDatesPassed
    ) {
      return true;
    } else if (
      !financeDate &&
      conditionalDate.length &&
      !areConditionalDatesPassed
    ) {
      return false;
    } else if (!conditionalDate.length && financeDate && isFinanceDatePassed) {
      return true;
    } else if (!conditionalDate.length && financeDate && !isFinanceDatePassed) {
      return false;
    }
  }

  calculateTimeZone(state) {
    if (state == "VIC" || state == "NSW" || state == "TAS" || state == "ACT") {
      return "Australia/Sydney";
    } else if (state == "QLD") {
      return "Australia/Brisbane";
    } else if (state == "WA") {
      return "Australia/Perth";
    } else {
      return "Australia/Adelaide";
    }
  }

  handleInputChange = ev => {
    const { name, value, checked, type } = ev.target;
    const newValue = type === "checkbox" ? checked : value;

    this.setState({ [name]: newValue });
  };

  handleDatePickerChange = (ev, index) => {
    if (ev && ev._isAMomentObject) {
      let value = "";
      switch (index) {
        case "auctionDate":
          value = ev.format("DD-MMM-YY hh:mm a");
          break;
        case "yearBuild":
          value = ev.format("YYYY");
          break;
        default:
          value = ev.format("DD-MMM-YY");
          break;
      }
      this.setState({ [index]: value })
    }
  };

  /**
   * handle Property Update from Modal Data
   * @param {property Details} data
   * @param {SytheticEvent} event
   */

  updateProperty(data, index, event, states) {
    event.preventDefault();
    if (event.target.value != data[event.target.name] && !states.locked) {
      if (!data.buyPrice && !states.buyPrice) {
        if (this.state.updatePropertyNotification) {
          this.props.showNotification("error", {
            title: "Buy Price Required!",
            message:
              "Buy Price needs to be filled in before you save, please select a value."
          });
        }
      } else {
        this.savePropertyUpdate(data, index, states);
      }
    }
  }

  updatePropertyDate(data, index, event, name, states) {
    let value = "";
    switch (name) {
      case "auctionDate":
        value = states.auctionDate;
        break;
      case "yearBuild":
        value = states.yearBuild;
        break;
      case "under_contract_exp_date":
        value = states.under_contract_exp_date;
        break;
      case "lsd":
        value = states.lsd;
      default:
        break;
    }
    if (value != data[name] && !states.locked) {
      this.savePropertyUpdate(data, index, states);
    }
  }

  async savePropertyUpdate(data, index, states) {
    let timezone = "Australia/Sydney",
      state = data.state.toUpperCase();
    timezone = this.calculateTimeZone(state)

    let property = {};
    property.filter = data.propertyId;
    property.fields = {
      address: states.address,
      auction_date: states.auctionDate
        ? moment
          .tz(
            states.auctionDate,
            ["DD/MM/YYYY hh:mm a", "DD-MMM-YY hh:mm a"],
            true,
            timezone
          )
          .toISOString()
        : "",
      notes: states.notes,
      type: states.propertyType,
      growth: states.growth,
      auto_val: states.autoValue,
      land_size: states.landSize,
      rent: states.rent,
      yields: states.yield,
      walk_score: states.walkScore,
      google_doc: states.googleDocLink,
      FSD: states.fsd,
      LSD:
        states.lsd &&
        moment(
          states.lsd,
          ["DD/MM/YYYY", "DD-MMM-YY"],
          true
        ).toISOString(),
      LSP: states.lsp,
      DSR: states.dsr,
      VR: states.vr,
      buy_price: states.buyPrice,
      sold_price: states.soldPrice,
      year_build: states.yearBuild,
      property_dog: states.propertyDog,
      under_contract_exp_date:
        states.under_contract_exp_date &&
        moment(
          states.under_contract_exp_date,
          ["DD/MM/YYYY", "DD-MMM-YY"],
          true
        ).toISOString(),
      flood_check: states.floodCheck,
      parking: states.parking,
      bath_rooms: states.baths,
      bed_rooms: states.beds,
      price: states.price,
      comments: states.comments,
      data: states.data,
      comparable_sales: states.comparable_sales
    };

    let notChangedField = _.pickBy(property.fields, null);
    let keys = _.keys(notChangedField);
    keys = _.remove(
      keys,
      key =>
        key != "sold_price" &&
        key != "auction_date" &&
        key != "FSD" &&
        key != "year_build" &&
        key != "google_doc" &&
        key != "property_dog" &&
        key != "under_contract_exp_date" &&
        key != "flood_check"
    );
    let length = Object.keys(keys).length;
    if (length >= 12) {
      property.fields["due_diligence"] = true;
    } else {
      property.fields["due_diligence"] = false;
    }

    if (data.auctionDate != states.auctionDate) {
      property.fields["potential_purchase_date"] = moment
        .tz(
          states.auctionDate,
          ["DD/MM/YYYY hh:mm a", "DD-MMM-YY hh:mm a"],
          true,
          timezone
        )
        .toISOString();
    }
    await api.put("/api/property/update", property).then(result => {
      if (result.notify) {
        alert(result.notify);
      } else {
        if (this.state.updatePropertyNotification) {
          this.props.showNotification("success", {
            title: "Success!",
            message: "Edit Successfully."
          });
        }

        this.updateRowIndex(result.properties, index);
      }
    });
  }

  // Earlier handles the watchlist but now handle PIG because FRWL phase removed
  async manageWatchlist(data, index, pigEngagement, row, event) {
    console.log(
      "data, index, pigEngagement, row",
      data,
      index,
      pigEngagement,
      row
    );
    let matter = "";
    /* if property is in phase other than PIG or HOT, then it should not become PIG or HOT */
    if (
      data.clientId &&
      (data.propertyPhase == "Presented" ||
        data.propertyPhase == "Proceed" ||
        data.propertyPhase == "Purchased")
    ) {
      if (this.state.updatePropertyNotification) {
        this.props.showNotification("warning", {
          title: "Warning!",
          message: `Property is in ${data.propertyPhase
            } phase please move engagement further or in queue`
        });
      }
    } else if (data.clientId && data.propertyPhase == "Missed") {
      this.ChangePropertyClient(data, index);
    } else {
      const propertyToUpdate = {};
      propertyToUpdate.filter = data.propertyId;
      const fetchPigEngagements = _.map(data.pigList, "clientEngagement");
      let newPigList = _.map(fetchPigEngagements, "_id");
      let changePhase = "";

      if (pigEngagement) {
        newPigList = pigEngagement;
      }

      const foundEngagement = data.pigList.find(
        engagement => engagement.clientEngagement._id === pigEngagement
      );
      if (foundEngagement) {
        /* If engagement already found in PIG list, then user would tried to remove it from the list */
        newPigList = data.pigList.filter(
          engagement => engagement.clientEngagement._id !== pigEngagement
        );
        newPigList = newPigList.map(element => {
          return {
            clientEngagement: element.clientEngagement._id,
            added_date: element.added_date
          };
        });
        matter = "Detached"; /* set matter to detached */
      } else {
        /* If engagement is not found, then user tried to put engagement into PIG */
        newPigList = data.pigList.map(engagement => ({
          clientEngagement: engagement.clientEngagement._id,
          added_date: engagement.added_date
        }));
        newPigList.push({ clientEngagement: pigEngagement });
        matter = "Attached";
      }

      /* Property fields to update */
      propertyToUpdate.fields = {
        piglist: newPigList,
        property_phase: data.propertyPhase != "HOT" ? "PIG" : "HOT"
      };
      const validBa = await this.verifyClientBA(pigEngagement, "PIG");
      if (validBa.success) {
        const propertyUpdateResult = await this.updatePropertyDetails(
          propertyToUpdate
        );
        if (propertyUpdateResult.notify) {
          alert(propertyUpdateResult.notify);
        } else {
          propertyUpdateResult.properties.pigList =
            propertyUpdateResult.properties.piglist;
          const propertyDetail = { ...data };

          if (this.state.updatePropertyNotification) {
            this.props.showNotification("success", {
              title: "Success!",
              message: "PIG List updated."
            });
          }

          this.addAuditHistory(
            pigEngagement,
            propertyDetail,
            propertyUpdateResult,
            matter
          );
          this.updateRowIndex(propertyUpdateResult.properties, index);
          this.updateEngagementUi(
            propertyUpdateResult.properties,
            "PIG",
            matter,
            pigEngagement
          );
        }
      } else {
        if (this.state.updatePropertyNotification) {
          this.props.showNotification("error", {
            title: "Not allowed",
            message: "This engagement is of another client"
          });
        }
      }
    }
  }

  async manageHotlist(data, index, hotEngagement, row) {
    if (
      data.clientId &&
      (data.propertyPhase == "Presented" ||
        data.propertyPhase == "Proceed" ||
        data.propertyPhase == "Purchased")
    ) {
      if (this.state.updatePropertyNotification) {
        this.props.showNotification("warning", {
          title: "Warning!",
          message: `Property is in ${data.propertyPhase
            } phase please move engagement further or in queue`
        });
      }
    } else if (data.clientId && data.propertyPhase == "Missed") {
      this.ChangePropertyClient(data, index);
    } else {
      const propertyToUpdate = {};
      propertyToUpdate.filter = data.propertyId;
      propertyToUpdate.fields = {};
      const fetchClients = _.map(data.hotList, "clientEngagement");
      let newHotList = _.map(fetchClients, "_id");
      let changePhase = "";
      let matter = "";
      if (hotEngagement) {
        newHotList = hotEngagement;
      }

      const foundEngagement = data.hotList.find(
        engagement => engagement.clientEngagement._id === hotEngagement
      );
      if (foundEngagement) {
        newHotList = data.hotList.filter(
          engagement => engagement.clientEngagement._id !== hotEngagement
        );
        newHotList = newHotList.map(element => {
          return {
            clientEngagement: element.clientEngagement._id,
            added_date: element.added_date
          };
        });
        matter = "Detached";
        if (data.hotList.length && data.hotList.length == 1) {
          propertyToUpdate.fields.property_phase = "PIG";
        }
      } else {
        newHotList = data.hotList.map(engagement => ({
          clientEngagement: engagement.clientEngagement._id,
          added_date: engagement.added_date
        }));
        newHotList.push({ clientEngagement: hotEngagement });
        matter = "Attached";
        propertyToUpdate.fields.property_phase = "HOT";
      }
      propertyToUpdate.fields.hotlist = newHotList;
      const validBa = await this.verifyClientBA(hotEngagement, "HOT");
      if (validBa.success) {
        const engagement = this.state.fetchedEngagementsList.find(
          element => element._id == hotEngagement
        );
        if (engagement && engagement.status != "Incubation") {
          const propertyUpdateResult = await this.updatePropertyDetails(
            propertyToUpdate
          );
          if (propertyUpdateResult.notify) {
            alert(propertyUpdateResult.notify);
          } else {
            propertyUpdateResult.properties.hotList =
              propertyUpdateResult.properties.hotlist;
            const propertyDetail = { ...data };

            if (this.state.updatePropertyNotification) {
              this.props.showNotification("success", {
                title: "Success!",
                message: "HOT List updated."
              });
            }

            this.addAuditHistory(
              hotEngagement,
              propertyDetail,
              propertyUpdateResult,
              matter
            );
            this.updateRowIndex(
              propertyUpdateResult.properties,
              index,
              propertyToUpdate.fields.property_phase
            );
            this.updateEngagementUi(
              propertyUpdateResult.properties,
              "HOT",
              matter,
              hotEngagement
            );
          }
        } else {
          if (this.state.updatePropertyNotification) {
            this.props.showNotification("warning", {
              title: "warning",
              message: "Incubation engagement can`t move in HOT Phase"
            });
          }
        }
      } else {
        if (this.state.updatePropertyNotification) {
          this.props.showNotification("error", {
            title: "Not allowed",
            message: "This engagement is of another client"
          });
        }
      }
    }
  }

  // Method to update the property details
  updatePropertyDetails(updateProperty) {
    return api.put("/api/property/update", updateProperty);
  }

  // Method for updating engagement, for audit history
  addAuditHistory(
    engagement,
    propertyDetail,
    updatedPropertyDetail,
    matter,
    phase
  ) {
    const updateEngagement = {};
    updateEngagement.filter = engagement;
    updateEngagement.interest = matter;
    updateEngagement.fields = {
      audit_trail: {
        property: propertyDetail.propertyId,
        property_phase: {
          from: propertyDetail.propertyPhase,
          to: phase ? phase : updatedPropertyDetail.properties.property_phase
        },
        matter,
        business_analyst: this.state.user._id
      }
    };

    api
      .put("/api/clientPurchases/", updateEngagement)
      .then(response => { })
      .catch(error => console.log("error", error));
  }

  checkQueueList(data, index, phase, engagement, event) {
    const foundIncubation = engagement
      ? this.state.fetchedEngagementsList.filter((fetchedEngagement, index) => {
        return (
          fetchedEngagement._id == engagement &&
          fetchedEngagement.status == "Incubation"
        );
      })
      : "";
    if (data.propertyPhase !== "Presented" || data.clientId == engagement) {
      if (this.state.updatePropertyNotification) {
        this.props.showNotification("warning", {
          title: "Wrong phase",
          message:
            data.clientId !== engagement
              ? "Property should be in Presented phase"
              : "Engagement already in Presented phase"
        });
      }
    } else if (foundIncubation && foundIncubation.length) {
      if (this.state.updatePropertyNotification) {
        this.props.showNotification("info", {
          title: "Incubation Client",
          message:
            "Incubation Client can`t be in Hotlist, Presented or in Queue. It can only be PIG, Proceed and then Purchased"
        });
      }
    } else {
      let matter = "";
      let queue =
        data.interestedClients && data.interestedClients.length
          ? _.map(data.interestedClients, "_id")
          : [];
      if (queue && queue.length && queue.indexOf(engagement) > -1) {
        queue = queue.filter(id => id != engagement);
        matter = "Detached";
      } else {
        queue.push(engagement);
        matter = "Attached";
      }
      let checkPresented =
        data.presentedList.length && _.intersection(data.presentedList, queue);
      if (!checkPresented.length) {
        this.updateQueueList(data, queue, index, matter, engagement);
      } else {
        this.setState({
          modalIsOpen: false
        });

        if (this.state.updatePropertyNotification) {
          const notifyData = {
            title: "Already Presented!",
            message:
              "This property has been presented to this client before.If you still want to proceed.",
            children: (
              <div className="row">
                <div className="col-xs-12">
                  <div className="col-xs-6">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={this.updateQueueList.bind(
                        this,
                        data,
                        queue,
                        index,
                        matter,
                        engagement
                      )}
                    >
                      Yes
                    </button>
                  </div>
                  <div className="col-xs-6">
                    <button type="button" className="btn btn-warning">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )
          };

          this.props.showNotification("warning", notifyData);
        }
      }
    }
  }

  async updateQueueList(data, engagement, index, matter, clickedEngagement) {
    let property = {};
    const _rows = this.state._rows;
    property.filter = data.propertyId;
    property.interest = "push";
    property.fields = {
      // interested_client: this.state.selectedDualList
      interested_client: engagement
    };
    const validBa = await this.verifyClientBA(clickedEngagement, "Presented");
    if (validBa.success) {
      this.reassignProperty(property, result => {
        if (result.success) {
          const notifyData = {};

          if (matter === "Attached") {
            this.updateEngagementUi(
              result.properties,
              "Queue",
              "Attached",
              clickedEngagement
            );
            notifyData.title = "Moved to Queue List!";
            notifyData.message = "Your client is in Queue.";
          } else {
            this.updateEngagementUi(
              result.properties,
              "Queue",
              "Detached",
              clickedEngagement
            );
            notifyData.title = "Detached from Queue List!";
            notifyData.message = "Detachment from queue list";
          }

          _rows[index].interestedClients = result.properties.interested_client;

          if (this.state.updatePropertyNotification) {
            this.props.showNotification("info", notifyData);
          }

          this.setState({ modalIsOpen: false });
          this.cb = null;
        }
      });
    } else {
      if (this.state.updatePropertyNotification) {
        this.props.showNotification("error", {
          title: "Not allowed",
          message: "This engagement is of another client"
        });
      }
    }
  }

  updateRowIndex(property, index, value) {
    const _rows = this.state._rows;
    let timezone = "Australia/Sydney";
    let state = property.state.toUpperCase();
    timezone = this.calculateTimeZone(state);
    let masterClient = "";

    if (property.property_phase === "HOT" || value === "HOT") {
      let firstClient =
        property.hotlist &&
        property.hotlist.length &&
        property.hotlist[0].clientEngagement;
      masterClient = firstClient
        ? firstClient.client.first_name +
        " " +
        (firstClient.client.middle_name
          ? firstClient.client.middle_name
          : "") +
        " " +
        firstClient.client.last_name
        : "";
      _rows[index].client = masterClient;
      _rows[index].buyerAdvocate =
        firstClient && firstClient.business_analyst
          ? firstClient.business_analyst.nickname
          : "";
    }

    if (property.property_phase === "PIG" || value === "PIG") {
      let firstClient =
        property.piglist &&
        property.piglist.length &&
        property.piglist[0].clientEngagement;
      masterClient = firstClient
        ? firstClient.client.first_name +
        " " +
        (firstClient.client.middle_name
          ? firstClient.client.middle_name
          : "") +
        " " +
        firstClient.client.last_name
        : "";
      _rows[index].client = masterClient;
      _rows[index].buyerAdvocate =
        firstClient && firstClient.business_analyst
          ? firstClient.business_analyst.nickname
          : "";
    }
    _rows[index].address = property.address ? property.address : "";
    _rows[index].beds = property.bed_rooms ? property.bed_rooms : "";
    _rows[index].baths = property.bath_rooms ? property.bath_rooms : "";
    _rows[index].parking = property.parking ? property.parking : "";
    _rows[index].price = property.price ? property.price : "";
    _rows[index].growth = property.growth ? property.growth : "";
    _rows[index].formattedAddress = property.formatted_address
      ? property.formatted_address
      : "";
    _rows[index].growthFilled = property.growth ? true : false;
    _rows[index].propertyType = property.type ? property.type : "";
    _rows[index].autoValue = property.auto_val ? property.auto_val : "";
    _rows[index].fsd = property.FSD ? property.FSD : "";
    _rows[index].lsd = property.LSD
      ? moment(property.LSD).format("DD-MMM-YY")
      : "";
    _rows[index].lsp = property.LSP;
    _rows[index].rent = property.rent ? property.rent : "";
    _rows[index].yield = property.yields ? property.yields : "";
    _rows[index].walkScore = property.walk_score ? property.walk_score : "";
    _rows[index].dsr = property.DSR ? property.DSR : "";
    _rows[index].vr = property.VR ? property.VR : "";
    _rows[index].landSize = property.land_size ? property.land_size : "";
    _rows[index].auctionDate = property.auction_date
      ? moment(property.auction_date)
        .tz(timezone)
        .format("DD-MMM-YY hh:mm a")
        .replace(/,/g, "")
      : "";
    _rows[index].propertyPhase = property.property_phase || "";
    _rows[index].watchList =
      property.watchlist && property.watchlist.length ? property.watchlist : [];
    _rows[index].hotList =
      property.hotlist && property.hotlist.length ? property.hotlist : [];
    _rows[index].pigList =
      property.piglist && property.piglist.length ? property.piglist : [];
    _rows[index].googleDocLink = property.google_doc ? property.google_doc : "";
    _rows[index].notes = property.notes
      ? property.notes
      : "";
    _rows[index].comments = property.comments
      ? property.comments
      : "";
    _rows[index].data = property.data
      ? property.data
      : "";
    _rows[index].comparable_sales = property.comparable_sales
      ? property.comparable_sales
      : "";
    _rows[index].yearBuild = property.year_build ? property.year_build : "";
    _rows[index].buyPrice = property.buy_price ? property.buy_price : "";
    _rows[index].soldPrice = property.sold_price ? property.sold_price : "";
    _rows[index].sold = property.sold == true ? !property.sold : property.sold;
    _rows[index].dueDiligence = property.due_diligence
      ? property.due_diligence
      : false;
    _rows[index].editNotes = false;
    _rows[index].propertyDog = property.property_dog || false;
    _rows[index].under_contract_exp_date = property.under_contract_exp_date
      ? moment(property.under_contract_exp_date).format("DD-MMM-YY")
      : "";
    _rows[index].floodCheck = property.flood_check;
    _rows[index].rating = this.findTheRating(property);
    _rows[index].client =
      property.client && property.client.client
        ? property.client.client.first_name +
        " " +
        (property.client.client.middle_name
          ? property.client.client.middle_name
          : "") +
        " " +
        property.client.client.last_name
        : masterClient;
    _rows[index].clientId = property.client ? property.client._id : "";
    _rows[index].baId =
      property.client && property.client.business_analyst._id
        ? property.client.business_analyst._id
        : "";
    _rows[index].buyerAdvocate =
      property.client && property.client.business_analyst.nickname
        ? property.client.business_analyst.nickname
        : "";
    _rows[index].prEngagement = property.client;
    _rows[index].engagement_added_date = property.engagement_added_date || "";
    _rows[index].presented_property_date =
      property.presented_property_date || null; // added line for the presented date of the property
    _rows[index].proceed_property_date = property.proceed_property_date || null; // added line for the proceed date of the property
    _rows[index].purchasedOn = property.purchasedOn || "";
    _rows[index].locked =
      property.locked && property.locked_ba !== this.state.user.nickname
        ? true
        : property.locked; // If property is locked set, true
    _rows[index].locked_ba = property.locked_ba ? property.locked_ba : ""; //
    _rows[index].locked_ba_id =
      this.props.buyers &&
      this.props.buyers.length &&
      this.props.buyers.find(buyer => buyer.nickname === property.locked_ba); //

    this.setState({ _rows }, () => {
      // this.showRow(_rows[index],index, "edit", this)
      var myTextEl = document.getElementById("notes-here" + property._id);
      if (myTextEl) {
        myTextEl.innerHTML = this.autolinker.link(property.notes);
      }
    });
  }

  // Set client list for Add Property, Edit Property
  setAddModal(property) {
    const filter = { selectEngagement: "associates" };
    api
      .get(
        "/api/clientPurchases/list?filter=" +
        encodeURIComponent(JSON.stringify(filter)) +
        "&&status=" +
        encodeURIComponent(JSON.stringify(["GoodToGo", "Incubation"]))
      )
      .then(result => {
        if (result.notify) {
          alertify.alert("", result.notify);
        } else {
          if (property) {
            const fetchedEngagementsList = _.sortBy(
              result.purchases,
              "G2G_date"
            );
            const propertyPigList = property.pigList.length
              ? _.compact(
                property.pigList.map(element => element.clientEngagement._id)
              )
              : [];
            const propertyHotList = property.hotList.length
              ? _.compact(
                property.hotList.map(element => element.clientEngagement._id)
              )
              : [];
            const propertyPrsentedProceedPurchasedEngagement = property.clientId
              ? [].concat(property.prEngagement._id)
              : [];
            const engagementsToSelect = [];
            let manageEngagementsList = [];
            let selectedDualList = [];
            let extractDifferentBasFromEngagements = [];

            const allMyEngagements = _.map(fetchedEngagementsList, "_id"); // Associate + Loged In BA`s engagements
            if (propertyPigList.length) {
              const pigList =
                property.pigList && property.pigList.length
                  ? _.map(property.pigList, "clientEngagement")
                  : [];
              this.setEngagementsForSelection(
                pigList,
                fetchedEngagementsList,
                "PIG",
                property.propertyPhase
              );
              this.handleEngagementUi(property, "PIG");
            }

            /* A check to be correct from the backend */
            if (propertyHotList.length) {
              const hotList =
                property.hotList && property.hotList.length
                  ? _.map(property.hotList, "clientEngagement")
                  : [];
              this.setEngagementsForSelection(
                hotList,
                fetchedEngagementsList,
                "HOT",
                property.propertyPhase
              );
              this.handleEngagementUi(property, "HOT");
            }

            if (
              property &&
              (property.propertyPhase === "Presented" ||
                property.propertyPhase === "Proceed" ||
                property.propertyPhase === "Purchased")
            ) {
              this.setEngagementsForSelection(
                null,
                fetchedEngagementsList,
                null,
                property.propertyPhase,
                property.prEngagement
              );
              this.handleEngagementUi(property, property.propertyPhase);
              if (
                property.interestedClients &&
                property.interestedClients.length
              ) {
                this.handleEngagementUi(property, "Queue");
              }
            }

            if (
              property &&
              property.propertyPhase == "Presented" &&
              property.interestedClients &&
              property.interestedClients.length
            ) {
              this.handleEngagementUi(property, "Queue");
            }

            if (property && property.propertyPhase == "Missed") {
              console.log("property.propertyPhase", property.propertyPhase);
              this.handleEngagementUi(property, "Missed");
              if (
                property.interestedClients &&
                property.interestedClients.length
              ) {
                this.handleEngagementUi(property, "Queue");
              }
            }

            if (property && property.propertyPhase == "Waiting") {
              this.handleEngagementUi(property, "Waiting");
              console.log(
                "property.interestedClients",
                JSON.parse(JSON.stringify(property.interestedClients))
              );
              if (
                property.interestedClients &&
                property.interestedClients.length
              ) {
                // property.interestedClients.shift();
                this.handleEngagementUi(property, "Queue");
              }
            }

            if (!this.state.engagementsToSelect.length) {
              this.setEngagementsForSelection(null, fetchedEngagementsList);
            }

            this.setState({
              fetchedEngagementsList,
              allMyEngagements
            });
          }
        }
      });
  }

  /**
   * handle
   * @param {object} prEngagement Engagement that is in Proceed, Purchased, or Presented
   */

  setEngagementsForSelection(
    engagementList,
    fetchedEngagementsList,
    localPhase,
    originalPhase,
    prEngagement
  ) {
    let manageEngagementsList = "",
      extractDifferentBasFromEngagements = "";
    const engagementsToSelect = [];
    if (engagementList) {
      manageEngagementsList = _.uniqBy(
        engagementList.concat(...fetchedEngagementsList),
        "_id"
      );
      extractDifferentBasFromEngagements = _.map(
        manageEngagementsList,
        "business_analyst"
      );
      extractDifferentBasFromEngagements = _.map(
        extractDifferentBasFromEngagements,
        "_id"
      );
      extractDifferentBasFromEngagements = _.uniq(
        extractDifferentBasFromEngagements,
        "_id"
      );
    } else {
      extractDifferentBasFromEngagements = _.map(
        fetchedEngagementsList,
        "business_analyst"
      );
      extractDifferentBasFromEngagements = _.map(
        extractDifferentBasFromEngagements,
        "_id"
      );
      extractDifferentBasFromEngagements = _.uniq(
        extractDifferentBasFromEngagements,
        "_id"
      );

      manageEngagementsList = fetchedEngagementsList;
    }

    if (engagementList) {
      manageEngagementsList = manageEngagementsList.filter(element => {
        return !engagementList.find(
          engagement => engagement._id == element._id
        );
      });
    } else if (
      originalPhase == "Presented" ||
      originalPhase == "Proceed" ||
      originalPhase == "Purchased"
    ) {
      manageEngagementsList = manageEngagementsList.filter(
        engagement => engagement._id != prEngagement._id
      );
    }

    extractDifferentBasFromEngagements.forEach(buyer => {
      let buyerName;
      let filterList = _.map(manageEngagementsList, (element, index) => {
        if (
          !element.property &&
          (element.business_analyst && element.business_analyst._id === buyer)
        ) {
          buyerName =
            element.business_analyst.nickname ||
            element.business_analyst.username;
          return {
            value: element._id,
            label: element.labelForScrapper
          };
        }
      });
      console.log("filterList", filterList);
      filterList = _.compact(filterList);
      console.log("filterList", filterList);
      engagementsToSelect.push({
        groupBy: buyerName,
        options: filterList
      });
    });

    this.setState({ engagementsToSelect, manageEngagementsList });
  }

  // Method to handle the engagement UI
  handleEngagementUi(propertyDetail, localPhase) {
    if (localPhase === "PIG") {
      propertyDetail.pigList.forEach(engagement => {
        const { clientEngagement } = engagement;
        const added_date = engagement.added_date
          ? moment(engagement.added_date)
            .format("DD-MMM-YY")
            .replace(/,/g, "")
          : "";
        const foundIndex = this.state.grid.findIndex((row, index) => {
          return row[0]._id === clientEngagement._id;
        });

        if (foundIndex > -1) {
          const alreadyExistsRow = [...this.state.grid[foundIndex]];
          alreadyExistsRow.splice(1, 1, {
            value: added_date,
            name: "added_date"
          });
          const grid = this.state.grid.filter(row => row.length);
          grid[foundIndex] = alreadyExistsRow;
          this.setState({ grid }, () => {
            JSON.parse(JSON.stringify(this.state.grid));
          });
        } else {
          const row = [];
          this.state.grid[0].map((elem, index) => {
            if (elem.name == "label") {
              row.push({
                value: clientEngagement.labelForScrapper,
                _id: clientEngagement._id,
                name: "label"
              });
            } else if (elem.value == localPhase) {
              row.splice(index, 0, { value: added_date, name: "added_date" });
            } else {
              row.push({});
            }
          });
          const grid = this.state.grid.filter(row => row.length);
          grid.push(row);
          this.setState({ grid }, () => { });
        }
      });
    }

    if (localPhase === "HOT") {
      propertyDetail.hotList.forEach(engagement => {
        const { clientEngagement } = engagement;
        const added_date = engagement.added_date
          ? moment(engagement.added_date)
            .format("DD-MMM-YY")
            .replace(/,/g, "")
          : "";

        const foundIndex = this.state.grid.findIndex((row, index) => {
          return row[0]._id === clientEngagement._id;
        });

        if (foundIndex > -1) {
          const alreadyExistsRow = [...this.state.grid[foundIndex]];
          alreadyExistsRow.splice(2, 1, {
            value: added_date,
            name: "added_date"
          });
          const grid = this.state.grid.filter(row => row.length);
          grid[foundIndex] = alreadyExistsRow;
          this.setState({ grid });
        } else {
          const row = [];
          this.state.grid[0].map((elem, index) => {
            if (elem.name == "label") {
              row.push({
                value: clientEngagement.labelForScrapper,
                _id: clientEngagement._id,
                name: "label"
              });
            } else if (elem.value == localPhase) {
              row.splice(index, 0, { value: added_date, name: "added_date" });
            } else {
              row.push({});
            }
          });
          const grid = this.state.grid.filter(row => row.length);
          grid.push(row);
          this.setState({ grid });
        }
      });
    }

    if (
      localPhase == "Presented" ||
      localPhase == "Proceed" ||
      localPhase == "Purchased"
    ) {
      const row = [];
      const clientEngagement = propertyDetail.prEngagement;



      if (localPhase == "Presented" || propertyDetail.presented_property_date) {
        const foundIndex = this.state.grid.findIndex((row, index) => {
          return row[0]._id === clientEngagement._id;
        });


        if (foundIndex > -1) {
          const alreadyExistsRow = [...this.state.grid[foundIndex]];
          alreadyExistsRow.splice(3, 1, {
            value:
              propertyDetail.presented_property_date != ""
                ? moment(propertyDetail.presented_property_date)
                  .format("DD-MMM-YY")
                  .replace(/,/g, "")
                : "",
            name: "timestamp"
          });
          const grid = this.state.grid.filter(row => row.length);
          grid[foundIndex] = alreadyExistsRow;
          this.setState({ grid }, () => {
            console.log(JSON.parse(JSON.stringify(this.state.grid)));
          });
        } else {
          this.state.grid[0].map((elem, index) => {
            if (elem.name == "label") {
              row.push({
                value: clientEngagement.labelForScrapper,
                _id: clientEngagement._id,
                name: "label"
              });
            } else if (elem.value == localPhase || (propertyDetail.presented_property_date && elem.value === "Presented")) {
              row.splice(index, 0, {
                value:
                  propertyDetail.presented_property_date != ""
                    ? moment(propertyDetail.presented_property_date)
                      .format("DD-MMM-YY")
                      .replace(/,/g, "")
                    : "Presented",
                name: "timestamp"
              });
            } else {
              row.push({});
            }
          });

          const grid = this.state.grid.filter(row => row.length);
          grid.push(row);
          this.setState({ grid });
        }
      }

      if (localPhase == "Proceed" || propertyDetail.proceed_property_date) {
        const foundIndex = this.state.grid.findIndex((row, index) => {
          return row[0]._id === clientEngagement._id;
        });

        if (foundIndex > -1) {
          const alreadyExistsRow = [...this.state.grid[foundIndex]];
          alreadyExistsRow.splice(4, 1, {
            value: propertyDetail.proceed_property_date
              ? moment(propertyDetail.proceed_property_date)
                .format("DD-MMM-YY")
                .replace(/,/g, "")
              : "Proceed",
            name: "timestamp"
          });
          const grid = this.state.grid.filter(row => row.length);
          grid[foundIndex] = alreadyExistsRow;
          this.setState({ grid });
        } else {
          this.state.grid[0].map((elem, index) => {
            if (elem.name == "label") {
              row.push({
                value: clientEngagement.labelForScrapper,
                _id: clientEngagement._id,
                name: "label"
              });
            } else if (elem.value == localPhase) {
              row.splice(index, 0, {
                value: propertyDetail.proceed_property_date
                  ? moment(propertyDetail.proceed_property_date)
                    .format("DD-MMM-YY")
                    .replace(/,/g, "")
                  : "Proceed",
                name: "timestamp"
              });
            } else {
              row.push({});
            }
          });
          const grid = this.state.grid.filter(row => row.length);
          grid.push(row);
          this.setState({ grid });
        }
      }

      if (localPhase == "Purchased") {
        const foundIndex = this.state.grid.findIndex((row, index) => {
          return row[0]._id === clientEngagement._id;
        });
        if (foundIndex > -1) {
          const alreadyExistsRow = [...this.state.grid[foundIndex]];
          alreadyExistsRow.splice(5, 1, {
            value:
              propertyDetail.purchasedOn != ""
                ? moment(propertyDetail.purchasedOn)
                  .format("DD-MMM-YY")
                  .replace(/,/g, "")
                : "Purchased",
            name: "timestamp"
          });
          const grid = this.state.grid.filter(row => row.length);
          grid[foundIndex] = alreadyExistsRow;
          this.setState({ grid });
        } else {
          this.state.grid[0].map((elem, index) => {
            if (elem.name == "label") {
              row.push({
                value: clientEngagement.labelForScrapper,
                _id: clientEngagement._id,
                name: "label"
              });
            } else if (elem.value == localPhase) {
              row.splice(index, 0, {
                value:
                  propertyDetail.purchasedOn != ""
                    ? moment(propertyDetail.purchasedOn)
                      .format("DD-MMM-YY")
                      .replace(/,/g, "")
                    : "Purchased",
                name: "timestamp"
              });
            } else {
              row.push({});
            }
          });

          const grid = this.state.grid.filter(row => row.length);
          grid.push(row);
          this.setState({ grid });
        }
      }

    }

    if (localPhase == "Queue") {
      console.log("in queue", propertyDetail);
      propertyDetail.interestedClients.forEach(engagement => {
        if (engagement._id != propertyDetail.prEngagement._id) {
          const clientEngagement = engagement;
          // const added_date = engagement.added_date ? moment(engagement.added_date).format('DD-MMM-YY').replace(/,/g, "") : "";
          const foundIndex = this.state.grid.findIndex((row, index) => {
            return row.length && row[0]._id === clientEngagement._id;
          });
          console.log(
            "clientEngagement",
            clientEngagement,
            "foundIndex",
            foundIndex
          );

          if (foundIndex > -1) {
            const alreadyExistsRow = [...this.state.grid[foundIndex]];
            alreadyExistsRow.splice(6, 1, {
              value: "Queued",
              name: "timestamp"
            });
            const grid = this.state.grid.filter(row => row.length);
            grid[foundIndex] = alreadyExistsRow;
            this.setState({ grid }, () => {
              console.log(JSON.parse(JSON.stringify(this.state.grid)));
            });
          } else {
            const row = [];
            this.state.grid[0].map((elem, index) => {
              if (elem.name == "label") {
                row.push({
                  value: clientEngagement.labelForScrapper,
                  _id: clientEngagement._id,
                  name: "label"
                });
              } else if (elem.value == localPhase) {
                row.splice(index, 0, { value: "Queued", name: "timestamp" });
              } else {
                row.push({});
              }
            });
            const grid = this.state.grid.filter(row => row.length);
            grid.push(row);
            this.setState({ grid }, () => {
              console.log(JSON.parse(JSON.stringify(this.state.grid)));
            });
          }
        }
      });
    }
    if (localPhase == "Waiting") {
      const row = [];
      const clientEngagement = propertyDetail.prEngagement;
      // const clientEngagement = propertyDetail.interestedClients && propertyDetail.interestedClients.length ? propertyDetail.interestedClients.shift() : "";
      if (clientEngagement != "") {
        const foundIndex = this.state.grid.findIndex((row, index) => {
          return row[0]._id === clientEngagement._id;
        });

        if (foundIndex > -1) {
          const alreadyExistsRow = [...this.state.grid[foundIndex]];
          alreadyExistsRow.splice(7, 1, {
            value: propertyDetail.timestamp
              ? moment(propertyDetail.timestamp)
                .format("DD-MMM-YY")
                .replace(/,/g, "")
              : "Waiting",
            name: "timestamp"
          });
          const grid = this.state.grid.filter(row => row.length);
          grid[foundIndex] = alreadyExistsRow;
          this.setState({ grid }, () => {
            console.log(JSON.parse(JSON.stringify(this.state.grid)));
          });
        } else {
          this.state.grid[0].map((elem, index) => {
            if (elem.name == "label") {
              row.push({
                value: clientEngagement.labelForScrapper,
                _id: clientEngagement._id,
                name: "label"
              });
            } else if (elem.value == localPhase) {
              row.splice(index, 0, {
                value: propertyDetail.timestamp
                  ? moment(propertyDetail.timestamp)
                    .format("DD-MMM-YY")
                    .replace(/,/g, "")
                  : "Waiting",
                name: "timestamp"
              });
            } else {
              row.push({});
            }
          });
          const grid = this.state.grid.filter(row => row.length);
          grid.push(row);
          this.setState({ grid }, () => {
            console.log(JSON.parse(JSON.stringify(this.state.grid)));
          });
        }
      }
    }
    if (localPhase == "Missed") {
      const row = [];
      const clientEngagement = propertyDetail.prEngagement;
      console.log("clientEngagement", clientEngagement);
      // const clientEngagement = propertyDetail.interestedClients && propertyDetail.interestedClients.length ? propertyDetail.interestedClients.shift() : "";
      if (clientEngagement != "") {
        const foundIndex = this.state.grid.findIndex((row, index) => {
          return row[0]._id === clientEngagement._id;
        });



        if (foundIndex > -1) {
          const alreadyExistsRow = [...this.state.grid[foundIndex]];
          alreadyExistsRow.splice(8, 1, {
            value: propertyDetail.timestamp
              ? moment(propertyDetail.timestamp)
                .format("DD-MMM-YY")
                .replace(/,/g, "")
              : "Missed",
            name: "timestamp"
          });
          const grid = this.state.grid.filter(row => row.length);
          grid[foundIndex] = alreadyExistsRow;
          this.setState({ grid }, () => {
            console.log(JSON.parse(JSON.stringify(this.state.grid)));
          });
        } else {
          this.state.grid[0].map((elem, index) => {
            if (elem.name == "label") {
              row.push({
                value: clientEngagement.labelForScrapper,
                _id: clientEngagement._id,
                name: "label"
              });
            } else if (elem.value == localPhase) {
              row.splice(index, 0, {
                value: propertyDetail.timestamp
                  ? moment(propertyDetail.timestamp)
                    .format("DD-MMM-YY")
                    .replace(/,/g, "")
                  : "Missed",
                name: "timestamp"
              });
            } else {
              row.push({});
            }
          });
          const grid = this.state.grid.filter(row => row.length);
          grid.push(row);
          this.setState({ grid }, () => {
            console.log(JSON.parse(JSON.stringify(this.state.grid)));
          });
        }
      }
    }
  }

  updateEngagementUi(propertyDetail, localPhase, matter, engagement) {
    const clientEngagement = this.state.fetchedEngagementsList.find(
      element => element._id === engagement
    );
    if (localPhase === "PIG") {
      if (matter === "Attached") {
        const row = [];
        const added_date = moment(new Date()).format("DD-MMM-YY");
        const foundIndex = this.state.grid.findIndex(row => {
          return row[0]._id == clientEngagement._id;
        });

        if (foundIndex > -1) {
          const grid = this.state.grid.filter(row => row.length);
          grid[foundIndex][1].value = added_date;
          grid[foundIndex][1].name = "added_date";
          this.setState({ grid }, () => {
            console.log(
              "this.state.grid",
              JSON.parse(JSON.stringify(this.state.grid))
            );
          });
        } else {
          this.state.grid[0].map((elem, index) => {
            if (elem.name == "label") {
              row.push({
                value: clientEngagement.labelForScrapper,
                _id: clientEngagement._id,
                name: "label"
              });
            } else if (elem.value == localPhase) {
              row.splice(index, 0, { value: added_date, name: "added_date" });
            } else {
              row.push({});
            }
          });
          const grid = this.state.grid.filter(row => row.length);
          grid.push(row);
          this.setState({ grid }, () => {
            console.log(
              "this.state.grid",
              JSON.parse(JSON.stringify(this.state.grid))
            );
          });
        }
      } else {
        const grid = this.state.grid.filter(row => row.length);
        grid.map(row => {
          if (row[0]._id == engagement) {
            delete row[1].value;
            delete row[1].name;
          }
        });
        this.setState({ grid }, () => {
          console.log(
            "this.state.grid",
            JSON.parse(JSON.stringify(this.state.grid))
          );
        });
      }
    } else if (localPhase === "HOT") {
      if (matter === "Attached") {
        const row = [];
        const added_date = moment(new Date()).format("DD-MMM-YY");
        const foundIndex = this.state.grid.findIndex(row => {
          return row[0]._id == clientEngagement._id;
        });

        if (foundIndex > -1) {
          const grid = this.state.grid.filter(row => row.length);
          grid[foundIndex][2].value = added_date;
          grid[foundIndex][2].name = "added_date";
          this.setState({ grid }, () => {
            console.log(
              "this.state.grid",
              JSON.parse(JSON.stringify(this.state.grid))
            );
          });
        } else {
          this.state.grid[0].map((elem, index) => {
            if (elem.name == "label") {
              row.push({
                value: clientEngagement.labelForScrapper,
                _id: clientEngagement._id,
                name: "label"
              });
            } else if (elem.value == localPhase) {
              row.splice(index, 0, { value: added_date, name: "added_date" });
            } else {
              row.push({});
            }
          });
          const grid = this.state.grid.filter(row => row.length);
          grid.push(row);
          this.setState({ grid }, () => {
            console.log(
              "this.state.grid",
              JSON.parse(JSON.stringify(this.state.grid))
            );
          });
        }
      } else {
        const grid = this.state.grid.filter(row => row.length);
        grid.map(row => {
          if (row[0]._id == engagement) {
            delete row[2].value;
            delete row[2].name;
          }
        });
        this.setState({ grid }, () => {
          console.log(
            "this.state.grid",
            JSON.parse(JSON.stringify(this.state.grid))
          );
        });
      }
    } else if (localPhase === "Presented") {
      if (matter === "Attached") {
        const row = [];
        const added_date = moment(new Date()).format("DD-MMM-YY");
        const foundIndex = this.state.grid.findIndex(row => {
          return row[0]._id == clientEngagement._id;
        });

        if (foundIndex > -1) {
          const grid = this.state.grid.filter(row => row.length);
          grid[foundIndex][3].value = added_date;
          grid[foundIndex][3].name = "added_date";
          this.setState({ grid }, () => {
            console.log(
              "this.state.grid",
              JSON.parse(JSON.stringify(this.state.grid))
            );
          });
        } else {
          this.state.grid[0].map((elem, index) => {
            if (elem.name == "label") {
              row.push({
                value: clientEngagement.labelForScrapper,
                _id: clientEngagement._id,
                name: "label"
              });
            } else if (elem.value == localPhase) {
              row.splice(index, 0, { value: added_date, name: "added_date" });
            } else {
              row.push({});
            }
          });
          const grid = this.state.grid.map(row => [...row]);
          grid.push(row);
          this.setState({ grid }, () => {
            console.log(
              "this.state.grid",
              JSON.parse(JSON.stringify(this.state.grid))
            );
          });
        }
      } else {
        const grid = this.state.grid.filter(row => row.length);
        grid.map(row => {
          if (row && row.length && row[0]._id == engagement) {
            delete row[3].value;
            delete row[3].name;
          }
        });
        this.setState({ grid }, () => {
          console.log(
            "this.state.grid",
            JSON.parse(JSON.stringify(this.state.grid))
          );
        });
      }
    } else if (localPhase === "Proceed") {
      if (matter === "Attached") {
        const row = [];
        const added_date = moment(new Date()).format("DD-MMM-YY");
        const foundIndex = this.state.grid.findIndex(row => {
          return row[0]._id == clientEngagement._id;
        });

        if (foundIndex > -1) {
          const grid = this.state.grid.filter(row => row.length);
          grid[foundIndex][4].value = added_date;
          grid[foundIndex][4].name = "added_date";
          this.setState({ grid }, () => {
            console.log(
              "this.state.grid",
              JSON.parse(JSON.stringify(this.state.grid))
            );
          });
        } else {
          this.state.grid[0].map((elem, index) => {
            if (elem.name == "label") {
              row.push({
                value: clientEngagement.labelForScrapper,
                _id: clientEngagement._id,
                name: "label"
              });
            } else if (elem.value == localPhase) {
              row.splice(index, 0, { value: added_date, name: "added_date" });
            } else {
              row.push({});
            }
          });
          const grid = this.state.grid.filter(row => row.length);
          grid.push(row);
          this.setState({ grid }, () => {
            console.log(
              "this.state.grid",
              JSON.parse(JSON.stringify(this.state.grid))
            );
          });
        }
      } else {
        const grid = this.state.grid.filter(row => row.length);
        grid.map(row => {
          if (row[0]._id == engagement) {
            delete row[4].value;
            delete row[4].name;
          }
        });
        this.setState({ grid }, () => {
          console.log(
            "this.state.grid",
            JSON.parse(JSON.stringify(this.state.grid))
          );
        });
      }
    } else if (localPhase === "Purchased") {
      if (matter === "Attached") {
        const row = [];
        const added_date = moment(new Date()).format("DD-MMM-YY");
        const foundIndex = this.state.grid.findIndex(row => {
          return row[0]._id == clientEngagement._id;
        });

        if (foundIndex > -1) {
          const grid = this.state.grid.filter(row => row.length);
          grid[foundIndex][5].value = added_date;
          grid[foundIndex][5].name = "added_date";
          this.setState({ grid }, () => {
            console.log(
              "this.state.grid",
              JSON.parse(JSON.stringify(this.state.grid))
            );
          });
        } else {
          this.state.grid[0].map((elem, index) => {
            if (elem.name == "label") {
              row.push({
                value: clientEngagement.labelForScrapper,
                _id: clientEngagement._id,
                name: "label"
              });
            } else if (elem.value == localPhase) {
              row.splice(index, 0, { value: added_date, name: "added_date" });
            } else {
              row.push({});
            }
          });
          const grid = this.state.grid.filter(row => row.length);
          grid.push(row);
          this.setState({ grid }, () => {
            console.log(
              "this.state.grid",
              JSON.parse(JSON.stringify(this.state.grid))
            );
          });
        }
      } else {
        const grid = this.state.grid.filter(row => row.length);
        grid.map(row => {
          if (row[0]._id == engagement) {
            for (let x = 3; x <= row.length - 1; x++) {
              row[x] = {}
            }
          }
        });
        this.setState({ grid }, () => {
          console.log(
            "this.state.grid",
            JSON.parse(JSON.stringify(this.state.grid))
          );
        });
      }
    } else if (localPhase === "Queue") {
      if (matter === "Attached") {
        const row = [];
        const grid = this.state.grid.filter(row => row.length);
        // const added_date = moment(new Date).format('DD-MMM-YYYY');
        const foundIndex = grid.findIndex(row => {
          return row[0]._id == clientEngagement._id;
        });

        if (foundIndex > -1) {
          const grid = this.state.grid.filter(row => row.length);
          grid[foundIndex][6].value = "Queued";
          grid[foundIndex][6].name = "queue";
          this.setState({ grid }, () => {
            console.log(
              "this.state.grid",
              JSON.parse(JSON.stringify(this.state.grid))
            );
          });
        } else {
          this.state.grid[0].map((elem, index) => {
            if (elem.name == "label") {
              row.push({
                value: clientEngagement.labelForScrapper,
                _id: clientEngagement._id,
                name: "label"
              });
            } else if (elem.value == localPhase) {
              row.splice(index, 0, { value: "Queued", name: "queue" });
            } else {
              row.push({});
            }
          });
          const grid = this.state.grid.filter(row => row.length);
          grid.push(row);
          this.setState({ grid }, () => {
            console.log(
              "this.state.grid",
              JSON.parse(JSON.stringify(this.state.grid))
            );
          });
        }
      } else {
        const grid = this.state.grid.filter(row => row.length);
        grid.map(row => {
          if (row[0]._id == engagement) {
            delete row[6].value;
            delete row[6].name;
            ``;
          }
        });
        this.setState({ grid }, () => {
          console.log(
            "this.state.grid",
            JSON.parse(JSON.stringify(this.state.grid))
          );
        });
      }
    }
  }

  /**
   * handle phase change in the Grid like UI
   */
  handlePhaseChange(cellDetails, propertyDetail, rowIndex) {
    // It means that user clicked on the very first row, i.e click on the phase name row
    if (cellDetails.row < 1) return;

    // Method to show the error or warning notifications
    const showErrorNotification = (title, message, shouldUpdate) =>
      shouldUpdate && this.props.showNotification("error", { title, message });

    // Method to show "Not Allowed" notification
    const showNotAllowed = message =>
      showErrorNotification(
        "Not allowed",
        message,
        this.state.updatePropertyNotification
      );

    // It contains details of current property Phase and which engagement is in which phase
    // i.e the details of grid like UI
    const grid = this.state.grid;
    // Gives you the _id of engagement whose row's cell clicked by BA to change the phases
    const engagementId = grid[cellDetails.row][0]._id;
    // Gives you the phase for which clicks on cell
    const gridPhase = grid[0][cellDetails.col].value;
    // Property phase and, clientId is engagements Id, if property is in presented, proceed, or purchased phase
    // then it would definitely have the client Id or engagement Id
    const { propertyPhase, clientId } = propertyDetail;

    switch (gridPhase) {
      case "PIG":
        if (
          propertyPhase !== "Waiting" &&
          propertyPhase !== "Purchased" &&
          propertyPhase !== "Proceed" &&
          propertyPhase !== "Presented"
        )
          this.manageWatchlist(
            propertyDetail,
            rowIndex,
            engagementId,
            cellDetails.row
          );
        else showNotAllowed(`Property phase is ${propertyPhase}`);
        break;

      case "HOT":
        if (
          propertyPhase !== "Waiting" &&
          propertyPhase !== "Purchased" &&
          propertyPhase !== "Proceed" &&
          propertyPhase !== "Presented"
        )
          this.manageHotlist(
            propertyDetail,
            rowIndex,
            engagementId,
            cellDetails.row
          );
        else showNotAllowed(`Property phase is ${propertyPhase}`);
        break;

      case "Presented":
        if (
          propertyPhase !== "Waiting" &&
          propertyPhase !== "Purchased" &&
          propertyPhase !== "Proceed" &&
          propertyPhase !== "Missed"
        )
          this.presentProperty(
            propertyDetail,
            rowIndex,
            engagementId,
            cellDetails.row
          );
        else showNotAllowed(`Property phase is ${propertyPhase}`);
        break;

      case "Proceed":
        if (
          propertyPhase === "Waiting" ||
          propertyPhase === "Missed" ||
          propertyPhase === "Purchased" ||
          (propertyPhase === "Proceed" && clientId !== engagementId)
        )
          showNotAllowed(`Property phase is ${propertyPhase}`);
        else
          this.handlePropertyPhase(
            propertyDetail,
            rowIndex,
            "Proceed",
            engagementId
          );
        break;

      case "Purchased":
        if (
          propertyPhase === "Waiting" ||
          propertyPhase === "Missed" ||
          (propertyPhase === "Purchased" && clientId !== engagementId)
        )
          showNotAllowed(`Property phase is ${propertyPhase}`);
        else
          this.handlePropertyPhase(
            propertyDetail,
            rowIndex,
            "Purchased",
            engagementId
          );
        break;

      case "Queue":
        if (
          propertyPhase === "Purchased" ||
          propertyPhase === "Proceed" ||
          propertyPhase === "Missed"
        )
          showNotAllowed(`Property phase is ${propertyPhase}`);
        else
          this.checkQueueList(propertyDetail, rowIndex, "Queue", engagementId);
        break;

      case "Waiting":
        showNotAllowed("You can't move engagement into waiting phase yourself");
        break;

      case "Missed":
        if (propertyPhase === "Purchased")
          showNotAllowed(
            `You can't move engagement into Missed When phase is ${propertyPhase}`
          );
        else if (propertyPhase === "Missed" && engagementId === clientId) {
          this.ChangePropertyClient(propertyDetail, rowIndex);
        } else if (clientId !== engagementId)
          showNotAllowed("This engagement can't move to Missed");
        else
          this.handlePropertyPhase(
            propertyDetail,
            rowIndex,
            "Missed",
            engagementId
          );
        break;
    }
  }

  /**
   * handle phase change in the new UI
   */
  handleAddingRow(event) {
    const engagement = this.state.fetchedEngagementsList.find(
      engagement => engagement._id === event.target.value
    );
    const grid = this.state.grid.map(row => [...row]);
    const row = this.state.grid[0].map(cell => {
      if (cell.value == "") {
        return {
          value: engagement.labelForScrapper,
          _id: engagement._id,
          name: "label"
        };
      } else {
        return {};
      }
    });
    const manageEngagementsList = this.state.manageEngagementsList.filter(
      engagement => {
        return !row.find(element => element._id == engagement._id);
      }
    );

    grid.push(row);
    this.setState({ grid, manageEngagementsList }, () => {
      this.setEngagementsForSelection(null, manageEngagementsList);
      //When select engagement from drop-down menu,
      //goes to PIG by the below method
      this.manageWatchlist(
        this.state.currentlySelectedProperty,
        this.state.currentlySelectedPropertyIndex,
        engagement._id
      );
    });
  }

  /**
   * handle Action on Property (i.e. - for Archive and Presented)
   * @param {object} data property detail
   * @param {number} index position in table list
   * @param {String} type what action required
   * @param {SytheticEvent} event
   */

  actionProperty(data, index, event) {
    // Array of debounced click events
    this.debouncedClickEvents = this.debouncedClickEvents || [];

    // Each click we store a debounce (a future execution scheduled in 250 milliseconds)
    const callback = _.debounce(_ => {
      // YOUR ON CLICKED CODE
      let property = {};
      const _rows = this.state._rows;
      property.filter = data.propertyId;
      if (data.archive === "archived" || data.archive === "donot-archive") {
        property.fields = { archive: "not-archived" };
        _rows[index].archive = "not-archived";
      } else {
        property.fields = { archive: "archived" };
        _rows[index].archive = "archived";
      }
      this.updateAll(_rows, property, function () { });

      this.debouncedClickEvents = [];
    }, 500);
    this.debouncedClickEvents.push(callback);

    // We call the callback, which has been debounced (delayed)
    callback();
  }

  onDoubleClicked(data, index, event) {
    // If there were click events registered we cancel them
    if (this.debouncedClickEvents.length > 0) {
      _.map(this.debouncedClickEvents, debounce => debounce.cancel());
      this.debouncedClickEvents = [];
    }
    // YOUR DOUBLE CLICK CODE
    let property = {};
    const _rows = this.state._rows;
    property.filter = data.propertyId;
    property.fields = { archive: "donot-archive" };
    _rows[index].archive = "donot-archive";
    this.updateAll(_rows, property, function () { });
  }

  presentProperty(data, index, presentEngagement, row, event) {
    if (
      (data.clientId &&
        data.propertyPhase == "Presented" &&
        presentEngagement != data.clientId) ||
      data.propertyPhase === "Proceed" ||
      data.propertyPhase === "Purchased"
    ) {
      if (this.state.updatePropertyNotification) {
        this.props.showNotification("warning", {
          title: "Warning!",
          message: `Property is in ${data.propertyPhase
            } phase please move engagement further or in queue`
        });
      }
    } else {
      const foundIncubation = presentEngagement
        ? this.state.fetchedEngagementsList.filter((engagement, index) => {
          return (
            engagement._id == presentEngagement &&
            engagement.status == "Incubation"
          );
        })
        : "";
      if (foundIncubation && foundIncubation.length) {
        if (this.state.updatePropertyNotification) {
          this.props.showNotification("info", {
            title: "Incubation Client",
            message:
              "Incubation Client can`t be in Hotlist or Presented. It can only be FRWL, Proceed and then Purchased"
          });
        }
      } else {
        let checkPresented =
          data.presentedList.length &&
          _.includes(data.presentedList, presentEngagement);

        /* TO be viewed carefully because the phase is waiting, which is not usual */
        if (!data.clientId || data.propertyPhase == "Waiting") {
          if (presentEngagement || data.clientId) {
            if (!checkPresented) {
              this.updatePresentProperty(data, index, presentEngagement, event);
            } else {
              if (this.state.updatePropertyNotification) {
                const notifyData = {
                  title: "Error!",
                  message:
                    "This property has been presented to this client before.If you still want to present the same property to this client again, then proeed :-",
                  children: (
                    <div className="row">
                      <div className="col-xs-12">
                        <div className="col-xs-6">
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={this.updatePresentProperty.bind(
                              this,
                              data,
                              index,
                              presentEngagement
                            )}
                          >
                            Yes
                          </button>
                        </div>
                        <div className="col-xs-6">
                          <button type="button" className="btn btn-warning">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                };

                this.props.showNotification("error", notifyData);
              }
            }
          } else {
            if (this.state.updatePropertyNotification) {
              this.props.showNotification("error", {
                title: "Error!",
                message:
                  "This property has no Client.You can not present to anyone."
              });
            }
          }
        } else {
          this.ChangePropertyClient(data, index);
          this.updateEngagementUi(
            data,
            data.propertyPhase,
            "Detached",
            presentEngagement
          );
          let phase = "PIG";
          if (data.hotList && data.hotList.length) {
            phase = "HOT";
          }
          this.addAuditHistory(
            presentEngagement,
            data,
            data,
            "Detached",
            phase
          );
        }
      }
      this.setState({
        modalIsOpen: false
      });
      this.cb = null;
    }
  }

  async updatePresentProperty(data, index, engagement, event) {
    let rowIndex = index;
    const _rows = this.state._rows;
    if (data.clientId === engagement) {
      this.updateEngagementUi(data, "Presented", "Detached", engagement);
    } else {
      let checkClient = engagement || data.clientId;
      const validBa = await this.verifyClientBA(
        engagement || data.clientId,
        "Presented"
      );
      if (validBa.success) {
        api
          .get(
            "/api/property/presented?filter=" + encodeURIComponent(checkClient)
          )
          .then(result => {
            if (result.presented) {
              let property = {};
              property.filter = data.propertyId;
              property.interest = "push";
              property.fields = {
                presented: checkClient
              };
              property.timestamp = moment().add(5, "days")._d;
              property.client = checkClient;
              property.presented_property_date = new Date().toISOString();
              this.reassignProperty(property, result => {
                if (result.success) {
                  const property = result.properties;
                  this.addAuditHistory([checkClient], data, result, "Attached");
                  if (rowIndex == undefined) {
                    rowIndex = _rows.findIndex(
                      propertyObject =>
                        propertyObject.propertyId == property._id
                    );
                  }

                  _rows[rowIndex].client =
                    property.client && property.client.client
                      ? property.client.client.first_name +
                      " " +
                      (property.client.client.middle_name
                        ? property.client.client.middle_name
                        : "") +
                      " " +
                      property.client.client.last_name
                      : "";
                  _rows[rowIndex].clientId = property.client
                    ? property.client._id
                    : "";
                  _rows[rowIndex].baId =
                    property.client && property.client.business_analyst._id
                      ? property.client.business_analyst._id
                      : "";
                  _rows[rowIndex].buyerAdvocate =
                    property.client && property.client.business_analyst.nickname
                      ? property.client.business_analyst.nickname
                      : "";
                  _rows[rowIndex].propertyPhase = property.property_phase;
                  _rows[rowIndex].presentedList = property.presented || [];
                  _rows[rowIndex].presented_property_date =
                    property.presented_property_date || "";
                  _rows[rowIndex].g2gDate =
                    property.client && property.client.G2G_date
                      ? moment(property.client.G2G_date)
                        .format("DD-MMM-YY")
                        .replace(/,/g, "")
                      : "-";
                  _rows[rowIndex].prEngagement = property.client
                    ? property.client
                    : "";

                  if (this.state.updatePropertyNotification) {
                    this.props.showNotification("success", {
                      title: "Success!",
                      message: "Presented Successfully."
                    });
                  }

                  // this.updateEngagementUi(result.properties, "Presented", "Attached", checkClient);
                  this.setState(
                    {
                      presentClient: "",
                      _rows,
                      grid: [
                        [
                          { readOnly: true, value: "", name: "label" },
                          { value: "PIG", readOnly: true, name: "phase" },
                          { value: "HOT", readOnly: true, name: "phase" },
                          { value: "Presented", readOnly: true, name: "phase" },
                          { value: "Proceed", readOnly: true, name: "phase" },
                          { value: "Purchased", readOnly: true, name: "phase" },
                          { value: "Queue", readOnly: true, name: "phase" },
                          { value: "Waiting", readOnly: true, name: "phase" },
                          { value: "Missed", readOnly: true, name: "phase" }
                        ]
                      ]
                    },
                    () => {
                      this.setAddModal(this.state._rows[rowIndex]);
                    }
                  );
                }
              });
            } else {
              if (result.message) {
                alert(result.message);
              } else {
                if (this.state.updatePropertyNotification) {
                  this.props.showNotification("error", {
                    title: `Already Assigned ${result.presentedPerClient
                      } Properties!`,
                    message: `You have already presented ${result.presentedPerClient
                      } properties to this client.`
                  });
                }
              }
            }
          });
      } else {
        if (this.state.updatePropertyNotification) {
          this.props.showNotification("error", {
            title: "Not allowed",
            message: "This engagement is of another client"
          });
        }
      }
    }
  }

  handleSelectProperty = property => {
    this.setState(({ selectedProperties }) => {
      const propertyIndex = selectedProperties.findIndex(
        x => x.propertyId === property.propertyId
      );

      if (propertyIndex === -1) {
        // Add property to list
        return {
          selectedProperties: [...selectedProperties, property]
        };
      } else {
        // Property already selected, remove from list
        return {
          selectedProperties: [
            ...selectedProperties.slice(0, propertyIndex),
            ...selectedProperties.slice(propertyIndex + 1)
          ]
        };
      }
    });
  }

  /**
   * Handle Default CSV Generation
   * @param {object} data property detail
   */
  defaultCSVGeneration(data) {
    const preferenceList = _.has(this.props, "user.info.preference_list.length")
      ? this.props.user.info.preference_list
      : ColumnList;

    const filter = _.map(preferenceList, "key");

    const defaultCSVData = _.map(data, property => _.pick(property, filter));

    this.setState({ defaultCSVData });
  }

  handleSoldProperty(data, index) {
    const _rows = this.state._rows;
    let property = {};
    property.filter = data.propertyId;
    property.fields = { sold: !data.sold };

    this.updateAll(_rows, property, response => {
      _rows[index].sold = response.properties.sold;
      this.setState({
        _rows
      });
    });
  }
  handleDeleteProperty(data, index) {
    this.setState({
      isDeleteModalOpen: true,
      mode: "delete property",
      dataToDelete: {
        data,
        index
      }
    })
  }
  toggleUpdateDeleteConfirmModal(shouldOpen) {
    this.setState({
      isDeleteModalOpen: shouldOpen,
      mode: "",
      dataToDelete: {}
    })
  }

  /* API call for Updating property Fields */
  updateAll(_rows, property, cb) {
    console.log("property", property);
    api
      .put("/api/property/update", property)
      .then(result => {
        console.log("result", result);
        if (result.notify) {
          alert("Message", result.notify);
        } else {
          this.setState({
            _rows
          });
          cb(result);
        }
      })
      .catch(error => {
        if (this.state.updatePropertyNotification) {
          this.props.showNotification("error", {
            title: "Error!",
            message: error.responseJSON && error.responseJSON.message
          });
        }
      });
  }

  verifyClientBA(filter, checkFor) {
    const url = checkFor
      ? "/api/property/check?filter=" +
      encodeURIComponent(filter) +
      "&&checkFor=" +
      checkFor
      : "/api/property/check?filter=" + encodeURIComponent(filter);
    return api.get(url);
  }

  /* API call for reassign Property */
  reassignProperty(property, cb) {
    console.log("Props", property);
    api.put("/api/property/assign", property).then(result => {
      if (result.notify) {
        alert(result.notify);
      } else {
        return cb(result);
      }
    });
  }

  async ChangePropertyClient(data, index, event) {
    const propertyDetails = Object.assign({}, data);
    const clientEngagement = propertyDetails.clientId;
    const oldPropertyData = { ...propertyDetails };
    let property = {};
    const _rows = this.state._rows;
    property.filter = propertyDetails.propertyId;
    if (
      propertyDetails.clientId &&
      (propertyDetails.propertyPhase == "Presented" ||
        propertyDetails.propertyPhase == "Proceed" ||
        propertyDetails.propertyPhase == "Purchased" ||
        propertyDetails.propertyPhase == "Missed")
    ) {
      const validBa = await this.verifyClientBA(property.filter);
      if (validBa.success) {
        property.fields = {};
        switch (propertyDetails.propertyPhase) {
          case "Presented":
            property.fields["presented_property_date"] = null;
            break;
          case "Proceed":
            if (
              propertyDetails.presented_property_date &&
              propertyDetails.presented_property_date != null
            ) {
              property.filter = propertyDetails.propertyId;
              property.interest = "push";
              property.fields = {
                presented: propertyDetails.clientId
              };
              property.timestamp = moment().add(5, "days")._d;
              property.client = propertyDetails.clientId;
              property.presented_property_date = new Date().toISOString();
            } else if (
              propertyDetails.presented_property_date == null &&
              propertyDetails.hotList &&
              propertyDetails.hotList.length
            ) {
              property.fields["proceed_property_date"] = null;
              property.fields["property_phase"] = "HOT";
            } else {
              property.fields["proceed_property_date"] = null;
              property.fields["property_phase"] = "PIG";
            }
            break;
          case "Purchased":
            if (
              propertyDetails.presented_property_date &&
              propertyDetails.presented_property_date != null &&
              propertyDetails.proceed_property_date == null
            ) {
              property.filter = propertyDetails.propertyId;
              property.interest = "push";
              property.fields = {
                presented: propertyDetails.clientId
              };
              property.timestamp = moment().add(5, "days")._d;
              property.client = propertyDetails.clientId;
              property.presented_property_date = new Date().toISOString();
              property.purchasedOn = true;
            } else if (
              propertyDetails.proceed_property_date &&
              propertyDetails.proceed_property_date != null
            ) {
              property.fields["purchasedOn"] = null;
              property.fields["presented_property_date"] = null;
              property.fields["proceed_property_date"] = null;
              property.fields["property_phase"] = "PIG";
              property.fields["timestamp"] = null;
              property.fields["client"] = propertyDetails.clientId;
            } else {
              property.fields["purchasedOn"] = null;
            }

          default:
            break;
        }

        this.reassignProperty(property, result => {
          // this.addAuditHistory([data.clientId], data, result, "Detached");
          let updatedProperty = result.properties;
          let masterClient = "";
          _rows[index].client = updatedProperty.client
            ? updatedProperty.client.client.first_name +
            " " +
            (updatedProperty.client.client.middle_name || "") +
            " " +
            updatedProperty.client.client.last_name
            : "";
          _rows[index].clientId = updatedProperty.client
            ? updatedProperty.client._id
            : "";
          _rows[index].baId =
            updatedProperty.client &&
              updatedProperty.client.business_analyst._id
              ? updatedProperty.client.business_analyst._id
              : "";
          _rows[index].buyerAdvocate =
            updatedProperty.client &&
              updatedProperty.client.business_analyst.nickname
              ? updatedProperty.client.business_analyst.nickname
              : "";
          _rows[index].g2gDate =
            updatedProperty.client && updatedProperty.client.G2G_date
              ? moment(updatedProperty.client.G2G_date)
                .format("DD-MMM-YY")
                .replace(/,/g, "")
              : "-";
          _rows[index].presented = false;
          _rows[index].propertyPhase = updatedProperty.property_phase;
          _rows[index].presented_property_date =
            updatedProperty.presented_property_date || null;
          _rows[index].proceed_property_date =
            updatedProperty.proceed_property_date || null;
          _rows[index].prEngagement = updatedProperty.client;

          if (updatedProperty.property_phase === "HOT") {
            let firstClient =
              propertyDetails.hotList &&
              propertyDetails.hotList.length &&
              propertyDetails.hotList[0].client;
            masterClient = firstClient
              ? firstClient.first_name +
              " " +
              (firstClient.middle_name ? firstClient.middle_name : "") +
              " " +
              firstClient.last_name
              : "";
            _rows[index].client = masterClient;
            _rows[index].buyerAdvocate =
              firstClient && firstClient.business_analyst
                ? firstClient.business_analyst.nickname
                : "";
          }
          let phase = "PIG";
          if (propertyDetails.hotList && propertyDetails.hotList.length) {
            phase = "HOT";
          }
          this.addAuditHistory(
            clientEngagement,
            oldPropertyData,
            undefined,
            "Detached",
            phase
          );
          if (propertyDetails.propertyPhase == "Purchased") {
            let updatedPurchase = {};
            updatedPurchase.filter = clientEngagement;
            updatedPurchase.fields = {
              property: ""
            };
            updatedPurchase.interest = "remove";
            console.log("updatedPurchase", updatedPurchase);
            api
              .put("/api/clientPurchases", updatedPurchase)
              .then(response => {
                console.log("response", response);
              })
              .catch(error => console.log("error", error));
          }
          this.setState(
            {
              _rows,
              modalIsOpen: false,
              grid: [
                [
                  { readOnly: true, value: "", name: "label" },
                  { value: "PIG", readOnly: true, name: "phase" },
                  { value: "HOT", readOnly: true, name: "phase" },
                  { value: "Presented", readOnly: true, name: "phase" },
                  { value: "Proceed", readOnly: true, name: "phase" },
                  { value: "Purchased", readOnly: true, name: "phase" },
                  { value: "Queue", readOnly: true, name: "phase" },
                  { value: "Waiting", readOnly: true, name: "phase" },
                  { value: "Missed", readOnly: true, name: "phase" }
                ]
              ]
            },
            () => {
              this.setAddModal(this.state._rows[index]);
            }
          );
        });
      } else {
        if (this.state.updatePropertyNotification) {
          this.props.showNotification("warning", {
            title: "Warning!",
            message:
              "This property is assigned to another BA's Client. You cannot cancel this property from them. Only their BA can do this."
          });
        }
      }
    } else {
      if (this.state.updatePropertyNotification) {
        this.props.showNotification("warning", {
          title: "Warning!",
          message:
            "This property is not assigned to any client or this is in other than Presented phase.You can not refuse this."
        });
      }
    }
  }

  updateNotification(data, event) {
    let notification = {};
    notification.filter = data.propertyId;
    notification.fields = { "userId.0.read": true };
    api
      .put(
        "/api/notification/?filter=" +
        encodeURIComponent(JSON.stringify(notification)),
        notification
      )
      .then(result => {
        if (result.notify) {
          alert(result.notify);
        } else {
          this.presentProperty(data);
        }
      });
  }

  getSUAForProperty = property => {
    console.log("property", property);
    let postcode = "";
    const suburb = property.suburb || "";
    const formattedAddress = property.formatted_address || "";

    if (formattedAddress) {
      // Extract postcode
      const words = formattedAddress.split(" ");
      postcode = words[words.length - 2].replace(",", "");
    }

    // Find property's SUA
    // Returns 'undefined' if SUA cannot be found
    return !isNaN(postcode) ?
      suaList.find(x => x.POST_CODE === postcode && x.LOCALITY.toLowerCase() === suburb.toLowerCase()) :
      suaList.find(x => x.LOCALITY.toLowerCase() === suburb.toLowerCase())
  };

  /**
   * Method to show accordion: property detail or phase change
   * @param property
   * @param index
   * @param filter
   */
  async showRow(property, index, filter) {
    const _rows = this.state._rows;
    _rows[index].editNotes = false;

    const setAutoLinkToNotesField = property => {
      const myTextEl = document.getElementById(
        "notes-here" + property.propertyId
      );
      if (myTextEl) {
        myTextEl.innerHTML = this.autolinker.link(property.notes);
      }
    };

    // Goes in this block when we
    // open the phase change dropdown
    if (filter == "editPhase") {
      this.setState(
        {
          editPhase: true,
          grid: [
            [
              { readOnly: true, value: "", name: "label" },
              { value: "PIG", readOnly: true, name: "phase" },
              { value: "HOT", readOnly: true, name: "phase" },
              { value: "Presented", readOnly: true, name: "phase" },
              { value: "Proceed", readOnly: true, name: "phase" },
              { value: "Purchased", readOnly: true, name: "phase" },
              { value: "Queue", readOnly: true, name: "phase" },
              { value: "Waiting", readOnly: true, name: "phase" },
              { value: "Missed", readOnly: true, name: "phase" }
            ]
          ],
          currentlySelectedProperty: property, // These two states are using for moving
          currentlySelectedPropertyIndex: index // engagement into PIG auto, when select
        },
        () => this.setAddModal(property)
      );

      format(property, "#datatables", index, $("#row-" + property.id));
    } else {
      // Goes in this block when click
      // on property details section

      // GET the property details so that we can
      // check if it is being editing by another BA
      const query = encodeURIComponent(
        JSON.stringify({ id: property.propertyId })
      );
      const {
        properties: [fetchedProperty]
      } = await api.get(`/api/property?filter=${query}`);
      //Update the property index(i.e local state)
      //with latest property data

      // DISABLE THE DSR AND WALKSCORE FETCH AS THEY DO NOT RUN AS DESIRED
      // fetchedProperty['walk_score'] = this.state._walkScore[index];
      // fetchedProperty['DSR'] = this.state._dsr[index];
      this.updateRowIndex(fetchedProperty, index);
      // it contain updated property data
      //i.e may be property is being editing so it has that info also
      const updatedProperty = this.state._rows[index];
      //Method will return object, locking details + general details of property
      this.setLockedPropertyUtility(updatedProperty, index);
      format(property, "#datatables", index, $(`#row-${property.id}`));
      // console.log("lockingDetailsObject", lockingDetailsObject);
      if (
        updatedProperty.locked &&
        updatedProperty.locked_ba != this.state.user.nickname
      ) {
        setAutoLinkToNotesField(updatedProperty);

        /* this.setState(lockingDetailsObject, () => {
                    }); */
      } else {
        setAutoLinkToNotesField(updatedProperty);
        // format(property, '#datatables', index, $('#row-' + property.id));

        // check for whether ba clicks for viewing the
        // details or click for hide the detail accrodion
        if (
          !document.querySelectorAll(".white-bg").length &&
          !updatedProperty.locked
        ) {
          console.log("Collapse the accrodion");
        } else {
          // Update the property if BA opens the property which is
          // not locked,but now will lock as he opens the property detail
          const updateProperty = {};
          updateProperty.filter = property.propertyId;
          updateProperty.fields = {};
          updateProperty.fields.locked = !updatedProperty.locked;
          updateProperty.fields.locked_ba = updateProperty.fields.locked
            ? this.state.user.nickname
            : "";

          api
            .put("/api/property/update", updateProperty)
            .then(res => {
              _rows[index].locked = res.properties.locked;
              _rows[index].locked_ba = res.properties.locked_ba;
              this.setState({
                _rows
              });
            })
            .catch(err => console.log("err>>>", err));
        }
        /* this.setState(lockingDetailsObject, () => {
                    }); */
      }
    }
  }

  setLockedPropertyUtility(property, index) {
    this.setState({
      editPhase: false,
      index: index,
      // If property is locked set, true
      locked:
        property.locked && property.locked_ba !== this.state.user.nickname
          ? true
          : false,
      locked_ba: property.locked_ba ? property.locked_ba : "",
      locked_ba_id:
        this.props.buyers &&
        this.props.buyers.length &&
        this.props.buyers.find(buyer => buyer.nickname === property.locked_ba),
      notes: property.notes ? property.notes : "",
      clientId: property.clientId,
      address: property.address,
      off_market_flag: property.flag,
      propertyType: property.propertyType,
      growth: property.growth,
      autoValue: property.autoValue,
      buyPrice: property.buyPrice,
      soldPrice: property.soldPrice,
      landSize: property.landSize,
      auctionDate: property.auctionDate,
      sellingPrice: property.sellingPrice,
      yearBuild: property.yearBuild,
      googleDocLink: property.googleDocLink,
      fsd: property.fsd,
      lsd: property.lsd
        ? property.lsd
        : "",
      lsp: property.lsp,
      rent: property.rent,
      yield: property.yield,
      walkScore: property.walkScore,
      dsr: property.dsr,
      vr: property.vr,
      hotList: false,
      propertyDog: property.propertyDog,
      under_contract_exp_date: property.under_contract_exp_date
        ? property.under_contract_exp_date
        : "",
      floodCheck: property.floodCheck,
      rating: property.rating,
      beds: property.beds,
      comments: property.comments ? property.comments : "",
      data: property.data ? property.data : "",
      comparable_sales: property.comparable_sales
        ? property.comparable_sales
        : "",
      baths: property.baths,
      parking: property.parking,
      price: property.price
    });
  }

  handlePropertyPhase(data, index, filter, engagement, event) {
    if (
      (data.propertyPhase === "Presented" && data.clientId != engagement) ||
      (data.propertyPhase === "Proceed" && data.clientId != engagement) ||
      (data.propertyPhase === "Purchased" && data.clientId != engagement)
    ) {
      if (this.state.updatePropertyNotification) {
        this.props.showNotification("warning", {
          title: "Warning!",
          message: "This engagement can only move in queue"
        });
      }
    } else {
      this.setState({
        queryTimer: true
      });
      let baId = this.props.user.info.userId
        ? this.props.user.info.userId
        : this.props.user.info._id;
      let permitBuyers = this.props.user.info
        ? this.props.user.info.senior_associates.findIndex(
          buyer => buyer === data.baId
        )
        : -1;
      if (data.propertyPhase != "Waiting") {
        if (
          (filter === "Missed" && data.clientId) ||
          filter === "Proceed" ||
          filter === "Purchased"
        ) {
          if (data.baId) {
            if (data.baId == baId || permitBuyers >= 0) {
              this.directlyPhaseHandling(data, index, filter, data.clientId);
            } else {
              if (this.state.updatePropertyNotification) {
                this.props.showNotification("warning", {
                  title: "Warning!",
                  message: "This property is currently with another client."
                });
              }
            }
          } else {
            this.directlyPhaseHandling(data, index, filter, engagement);
          }
        } else {
          if (this.state.updatePropertyNotification) {
            this.props.showNotification("error", {
              title: "Error!",
              message:
                "This property has no Client. You can not proceed without a client."
            });
          }
        }
      } else {
        if (this.state.updatePropertyNotification) {
          this.props.showNotification("error", {
            title: "Already Presented!",
            message:
              "This property is already presented. You can add your client in queue."
          });
        }
      }
      this.setState({
        modalIsOpen: false,
        queryTimer: false
      });
      this.cb = null;
    }
    // event.preventDefault();
  }

  /* check whether the engagements is able for move into proceed, i.e the same engagement should not in proceed with another client */
  checkEngagementIsAbleToProceed(engagement) {
    const encodedEngagement = encodeURIComponent(engagement);
    return api.get(`/api/property/proceed?filter=${encodedEngagement}`);
  }

  /* check whether the engagements is able for move into purchased, i.e the same engagement should not in purchased with another client */
  checkEngagementIsAbleToPurchased(engagement) {
    const encodedEngagement = encodeURIComponent(engagement);
    return api.get(`/api/property/purchased?filter=${encodedEngagement}`);
  }
  /* check whether the engagements is able for move into purchased, i.e the same engagement should not in purchased with another client */
  updateClient(updateEngagement) {
    return api.put(`/api/client/updatePurchases`, updateEngagement);
  }

  /* check whether the engagements is able for move into purchased, i.e the same engagement should not in purchased with another client */
  updateClientEngagement(updateEngagement) {
    return api.put(`/api/clientPurchases`, updateEngagement);
  }

  async directlyPhaseHandling(data, index, filter, clientEngagement) {
    console.log(
      "data, index, filter, clientEngagement",
      data,
      index,
      filter,
      clientEngagement
    );
    const oldPropertyData = { ...data };
    const _rows = this.state._rows;
    const propertyToUpdate = {};
    propertyToUpdate.filter = oldPropertyData.propertyId;
    const validBa = await this.verifyClientBA(clientEngagement, "Proceed");
    if (validBa.success) {
      console.log("validBa", validBa, filter);
      if (
        filter == "Proceed" &&
        oldPropertyData.propertyPhase !== "Purchased"
      ) {
        const engagementAbleResult = await this.checkEngagementIsAbleToProceed(
          clientEngagement
        );
        if (engagementAbleResult.proceed) {
          // Means we can proceed further, i.e the engagement is not already proceed to another property
          propertyToUpdate.fields = {
            property_phase: "Proceed",
            timestamp: null,
            client: clientEngagement,
            proceed_property_date: new Date().toISOString()
          };

          const propertyUpdateResult = await this.updatePropertyDetails(
            propertyToUpdate
          );
          if (propertyUpdateResult.notify) {
            // If error
            alert("Message", propertyUpdateResult.notify);
          } else {
            const propertyData = propertyUpdateResult.properties;
            this.setState(
              {
                grid: [
                  [
                    { readOnly: true, value: "", name: "label" },
                    { value: "PIG", readOnly: true, name: "phase" },
                    { value: "HOT", readOnly: true, name: "phase" },
                    { value: "Presented", readOnly: true, name: "phase" },
                    { value: "Proceed", readOnly: true, name: "phase" },
                    { value: "Purchased", readOnly: true, name: "phase" },
                    { value: "Queue", readOnly: true, name: "phase" },
                    { value: "Waiting", readOnly: true, name: "phase" },
                    { value: "Missed", readOnly: true, name: "phase" }
                  ]
                ]
              },
              () => {
                this.updateRowIndex(propertyData, index);
                this.setAddModal(this.state._rows[index]);
                this.addAuditHistory(
                  clientEngagement,
                  oldPropertyData,
                  propertyUpdateResult,
                  "Attached"
                );

                if (this.state.updatePropertyNotification) {
                  this.props.showNotification("info", {
                    title: "Moved to next Phase!",
                    message: "This Property have been moved to Proceed Phase."
                  });
                }
              }
            );
          }
        } else {
          console.log(
            "in else",
            engagementAbleResult,
            "oldPropertyData",
            oldPropertyData
          );
          if (oldPropertyData.propertyPhase === "Proceed") {
            // If property phase is already proceed and even that user clicks on proceed then he tried to remove it
            this.ChangePropertyClient(oldPropertyData, index);
            this.updateEngagementUi(
              oldPropertyData,
              "Proceed",
              "Detached",
              clientEngagement
            );
            let phase = "PIG";
            if (oldPropertyData.hotList && oldPropertyData.hotList.length) {
              phase = "HOT";
            }
            this.addAuditHistory(
              clientEngagement,
              oldPropertyData,
              oldPropertyData,
              "Detached",
              phase
            );
          } else {
            if (this.state.updatePropertyNotification) {
              this.props.showNotification("error", {
                title: "Already Proceed",
                message:
                  "This engagement is already been proceed with other property"
              });
            }
          }
        }
      } else if (filter == "Purchased" || filter == "Missed") {
        if (filter == "Purchased") {
          /* check engagement is able for moving into purchased i.e one engagement should not purchased with another property */
          const engagementAbleResult = await this.checkEngagementIsAbleToPurchased(
            clientEngagement
          );
          if (engagementAbleResult.purchased) {
            const updateProperty = {};
            updateProperty.filter = oldPropertyData.propertyId;
            updateProperty.fields = {
              interest: "REMOVEHOTENGAGEMENTS",
              engagement: clientEngagement
            };
            /* Update the property detail i.e removing the engagement which is currently made purchased, from all hot properties */
            const updatedPropertyResult = await this.updatePropertyDetails(
              updateProperty
            );
            if (updatedPropertyResult.notify) {
              alert("Message", updatedPropertyResult.notify);
            } else {
              // _rows[index].propertyPhase = filter;
              propertyToUpdate.fields = {
                property_phase: filter,
                timestamp: null,
                client: clientEngagement,
                purchasedOn: new Date().toISOString()
              };
              const propertyUpdateResult = await this.updatePropertyDetails(
                propertyToUpdate
              );
              if (propertyUpdateResult.notify) {
                // If error
                alert("Message", propertyUpdateResult.notify);
              } else {
                const propertyData = propertyUpdateResult.properties;
                this.setState(
                  {
                    grid: [
                      [
                        { readOnly: true, value: "", name: "label" },
                        { value: "PIG", readOnly: true, name: "phase" },
                        { value: "HOT", readOnly: true, name: "phase" },
                        { value: "Presented", readOnly: true, name: "phase" },
                        { value: "Proceed", readOnly: true, name: "phase" },
                        { value: "Purchased", readOnly: true, name: "phase" },
                        { value: "Queue", readOnly: true, name: "phase" },
                        { value: "Waiting", readOnly: true, name: "phase" },
                        { value: "Missed", readOnly: true, name: "phase" }
                      ]
                    ]
                  },
                  async () => {
                    // this.updateRowIndex(propertyData, index);
                    // this.setAddModal(this.state._rows[index]);
                    /* Add the audit history */
                    this.addAuditHistory(
                      clientEngagement,
                      oldPropertyData,
                      propertyUpdateResult,
                      "Attached"
                    );
                    const updateClient = {};
                    updateClient.filter = propertyData.client.client._id;
                    updateClient.purchase = propertyData.client._id;
                    /* Update client that a purchased has been made against it client engagement */
                    const updateClientResult = await this.updateClient(
                      updateClient
                    );
                    if (updateClientResult.notify) {
                      alert("Message", updateClientResult.notify);
                    } else {
                      const updatedPurchase = {};

                      updatedPurchase.filter = propertyData.client._id;
                      updatedPurchase.fields = {
                        property: propertyData._id
                      };
                      /* Update the client enagagement`s property field that this property has been made purchased */
                      const updateEngagementResult = await this.updateClientEngagement(
                        updatedPurchase
                      );
                      if (updateEngagementResult.notify) {
                        alert("Message", updateEngagementResult.notify);
                      } else {
                        this.updateRowIndex(propertyData, index);
                        this.setAddModal(this.state._rows[index]);
                      }
                    }

                    if (this.state.updatePropertyNotification) {
                      this.props.showNotification("info", {
                        title: "Moved to next Phase!",
                        message:
                          "This Property have been moved to Proceed Phase."
                      });
                    }
                  }
                );
              }
            }
          } else {
            if (oldPropertyData.propertyPhase === "Purchased") {
              // If property phase is already proceed and even that user clicks on proceed then he tried to remove it
              this.ChangePropertyClient(oldPropertyData, index);
              this.updateEngagementUi(
                oldPropertyData,
                "Purchased",
                "Detached",
                clientEngagement
              );
              let phase = "PIG";
              if (oldPropertyData.hotList && oldPropertyData.hotList.length) {
                phase = "HOT";
              }
              // console.log("clientEngagement, oldPropertyData, propertyUpdateResult, Detached, phase", clientEngagement, oldPropertyData, propertyUpdateResult, phase)
              this.addAuditHistory(
                clientEngagement,
                oldPropertyData,
                undefined,
                "Detached",
                phase
              );
            } else {
              if (this.state.updatePropertyNotification) {
                this.props.showNotification("error", {
                  title: "Already purchased",
                  message: "This engagement has already purchased property"
                });
              }
            }
          }
        }

        if (filter == "Missed") {
          _rows[index].propertyPhase = filter;
          propertyToUpdate.filter = oldPropertyData.propertyId;
          propertyToUpdate.fields = {
            property_phase: filter,
            timestamp: null
          };
          if (clientEngagement) {
            propertyToUpdate.fields["client"] = clientEngagement;
          }
          propertyToUpdate.fields["purchasedOn"] = "";
          console.log("validBa", validBa, filter, propertyToUpdate);
          const { properties: propertyData } = await this.updatePropertyDetails(
            propertyToUpdate
          );
          console.log("propertyData", propertyData, index);
          this.setState(
            {
              grid: [
                [
                  { readOnly: true, value: "", name: "label" },
                  { value: "PIG", readOnly: true, name: "phase" },
                  { value: "HOT", readOnly: true, name: "phase" },
                  { value: "Presented", readOnly: true, name: "phase" },
                  { value: "Proceed", readOnly: true, name: "phase" },
                  { value: "Purchased", readOnly: true, name: "phase" },
                  { value: "Queue", readOnly: true, name: "phase" },
                  { value: "Waiting", readOnly: true, name: "phase" },
                  { value: "Missed", readOnly: true, name: "phase" }
                ]
              ]
            },
            () => {
              this.updateRowIndex(propertyData, index);
              this.setAddModal(this.state._rows[index]);
            }
          );

          if (this.state.updatePropertyNotification) {
            this.props.showNotification("success", {
              title: "Success!",
              message: "This Property have been finalise."
            });
          }
        }
      }
    } else {
      if (this.state.updatePropertyNotification) {
        this.props.showNotification("error", {
          title: "Not allowed",
          message: "This engagement is of another client"
        });
      }
    }
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    });
  }

  updatePropertyInspector(data, index, event) {
    let property = {};
    const _rows = this.state._rows;
    property.filter = data.propertyId;
    let baId = this.props.user.info.userId
      ? this.props.user.info.userId
      : this.props.user.info._id;
    if (data.inspectedBy) {
      let nickname = this.props.user.info.nickname
        ? this.props.user.info.nickname
        : "";
      if (data.inspectedBy === nickname) {
        property.fields = {
          inspected_by: null
        };
        _rows[index].inspectedBy = "";
        this.updateAll(_rows, property, function () { });
      } else {
        if (this.state.updatePropertyNotification) {
          this.props.showNotification("warning", {
            title: "Warning!",
            message: "This Property is already inspected."
          });
        }
      }
    } else {
      property.fields = {
        inspected_by: baId
      };
      _rows[index].inspectedBy = this.props.user.info.nickname
        ? this.props.user.info.nickname
        : "";
      this.updateAll(_rows, property, function () { });
    }
  }

  maintainPagination(property, event) {
    let data = {
      offset: this.state.offset,
      limit: this.state.limit,
      property: property,
      filter: this.state.filter
    };
    localStorage.setItem("pagination", JSON.stringify(data));
  }

  editNotes(data, index, event) {
    event.preventDefault();
    const _rows = this.state._rows;
    _rows[index].editNotes = true;
    this.setState({
      _rows
    });
  }

  updateBuyPriceAndPropertyDog(data, index, event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value
      },
      () => {
        if (target.type === "checkbox") {
          let property = {};
          property.filter = data.propertyId;
          property.fields = {
            property_dog: this.state.propertyDog
          };
          api.put("/api/property/update", property).then(result => {
            if (result.notify) {
              alert(result.notify);
            } else {
              if (this.state.updatePropertyNotification) {
                this.props.showNotification("success", {
                  title: "Success!",
                  message: "Edit Successfully."
                });
              }

              this.updateRowIndex(result.properties, index);
            }
          });
        }
      }
    );
  }

  handleAddPropertyPanelToggle = () => {
    window.postMessage(
      {
        type: "checkSRPConnection",
      }, "*");
    this.setState(prevState => ({ plusIcon: !prevState.plusIcon }));
  }

  clearRefinementsFilter(e) {
    e.preventDefault();
    const { pathname } = browserHistory.getCurrentLocation();

    this.setState(
      prevState => {
        const { tableStagedFilters } = prevState;

        this.tableSearchInput.value = "";

        Object.keys(tableStagedFilters).forEach(key => {
          switch (key) {
            case "bas":
            case "engagements":
            case "phases":
            case "prices":
            case "spotters":
            case "states":
              tableStagedFilters[key] = [];
              break;

            case "dateMax":
            case "dateMin":
              tableStagedFilters[key] = moment()
                .subtract(6, "months")
                .format("DD/MM/YY");
              break;

            case "search":
            case "inspectionDateMax":
            case "inspectionDateMin":
              tableStagedFilters[key] = "";
              break;

            default:
              tableStagedFilters[key] = false;
          }
        });

        return { tableStagedFilters };
      },
      () => browserHistory.replace({ pathname })
    );
  }
}

export default PropertyListBase;
