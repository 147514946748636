import React, { Component } from "react";
import ReactDOM from "react-dom";

class Modal extends Component {
  componentWillMount() {
    this.node = document.createElement("div");
    document.body.appendChild(this.node);
    ReactDOM.unstable_renderSubtreeIntoContainer(
      this,
      this.props.children,
      this.node
    );
  }

  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this.node);
    document.body.removeChild(this.node);
  }

  render() {
    return null;
  }
}

export default Modal;
