/* Phase List (used in Propertny List Page) */

module.exports = {
	"PIG" : "#dbe5f1",
	"HOT" : "#E16446",
	"Presented" : "#fbd5b5",
	"Waiting" : "#fac08f",
	"Due Diligence" : "#e36c09",
	"Proceed" : "#c3d69b",
	"Purchased" : "#9bbb59",
	"Missed" : "#ff0000"
}
