'use strict';
import Base  from './ClientLoginBase';
import Render  from './ClientLoginRender';
import { connect } from 'react-redux';

class ClientLogin extends Base {
    render() {
        return Render.call(this, this.props, this.state);
    }
}

export default ClientLogin = connect((state)=>{
    return {user:state.user || {}}
})(ClientLogin);
