import React, { PureComponent } from "react";
import moment from "moment";

// Modules
import { durationFromTodayToDateInDays } from "../../common/date-helpers";

// Components
import {
  FormCardHeader,
  FormDatepicker,
  FormInput,
  FormReadOnly
} from "../FormComponents";

function getDaysSinceSettle(settleDate) {
  if (!settleDate) {
    return "Settle date hasn't been set";
  }

  const today = moment().startOf("day");

  if (settleDate.isBefore(today)) {
    return durationFromTodayToDateInDays(settleDate);
  } else if (settleDate.isSame(today)) {
    return "Settle date is today!";
  } else {
    return "Property hasn't settled";
  }
}

function getDaysVacant(dateAvailableForRent, tenantedDate) {
  if (tenantedDate) {
    return "Property is tenanted";
  } else if (dateAvailableForRent) {
    return durationFromTodayToDateInDays(dateAvailableForRent);
  } else {
    return "Date Available for Rent hasn't been set";
  }
}

class AwaitingTenant extends PureComponent {
  render() {
    const {
      actualRent,
      dateAvailableForRent,
      onDateChange,
      onInputChange,
      propertyManager,
      settleDate,
      tenantedDate
    } = this.props;

    return (
      <div>
        <FormCardHeader>Awaiting Tenant</FormCardHeader>

        <div className="content" style={{ zIndex: "initial" }}>
          <FormInput
            label="Property Manager"
            name="propertyManager"
            onChange={onInputChange}
            placeholder="Property Manager"
            type="text"
            value={propertyManager}
          />

          <FormDatepicker
            label="Date Available for Rent"
            onChange={onDateChange("dateAvailableForRent")}
            value={dateAvailableForRent}
          />

          <FormReadOnly
            label="Days Since Settle"
            value={getDaysSinceSettle(settleDate)}
          />

          <FormReadOnly
            label="Days Vacant"
            value={getDaysVacant(dateAvailableForRent, tenantedDate)}
          />

          <FormDatepicker
            label="Tenanted Date"
            onChange={onDateChange("tenantedDate")}
            value={tenantedDate}
          />

          <FormInput
            label="Actual Rent"
            name="actualRent"
            onChange={onInputChange}
            placeholder="$9,999/per week"
            type="text"
            value={actualRent}
          />
        </div>
      </div>
    );
  }
}

export default AwaitingTenant;
