import React, { PureComponent } from "react";
import Geosuggest from "react-geosuggest";
import Select from "react-select";
import "react-select/dist/react-select.css";

// Modules
import priceList from "../../common/priceList";


class AddProperty extends PureComponent {
  render() {
    const {
      address,
      baths,
      beds,
      buyPrice,
      cars,
      engagements,
      engagementsOptions,
      isOverridingAddress,
      onClose,
      onGeosuggestSuggestSelect,
      onInputChange,
      onOverrideAddressToggle,
      onSelectChange,
      onSubmit,
      phase,
      price,
      state,
      suburb,
      url
    } = this.props;

    return (
      <div className="row">
        <div className="col-xs-12 col-lg-offset-2 col-lg-8">
          <div className="addPropertyBox">
            {/* Header */}
            <div className="row">
              <button
                type="button"
                className="close"
                data-toggle="collapse"
                href="#collapseAddBody"
                onClick={onClose}
              >
                &times;
              </button>
              <h4 className="modal-title text-center">Add Property</h4>
            </div>

            <div className="row">
              <form className="form-horizontal m-t-15">
                <div className="row">
                  <label className="right m-r-13">
                    <input
                      name="override"
                      type="checkbox"
                      checked={isOverridingAddress}
                      onChange={onOverrideAddressToggle}
                    />{" "}
                    Override
                  </label>
                </div>

                {/* Address */}
                <fieldset>
                  <div className="form-group">
                    <label className="col-xs-2 control-label">
                      Address<star>*</star>
                    </label>
                    <div className="col-xs-10">
                      {isOverridingAddress ? (
                        <input
                          type="text"
                          name="address"
                          className="form-control"
                          placeholder="Address here..."
                          value={address}
                          onChange={onInputChange}
                        />
                      ) : (
                        <Geosuggest
                          placeholder="Start typing..."
                          country="au"
                          onSuggestSelect={onGeosuggestSuggestSelect}
                        />
                      )}
                    </div>
                  </div>
                </fieldset>

                {/* Phase */}
                <fieldset>
                  <div className="form-group">
                    <label className="col-xs-2 control-label">
                      Phase<star>*</star>
                    </label>
                    <div className="col-xs-10">
                      <select
                        name="phase"
                        className="form-control"
                        onChange={onInputChange}
                        value={phase}
                        required
                      >
                        <option value="">Select Phase</option>
                        <option value="PIG">PIG</option>
                        <option value="HOT">HOT</option>
                        <option value="Presented">PRESENTED</option>
                      </select>
                    </div>
                  </div>
                </fieldset>

                {/* Engagement */}
                <fieldset>
                  <div className="form-group">
                    <label className="col-xs-2 control-label">Engagement</label>
                    <div className="col-xs-10">
                      <Select
                        multi
                        onChange={onSelectChange("engagements")}
                        options={engagementsOptions}
                        placeholder="Select engagements..."
                        value={engagements}
                      />
                    </div>
                  </div>
                </fieldset>

                {/* URL */}
                <fieldset>
                  <div className="form-group">
                    <label className="col-xs-2 control-label">URL</label>
                    <div className="col-xs-10">
                      <input
                        type="text"
                        placeholder="url of this property"
                        className="form-control"
                        name="url"
                        value={url}
                        onChange={onInputChange}
                        required
                      />
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <div className="form-group column-sizing">
                    <div className="col-xs-12">
                      <div className="row">
                        {/* Suburb */}
                        <label className="col-xs-2 control-label">
                          Suburb<star>*</star>
                        </label>
                        <div className="col-xs-4">
                          <input
                            type="text"
                            placeholder="suburb"
                            className="form-control"
                            name="suburb"
                            value={suburb}
                            onChange={onInputChange}
                            required
                          />
                        </div>

                        {/* State */}
                        <label className="col-xs-2 control-label">
                          State<star>*</star>
                        </label>
                        <div className="col-xs-4">
                          <input
                            type="text"
                            placeholder="state"
                            className="form-control"
                            name="state"
                            value={state}
                            onChange={onInputChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <div className="form-group column-sizing">
                    <div className="col-xs-12">
                      <div className="row">
                        {/* Beds */}
                        <label className="col-xs-2 control-label">Beds</label>
                        <div className="col-xs-4">
                          <input
                            type="text"
                            placeholder="Beds"
                            className="form-control"
                            name="beds"
                            value={beds}
                            onChange={onInputChange}
                            required
                          />
                        </div>

                        {/* Baths */}
                        <label className="col-xs-2 control-label">Baths</label>
                        <div className="col-xs-4">
                          <input
                            type="text"
                            placeholder="Baths"
                            className="form-control"
                            name="baths"
                            value={baths}
                            onChange={onInputChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <div className="form-group column-sizing">
                    <div className="col-xs-12">
                      <div className="row">
                        {/* Cars */}
                        <label className="col-xs-2 control-label">Cars</label>
                        <div className="col-xs-4">
                          <input
                            type="text"
                            placeholder="Parking spaces"
                            className="form-control"
                            name="cars"
                            value={cars}
                            onChange={onInputChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <div className="form-group column-sizing">
                    <div className="col-xs-12">
                      <div className="row">
                        {/* Buy price */}
                        <label className="col-xs-2 control-label">
                          Buy Price<star>*</star>
                        </label>
                        <div className="col-xs-4">
                          <select
                            name="buyPrice"
                            className="form-control"
                            onChange={onInputChange}
                            value={buyPrice}
                            required
                          >
                            <option value="">Select Price</option>
                            {priceList.map((key, index) => (
                              <option key={index} value={key}>
                                {key}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* Advertised price */}
                        <label className="col-xs-2 control-label">
                          Adv Price<star>*</star>
                        </label>
                        <div className="col-xs-4">
                          <input
                            type="text"
                            placeholder="Price"
                            className="form-control"
                            name="price"
                            value={price}
                            onChange={onInputChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>

            {/* Footer */}
            <div className="modal-footer cntr">
              <button
                className="sweet-confirm btn btn-info btn-fill"
                onClick={onSubmit}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  }
}

export default AddProperty;
