'use strict';
import React, { Component } from 'react';
import api from '../../common/api';
import { browserHistory } from 'react-router';

class FeedbackFormBase extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            description : "",
            severity : "",
            image : null,
            file : null
        }
        this.onDrop = this.onDrop.bind(this)
        this.addFeedback = this.addFeedback.bind(this);
        this.clearForm = this.clearForm.bind(this);
    }

    componentWillMount() {
        const admin = this.props.user ? this.props.user.admin : '';
        const advocateToken = localStorage.getItem('USER_TOKEN');
        const clientToken = localStorage.getItem('CLIENT_TOKEN');
        if(!admin && !clientToken) {
            browserHistory.push('client-login');
        }else if(!admin) {
            browserHistory.push('/home/shortlisted-property');
        } else if (!advocateToken) {
            browserHistory.push('login');
        }
    }

    onDrop(files) {
        const user = this.state.user;
        this.setState({
            files,
            file : files[0].preview
        })
        var formData = new FormData();
        formData.append('file',files[0]);
        api.post('/api/upload/', formData).then(result => {
            if (result.notify) {
                alert('Message', result.notify);
            } else {
                this.setState({
                    image : result.path
                })
            }
        });
    }

    addFeedback(event) {
        event.preventDefault();
        if(!this.state.severity || !this.state.description) {
            alert("Please fill all the required fields");
        } else {
            let feedback = {};
            feedback.severity = this.state.severity;
            feedback.description = this.state.description;
            feedback.image = this.state.image ? this.state.image : '';
            api.post('/api/feedback/add', feedback).then(result => {
                if (result.notify) {
                    alert('Message', result.notify);
                } else {
                    this.setState({
                        description : "", severity : "", image : null, file : null
                    })
                    // alert("Profile Updated")
                }
            });
        }
    }

    clearForm(event) {
        event.preventDefault();
        this.setState({
            description : "", severity : "", image : null, file : null
        })
    }

}

module.exports = FeedbackFormBase;
