'use strict';
import { Component } from 'react';
import { browserHistory } from 'react-router';

// Modules
import api from '../../common/api';
import * as redux from '../../redux/redux';

class ClientLoginBase extends Component {
    constructor(props) {
        super(props);
        this.state = {username:'',password:'', type:"password"}
        this.login = this.login.bind(this);
    }

    /* API call for Client Login */
    login(e) {
        e.preventDefault();
        // let {username, password} = this.state;
        let clientId = this.refs.username.value;
        let password = this.refs.password.value;
        if (!clientId || !password) {
            alert('Please fill all fields');
        } else {
            api.post('/api/client/login', {clientId, password}).then(result => {
                if (result.notify) {
                    alert(result.notify);
                } else {
                    redux.setUser(result);
                    alert("login Successfully.")
                    localStorage.setItem('CLIENT_TOKEN',result.token)
                    browserHistory.push('/home/shortlisted-property');
                }
            });
        }
    }
}

module.exports = ClientLoginBase;
