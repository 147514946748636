import React from "react";
import "./PanelHeader.css";

function PanelHeader({ title, children, waterMark }) {
  let watermarkIcon;

  if (waterMark === "water-mark-green") {
    watermarkIcon = (
      <span className="label label-default content-panel-header_watermark">
        Met watermark
      </span>
    );
  } else if (waterMark === "water-mark-red") {
    watermarkIcon = (
      <span className="label label-danger content-panel-header_watermark">
        Below watermark
      </span>
    );
  }

  return (
    <header className="content-panel-header">
      <h3 className="content-panel-header__title">{title}</h3>
      {waterMark && watermarkIcon}
      <div className="content-panel-header__options">{children}</div>
    </header>
  );
}

export default PanelHeader;
