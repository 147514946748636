'use strict';
import React, { Component } from 'react';
import api from '../../common/api';
import { browserHistory } from 'react-router'

class ResetPasswordBase extends Component {
    constructor(props) {
        super(props);
        this.state={
            newPassword : '',
            confirmPassword : ''
        };
        this.reset=this.reset.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value =  target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    reset(e){
        e.preventDefault();
        if(!this.state.newPassword || !this.state.confirmPassword) {
            alert("Please fill all fields");
        }else if(this.state.newPassword != this.state.confirmPassword) {
            alert("Passwords don't match ")
        } else {
            let user = {};
            user.update = {
                password : this.state.newPassword
            }

            api.post('/api/auth/resetPassword?authtoken=' + encodeURIComponent(this.props.params.verificationCode),user).then(result => {
                if (result.notify) {
                    alert(result.notify);
                } else {
                    alert(result.message)
                    browserHistory.push('/login');
                }
            })
        }
    }

}

module.exports = ResetPasswordBase;
