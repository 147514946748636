'use strict';
import React from 'react';
import Dropzone from 'react-dropzone';

export default function(){
    return FeedbackFormComponentRender.call(this);
}

var FeedbackFormComponentRender = function(){
    return (
    	<div className="row">
			<div className="col-md-offset-2 col-md-8">
                <div className="card">
                    <form id="changePasswordFormValidation" autoComplete="off">
                        <div className="header">
							<h4 className="title">
								Feedback Form
							</h4>
						</div>
                        <div className="content">
                            <div className="form-group">
                                <label className="control-label">
				                    Description <star>*</star>
				                </label>
                                <textarea className="form-control"
                                    name="description"
                                    type="password"
                                    placeholder="Write description here..."
                                    required="true"
                                    style={{height : '25%'}}
                                    value={this.state.description}
                                    onChange={(e)=>{this.setState({description:e.target.value});}}
				                />
                            </div>
                            <div className="form-group">
                                <label className="control-label">
				                    Severity <star>*</star>
				                </label>
                                <select name="severity" className="form-control width-force-100" value={this.state.severity}  onChange={(e)=>{this.setState({severity:e.target.value});}} >
                                    <option value="">Select Severity</option>
                                    <option value="urgent">Urgent</option>
                                    <option value="high">High</option>
                                    <option value="low">Low</option>
                                    <option value="suggestion">Suggestion</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="control-label">
				                    Upload Screenshot
				                </label>
	                            <Dropzone
	                        		name="image"
	                        		accept="image/jpeg, image/png"
	                        		activeStyle={{border:'1px solid green'}}
	                        		rejectStyle={{border:'1px solid red'}}
	                        		style={{height : '100px', width :'100px'}}
	                        		onDrop={this.onDrop.bind(this)}
	                        	>	
                          			<img className="avatar border-white" style={{height :'100px'}} src={this.state.file ? this.state.file : "/img/face-0.jpg"} alt="..."/>
	                          	</Dropzone>
                            </div>
                            <div className="category"><star>*</star> Required fields</div>
                        </div>
						<div className="card-footer text-center">
							<button type="submit" className="btn btn-info btn-fill" onClick={this.addFeedback}>Save</button>
							<button className="btn m-l-5" style={{display:'inline-block'}} onClick={this.clearForm} >Clear</button>
							<div className="clearfix"></div>
						</div>
                    </form>
                </div>
            </div>
		</div>
    )
};

