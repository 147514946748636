import { connect } from "react-redux";

// Modules
import Base from "./PropertyListBase";
import Render from "./PropertyListRender";

// Components
import withNotifications from "../withNotifications";

class PropertyList extends Base {
  render() {
    return Render.call(this, this.props, this.state);
  }
}

const mapStateToProps = state => ({
  buyers: state.buyers || [],
  // Unsure if this is used
  filter: state.filter || [],
  // Unsure if this is used
  pagination: state.pagination || "",
  socket: state.socket || "",
  user: state.user || {}
});

export default connect(mapStateToProps)(withNotifications(PropertyList));
