import React, { Component } from 'react';
import { connect } from 'react-redux';
import randomColor from 'randomcolor';
import './ChartLabel.css';

// Modules
import { fetchCommonCounts, fetchHotCounts } from './utils';

// Components
import PropertiesPieChart from './PropertiesPieChart';
import MetricsContainer from '../../containers/MetricsContainer';

const createBAsColourMap = bas => {
	const randomColours = randomColor({ count: bas.length });

	const mapped = bas.map(ba => [ ba._id, randomColours.pop() ]);
	return new Map(mapped);
};

class MetricsDashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			proceedData: [],
			presentedData: [],
			missedData: [],
			purchasedData: [],
			hotData: [],
			isPhaseLoad: true,
			isHotPhaseLoad: true,
		};
		this.createPieChart = this.createPieChart.bind(this);
	}

	componentWillMount({buyers} = this.props) {
		this.createPieChart(buyers);
	}

	componentWillReceiveProps({buyers}) {
		this.createPieChart(buyers);	
	}

	createPieChart(buyers) {
		if (!buyers) {
			return;
		}

		fetchCommonCounts(buyers).then(data => {
			// Conditionally add new state data
			const newState = Object.assign({},
				data.Proceed   && { proceedData:   data.Proceed },
				data.Presented && { presentedData: data.Presented },
				data.Missed    && { missedData:    data.Missed },
				data.Purchased && { purchasedData: data.Purchased }
			);

			this.setState(newState, () => {
				this.setState({ isPhaseLoad : false })
			});
		});
		fetchHotCounts(buyers).then(hotData => this.setState({ hotData }, () =>{
			this.setState({ isHotPhaseLoad : false })
		}));
	}

	render() {
		const { buyers: bas = [] } = this.props;
		const {
			proceedData,
			presentedData,
			missedData,
			purchasedData,
			hotData,
			isPhaseLoad,
			isHotPhaseLoad
		} = this.state;

		const colourMap = createBAsColourMap(bas);
		
		return (
			<div>
				{/* This component sits within a .row, so col-xs-12 nullifies that */}
				<div className="col-xs-12">			
				
					<div className="pie-chart-container">
						{bas.map(ba => {
									if(ba.user_role == "BA") {
										return (
											<span key={ba._id} className="pie-chart-label">
												<span className="pie-chart-label__colour" style={{ backgroundColor: colourMap.get(ba._id) }} />
												<span className="pie-chart-label__title">{ba.nickname}</span>
											</span>
										)
									}
								}
							)
						}
					</div>

					<div className="row" style={{ marginBottom: 15 }}>
						<div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
							<PropertiesPieChart
								data={hotData}
								colourMap={colourMap}
								phase='HOT'
								isLoading={isHotPhaseLoad}
							/>
						</div>
						<div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
							<PropertiesPieChart
								data={presentedData}
								colourMap={colourMap}
								phase='Presented'
								isLoading={isPhaseLoad}
							/>
						</div>
						<div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
							<PropertiesPieChart
								data={proceedData}
								colourMap={colourMap}
								phase='Proceed'
								isLoading={isPhaseLoad}
							/>
						</div>
						{/* Apply offset to center at md, clear at lg */}
						<div className="col-xs-6 col-sm-4 col-md-offset-3 col-md-3 col-lg-offset-0 col-lg-2">
							<PropertiesPieChart
								data={purchasedData}
								colourMap={colourMap}
								phase='Purchased'
								isLoading={isPhaseLoad}
							/>
						</div>
						<div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
							<PropertiesPieChart
								data={missedData}
								colourMap={colourMap}
								phase='Missed'
								isLoading={isPhaseLoad}
							/>
						</div>
					</div>
				</div>
				
				<MetricsContainer />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user || {},
	buyers: state.buyers
})


export default connect(mapStateToProps)(MetricsDashboard)