import React, { Component } from "react";
import Datetime from "react-datetime";
import moment from "moment";

// Modules
import priceList from "../../common/priceList";

// Components
import StarRatings from "../../components/startRating/index";

// CSS
import "./custom-calendar.css";

class PropertyTableRowDetails extends Component {
  state = {
    address: "",
    auctionDate: "",
    autoValue: "",
    baths: "",
    beds: "",
    buyPrice: "",
    comments: "",
    comparable_sales: "",
    data: "",
    dsr: "",
    floodCheck: "",
    fsd: "",
    googleDocLink: "",
    growth: "",
    landSize: "",
    locked_ba: "",
    locked: "",
    lsd: "",
    lsp: "",
    notes: "",
    parking: "",
    price: "",
    propertyDog: "",
    propertyType: "",
    rating: 0,
    rent: "",
    soldPrice: "",
    under_contract_exp_date: "",
    vr: "",
    walkScore: "",
    yearBuild: "",
    yield: ""
  };

  componentWillReceiveProps(props) {
    this.setState(props);
  }

  handleUpdateProperty = ev =>
    this.props.updateProperty(
      this.props.propertyDetail,
      this.props.rowIndex,
      ev,
      this.state
    );

  handleUpdatePropertyDate = (date, name) =>
    this.props.updatePropertyDate(
      this.props.propertyDetail,
      this.props.rowIndex,
      date,
      name,
      this.state
    );

  handleUpdateBuyPriceAndPropertyDog = ev =>
    this.props.updateBuyPriceAndPropertyDog(
      this.props.propertyDetail,
      this.props.rowIndex,
      ev
    );

  handleShowRow = () =>
    this.props.showRow(this.props.propertyDetail, this.props.rowIndex);

  handleEditNotes = ev =>
    this.props.editNotes(this.props.propertyDetail, this.props.rowIndex, ev);

  handleUnlockTheProperty = ev =>
    this.props.unlockTheProperty(
      this.props.propertyDetail,
      this.props.rowIndex,
      ev
    );

  handleChangeRating = (startRating, ev) => {
    ev.preventDefault();
    this.props.changeRating(
      startRating,
      this.props.propertyDetail,
      this.props.rowIndex
    );
  };

  handleInputChange = ev => {
    const { name, value, checked, type } = ev.target;
    const newValue = type === "checkbox" ? checked : value;

    this.setState({ [name]: newValue });
  };

  handleDatePickerChange = (date, field) => {
    if (moment.isMoment(date) && date.isValid()) {
      this.setState({ [field]: date });
    }
  };

  render() {
    const {
      address,
      auctionDate,
      autoValue,
      baths,
      beds,
      buyPrice,
      comments,
      comparable_sales,
      data,
      dsr,
      floodCheck,
      fsd,
      googleDocLink,
      growth,
      landSize,
      locked_ba,
      locked,
      lsd,
      lsp,
      notes,
      parking,
      price,
      propertyDog,
      propertyType,
      rating,
      rent,
      soldPrice,
      under_contract_exp_date,
      vr,
      walkScore,
      yearBuild,
      yield: _yield
    } = this.state;

    const { propertyDetail } = this.props;

    return (
      <section>
        <form method="get" action="/" className="form-horizontal">
          <div className="row">
            <div className="col-sm-1 col-xs-12" />
            <div className="col-sm-3 col-xs-12">
              <label className="control-label">
                <input
                  type="checkbox"
                  name="propertyDog"
                  className="fl-l"
                  checked={propertyDog ? true : false}
                  onChange={this.handleUpdateBuyPriceAndPropertyDog}
                  disabled={locked}
                />
                <span className="p-l-5">Avoid this property</span>
              </label>
            </div>
            <div className="col-sm-3 col-xs-12">
              <label className="control-label">
                <span className="p-l-5 p-r-5">Flood Check</span>
                <select
                  name="floodCheck"
                  className="form-control flood-check mar-0 pad-0 hyt-30"
                  value={floodCheck}
                  onChange={this.handleInputChange}
                  onBlur={this.handleUpdateProperty}
                  id="floodCheck"
                  disabled={locked}
                >
                  <option value="" />
                  <option value="Flood Zone">Flood Zone</option>
                  <option value="Not a flood zone">Not a flood zone</option>
                  <option value="N/A">N/A</option>
                </select>
              </label>
            </div>
            {locked && (
              <div className="col-sm-3 col-xs-12">
                <label
                  className="control-label"
                  style={{ display: "block", float: "initial" }}
                >
                  Locked By: {locked_ba}
                  <button onClick={this.handleUnlockTheProperty}>Unlock</button>
                </label>
              </div>
            )}
            <button
              type="button"
              className="close m-r-20"
              onClick={this.handleShowRow}
            >
              &times;
            </button>
          </div>
          <fieldset className=" col-xs-12">
            <div className="form-group col-xs-12 m-t-15">
              <div className="row">
                <label className="col-sm-1 col-xs-12 control-label">
                  BuyPrice
                </label>
                <div className="col-sm-2 col-xs-12">
                  <select
                    name="buyPrice"
                    className="form-control width-force-100"
                    value={buyPrice || ""}
                    onChange={this.handleInputChange}
                    onBlur={this.handleUpdateProperty}
                    disabled={locked}
                  >
                    <option value="">Select Price</option>
                    {priceList.map((key, index) => {
                      return (
                        <option key={index} value={key}>
                          {key}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <label className="col-sm-1 col-xs-12 control-label">
                  AucDate
                </label>
                <div className="col-sm-2 col-xs-12">
                  <Datetime
                    value={auctionDate || ""}
                    timeFormat
                    dateFormat="DD-MMM-YY hh:mm a"
                    showTimeSelect
                    onChange={date =>
                      this.handleDatePickerChange(date, "auctionDate")
                    }
                    onBlur={date =>
                      this.handleUpdatePropertyDate(date, "auctionDate")
                    }
                    inputProps={{ placeholder: "N/A", disabled: locked }}
                  />
                </div>
                <label className="col-sm-1 col-xs-12 control-label">
                  LandSize
                </label>
                <div className="col-sm-2 col-xs-12">
                  <input
                    type="text"
                    placeholder="land Size"
                    className="form-control width-force-100"
                    name="landSize"
                    value={landSize || ""}
                    ref="landSize"
                    onChange={this.handleInputChange}
                    onBlur={this.handleUpdateProperty}
                    disabled={locked}
                  />
                </div>
                <label className="col-sm-1 col-xs-12 control-label p-l-0 p-r-0">
                  Year Built
                </label>
                <div className="col-sm-2 col-xs-12">
                  <Datetime
                    value={yearBuild ? moment(yearBuild) : ""}
                    timeFormat={false}
                    dateFormat="YYYY"
                    onChange={date =>
                      this.handleDatePickerChange(date, "yearBuild")
                    }
                    onBlur={date =>
                      this.handleUpdatePropertyDate(date, "yearBuild")
                    }
                    inputProps={{ placeholder: "N/A", disabled: locked }}
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="col-xs-12">
            <div className="form-group col-xs-12 m-t-15">
              <div className="row">
                <div className="col-xs-12">
                  <div className="row">
                    <label className="col-sm-1 col-xs-12 control-label">
                      Type
                    </label>
                    <div className="col-sm-2 col-xs-12">
                      <input
                        type="text"
                        placeholder="Type"
                        className="form-control width-force-100"
                        name="propertyType"
                        value={propertyType || ""}
                        onChange={this.handleInputChange}
                        onBlur={this.handleUpdateProperty}
                        required
                        disabled={locked}
                      />
                    </div>
                    <label className="col-sm-1 col-xs-12 control-label">
                      Growth
                    </label>
                    <div className="col-sm-2 col-xs-12">
                      <input
                        type="text"
                        placeholder="growth"
                        className="form-control width-force-100"
                        name="growth"
                        value={growth}
                        onChange={this.handleInputChange}
                        onBlur={this.handleUpdateProperty}
                        disabled={locked}
                      />
                    </div>
                    <label className="col-sm-1 col-xs-12 control-label">
                      AutoVal
                    </label>
                    <div className="col-sm-2 col-xs-12">
                      <input
                        type="text"
                        placeholder="auto value"
                        className="form-control width-force-100"
                        name="autoValue"
                        value={autoValue || ""}
                        onChange={this.handleInputChange}
                        onBlur={this.handleUpdateProperty}
                        disabled={locked}
                      />
                    </div>
                    <label className="col-sm-1  col-xs-12 control-label">
                      FSD
                    </label>
                    <div className="col-sm-2 col-xs-12">
                      <input
                        type="text"
                        placeholder="FSD"
                        className="form-control width-force-100"
                        name="fsd"
                        value={fsd || ""}
                        onChange={this.handleInputChange}
                        onBlur={this.handleUpdateProperty}
                        disabled={locked}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="col-xs-12">
            <div className="form-group col-xs-12 m-t-15">
              <div className="row">
                <label className="col-sm-1 col-xs-12 control-label">LSD</label>
                <div className="col-sm-2 col-xs-12">
                  <Datetime
                    value={lsd || ""}
                    timeFormat={false}
                    dateFormat="DD-MMM-YY"
                    onChange={date => this.handleDatePickerChange(date, "lsd")}
                    onBlur={date => this.handleUpdatePropertyDate(date, "lsd")}
                    inputProps={{ placeholder: "N/A", disabled: locked }}
                  />
                </div>
                <label className="col-sm-1 col-xs-12 control-label">Rent</label>
                <div className="col-sm-2 col-xs-12">
                  <input
                    type="text"
                    placeholder="rent"
                    className="form-control width-force-100"
                    name="rent"
                    value={rent || ""}
                    onChange={this.handleInputChange}
                    onBlur={this.handleUpdateProperty}
                    disabled={locked}
                  />
                </div>
                <label className="col-sm-1 col-xs-12 control-label">
                  Yield
                </label>
                <div className="col-sm-2 col-xs-12">
                  <input
                    type="text"
                    placeholder="yield"
                    className="form-control width-force-100"
                    name="yield"
                    value={_yield || ""}
                    onChange={this.handleInputChange}
                    onBlur={this.handleUpdateProperty}
                    disabled={locked}
                  />
                </div>
                <label className="col-sm-1 col-xs-12 control-label">
                  WalkScore
                </label>
                <div className="col-sm-2 col-xs-12">
                  <input
                    type="text"
                    placeholder="Walk Score"
                    className="form-control width-force-100"
                    name="walkScore"
                    value={walkScore || ""}
                    onChange={this.handleInputChange}
                    onBlur={this.handleUpdateProperty}
                    disabled={locked}
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="col-xs-12 col-xs-12">
            <div className="form-group col-xs-12 m-t-15">
              <div className="row">
                <label className="col-sm-1 col-xs-12 control-label">
                  LSP
                </label>
                <div className="col-sm-2 col-xs-12">
                  <input
                    type="text"
                    placeholder="LSP..."
                    className="form-control width-force-100"
                    name="lsp"
                    value={lsp || ""}
                    onChange={this.handleInputChange}
                    onBlur={this.handleUpdateProperty}
                    disabled={locked}
                  />
                </div>
                <label className="col-sm-1 col-xs-12 control-label">
                  DSR
                  <i className="ti-plus" />
                </label>
                <div className="col-sm-2 col-xs-12">
                  <input
                    type="text"
                    placeholder="DSR+"
                    className="form-control width-force-100"
                    name="dsr"
                    value={dsr || ""}
                    onChange={this.handleInputChange}
                    onBlur={this.handleUpdateProperty}
                    disabled={locked}
                  />
                </div>
                <label className="col-sm-1  col-xs-12 control-label">VR</label>
                <div className="col-sm-2 col-xs-12">
                  <input
                    type="text"
                    placeholder="VR"
                    className="form-control width-force-100"
                    name="vr"
                    value={vr || ""}
                    onChange={this.handleInputChange}
                    onBlur={this.handleUpdateProperty}
                    disabled={locked}
                  />
                </div>
                <label className="col-sm-1 col-xs-12 control-label ">
                  Sold Price
                </label>
                <div className="col-sm-2 col-xs-12">
                  <input
                    type="text"
                    placeholder="Sold Price Here"
                    className="form-control width-force-100"
                    name="soldPrice"
                    value={soldPrice || ""}
                    onChange={this.handleInputChange}
                    onBlur={this.handleUpdateProperty}
                    disabled={locked}
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="col-xs-12 col-xs-12">
            <div className="form-group col-xs-12 m-t-15">
              <div className="row">
                <label
                  className="col-sm-1 col-xs-12 control-label "
                  style={{ padding: "0px 15px 0px 0px" }}
                >
                  Under Contract Exp. Date
                </label>
                <div className="col-sm-2 col-xs-12">
                  <Datetime
                    value={under_contract_exp_date || ""}
                    timeFormat={false}
                    dateFormat="DD-MMM-YY"
                    onChange={date =>
                      this.handleDatePickerChange(
                        date,
                        "under_contract_exp_date"
                      )
                    }
                    onBlur={date =>
                      this.handleUpdatePropertyDate(
                        date,
                        "under_contract_exp_date"
                      )
                    }
                    inputProps={{ placeholder: "N/A", disabled: locked }}
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="col-xs-12 col-xs-12">
            <div className="form-group col-xs-12 m-t-15">
              <div className="row">
                <label className="col-sm-1 col-xs-12 control-label">Beds</label>
                <div className="col-sm-2 col-xs-12">
                  <input
                    type="text"
                    placeholder="Beds"
                    className="form-control width-force-100"
                    name="beds"
                    value={beds || ""}
                    onChange={this.handleInputChange}
                    onBlur={this.handleUpdateProperty}
                    disabled={locked}
                  />
                </div>
                <label className="col-sm-1  col-xs-12 control-label">
                  Bathrooms
                </label>
                <div className="col-sm-2 col-xs-12">
                  <input
                    type="text"
                    placeholder="Bathrooms"
                    className="form-control width-force-100"
                    name="baths"
                    value={baths || ""}
                    onChange={this.handleInputChange}
                    onBlur={this.handleUpdateProperty}
                    disabled={locked}
                  />
                </div>
                <label className="col-sm-1 col-xs-12 control-label ">
                  Car Parkings
                </label>
                <div className="col-sm-2 col-xs-12">
                  <input
                    type="text"
                    placeholder="Car Parkings"
                    className="form-control width-force-100"
                    name="parking"
                    value={parking || ""}
                    onChange={this.handleInputChange}
                    onBlur={this.handleUpdateProperty}
                    disabled={locked}
                  />
                </div>
                <label
                  className="col-sm-1 col-xs-12 control-label "
                  style={{ padding: "0px 15px 0px 0px" }}
                >
                  Advertisement Price
                </label>
                <div className="col-sm-2 col-xs-12">
                  <input
                    type="text"
                    placeholder="Price"
                    className="form-control width-force-100"
                    name="price"
                    value={price || ""}
                    onChange={this.handleInputChange}
                    onBlur={this.handleUpdateProperty}
                    disabled={locked}
                  />
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset className="col-xs-12 col-xs-12">
            <div className="form-group col-xs-12 m-t-15">
              <div className="row">
                <label className="col-sm-1 col-xs-12 control-label">
                  Address
                </label>
                <div className="col-sm-11 col-xs-12">
                  <input
                    type="text"
                    className="form-control width-force-100"
                    name="address"
                    value={address || ""}
                    onChange={this.handleInputChange}
                    onBlur={this.handleUpdateProperty}
                    disabled={locked}
                  />
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset className="col-xs-12 col-xs-12">
            <div className="form-group col-xs-12">
              <div className="row">
                <label className="col-sm-1 col-xs-12 control-label">
                  Property Rating
                </label>
                <div
                  className="col-sm-11 col-xs-12"
                  style={{ marginTop: "1rem" }}
                >
                  <StarRatings
                    rating={rating}
                    starRatedColor="#5cb85c"
                    changeRating={this.handleChangeRating}
                    numberOfStars={5}
                    starDimension="2rem"
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="col-xs-12">
            <div className="form-group col-xs-12 m-t-15">
              <div className="row">
                <label className="col-sm-1 col-xs-12 control-label">
                  Notes
                </label>
                <div className="col-sm-11 col-xs-12">
                  {(propertyDetail.editNotes || locked) ? (
                    <textarea
                      className="form-control width-force-100"
                      placeholder="Here can be your nice detail for this property"
                      name="notes"
                      rows="3"
                      onChange={this.handleInputChange}
                      value={notes || ""}
                      onBlur={this.handleUpdateProperty}
                      disabled={locked}
                      style={{ whiteSpace: 'pre-line' }}
                    />
                  ) : (
                    <div
                      title="Double Click to Edit"
                      id={"notes-here" + propertyDetail.propertyId}
                      className="form-control width-force-100 non-edit-notes"
                      onDoubleClick={this.handleEditNotes}
                    >
                      {notes || ""}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="col-xs-12">
            <div className="form-group col-xs-12 m-t-15">
              <div className="row">
                <label
                  className="col-sm-1 control-label col-xs-12"
                  style={{ paddingLeft: "0px" }}
                >
                  Additional Info
                </label>
                <div className="col-sm-11 col-xs-12">
                  <input
                    type="text"
                    placeholder="Doc Link Here"
                    className="form-control width-force-100"
                    name="googleDocLink"
                    value={googleDocLink || ""}
                    onChange={this.handleInputChange}
                    onBlur={this.handleUpdateProperty}
                    disabled={locked}
                  />
                </div>
              </div>
            </div>
          </fieldset>
          {googleDocLink && (
            <div className="form-group form-group col-xs-12">
              <div className="row">
                <label className="col-sm-1 col-xs-12 control-label">
                  DocsLink
                </label>
                <div className="col-sm-11 col-xs-12">
                  <a
                    href={googleDocLink}
                    target="blank"
                    title="Cick to go to Docs"
                  >
                    Google Docs
                  </a>
                </div>
              </div>
            </div>
          )}

          <fieldset className="col-xs-12">
            <div className="form-group col-xs-12 m-t-15">
              <div className="row">
                <label className="col-sm-1 col-xs-12 control-label">
                  Comments
                </label>
                <div className="col-sm-11 col-xs-12">
                  {(propertyDetail.editNotes || locked) ? (
                    <textarea
                      className="form-control width-force-100"
                      placeholder="Here can be your nice detail for this property"
                      name="comments"
                      rows="3"
                      onChange={this.handleInputChange}
                      value={comments || ""}
                      onBlur={this.handleUpdateProperty}
                      disabled={locked}
                      style={{ whiteSpace: 'pre-line' }}
                    />
                  ) : (
                    <div
                      title="Double Click to Edit"
                      id={"notes-here" + propertyDetail.propertyId}
                      className="form-control width-force-100 non-edit-notes"
                      onDoubleClick={this.handleEditNotes}
                    >
                      {comments || ""}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="col-xs-12">
            <div className="form-group col-xs-12 m-t-15">
              <div className="row">
                <label className="col-sm-1 col-xs-12 control-label">Data</label>
                <div className="col-sm-11 col-xs-12">
                  {(propertyDetail.editNotes || locked) ? (
                    <textarea
                      className="form-control width-force-100"
                      placeholder="Here can be your nice detail for this property"
                      name="data"
                      rows="3"
                      onChange={this.handleInputChange}
                      value={data || ""}
                      onBlur={this.handleUpdateProperty}
                      disabled={locked}
                      style={{ whiteSpace: 'pre-line' }}
                    />
                  ) : (
                    <div
                      title="Double Click to Edit"
                      id={"notes-here" + propertyDetail.propertyId}
                      className="form-control width-force-100 non-edit-notes"
                      onDoubleClick={this.handleEditNotes}
                    >
                      {data || ""}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="col-xs-12">
            <div className="form-group col-xs-12 m-t-15">
              <div className="row">
                <label className="col-sm-1 col-xs-12 control-label">
                  Comparable Sales
                </label>
                <div className="col-sm-11 col-xs-12">
                  {(propertyDetail.editNotes || locked) ? (
                    <textarea
                      className="form-control width-force-100"
                      placeholder="Here can be your nice detail for this property"
                      name="comparable_sales"
                      rows="3"
                      onChange={this.handleInputChange}
                      value={comparable_sales || ""}
                      onBlur={this.handleUpdateProperty}
                      disabled={locked}
                      style={{ whiteSpace: 'pre-line' }}
                    />
                  ) : (
                    <div
                      title="Double Click to Edit"
                      id={"notes-here" + propertyDetail.propertyId}
                      className="form-control width-force-100 non-edit-notes"
                      onDoubleClick={this.handleEditNotes}
                    >
                      {comparable_sales || ""}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </fieldset>
        </form>

        <div className="row">
          <div className="modal-footer cntr col-xs-12 m-t-15">
            <button
              className="sweet-confirm btn btn-success btn-fill"
              onClick={this.handleUpdateProperty}
              disabled={locked}
            >
              Save
            </button>
            <button
              className="sweet-cancel btn btn-default"
              onClick={this.handleShowRow}
            >
              Cancel
            </button>
          </div>
        </div>
      </section>
    );
  }
}

export default PropertyTableRowDetails;
