import React, { PureComponent } from "react";

// Modules
import brokers from "../../common/financeBrokers";
import { getClientFullName, labelCreator } from "../../common/client-helpers";

// Components
import {
  BASelect,
  BudgetSelect,
  FormCardHeader,
  FormDatepicker,
  FormInput,
  FormOptionalPercentageInput,
  FormReadOnly,
  StateSelect,
  StatusSelect
} from "../FormComponents";

class PurchaseDetail extends PureComponent {
  render() {
    const {
      baId,
      bas,
      budget,
      client,
      engageDate,
      exactBudget,
      financeBroker,
      g2gDate,
      growth,
      growthRef,
      incubationType,
      initialDate,
      onDateChange,
      onInputChange,
      onInputNaChange,
      property,
      state,
      status,
      yield: _yield,
      yieldRef
    } = this.props;
    const clientName = getClientFullName(client);

    return (
      <div>
        <FormCardHeader>General Details</FormCardHeader>

        <div className="content" style={{ zIndex: "initial" }}>
          <FormReadOnly label="Client" value={clientName} />

          <FormReadOnly
            label="Label for Scrapper"
            value={labelCreator(clientName, budget, g2gDate)}
          />

          <FormReadOnly
            label="Property"
            value={property ? property.address : ""}
          />

          <div className="form-group">
            <BASelect
              bas={bas}
              name="baId"
              onChange={onInputChange}
              value={baId || ""}
            />
          </div>

          <div className="form-group">
            <StatusSelect
              name="status"
              onChange={onInputChange}
              value={status}
            />
          </div>

          <BudgetSelect name="budget" onChange={onInputChange} value={budget} />

          <FormInput
            label="Exact Budget"
            name="exactBudget"
            onChange={onInputChange}
            placeholder="9999"
            type="text"
            value={exactBudget}
          />

          <FormOptionalPercentageInput
            label="Growth"
            innerRef={growthRef}
            name="growth"
            onChange={onInputChange}
            onNAChange={onInputNaChange("growth")}
            value={growth}
          />

          <FormOptionalPercentageInput
            label="Yield"
            innerRef={yieldRef}
            name="yield"
            onChange={onInputChange}
            onNAChange={onInputNaChange("yield")}
            value={_yield}
          />

          {/* Incubation Type */}
          {status === "Incubation" && (
            <div className="form-group">
              <label>Incubation Type</label>
              <div>
                <label style={{ margin: 5 }}>ER:</label>
                <input
                  checked={incubationType === "er"}
                  name="incubationType"
                  onChange={onInputChange}
                  type="radio"
                  value="er"
                />

                <label style={{ margin: 5 }}>PA:</label>
                <input
                  checked={incubationType === "pa"}
                  name="incubationType"
                  onChange={onInputChange}
                  type="radio"
                  value="pa"
                />
              </div>
            </div>
          )}

          <StateSelect name="state" onChange={onInputChange} value={state} />

          {/* Financer */}
          <div className="form-group">
            <label>Financer</label>
            <input
              className="form-control textUpperCaseNormalWeight"
              list="finance_broker"
              name="financeBroker"
              onChange={onInputChange}
              value={financeBroker}
            />
            <datalist id="finance_broker">
              {brokers.map(broker => (
                <option key={broker} value={broker.toUpperCase()}>
                  {broker}
                </option>
              ))}
            </datalist>
          </div>

          <FormDatepicker
            label="Good to Go Date"
            onChange={onDateChange("g2gDate")}
            value={g2gDate}
          />

          <FormDatepicker
            label="Engagement Date"
            onChange={onDateChange("engageDate")}
            value={engageDate}
          />

          <FormDatepicker
            label="Initial Date"
            onChange={onDateChange("initialDate")}
            value={initialDate}
          />
        </div>
      </div>
    );
  }
}

export default PurchaseDetail;
