// Map colours (https://betterfigures.org/2015/06/23/picking-a-colour-scale-for-scientific-graphics/)
const lightBlue = "#a6cee3";
const blue = "#1f78b4";
const lightGreen = "#b2df8a";
const green = "#33a02c";
const lightRed = "#fb9a98";
const red = "#e31a1d";
const lightOrange = "#fdc06f";
const orange = "#ff7e00";
const lightPurple = "#cab2d6";
const purple = "#6a3d9a";
const lightYellow = "#ffff98";
const brown = "#b15829";

const statusMap = new Map([
  [
    "AwaitingTenant",
    {
      colour: lightGreen,
      displayName: "Awaiting for Tenant"
    }
  ],
  [
    "Blueprint",
    {
      colour: blue,
      displayName: "Blueprint"
    }
  ],
  [
    "Conditional",
    {
      colour: lightOrange,
      displayName: "Conditional"
    }
  ],
  [
    "Engaged",
    {
      colour: purple,
      displayName: "Engaged"
    }
  ],
  [
    "GoodToGo",
    {
      colour: green,
      displayName: "G2G"
    }
  ],
  [
    "Hold-LT",
    {
      colour: red,
      displayName: "Hold-LT"
    }
  ],
  [
    "Hold",
    {
      colour: red,
      displayName: "Hold"
    }
  ],
  [
    "Inc-LT",
    {
      colour: brown,
      displayName: "Inc-LT"
    }
  ],
  [
    "Incubation",
    {
      colour: brown,
      displayName: "Inc"
    }
  ],
  [
    "YetToSettle",
    {
      colour: lightPurple,
      displayName: "Yet to Settle"
    }
  ]
]);

const sortOrder = [
  "GoodToGo",
  "Incubation",
  "Inc-LT",
  "Engaged",
  "Hold",
  "Hold-LT",
  "Conditional",
  "AwaitingTenant",
  "YetToSettle",
  "Blueprint"
];

// Add sort indices
sortOrder.forEach((status, i) => {
  // i + 1 as if we're sorting we don't want 0 as a sortIndex
  statusMap.get(status).sortIndex = i + 1;
});

const sortByStatus = status =>
  statusMap.has(status) && statusMap.get(status).sortIndex;

export { statusMap, sortByStatus };
