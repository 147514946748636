'use strict';
import React, { Component } from 'react';
import api from '../../common/api';
import { browserHistory } from 'react-router';
import moment from 'moment';

class FeedbackListBase extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            feedbackList : [],
            csvList : [],
            hideClosed : true
        }
        this.filterFeedbackList = this.filterFeedbackList.bind(this)
    }

    componentWillMount() {
        const admin = this.props.user ? this.props.user.admin : '';
        const advocateToken = localStorage.getItem('USER_TOKEN');
        const clientToken = localStorage.getItem('CLIENT_TOKEN');
        if(!admin && !clientToken) {
            browserHistory.push('client-login');
        }else if(!admin) {
            browserHistory.push('/home/shortlisted-property');
        } else if (!advocateToken) {
            browserHistory.push('login');
        }
    }

    componentDidMount() {
        this.getFeedbackList();
    }

    getFeedbackList() {
        console.log("this.state.hideClosed", this.state.hideClosed);
        api.get('/api/feedback/list?filter=' + encodeURIComponent(JSON.stringify(this.state.hideClosed))).then(result => {
            if (result.notify) {
                alert(result.notify);
            } else {
                this.feedbackList(result)
            }
        }).catch(error => {
            console.log("error is ", error);
        })
    }

    feedbackList(data) {
        this.setState({
            feedbackList : data.feedbacks
        },() =>{
            this.manipulateCSVData(data)
        })
    }

    manipulateCSVData(data) {
        let feedbackList = [];
        data.feedbacks.forEach((feedbackData, index) => {
            feedbackList.push({
                id : index +1,
                date : moment(feedbackData.created_on).format('DD-MMM-YY'),
                severity : feedbackData.severity,
                Description : feedbackData.description,
                status : feedbackData.status
            })
        })    
        this.setState({
            csvList : feedbackList 
        })
    }

    filterFeedbackList(event) {
        event.preventDefault();
        this.setState({
            hideClosed : !this.state.hideClosed
        }, ()=> {
            this.getFeedbackList();
        })
    }
}

module.exports = FeedbackListBase;
