'use strict';
import React from 'react';
import {Link} from 'react-router';
import Header from '../header/Header';
import Footer from '../footer/Footer';

export default function(){
    return ForgotPasswordRender.call(this);
}

var ForgotPasswordRender = function(){

	const divStyle = {
        backgroundImage: 'url(' + "img/background-6.jpg" + ')',
        overflow: 'hidden',
        backgroundSize: 'cover'
    };

    return (
    	<div className="wrapper">
            <Header home />
            <div className="wrapper wrapper-full-page">
                <div className="full-page login-page" data-color="" data-image="img/background-6.jpg" >
                    <div className="content">
                        <div className="container">
                            <div className="row" id="login">
						    	<div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
						            <div data-color="blue" className="card pages-bgcolor">
						                <form id="loginFormValidation">
						                    <div className="header">
						                        <h4 className="title pages-color">
						                            Forgot Password
						                        </h4>
						                        <p className="pages-color">To reset your password, enter the username you use to sign in</p>
						                    </div>
						                    <div className="content">
						                        <div className="form-group">
						                            <label className="control-label pages-color">
						                                Username
						                                <star>*</star>
						                            </label>
						                            <input className="form-control" placeholder="Enter username" name="username" type="text" required="true" autoComplete="off" onChange={(e)=>{this.setState({username:e.target.value})}} value={this.state.username} />
						                        </div>
						                    </div>
						                    <div className="footer text-center">
						                        <button type="submit" className="btn pages-buttoncolor" onClick={this.forgotPassword}>Get Reset Link</button>
						                        <div className="forgot">
						                            <Link to="/login" className="pages-color">Login</Link>
						                        </div>
						                    </div>
						                </form>
						            </div>
						        </div>
         					</div>
                        </div>    
                    </div>
                    <Footer />
                    <div className="full-page-background" style={divStyle} >
                    </div> 
                </div>
            </div> 
        </div>
    )
};