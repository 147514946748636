
// Modules
import MailStore from './store';
import { login, filter, propertyPriceDetail, socketConnection, pagination, buyers, purchases, users, businessDays } from './actions/index';
import api from '../common/api';

//get user from redux store...
export function getUserAsync() {
    return new Promise(resolve => {
        const user = MailStore.getState().user;

        if (user) {
            resolve(user);
        } else {
            const token = localStorage.getItem('USER_TOKEN');

        	if (token) {
                api.get('/api/auth/user').then(res => {
                    if (res.success) {
                        const user = { ...res, token, admin: true };
                        if(user.info.user_role === "super-admin" || user.info.user_role === "manager") getBusinessDaysAsync()
                        setUser(user);
                        resolve(user);
                    } else {
                        resolve(null);
                    }
                });
        	}
        }
    });
}
//get business days from redux store...
export function getBusinessDaysAsync() {
    return new Promise(resolve => {
        const businessDays = MailStore.getState().businessDays;

        if (businessDays) {
            resolve(businessDays);
        } else {
            const token = localStorage.getItem('USER_TOKEN');
        	if (token) {
                api.get('/api/business/').then(result => {
                    if (result) {
                        const businessDays = result.days[0];
                        setBusinessDays(businessDays);
                        resolve(businessDays);
                    } else {
                        resolve(null);
                    }
                })
        	}
        }
    });
}
//get business days from redux store...
export function updateBusinessDaysAsync() {
    return new Promise(resolve => {

        const token = localStorage.getItem('USER_TOKEN');
        if (token) {
            api.get('/api/business/').then(result => {
                if (result) {
                    const businessDays = result.days[0];
                    setBusinessDays(businessDays);
                    resolve(businessDays);
                } else {
                    resolve(null);
                }
            })
        }
    });
}


//store user in redux store....
export function setUser(data) {
    MailStore.dispatch(login(data));
}
//store business days in redux store....
export function setBusinessDays(data) {
    MailStore.dispatch(businessDays(data));
}

//store filter in redux store....
export function setFilter(data) {
    MailStore.dispatch(filter(data));
}

//store price detail in redux store....
export function setPropertyPriceDetail(data) {
    MailStore.dispatch(propertyPriceDetail(data));
}

//store socket connection in redux store....
export function setSocketConnection(data) {
    MailStore.dispatch(socketConnection(data));
}

//store property list pagination in redux store....
export function setPropertyPagination(data) {
    MailStore.dispatch(pagination(data));
}

//store buyers list in redux store....
export function setBuyers(data) {
    MailStore.dispatch(buyers(data));
}

//store users list in redux store....
export function setUsers(data) {
    MailStore.dispatch(users(data));
}

//store purchases list(i.e. Client) in redux store....
export function setPurchases(data) {
    MailStore.dispatch(purchases(data));
}
