import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

// Modules
import api from '../common/api';
import phaseList from '../common/phaseList';

class AuditTrailContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedEngagement:"",
            auditTrail: []
        };

        this.handleSelector = this.handleSelector.bind(this);
        this.fetchAuditTrail = this.fetchAuditTrail.bind(this);
        this.renderAuditTrailTable = this.renderAuditTrailTable.bind(this);
    }

    async componentDidMount() {
        const user = this.props.user.info ? this.props.user.info : await api.get('/api/users/getUserData');
        const filter = JSON.stringify({ selectEngagement: (user.user_role === 'super-admin' || user.user_role === 'manager') ? 'All' : 'mineAll' })
        api.get(`/api/clientPurchases/list?filter=${filter}`)
            .then(response => {
                const engagements = response.purchases || [];
                /* const engagements = _(stageGroups)
                    .flatMap(x => x.purchases)
                    .filter(x => x.client !== undefined)
                    .sortBy(['client.first_name', 'client.last_name'])
                    .value(); */

                this.setState({ engagements });
            });
    }

    handleSelector({ target }) {
        const input = { [target.name]: target.value };

        this.setState(input, () =>
            this.fetchAuditTrail(this.state.selectedEngagement)
        );
    }

    fetchAuditTrail(id) {
        if(id) {
            const query = encodeURIComponent(id);
    
            api.get(`/api/clientPurchases/auditTrail?filter=${query}`)
                .then(auditTrail => this.setState(auditTrail))
                .catch(console.error);
        }
    }

    renderAuditTrailTable() {
        const { auditTrail } = this.state;
        
        if (auditTrail.length === 0) {
            return (
                <tr>
                    <td colSpan="5" style={{ textAlign: 'center' }}>Engagement has no history</td>
                </tr>
            );
        }

        const phaseStyle = {
            padding: 8,
            colour: 'white',
            backgroundColor: 'pink',
            borderRadius: '30%'
        };

        return auditTrail.map((event, i) => {
            // Alias variables
            const action = event.audit_trail.matter;
            const prevPhase = event.audit_trail.property_phase.from;
            const newPhase = event.audit_trail.property_phase.to;

            // Styles
            const actionStyle = { color: getColourForAction(action) };
            const prevPhaseStyle = Object.assign({}, phaseStyle, { backgroundColor: phaseList[prevPhase] });
            const newPhaseStyle = Object.assign({}, phaseStyle, { backgroundColor: phaseList[newPhase] });

            return (
                <tr key={i}>
                    <td style={actionStyle}>{action}</td>
                    <td>
                        <a href={`list?_id=${event.audit_trail.property._id}`}>{event.audit_trail.property.address}</a>
                    </td>
                    <td>
                        <span style={prevPhaseStyle}>{prevPhase}</span>
                    </td>
                    <td>
                        <span style={newPhaseStyle}>{newPhase}</span>
                    </td>
                    <td>{moment(event.audit_trail.createdAt).format('DD-MMM-YYYY')}</td>
                </tr>
            );
        });
    }

    render() {
        const {
            auditTrail,
            engagements,
            selectedEngagement
        } = this.state;

        return (
        	<div className="col-md-12">
	    		<div className="card">
	    			<div className="header">
	            		<h4 className="title" style={{ fontFamily: '"Poppins", sans-serif' }}>Audit Trail</h4>
			        </div>
			        <div className="content">
                        <select
                            className="form-control"
                            onChange={this.handleSelector}
                            name="selectedEngagement"
                            value={selectedEngagement}
                        >
                            <option value="">Select Engagement</option>
                            {engagements && engagements.map(({ _id, budget, client }) => {
                                const name = `${client.first_name} ${client.last_name} ${budget || ''}`;
                                return <option key={_id} value={_id}>{name}</option>;
                            })}
                        </select>
                        <div className="row">
                            <div className="modal-footer cntr col-xs-12 m-t-15">
                                {auditTrail && (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Action</th>
                                                <th>Property</th>
                                                <th>Previous Phase</th>
                                                <th>New Phase</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderAuditTrailTable()}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
			        </div>
	            </div>
    		</div>
		);
    }
}

const getColourForAction = action => {
    switch(action) {
        case "Attached":
            return 'green';
        
        case "Detached":
            return 'red';

        default:
            return 'initial';
    }
};

const mapStateToProps = (store) => {
    const user = store.user || {};
    return {user};
}

export default connect(mapStateToProps)(AuditTrailContainer);
