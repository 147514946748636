'use strict';
import React from 'react';
import {Link} from 'react-router';
import { browserHistory } from 'react-router';
import Modal from '../Modal';
import PresentedNotificationModal from './PresentedTimestampNotification';

export default function(){
    return NotificationComponentRender.call(this);
}

var NotificationComponentRender = function(){
    return (
        <div>
            {this.state.presentedNotification.length>0 && <Modal>
                <PresentedNotificationModal updatePresentedNotification={this.updatePresentedNotification.bind(this)} presentedList={this.state.presentedNotification} />
             </Modal>   
            }
        	<ul className="nav navbar-nav navbar-right">
                    { this.props.user.info ?
                    <li className="dropdown">
        	            <a className="dropdown-toggle btn-rotate cursor-position" data-toggle="dropdown">
        	                <i className="ti-bell"></i>
        	                <span className="notification">{this.state.notification && this.state.notification.length ? this.state.notification.length : 0}</span>
        	                <p className="hidden-md hidden-lg">
        	                    Notifications
        	                    <b className="caret"></b>
        	                </p>
        	            </a>
                        <div>
            	            <ul className="dropdown-menu" style={{width : '900px', height : '400px', overflow : 'auto'}}>
                                { this.state.notification && this.state.notification.length ? <li><div className="col-xs-12"><button className="btn btn-info btn-fill right m-a-5" onClick={this.updateAllNotification.bind(this)} >Clear All</button></div></li> : ''}
            	                { this.state.notification && this.state.notification.length ? this.state.notification.map((notify, index)=> {
            	                   return( 
                                        <li key={index}>
                                            <div className="col-xs-11">
                                                <p>{notify.text}</p>
                                            </div>
                                            <div className="col-xs-1" style={{top:'7px'}}>
                                                <i className="ti-close text-size right cur-pointer" onClick={this.updateNotificationStatus.bind(this, notify, index)}></i>
                                            </div>
                                        </li>
            	                )}) : <li><a className="cntr" href="#not1">No Notification</a></li> }
            	            </ul>
                        </div>
                    </li> : ''
                    }
                    <li className="dropdown">
                        <a className="dropdown-toggle btn-rotate" data-toggle="dropdown">
                            <i className="ti-user"></i>
                            <p className="hidden-md hidden-lg">
                                User
                                <b className="caret"></b>
                            </p>
                        </a>
                        <ul className="dropdown-menu">
                            <li><Link to="/home/change-password">Change Password</Link></li>
                            <li><Link to="/home/feedback">Feedback Form</Link></li>
                            <li><Link to="/home/feedback-list">Feedback List</Link></li>
                            <li><a onClick={this.logout}>Sign out</a></li>
                        </ul>
                    </li>
            </ul> 
        </div>
    )
};
