import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import "./MasterDashboard.css";

// Modules
import api from "../../common/api";
import { responseToTableValues } from "../../containers/tables/utils";

// Components
import AwaitingForTenantContainer from "../../containers/tables/AwaitingForTenantContainer";
import BaTablesContainer from "../../containers/tables/BATablesContainer";
import BlueprintsContainer from "../../containers/tables/BlueprintsContainer";
import ConditionalContainer from "../../containers/tables/ConditionalContainer";
import PrintButton from "../printElement/PrintButton";
import Spinner from "../../common/spinner";
import YetToSettleContainer from "../../containers/tables/YetToSettleContainer";

class MasterDashboard extends Component {
  state = { engagements: [], isLoading: false };

  componentDidMount() {
    this.setState({ isLoading: true });
    api.get("/api/engagements").then(engagements => {
      this.setState({
        isLoading: false,
        engagements: engagements.items.map(responseToTableValues)
      });
    });
  }

  render() {
    const groupedByBa = _.groupBy(this.state.engagements, "ba.props.ba._id");

    return (
      <div>
        <PrintButton />
        <div className="col-lg-12">
          <div className="content-panel">
            <ConditionalContainer />
          </div>
        </div>

        <div className="col-lg-12">
          <div className="content-panel blueprints-table">
            <BlueprintsContainer />
          </div>
        </div>

        <div className="col-lg-12">
          <div className="content-panel">
            <AwaitingForTenantContainer />
          </div>
        </div>

        <div className="col-lg-12">
          <div className="content-panel yet-to-settle-table">
            <YetToSettleContainer />
          </div>
        </div>

        {/* This component sits within a .row, so col-xs-12 nullifies that */}
        <div className="col-xs-12">
          <hr className="divider" />
        </div>

        {/* This component sits within a .row, so col-xs-12 nullifies that */}
        <div className="col-xs-12">
          <div className="row">
            {/* Assuming this.props.users is empty is semi-dangerous, as if anything
                goes wrong it will incorrectly convey that it is still loading */}
            {this.state.isLoading || _.isEmpty(this.props.users) ? (
              <div style={{ margin: "2em" }}>
                <Spinner />
              </div>
            ) : (
              <BaTablesContainer
                users={this.props.users}
                engagements={groupedByBa}
                businessDays={this.props.businessDays}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ users: state.buyers || [] , businessDays: state.businessDays|| {}});

export default connect(mapStateToProps)(MasterDashboard);
