import React, { Component } from 'react';
import _ from 'lodash';
import formatNumber from 'format-number';
import { Bar } from 'react-chartjs-2';

// Modules
import api from '../common/api';
import suaList from '../common/suburbToSua.json';
import { statusMap } from '../common/client-status-settings';
import { sortByBudget } from '../common/budget-tiers';
import EngagementPivot from '../components/metricsDashboard/EngagementPivot';
import PanelHeader from '../components/panelHeader/PanelHeader';

const getPWPPurchases = currentYearPurchases => {
    const counts = _.countBy(currentYearPurchases, 'client.client.type');
    const pwpCount = counts.PWP || 0;
    const baCount = counts.BA || 0;

    const total = pwpCount + baCount;

    const calculatePercentage = (total, count) => {
        const percentage = count / total;

        if (percentage === Infinity) return 0;
        return percentage;
    };
    
    return {
        total,
        pwpCount: {
            value: pwpCount,
            props: { percentage: calculatePercentage(total, pwpCount) }
        },
        baCount: {
            value: baCount,
            props: { percentage: calculatePercentage(total, baCount) }
        }
    };
};

const getSUAForProperty = property => {
    let postcode = "";
    const suburb = property.suburb || '';
    const formattedAddress = property.formatted_address || '';
    
    if (formattedAddress) {
        // Extract postcode
        const words = formattedAddress.split(" ");
        postcode = words[words.length - 2].replace(",", "");
    }

    // Find property's SUA
    // Returns 'undefined' if SUA cannot be found
    return !isNaN(postcode) ? 
    suaList.find(x => x.POST_CODE === postcode && x.LOCALITY.toLowerCase() === suburb.toLowerCase()) : 
    suaList.find(x => x.LOCALITY.toLowerCase() === suburb.toLowerCase() )
};

const getRegionalMapping = currentYearPurchases => {
    const suas = currentYearPurchases.map(getSUAForProperty);
    const suaCounts = _.countBy(suas, sua => sua ? sua.SUA_NAME : 'Non SUA');
    
    return _.transform(suaCounts, (result, value, key) => {
        const row = {
            sua: key,
            count: value,
            percentage: value * 100 / currentYearPurchases.length
        };
        result.push(row);
    }, []);
};

export default class MetricsContainer extends Component {
    state = {
        engagementTotals: {},
        engagementPivotTable: []
    };

    componentDidMount() {
        // Client Breakdown
        api.get('/api/clientPurchases/getCount')
            .then(engagementTotals => {
                const totals = engagementTotals.list
                    .reduce((acc, { _id: status, count }) => {
                        acc[status] = count;
                        return acc;
                    }, {});

                this.setState(prevState => {
                    const newTotals = Object.assign({}, prevState.engagementTotals, totals);
                    return { engagementTotals: newTotals };
                });
            });
            
        // Engagement Pivot Table
        const query = encodeURIComponent(JSON.stringify({ status: "GoodToGo" }));
        api.get(`/api/clientPurchases/clientPivotTable?filter=${query}`)
            .then(g2gEngagements => {
                // Tranform into a managable format
                const addBudget = g2gEngagements.purchases
                    .map(({ _id: budget, purchase }) => 
                        purchase.map(x => Object.assign(x, { budget })));

                const allEngagements = _.flatten(addBudget);

                const mergeStates = (acc, val) => {
                    // Normalize falsey values
                    if (!val.state) val.state = Boolean(val.state);

                    const found = _.find(acc, x => x.state === val.state);

                    if (found) {
                        found.engagements = found.engagements.concat(val.engagements);
                    } else {
                        // First occurrence of entry
                        acc.push(val);
                    }

                    return acc;
                };

                // Group and sort by state
                const states = _
                    .chain(allEngagements)
                    .groupBy('state')
                    .sortBy('[0].state')
                    .map(x => ({ state: x[0].state, engagements: x }))
                    .reduce(mergeStates, [])
                    .value();

                const defaultSectionName = "Unspecified";

                // Transform to state > budget > table (sorted by BA) 
                const statesWithBudgets = _.map(states, ({ state, engagements }) => {
                    // Group and sort state's engagments by budget
                    const budgets = _
                        .chain(engagements)
                        .groupBy('budget')
                        .sortBy(x => sortByBudget(x[0].budget))
                        .map(x => ({ budget: x[0].budget, engagements: x }))
                        .value();
                    
                    // Sort budget's engagements by BA nickname
                    const sortEngagements = _.map(budgets,
                        ({ budget, engagements }) => {
                            const sorted  = _.sortBy(engagements, 'ba.nickname');

                            const budgetObj = {
                                name: budget || defaultSectionName,
                                quantity: engagements.length
                            };

                            return { budget: budgetObj, engagements: sorted };
                        }
                    );

                    const stateObj = {
                        name: state || defaultSectionName,
                        quantity: _.sumBy(sortEngagements, 'engagements.length')
                    };
                    
                    return { state: stateObj, budgets: sortEngagements };
                });
                this.setState({ engagementPivotTable: statesWithBudgets });
            });
    }

    render() {
        const { engagementTotals, engagementPivotTable } = this.state;
        
        const clientBreakdownCols = [
            'Engaged',
            // 'Blueprint',
            'Incubation',
            'GoodToGo',
            'Conditional',
            'YetToSettle',
            'AwaitingTenant',
            'Hold'
        ];

        const clientBreakdownData = {
            labels: clientBreakdownCols.map(x => statusMap.get(x).displayName),
            datasets: [{
                backgroundColor: clientBreakdownCols.map(x => statusMap.get(x).colour),
                data: clientBreakdownCols.map(x => engagementTotals[x])
            }]
        };
        
        return (
            <div>
                <div className="col-xs-12">
                    <div className="content-panel">
                        <PanelHeader title="Engagement Pivot Table" />
                    
                        {engagementPivotTable.length ? <EngagementPivot data={engagementPivotTable} /> : ''}
                    </div>
                </div>

                <div className="col-xs-12">
                    <div className="content-panel">
                        <PanelHeader title="Client Breakdown" />
                        <div style={{ height: 200 }}>
                            <Bar 
                                data={clientBreakdownData}
                                options={{
                                    legend: { display: false },
                                    maintainAspectRatio: false
                                }}
                                height={50}
                                width={100}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
	}
}
